import { initializeApp } from 'firebase/app';
import {
    getStorage,
    ref,
    uploadBytes,
    getDownloadURL,
    deleteObject
} from 'firebase/storage';

const firebaseConfig = {
    apiKey: 'AIzaSyAHtpEDQtawKw59gKJDGMzsMlKU9lfCPH0',
    authDomain: 'farmgro-a01bb.firebaseapp.com',
    projectId: 'farmgro-a01bb',
    storageBucket: 'farmgro-a01bb.appspot.com',
    messagingSenderId: '164123035583',
    appId: '1:164123035583:web:5ebf57e7d5394a1cf4030a'
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

export { storage, ref, uploadBytes, getDownloadURL, deleteObject };
