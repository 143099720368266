import { IBlogCommentBody, IBlogCommentParams } from '../interfaces/Blog';
import { IAddCartBody, ICartParams, IUpdateCartBody } from '../interfaces/Cart';
import { ISaveContactBody } from '../interfaces/Contact';
import {
    IDeliveryAddressBody,
    IVerifyMobileBody
} from '../interfaces/DeliveryAddress';
import { IOfferParams } from '../interfaces/Offer';
import { IUserOrderParams } from '../interfaces/Order';
import { IInitiateOrderBody } from '../interfaces/Payment';
import {
    ICreateProductReviewBody,
    IProductParams,
    IProductReviewParams
} from '../interfaces/Product';
import {
    IEmailVerifyParams,
    ILogin,
    IPhoneVerifyParams,
    IResetPasswordBody,
    ISearchHistoryParams,
    ISignUp
} from '../interfaces/User';
import { IWalletStatementParams } from '../interfaces/Wallet';
import { IWishlistParams } from '../interfaces/Wishlist';
import { IAuthUser } from '../reducers/authReducers';
import { api } from './api';

let auth = {
    userLogin: (body: ILogin) => api.post('/auth/login', body),
    userRegister: (body: ISignUp) => api.post('/auth/register', body),
    verifyEmail: (body: IEmailVerifyParams) => api.post(`/verify/email`, body),
    verifyPhone: (body: IPhoneVerifyParams) => api.post('/verify/phone', body),
    forgotPassword: (email: string) => api.post('/password/forgot', { email }),
    resetPassword: (token: string, body: IResetPasswordBody) =>
        api.post(`/password/reset/${token}`, body),
    googleLogin: (accessToken: string) =>
        api.post('/social/auth/google/check/token', { accessToken }),
    facebookLogin: (accessToken: string) =>
        api.post('/social/auth/fb/check/token', { accessToken }),
    getSearchHistory: (params?: ISearchHistoryParams) =>
        api.get('/search/histories', { params }),
    otpVerification: (phone: string) => api.post('/auth/otp', { phone })
};

let user = {
    getProfile: () => api.get('/profile'),
    getUserOrders: (params?: IUserOrderParams) =>
        api.get('/orders/user', { params }),
    getOrderDetail: (id: string) => api.get(`/orders/${id}`),
    updateProfile: (body: IAuthUser) => api.patch(`/profile`, body),

    cancelOrder: (id: string) => api.patch(`/orders/cancel/${id}`),
    updateEmailRequest: (email: string) =>
        api.post('/profile/update/email/request', { email }),
    updateEmail: (email: string, verification_code: string) =>
        api.patch('/profile/update/email', { email, verification_code }),
    updatePhoneRequest: (phone: string) =>
        api.post('/profile/update/phone/request', { phone }),
    updatePhone: (phone: string, verification_code: string) =>
        api.patch('/profile/update/phone', { phone, verification_code })
};

let product = {
    getProductBySlug: (slug: string) => api.get(`/products/${slug}`),
    getProductReviews: (id: string, params: IProductReviewParams) =>
        api.get(`/products/review/product/${id}`, { params }),
    createProductReview: (body: ICreateProductReviewBody) =>
        api.post('/products/review/create', body),
    getProductTypes: () => api.get('/products/type'),
    getProductCategories: () => api.get('/products/category'),
    getProducts: (params?: IProductParams) => api.get('/products', { params }),
    getRandomProducts: (exclude?: string) =>
        api.get('/products/random', { params: { exclude } })
};

let cart = {
    getCartList: (params?: ICartParams) => api.get('/carts', { params }),
    addToCart: (body: IAddCartBody, params?: ICartParams) =>
        api.post('/carts', body, { params }),
    updateCart: (id: string, body: IUpdateCartBody) =>
        api.patch(`/carts/${id}`, body),
    deleteCart: (id: string) => api.delete(`/carts/${id}`),
    resetCart: () => api.delete('/carts/reset'),
    getWishList: (params?: IWishlistParams) =>
        api.get('/wish_list', { params }),
    addToWishList: (id: string) => api.post(`/wish_list/${id}`),
    deleteWishlist: (id: string) => api.delete(`/wish_list/${id}`),
    addWishlistToCart: () => api.get(`/carts/import/wishlist`)
};

let deliveryAddress = {
    getDeliveryAddress: () => api.get('/delivery_address'),
    saveDeliveryAddress: (body: IDeliveryAddressBody) =>
        api.post('/delivery_address', body),
    deleteAddress: (id: string) => api.delete(`/delivery_address/${id}`),
    updateAddress: (id: string, body: IDeliveryAddressBody) =>
        api.put(`/delivery_address/${id}`, body),
    sendOTP: (id: string) =>
        api.post('/delivery_address/send/otp', { address_id: id }),
    verifyMobile: (body: IVerifyMobileBody) =>
        api.post('/delivery_address/verify/mobile', body)
};

let address = {
    getProvinces: () => api.get('/provinces'),
    getDistricts: (province: string) =>
        api.get(`/provinces/district/${province}`),
    getRingroadAreas: () => api.get('/ringroad'),
    getAreasByDistrict: (id: string) => api.get(`/ringroad/district/${id}`)
};

let payment = {
    getDeliveryCharge: () => api.get('/charges'),
    initiateOrder: (body: IInitiateOrderBody) => api.post('/orders', body)
};

let coupon = {
    checkCouponValidity: (code: string, carts: string[]) =>
        api.post(`/coupons/check/validity`, { code, carts })
};
let newsfeed = {
    subscribeNewsfeed: (email: string) =>
        api.post('/newsfeed/subscribe', { email })
};

let contact = {
    getContactInfo: () => api.get('/contact_information'),
    saveContact: (body: ISaveContactBody) => api.post('/contact_query', body)
};

let pages = {
    getFAQ: () => api.get('/faqs'),
    getPageBySlug: (slug: string) => api.get(`/pages/${slug}`)
};

let valuedCustomers = {
    getValuedCustomers: () => api.get('/valued_customers')
};

let blogs = {
    getBlogs: () =>
        api.get('/blogs?resultsPerPage=18&sort=latest&status=active'),
    getBlogBySlug: (slug: string) => api.get(`/blogs/${slug}`),
    likeBlog: (blog: string) => api.post('/blogs/like', { blog }),
    dislikeBlog: (blog: string) => api.post('/blogs/dislike', { blog }),
    getBlogComments: (id: string, params?: IBlogCommentParams) =>
        api.get(`/blogs/comments/${id}`, { params }),
    postCommnent: (body: IBlogCommentBody) => api.post('/blogs/comment', body),
    getSimilarBlogs: (id: string) => api.get(`/blogs/similar/${id}`)
};

let testimonials = {
    getTestimonials: () =>
        api.get('/testimonials?resultsPerPage=18&sort=latest')
};

let banners = {
    getHomeBanners: () => api.get('/banners')
};

let offers = {
    getOffers: (params?: IOfferParams) => api.get('/offers', { params })
};

let wallet = {
    checkWalletValidity: (redeem_amount: number) =>
        api.post('/wallet/check/validity', { redeem_amount }),
    getWalletBalance: () => api.get('/wallet/auth/balance'),
    getWalletStatements: (params?: IWalletStatementParams) =>
        api.get('/wallet/auth/statements', { params })
};

let bill = {
    generateBill: (id: string) =>
        api.get(`/generate/invoice/${id}`, { responseType: 'blob' })
};

const request = {
    ...auth,
    ...user,
    ...product,
    ...cart,
    ...deliveryAddress,
    ...address,
    ...payment,
    ...coupon,
    ...newsfeed,
    ...contact,
    ...pages,
    ...valuedCustomers,
    ...blogs,
    ...testimonials,
    ...banners,
    ...offers,
    ...wallet,
    ...bill
};

export default request;
