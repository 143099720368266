import { Button, Card, Divider, Image, Space, Steps, Tag } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useState } from 'react';
import {
    FiClipboard,
    FiHelpCircle,
    FiPackage,
    FiPhoneCall
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { defaultImage } from '../../../../../helpers/constants';
import request from '../../../../../helpers/request';
import { IOrder, OrderStatusType } from '../../../../../interfaces/Order';
import { showToast } from '../../../../../utils/toast';
import AppButton from '../../../shared/AppButton';
import OrderPopover from './OrderPopover';

interface IProps {
    order: IOrder;
    onCancel?: () => void;
    mobile?: boolean;
}

const deliverySteps: OrderStatusType[] = [
    'Placed',
    'Approved',
    'Packed',
    'On the way',
    'Delivered'
];

const { Step } = Steps;

const OrderCard = ({ order, onCancel, mobile }: IProps) => {
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

    const [cancelLoading, setCancelLoading] = useState(false);

    const handleOk = () => {
        if (order._id) {
            setCancelLoading(true);

            request
                .cancelOrder(order._id)
                .then(() => {
                    onCancel && onCancel();

                    setIsCancelModalVisible(false);

                    setCancelLoading(false);

                    showToast('success', 'Your order has been cancelled');
                })
                .catch((err: AxiosError) => {
                    showToast(err.response?.data?.message);

                    setCancelLoading(false);
                });
        }
    };

    const handleCancel = () => {
        setIsCancelModalVisible(false);
    };
    const showCancelModal = () => {
        setIsCancelModalVisible(true);
    };

    return (
        <>
            {!mobile ? (
                <Card
                    title={
                        <span>
                            Order ID : #{order.identifier}
                            {order.refunded ? (
                                <Tag color="red" className="ms-4 rounded-pill">
                                    Refunded
                                </Tag>
                            ) : order.payment_type === 'Online Payment' &&
                              order.status === 'Cancelled' ? (
                                <Tag color="red" className="ms-4 rounded-pill">
                                    Refund Requested
                                </Tag>
                            ) : (
                                ''
                            )}
                        </span>
                    }
                    className="text-montserrat text-16 text-dark-color"
                    bordered={false}
                    headStyle={{
                        color: '#1C1E1C',
                        fontWeight: 600
                    }}
                    extra={
                        <span className="text-montserrat text-14 text-gray-2">
                            <FiPackage size={20} className="me-2" />
                            Delivery Timing:{' '}
                            {dayjs(order.delivery_date).format('MMM D')},{' '}
                            {order.delivery_time}
                        </span>
                    }
                >
                    <div className="d-flex pb-4">
                        <Image
                            src={
                                order.carts?.length &&
                                order.carts[0].cart?.product?.images?.length
                                    ? order.carts[0].cart?.product?.images[0]
                                    : defaultImage
                            }
                            alt={order.user?.name}
                            preview={false}
                            width={96}
                            height={100}
                            className="rounded object-cover"
                            wrapperClassName="me-3"
                        />

                        <div className="flex-grow-1 d-flex flex-column">
                            <div className="flex-grow-1">
                                <span className="text-inter text-500 text-16 text-dark">
                                    Order from {order.user?.name} -{' '}
                                    {order.delivery_address?.address?.area}
                                </span>

                                <br />

                                <span className="text-montserrat text-14 text-gray-2">
                                    Ordered Date:{' '}
                                    {dayjs(order.createdAt).format(
                                        'MMM D, YYYY'
                                    )}
                                </span>
                            </div>

                            <span className="text-inter text-500 text-16 text-dark">
                                {order.carts?.length} Item
                                {(order.carts?.length ?? 0) > 1 && 's'}
                                {!!order.carts?.length && (
                                    <OrderPopover carts={order.carts}>
                                        <FiHelpCircle
                                            className="ms-2 text-gray-2 text-hover-orange cursor-pointer"
                                            size={14}
                                        />
                                    </OrderPopover>
                                )}
                            </span>
                        </div>
                    </div>

                    <Divider className="m-0" />

                    <div className="row py-4">
                        <div className="col-4">
                            <span className="text-montserrat text-gray-3 text-600 text-16">
                                Sub Total
                            </span>
                            <br />
                            <span className="text-montserrat text-gray-2 text-16">
                                Delivery Charge
                            </span>
                            <br />
                            {!!order.coupon_discount_amount && (
                                <>
                                    <span className="text-montserrat text-gray-2 text-16">
                                        Promo Code Discount
                                    </span>
                                    <br />
                                </>
                            )}
                            {!!order.wallet_adjustment && (
                                <>
                                    <span className="text-montserrat text-gray-2 text-16">
                                        Redeem Amount
                                    </span>
                                    <br />
                                </>
                            )}

                            <span className="dark-bold-font">Grand Total</span>
                        </div>
                        <div className="col-8 amounts">
                            <span className="text-montserrat text-gray-3 text-600 text-16">
                                Rs. {order.sub_total}
                            </span>
                            <br />
                            <span className="text-montserrat text-gray-2 text-16">
                                Rs. {order.delivery_fee}
                            </span>
                            <br />

                            {!!order.coupon_discount_amount && (
                                <>
                                    <span className="text-montserrat text-gray-2 text-16">
                                        Rs. {order.coupon_discount_amount}
                                    </span>
                                    <br />
                                </>
                            )}

                            {!!order.wallet_adjustment && (
                                <>
                                    <span className="text-montserrat text-gray-2 text-16">
                                        Rs. {order.wallet_adjustment}
                                    </span>
                                    <br />
                                </>
                            )}

                            <span
                                className="dark-bold-font"
                                style={{ float: 'right' }}
                            >
                                Rs. {order.total_amount}
                            </span>
                        </div>
                    </div>

                    <Divider className="m-0" />

                    <div className="row pt-4">
                        <div className="col-12">
                            {order.status === 'Cancelled' ? (
                                <p className="text-montserrat text-dark text-600 text-16 mb-0 text-end">
                                    Cancelled
                                </p>
                            ) : (
                                <>
                                    <h6 className="dark-thin-font ">
                                        Track Order
                                    </h6>

                                    <Steps
                                        progressDot
                                        current={
                                            !!order.status
                                                ? deliverySteps.indexOf(
                                                      order.status
                                                  )
                                                : undefined
                                        }
                                    >
                                        {deliverySteps.map((step, index) => (
                                            <Step title={step} key={index} />
                                        ))}
                                    </Steps>
                                </>
                            )}
                        </div>
                    </div>

                    {order.status !== 'Cancelled' && (
                        <>
                            <Divider className="m-0 mt-4" />

                            <div
                                className="row pt-4"
                                style={{ borderBottom: 'none' }}
                            >
                                <div className="col-6">
                                    {!!order.delivery_associate?.phone && (
                                        <div>
                                            <span className="text-roboto text-14 text-dark-color">
                                                Delivery Person :{' '}
                                                <span className="text-gray-2">
                                                    {
                                                        order.delivery_associate
                                                            .name
                                                    }
                                                </span>
                                            </span>

                                            <br />
                                            <a
                                                href={`tel:${order.delivery_associate.phone}`}
                                                className="text-orange cursor-pointer text-14"
                                            >
                                                <FiPhoneCall />{' '}
                                                {order.delivery_associate.phone}
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <div className="col-6">
                                    <Space style={{ float: 'right' }}>
                                        {order.status !== 'Delivered' && (
                                            <Button
                                                type="link"
                                                htmlType="button"
                                                onClick={showCancelModal}
                                            >
                                                Cancel Order
                                            </Button>
                                        )}

                                        <Link to={`/order/${order._id}`}>
                                            <AppButton
                                                type="primary"
                                                htmlType="button"
                                                size="sm"
                                                className="px-4 d-flex align-items-center text-400"
                                            >
                                                <FiClipboard
                                                    style={{
                                                        marginRight: '5px'
                                                    }}
                                                    size={18}
                                                />
                                                View Bill
                                            </AppButton>
                                        </Link>
                                    </Space>
                                </div>
                            </div>
                        </>
                    )}
                </Card>
            ) : (
                <div
                    className="py-3"
                    style={{ borderBottom: '1px solid #E2E2E2' }}
                >
                    <div className="mb-2">
                        <div className="mb-1">
                            {order.refunded ? (
                                <Tag color="red" className="rounded-pill">
                                    Refunded
                                </Tag>
                            ) : order.payment_type === 'Online Payment' &&
                              order.status === 'Cancelled' ? (
                                <Tag color="red" className="rounded-pill">
                                    Refund Requested
                                </Tag>
                            ) : (
                                ''
                            )}
                        </div>

                        <p className="text-montserrat text-16 text-600 text-dark-color mb-0">
                            Order ID : #{order.identifier}
                        </p>

                        <p className="text-montserrat text-12 text-400 text-gray-3 mb-0">
                            Delivery Timing:{' '}
                            {dayjs(order.delivery_date).format('MMM D')},{' '}
                            {order.delivery_time}
                        </p>
                    </div>

                    <div className="d-flex">
                        <Image
                            src={
                                order.carts?.length &&
                                order.carts[0].cart?.product?.images?.length
                                    ? order.carts[0].cart?.product?.images[0]
                                    : defaultImage
                            }
                            alt={order.user?.name}
                            preview={false}
                            width={96}
                            height={100}
                            className="rounded object-cover"
                            wrapperClassName="me-3"
                        />

                        <div className="flex-grow-1 d-flex flex-column">
                            <div className="flex-grow-1">
                                <span className="text-montserrat text-600 text-16 text-dark-color">
                                    Order from {order.user?.name} -{' '}
                                    {order.delivery_address?.address?.area}
                                </span>

                                <br />

                                <span className="text-montserrat text-14 text-gray-2">
                                    Ordered Date:{' '}
                                    {dayjs(order.createdAt).format(
                                        'MMM D, YYYY'
                                    )}
                                </span>
                            </div>

                            <span className="text-inter text-500 text-16 text-dark">
                                {order.carts?.length} Item
                                {(order.carts?.length ?? 0) > 1 && 's'}
                                {!!order.carts?.length && (
                                    <OrderPopover carts={order.carts}>
                                        <FiHelpCircle
                                            className="ms-2 text-gray-2 text-hover-orange cursor-pointer"
                                            size={14}
                                        />
                                    </OrderPopover>
                                )}
                            </span>

                            <div className="mt-4 d-flex justify-content-end align-items-center">
                                {order.status !== 'Delivered' && (
                                    <Button
                                        disabled={order.status === 'Cancelled'}
                                        type="link"
                                        htmlType="button"
                                        onClick={showCancelModal}
                                        className="text-montserrat text-12 text-gray-3 px-0 me-3"
                                    >
                                        {order.status === 'Cancelled'
                                            ? 'Cancelled'
                                            : 'Cancel Order'}
                                    </Button>
                                )}

                                <Link to={`/order/${order._id}`}>
                                    <AppButton
                                        type="primary"
                                        htmlType="button"
                                        size="sm"
                                        className="px-4 d-flex align-items-center text-400"
                                    >
                                        <FiClipboard
                                            style={{
                                                marginRight: '5px'
                                            }}
                                            size={18}
                                        />
                                        View Bill
                                    </AppButton>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Modal
                visible={isCancelModalVisible}
                onOk={handleOk}
                confirmLoading={cancelLoading}
                onCancel={handleCancel}
                okText="Yes! Cancel it"
                cancelText="No! Don’t Cancel"
                cancelButtonProps={{
                    style: {
                        border: '1px solid #F47B34',
                        color: '#F47B34',
                        width: '208px',
                        height: '55px',
                        borderRadius: '40px',
                        margin: '10px'
                    }
                }}
                okButtonProps={{
                    style: {
                        border: '1px solid #F47B34',
                        color: '#fff',
                        width: '208px',
                        height: '55px',
                        borderRadius: '40px',
                        margin: '10px 0'
                    }
                }}
            >
                <p>Are you sure you want to cancel this order?</p>
            </Modal>
        </>
    );
};

export default OrderCard;
