import {
    GET_BANNERS,
    GET_NAV_MEAT_MARKET,
    GET_NAV_SEASONAL_EXOTIC,
    GET_NAV_VEGETABLES,
    GET_VALUED_CUSTOMERS,
    GET_HOME_OFFERS,
    GET_HOME_VEGETABLES,
    GET_HOME_MEAT_PRODUCTS,
    GET_HOME_FRUITS,
    GET_HOME_DAIRY_PRODUCTS,
    GET_HOME_MISCELLANEOUS_PRODUCTS,
    GET_FOOTER_FRUITS_VEGETABLES,
    GET_FOOTER_DAIRY,
    GET_FOOTER_MEAT,
    GET_FOOTER_MISC
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IBanner } from '../interfaces/Banner';
import { ICustomer } from '../interfaces/Customer';
import { IProduct } from '../interfaces/Product';
import { IOffer } from '../interfaces/Offer';

export interface IHomeState {
    customers: ICustomer[];
    banners: IBanner[];
    navVegetables: IProduct[];
    navMeatMarket: IProduct[];
    navSeasonalExotic: IProduct[];
    offers: IOffer[];
    homeVegetables: IProduct[];
    homeMeatProducts: IProduct[];
    homeFruits: IProduct[];
    homeDairyProducts: IProduct[];
    homeMiscellaneousProducts: IProduct[];
    footerFruitsVeg: IProduct[];
    footerDairy: IProduct[];
    footerMeat: IProduct[];
    footerMisc: IProduct[];
}

const initialState: IHomeState = {
    customers: [],
    banners: [],
    navVegetables: [],
    navMeatMarket: [],
    navSeasonalExotic: [],
    offers: [],
    homeVegetables: [],
    homeMeatProducts: [],
    homeFruits: [],
    homeDairyProducts: [],
    homeMiscellaneousProducts: [],
    footerFruitsVeg: [],
    footerDairy: [],
    footerMeat: [],
    footerMisc: []
};

const homeReducer = (state = initialState, action: ActionType): IHomeState => {
    switch (action.type) {
        case GET_VALUED_CUSTOMERS:
            return {
                ...state,
                customers: action.payload
            };

        case GET_BANNERS:
            return {
                ...state,
                banners: action.payload
            };

        case GET_NAV_VEGETABLES:
            return {
                ...state,
                navVegetables: action.payload
            };

        case GET_NAV_MEAT_MARKET:
            return {
                ...state,
                navMeatMarket: action.payload
            };

        case GET_NAV_SEASONAL_EXOTIC:
            return {
                ...state,
                navSeasonalExotic: action.payload
            };

        case GET_HOME_OFFERS:
            return {
                ...state,
                offers: action.payload
            };

        case GET_HOME_VEGETABLES:
            return {
                ...state,
                homeVegetables: action.payload
            };

        case GET_HOME_MEAT_PRODUCTS:
            return {
                ...state,
                homeMeatProducts: action.payload
            };

        case GET_HOME_FRUITS:
            return {
                ...state,
                homeFruits: action.payload
            };

        case GET_HOME_DAIRY_PRODUCTS:
            return {
                ...state,
                homeDairyProducts: action.payload
            };

        case GET_HOME_MISCELLANEOUS_PRODUCTS:
            return {
                ...state,
                homeMiscellaneousProducts: action.payload
            };

        case GET_FOOTER_FRUITS_VEGETABLES:
            return {
                ...state,
                footerFruitsVeg: action.payload
            };

        case GET_FOOTER_DAIRY:
            return {
                ...state,
                footerDairy: action.payload
            };

        case GET_FOOTER_MEAT:
            return {
                ...state,
                footerMeat: action.payload
            };

        case GET_FOOTER_MISC:
            return {
                ...state,
                footerMisc: action.payload
            };

        default:
            return state;
    }
};

export default homeReducer;
