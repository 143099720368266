import FileResizer from 'react-image-file-resizer';
import {
    storage,
    ref,
    uploadBytes,
    getDownloadURL,
    deleteObject
} from '../helpers/firebase';

export const getExtension = (filename: string) => filename.split('.').pop();

export const resizeFile = (file: File) =>
    new Promise((resolve) => {
        FileResizer.imageFileResizer(
            file,
            512,
            512,
            'JPEG',
            80,
            0,
            (uri) => {
                resolve(uri);
            },
            'file'
        );
    });

export const deleteImageFromURL = (profile: string) => {
    let oldImage = ref(storage, profile);

    deleteObject(oldImage)
        .then(() => {})
        .catch(() => {});
};

export const firebaseImageUpload = (image: File, profile?: string) =>
    new Promise<string>((resolve, reject) => {
        resizeFile(image)
            .then((newImg) => {
                let resizedImg = newImg as File;

                const imageName =
                    Date.now().toString() + '.' + getExtension(resizedImg.name);

                const newImage = new File([resizedImg], imageName, {
                    type: resizedImg.type
                });

                const profileImageRef = ref(storage, `/profile/${imageName}`);

                uploadBytes(profileImageRef, newImage)
                    .then(() => {
                        getDownloadURL(profileImageRef)
                            .then((fileUrl) => {
                                resolve(fileUrl);
                            })
                            .catch(() => {
                                reject();
                            });
                    })
                    .catch(() => {
                        reject();
                    });
            })

            .catch(() => {
                reject();
            })
            .finally(() => {
                if (profile) deleteImageFromURL(profile);
            });
    });

export const getInitials = (text?: string) =>
    text
        ?.match(/(\b\S)?/g)
        ?.join('')
        .match(/(^\S|\S$)?/g)
        ?.join('')
        ?.toUpperCase() ?? '';

export const isOfType = <T>(varToBeChecked: any): varToBeChecked is T =>
    (varToBeChecked as T) !== undefined;
