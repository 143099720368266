import { Avatar } from 'antd';
import React from 'react';
import bannerImg from '../../../assets/images/myPanel/banner.png';
import queryString from 'query-string';
import { $FIXME } from '../../../helpers/constants';
import { useHistory, useLocation } from 'react-router-dom';
import AppButton from '../shared/AppButton';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import { useMediaQuery } from 'react-responsive';
import { getInitials } from '../../../utils/functions';

const Banner: React.FC<any> = () => {
    const { user } = useSelector((state: StateType) => state.user);

    const location = useLocation();

    const history = useHistory();

    const query: $FIXME = queryString.parse(location.search);

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${bannerImg})`
                }}
                className="panel-banner-container"
            >
                <div className="avatar-item-profile">
                    <Avatar
                        src={user.profile}
                        size={isDesktopOrLaptop ? 85 : 50}
                        style={{ border: '2px solid #f47b34' }}
                        className="bg-orange"
                    >
                        {getInitials(user.name)}
                    </Avatar>

                    <br />
                    <p className="avatar-name">{user.name}</p>
                    <p className="avatar-contact mb-0">{user.phone}</p>

                    {isDesktopOrLaptop && 'profile' in query && (
                        <AppButton
                            size="sm"
                            className="px-4 mt-3"
                            type="primary-outlined"
                            onClick={() => {
                                history.push({
                                    pathname: '/dashboard',
                                    search: 'profile=edit'
                                });
                            }}
                        >
                            Edit Profile
                        </AppButton>
                    )}
                </div>
            </div>
        </>
    );
};

export default Banner;
