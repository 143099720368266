import Logo from '../../../assets/images/logo.png';

const Splash = () => {
    return (
        <div style={{ height: '100vh' }} className="d-flex align-items-center">
            <img
                src={Logo}
                width={169}
                className="mx-auto animated pulse"
                alt="logo"
            />
        </div>
    );
};

export default Splash;
