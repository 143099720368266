import { useSelector } from 'react-redux';
import { defaultImage, meatMarketTypeId } from '../../../helpers/constants';
import { StateType } from '../../../reducers';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import SimpleBar from 'simplebar-react';
import { IType } from '../../../interfaces/Product';
import { FiArrowRight } from 'react-icons/fi';
import { useRef } from 'react';
import { useHover } from 'usehooks-ts';

interface IProps {
    category: string;
}

const Categories = ({ category }: IProps) => {
    const { productTypes } = useSelector((state: StateType) => state.category);

    return (
        <div
            className={`app-container features-container ${category} mb-lg-n5`}
        >
            <p className="landing-title mb-3 mb-lg-4">Explore by categories</p>

            <SimpleBar className="category-wrapper">
                {productTypes.map((type, index) =>
                    type.parent_id === null ? <CategoryCard type={type} /> : ''
                )}
            </SimpleBar>
        </div>
    );
};

export default Categories;

interface ICategoryCardProps {
    type: IType;
}

const CategoryCard = ({ type }: ICategoryCardProps) => {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const isMobile = useMediaQuery({ maxWidth: 576 });

    const history = useHistory();

    const hoverRef = useRef<HTMLDivElement>(null);

    const isHover = useHover(hoverRef);

    return (
        <div className="w-100 px-1 px-lg-2" ref={hoverRef}>
            <div
                className="categories-card py-2 px-1 px-md-2 px-lg-3 mb-lg-5 d-flex flex-column align-items-center align-items-lg-stretch justify-content-center justify-content-lg-between cursor-pointer"
                onClick={() =>
                    history.push(
                        `/products?${
                            type._id === meatMarketTypeId
                                ? 'meatMarket=true'
                                : `type=${type._id}`
                        }`
                    )
                }
            >
                <div className="order-lg-first order-last">
                    <p className="mb-0 card-title">{type.name}</p>
                </div>

                <div className="d-flex justify-content-between">
                    {isDesktopOrLaptop && (
                        <div className="d-flex align-items-center align-self-end">
                            {isHover && (
                                <>
                                    <p className="see-all-text mb-0">See all</p>

                                    <FiArrowRight className="see-all-text ms-1" />
                                </>
                            )}
                        </div>
                    )}

                    <img
                        className="mb-1 mb-lg-0"
                        src={!!type.image ? type.image : defaultImage}
                        height={isDesktopOrLaptop ? 85 : isMobile ? 45 : 60}
                        width={isDesktopOrLaptop ? 85 : isMobile ? 45 : 60}
                        alt="category"
                    />
                </div>
            </div>
        </div>
    );
};
