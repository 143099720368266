import { Breadcrumb, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { BiCheck, BiChevronLeft, BiLockAlt } from 'react-icons/bi';
import { RiLoader2Line } from 'react-icons/ri';
import { IoMdClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setMinOrderValid } from '../../actions/cartActions';
import { StateType } from '../../reducers';
import AppButton from '../components/shared/AppButton';
import CheckoutSummary from '../screens/checkout/CheckoutSummary';
import MainLayout from './MainLayout';
import { $FIXME } from '../../helpers/constants';
import queryString from 'query-string';
import { getDeliveryCharge } from '../../actions/paymentActions';
import { getRandomProducts } from '../../actions/productActions';
import ProductSlider from '../components/shared/slider/Slider';
import { useMediaQuery } from 'react-responsive';
import SimpleBar from 'simplebar-react';
import Card from '../components/shared/Card';
import { toggleDrawer } from '../../actions/uiActions';

interface IProps {
    children: React.ReactNode;
    backgroundColor?: string;
    step: number;
    goToStep: (step: number) => void;
}

const CheckoutLayout = (props: IProps) => {
    const { saveDeliverySuccess, selectedDeliveryAddress } = useSelector(
        (state: StateType) => state.deliveryAddress
    );

    const location = useLocation();

    const query: $FIXME = queryString.parse(location.search);

    const isBuyNow = 'buy-now' in query;

    const [minOrderOpen, setMinOrderOpen] = useState(false);

    const { deliveryCharge } = useSelector((state: StateType) => state.payment);

    const { randomProducts } = useSelector((state: StateType) => state.product);

    const cart = useSelector((state: StateType) => state.cart);

    const { isMinOrderValid, sub_total, cartDetail } = cart;

    const dispatch = useDispatch();

    const steps = [
        'Delivery Details',
        'Mobile Verification',
        'Delivery Date & Time',
        'Payement Options'
    ];

    useEffect(() => {
        dispatch(getDeliveryCharge());
        dispatch(getRandomProducts());
    }, [dispatch]);

    useEffect(() => {
        if (!isMinOrderValid) {
            setMinOrderOpen(true);
        }
    }, [isMinOrderValid]);

    useEffect(() => {
        if (
            !!deliveryCharge.min_order_amt?.inside_ringroad &&
            !!deliveryCharge.min_order_amt.outside_ringroad &&
            ((selectedDeliveryAddress?.inside_ringroad === true &&
                (isBuyNow ? cartDetail.sub_total : sub_total) >=
                    deliveryCharge.min_order_amt.inside_ringroad) ||
                ((!selectedDeliveryAddress ||
                    selectedDeliveryAddress?.inside_ringroad === false) &&
                    (isBuyNow ? cartDetail.sub_total : sub_total) >=
                        deliveryCharge.min_order_amt.outside_ringroad))
        ) {
            dispatch(setMinOrderValid(true));
        } else {
            dispatch(setMinOrderValid(false));
        }
    }, [
        cartDetail.sub_total,
        isBuyNow,
        sub_total,
        deliveryCharge.min_order_amt,
        selectedDeliveryAddress,
        dispatch
    ]);

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const isMd = useMediaQuery({ minWidth: 768 });

    return (
        <MainLayout hideFooter={!isMd} backgroundColor="#f9f9f9">
            {isDesktopOrLaptop ? (
                <div className="bg-white mb-4">
                    <Breadcrumb className="app-container app-breadcrumbs text-montserrat py-2 bg-white ">
                        <Breadcrumb.Item>
                            <Link to="/" className="text-montserrat">
                                Home
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item className="text-orange">
                            Checkout
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            ) : (
                <div className="bg-gray">
                    <div className="py-3 app-container">
                        <Link
                            to="/cart"
                            className="text-600 text-14 text-montserrat text-black"
                        >
                            <BiChevronLeft size={24} className="me-2 ms-n2" />
                            Checkout
                        </Link>
                    </div>
                </div>
            )}

            {!isMinOrderValid && minOrderOpen && (
                <div className="bg-gray pb-4">
                    <div className="app-container">
                        <div className="fade-in checkout-min-container bg-white px-3 px-md-4 py-3 d-flex align-items-center rounded position-relative">
                            <p
                                className={`mb-0 text-montserrat text-400 text-meat-dark flex-grow-1 ${
                                    isMd ? 'text-16' : 'text-14'
                                }`}
                            >
                                Minimum order of Rs.
                                {selectedDeliveryAddress?.inside_ringroad
                                    ? deliveryCharge.min_order_amt
                                          ?.inside_ringroad
                                    : deliveryCharge.min_order_amt
                                          ?.outside_ringroad}{' '}
                                required.
                            </p>

                            <AppButton
                                size="sm"
                                type="primary-outlined"
                                className="px-4"
                                onClick={() => dispatch(toggleDrawer(true))}
                            >
                                View Cart
                            </AppButton>

                            <div
                                className="min-order-close bg-light-gray rounded-circle cursor-pointer"
                                onClick={() => {
                                    setMinOrderOpen(false);
                                }}
                            >
                                <IoMdClose size={14} className="text-dark" />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {props.step === 1 && saveDeliverySuccess && (
                <div className="bg-gray">
                    <div className="app-container pb-4">
                        <div className="fade-in checkout-new-container bg-white px-3 px-md-4 py-3 d-flex align-items-center rounded">
                            <p
                                className={`mb-0 text-montserrat text-600 text-black flex-grow-1 ${
                                    isMd ? 'text-16' : 'text-14'
                                }`}
                            >
                                New delivery address has been added to “
                                {saveDeliverySuccess}”. You can manage addresses
                                anytime from settings.
                            </p>

                            <AppButton size="sm">Manage Address</AppButton>
                        </div>
                    </div>
                </div>
            )}

            <div className="bg-gray pb-5 pb-md-0">
                <div className="app-container pb-5">
                    <Row gutter={20}>
                        <Col xs={24} md={14} lg={17}>
                            <div
                                className={`rounded mb-3 ${
                                    isMd ? 'bg-white' : ''
                                }`}
                            >
                                <div className="py-3 px-0 px-md-2 px-lg-4">
                                    <Row>
                                        {steps.map((label, index) => {
                                            const step = index + 1;

                                            return (
                                                <Col span={6}>
                                                    <div
                                                        className={`px-2 d-flex flex-column flex-lg-row align-items-center ${
                                                            step < props.step &&
                                                            step !== 2
                                                                ? 'cursor-pointer'
                                                                : ''
                                                        }`}
                                                        onClick={() => {
                                                            if (
                                                                step <
                                                                    props.step &&
                                                                step !== 2
                                                            ) {
                                                                props.goToStep(
                                                                    step
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            className={`${
                                                                props.step ===
                                                                step
                                                                    ? 'step-icon-current-wrapper'
                                                                    : ''
                                                            } me-lg-2 mb-2`}
                                                        >
                                                            <div
                                                                className={`step-icon ${
                                                                    step <
                                                                    props.step
                                                                        ? 'step-icon-done'
                                                                        : step ===
                                                                          props.step
                                                                        ? 'step-icon-current'
                                                                        : 'step-icon-pending'
                                                                }`}
                                                            >
                                                                {step <
                                                                props.step ? (
                                                                    <BiCheck
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                ) : step ===
                                                                  props.step ? (
                                                                    <BiLockAlt
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <RiLoader2Line
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="d-flex flex-column align-items-center align-items-lg-start">
                                                            <p className="text-roboto text-10 text-gray-6 mb-0">
                                                                STEP {step}
                                                            </p>

                                                            <h2
                                                                className={`text-center text-roboto text-14 mb-0 ${
                                                                    step >
                                                                    props.step
                                                                        ? 'text-gray-7'
                                                                        : 'text-black'
                                                                }`}
                                                            >
                                                                {label}
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </div>

                                <hr className="text-border p-0 m-0" />

                                {props.children}
                            </div>
                        </Col>

                        <Col xs={24} md={10} lg={7}>
                            <CheckoutSummary
                                cart={isBuyNow ? cartDetail : cart}
                                isBuyNow={isBuyNow}
                            />
                        </Col>
                    </Row>

                    {isMd && !!randomProducts.length && (
                        <div className="product-container-wrapper">
                            <div className="product-header d-flex justify-content-between align-items-center mb-3">
                                <p className="landing-title mb-0">
                                    Similar Products
                                </p>
                            </div>

                            {isDesktopOrLaptop ? (
                                <div className="my-2 dariy-slider-section">
                                    <ProductSlider products={randomProducts} />
                                </div>
                            ) : (
                                <SimpleBar className="mx-n3 product-card-wrapper">
                                    {randomProducts.map((product, index) => {
                                        return (
                                            <Card
                                                disableHover={
                                                    !isDesktopOrLaptop
                                                }
                                                key={index}
                                                product={product}
                                                style={{
                                                    minWidth: 200,
                                                    width: 200
                                                }}
                                            />
                                        );
                                    })}
                                </SimpleBar>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </MainLayout>
    );
};

export default CheckoutLayout;
