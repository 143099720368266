import { ReactNode } from 'react';

interface IProps {
    children: ReactNode;
}

const Error = (props: IProps) => {
    return <p className="text-danger mb-2 mt-n2 text-12">{props.children}</p>;
};

export default Error;
