import React, { useEffect, useRef, useState } from 'react';
import { Image, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import {
    createProductReview,
    getProductBySlug,
    getProductReviews,
    resetProductReviews
} from '../../../actions/productActions';
import { ICreateProductReviewBody } from '../../../interfaces/Product';
import { defaultImage, meatMarketTypeId } from '../../../helpers/constants';
import dayjs from 'dayjs';
import AppButton from '../shared/AppButton';
import { useMediaQuery } from 'react-responsive';
import { useElementSize } from 'usehooks-ts';
import {
    Carrot,
    CarrotEmpty,
    ChickenEmpty,
    Chicken
} from '../../../assets/images/icons';

const { TabPane } = Tabs;

function callback(key: any) {
    console.log(key);
}

interface IProps {}

const ProductDetail = (props: IProps) => {
    const { product, reviews } = useSelector(
        (state: StateType) => state.product
    );

    const [myReview, setMyReview] = useState<ICreateProductReviewBody>({});

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(resetProductReviews());

        if (product._id) {
            dispatch(getProductReviews(product._id, {}));
        }
    }, [dispatch, product._id]);

    const handleRating = (value: number) => {
        setMyReview({
            ...myReview,
            rating: value.toString()
        });
    };

    const handleReviewChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMyReview({
            ...myReview,
            review: e.target.value
        });
    };

    const handleReviewSubmit = () => {
        dispatch(
            createProductReview({ ...myReview, product_id: product._id })
        ).then(() => {
            if (product._id) {
                dispatch(resetProductReviews());
                dispatch(getProductReviews(product._id, {}));

                setMyReview({ rating: undefined, review: '' });
            }

            if (product.slug) {
                dispatch(getProductBySlug(product.slug));
            }
        });
    };

    const descRef = useRef<HTMLDivElement>(null);

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const isMobile = useMediaQuery({ maxWidth: 576 });

    const [squareRef, { height }] = useElementSize();

    const [readMore, setReadMore] = useState(false);

    const isMeat =
        product.type?._id === meatMarketTypeId ||
        product.type?.parent_id?._id === meatMarketTypeId;

    return (
        <div
            className={`product-detail-inner ${
                isMeat ? 'product-detail-inner-meat' : ''
            }`}
        >
            <Tabs defaultActiveKey="1" onChange={callback} size={'large'}>
                <TabPane tab="Product Detail" key="1">
                    <div className="mb-4 mb-lg-5">
                        <div
                            ref={descRef}
                            className="position-relative"
                            style={
                                !isDesktopOrLaptop && !readMore
                                    ? {
                                          maxHeight: 160,
                                          overflow: 'hidden',
                                          position: 'relative'
                                      }
                                    : {}
                            }
                        >
                            <div
                                ref={squareRef}
                                className="product-description"
                                dangerouslySetInnerHTML={{
                                    __html: product.detail ?? ''
                                }}
                            />
                            {!isDesktopOrLaptop &&
                                height > 160 &&
                                !readMore && (
                                    <div className="product-description-mask" />
                                )}
                        </div>

                        {!isDesktopOrLaptop && (height > 160 || readMore) && (
                            <p
                                className="text-700 text-montserrat text-14 cursor-pointer mt-2 mb-0"
                                onClick={() => {
                                    if (readMore) {
                                        descRef.current?.scrollIntoView();
                                    }
                                    setReadMore(!readMore);
                                }}
                            >
                                {readMore ? 'Read Less' : 'Read More'}
                            </p>
                        )}
                    </div>

                    <div className="product-tags mb-5">
                        <h2>Tags</h2>
                        <ul className="mb-n2">
                            {product.tags?.split(',')?.map((tag, index) => (
                                <li key={index} className="mb-2">
                                    {tag}
                                </li>
                            ))}
                        </ul>
                    </div>
                </TabPane>

                <TabPane
                    tab={`Reviews (${reviews.totalDocuments ?? 0})`}
                    key="3"
                >
                    <div className="reviews-wrapper">
                        <h3>
                            {reviews.totalDocuments ?? 0} review for{' '}
                            {product.name}
                        </h3>
                        <div className="reviews-wrapper-inner">
                            <div className="review-section">
                                {reviews.reviews.map((review, index) => (
                                    <div className="review" key={index}>
                                        <div className="reviewer-img">
                                            <Image
                                                preview={false}
                                                src={review.user?.profile ?? ''}
                                                alt=""
                                                fallback={defaultImage}
                                            />
                                        </div>
                                        <div className="reviewer-body">
                                            <h2>
                                                {review.user?.name} -{' '}
                                                {dayjs(review.createdAt).format(
                                                    'MMMM DD'
                                                )}
                                            </h2>
                                            <div className="review-title">
                                                <ul>
                                                    {[...Array(5)].map(
                                                        (item, index) => (
                                                            <li key={index}>
                                                                {Math.round(
                                                                    review.rating ??
                                                                        0
                                                                ) > index ? (
                                                                    isMeat ? (
                                                                        <Chicken />
                                                                    ) : (
                                                                        <Carrot />
                                                                    )
                                                                ) : isMeat ? (
                                                                    <ChickenEmpty />
                                                                ) : (
                                                                    <CarrotEmpty />
                                                                )}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                            <p>{review.review}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="review-form-section">
                                <div className="review-form-header">
                                    <h2>Add your review</h2>
                                    <p>
                                        Your email address will not be
                                        published. Required fields are marked *
                                    </p>
                                </div>
                                <hr />
                                <form>
                                    <div>
                                        <label
                                            htmlFor=""
                                            className="rate-label"
                                        >
                                            Your Rating *
                                        </label>
                                        <div className="rating">
                                            {[...Array(5)].map(
                                                (item, index) => (
                                                    <div
                                                        className="rate"
                                                        onClick={() => {
                                                            handleRating(
                                                                index + 1
                                                            );
                                                        }}
                                                        key={index}
                                                    >
                                                        <ul>
                                                            {[
                                                                ...Array(
                                                                    index + 1
                                                                )
                                                            ].map((i, indx) => (
                                                                <li key={indx}>
                                                                    {parseInt(
                                                                        myReview.rating ??
                                                                            '0'
                                                                    ) ===
                                                                    index +
                                                                        1 ? (
                                                                        isMeat ? (
                                                                            <Chicken />
                                                                        ) : (
                                                                            <Carrot />
                                                                        )
                                                                    ) : isMeat ? (
                                                                        <ChickenEmpty />
                                                                    ) : (
                                                                        <CarrotEmpty />
                                                                    )}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-element mb-3">
                                        <label htmlFor="">Description *</label>
                                        <textarea
                                            placeholder="Add your review here..."
                                            onChange={handleReviewChange}
                                            value={myReview.review}
                                        />
                                    </div>

                                    <AppButton
                                        onClick={handleReviewSubmit}
                                        className="px-4"
                                        size={isDesktopOrLaptop ? 'lg' : 'sm'}
                                        style={{
                                            width: isMobile ? '100%' : 'auto'
                                        }}
                                        isMeat={isMeat}
                                        disabled={
                                            !myReview.rating || !myReview.review
                                        }
                                    >
                                        Add My Review
                                    </AppButton>
                                </form>
                            </div>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default ProductDetail;
