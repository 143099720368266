import { useState } from 'react';
import AppButton from '../../components/shared/AppButton';
import OtpInput from 'react-otp-input';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import request from '../../../helpers/request';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { Drawer } from 'antd';

export type DeliveryAddressType = 'Default' | 'Home' | 'Office' | 'Other';

interface IProps {
    onNext: () => void;
    goBack: () => void;
}

const MobileVerification = (props: IProps) => {
    const [OTP, setOTP] = useState('');

    const [OTPLoading, setOTPLoading] = useState(false);

    const { selectedDeliveryAddress: selectedAddress } = useSelector(
        (state: StateType) => state.deliveryAddress
    );

    const { isMinOrderValid } = useSelector((state: StateType) => state.cart);

    const handleOTPChange = (otp: string) => {
        setOTP(otp);
    };

    const handleSendOTP = () => {
        request
            .sendOTP(selectedAddress?._id ?? '')
            .then((res: AxiosResponse) => {
                toast.success(
                    `${res.data?.otp} is your test OTP. Please enter the OTP to verify your phone.`,
                    {
                        position: toast.POSITION.TOP_CENTER
                    }
                );
            })
            .catch((err: AxiosError) => {
                toast.error(
                    err.response?.data.message ?? 'Something went wrong',
                    {
                        position: toast.POSITION.TOP_CENTER
                    }
                );
            });
    };

    const handleOTPSubmit = () => {
        if (selectedAddress?._id) {
            setOTPLoading(true);

            request
                .verifyMobile({ address_id: selectedAddress._id, otp: OTP })
                .then(() => {
                    setOTPLoading(false);

                    props.onNext();
                })
                .catch((err: AxiosError) => {
                    toast.error(
                        err.response?.data?.message ?? 'Something went wrong',
                        {
                            position: toast.POSITION.TOP_CENTER
                        }
                    );

                    setOTPLoading(false);
                });
        }
    };

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const isMd = useMediaQuery({ minWidth: 768 });

    return (
        <div className="py-3 px-md-3">
            <p className="mt-3 mt-md-5 text-montserrat text-16 text-gray-3 mb-3">
                We need your phone number so we can inform you about any delay
                or problem.
            </p>

            <p className="text-montserrat text-16 text-gray-3 mb-5">
                6 digits code send your phone{' '}
                <span className="text-orange">{selectedAddress?.mobile}</span>
                {/* <span className="text-orange text-underline text-600 cursor-pointer ms-3">
                    Update Number
                </span> */}
            </p>

            <div>
                <p className="text-montserrat text-600 text-dark-color mb-3">
                    Enter OTP Code
                </p>

                <OtpInput
                    containerStyle="checkout-opt-input mb-3 text-roboto"
                    numInputs={6}
                    value={OTP}
                    onChange={handleOTPChange}
                />

                <p className="text-montserrat text-16 text-gray-3 mb-5">
                    If you didn’t receive the code.{' '}
                    <span
                        className="text-orange text-600 cursor-pointer"
                        onClick={handleSendOTP}
                    >
                        Resend Now.
                    </span>
                </p>
            </div>

            {isMd && (
                <div className="d-flex justify-content-between mt-5">
                    <div />

                    <AppButton
                        size={isDesktopOrLaptop ? 'lg' : 'sm'}
                        onClick={handleOTPSubmit}
                        className="px-4"
                        disabled={
                            OTP.length !== 6 || OTPLoading || !isMinOrderValid
                        }
                        loading={OTPLoading}
                    >
                        Next: Delivery Date & Time
                    </AppButton>
                </div>
            )}

            <Drawer
                visible={!isMd}
                placement="bottom"
                closable={false}
                mask={false}
                height={88}
            >
                <div className="d-flex justify-content-between">
                    <AppButton
                        size="sm"
                        onClick={() => props.goBack()}
                        className="px-4"
                    >
                        Back
                    </AppButton>

                    <AppButton
                        size="sm"
                        onClick={handleOTPSubmit}
                        className="px-4"
                        disabled={
                            OTP.length !== 6 || OTPLoading || !isMinOrderValid
                        }
                        loading={OTPLoading}
                    >
                        Next
                    </AppButton>
                </div>
            </Drawer>
        </div>
    );
};

export default MobileVerification;
