import {
    GET_CARTS,
    GET_CART_DETAIL,
    GET_WISHLIST,
    SET_MIN_ORDER_VALID
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { ICart, ICartResponse } from '../interfaces/Cart';
import { IWishlistResponse } from '../interfaces/Wishlist';

export interface ICartState extends ICartResponse {
    cartsLoading: boolean;
    carts: ICart[];
    isMinOrderValid: boolean;
    wishlist: IWishlistResponse;
    cartDetail: ICartResponse;
}

const initialState: ICartState = {
    cartsLoading: false,
    carts: [],
    delivery_fee: 0,
    sub_total: 0,
    total_amount: 0,
    isMinOrderValid: false,
    wishlist: {
        lists: []
    },
    cartDetail: {
        carts: [],
        delivery_fee: 0,
        sub_total: 0,
        total_amount: 0
    }
};

const cartReducer = (state = initialState, action: ActionType): ICartState => {
    switch (action.type) {
        case GET_CARTS:
            return {
                ...state,
                ...action.payload
            };

        case SET_MIN_ORDER_VALID:
            return {
                ...state,
                isMinOrderValid: action.payload
            };

        case GET_WISHLIST:
            return {
                ...state,
                wishlist: action.payload
            };

        case GET_CART_DETAIL:
            return {
                ...state,
                cartDetail: action.payload
            };

        default:
            return state;
    }
};

export default cartReducer;
