import { List, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ReactElement, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiHomeAlt } from 'react-icons/bi';
import { BsGlobe } from 'react-icons/bs';
import { FiBriefcase, FiEdit } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import {
    getAreasByDistrict,
    getDistrictByProvince,
    getProvinces
} from '../../../../../actions/addressActions';
import { getDeliveryAddress } from '../../../../../actions/deliveryAddressActions';
import request from '../../../../../helpers/request';
import {
    DeliveryAddressType,
    IDeliveryAddress
} from '../../../../../interfaces/DeliveryAddress';
import { AppDispatch } from '../../../../../reducers';
import { showToast } from '../../../../../utils/toast';
import AddressModal from './AddressModal';

interface IProps {
    address: IDeliveryAddress;
    isMobile?: boolean;
}

interface LabelIcons {
    label: DeliveryAddressType;
    icon: ReactElement;
}

const AddressList = ({ address, isMobile }: IProps) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [showEditModal, setShowEditModal] = useState(false);

    const [deleting, setDeleting] = useState(false);

    const dispatch = useDispatch<AppDispatch>();

    const icons: LabelIcons[] = [
        {
            label: 'Home',
            icon: <BiHomeAlt size={18} />
        },
        {
            label: 'Work',
            icon: <FiBriefcase size={18} />
        },
        {
            label: 'Other',
            icon: <BsGlobe size={18} />
        }
    ];

    return (
        <div>
            <List.Item className="py-3 py-md-4">
                <List.Item.Meta
                    avatar={
                        icons.find((item) => item.label === address.label)?.icon
                    }
                    title={address.label}
                    description={`${address?.address?.landmark1}, ${
                        address?.address?.area
                    }, ${address?.address?.district}, ${
                        address?.address?.province
                    }, ${address?.address?.country}${
                        address?.inside_ringroad ? ' (Inside Ringroad)' : ''
                    }`}
                />
                <Space>
                    <div>
                        <button
                            className="icon-btn"
                            onClick={() => {
                                dispatch(getProvinces());

                                if (address.address?.province)
                                    dispatch(
                                        getDistrictByProvince(
                                            address.address?.province
                                        )
                                    ).then((districts) => {
                                        let districtId = districts.find(
                                            (district) =>
                                                district.name ===
                                                address.address?.district
                                        )?._id;

                                        if (districtId)
                                            dispatch(
                                                getAreasByDistrict(districtId)
                                            );
                                    });

                                setShowEditModal(true);
                            }}
                        >
                            <FiEdit size={22} />
                        </button>
                    </div>
                    <div>
                        <button
                            className="icon-btn"
                            onClick={() => setShowDeleteModal(true)}
                        >
                            <AiOutlineDelete size={22} />
                        </button>
                    </div>
                </Space>
            </List.Item>

            <Modal
                title={
                    <>
                        <h4>Address delete confirmation</h4>{' '}
                        <span>Delete the current address</span>{' '}
                    </>
                }
                visible={showDeleteModal}
                onOk={() => {
                    if (address._id) {
                        setDeleting(true);

                        request
                            .deleteAddress(address._id)
                            .then(() => {
                                setDeleting(false);

                                showToast(
                                    'success',
                                    'Address has been removed'
                                );

                                setShowDeleteModal(false);

                                dispatch(getDeliveryAddress());
                            })
                            .catch(() => {
                                setDeleting(false);
                            });
                    }
                }}
                onCancel={() => setShowDeleteModal(false)}
                okText="Yes! Delete it"
                cancelText="No! Don’t Delete"
                cancelButtonProps={{
                    style: {
                        border: '1px solid #F47B34',
                        color: '#F47B34',
                        width: '208px',
                        height: '55px',
                        borderRadius: '40px',
                        margin: '10px'
                    }
                }}
                confirmLoading={deleting}
                okButtonProps={{
                    style: {
                        border: '1px solid #F47B34',
                        color: '#fff',
                        width: '208px',
                        height: '55px',
                        borderRadius: '40px',
                        margin: '10px 0'
                    }
                }}
            >
                <p>
                    Are you sure you want to remove this address from your list?
                    Dont worry you can add new address anytime you feel like
                    adding.
                </p>
            </Modal>

            <AddressModal
                visible={showEditModal}
                onOk={() => setShowEditModal(false)}
                onCancel={() => setShowEditModal(false)}
                editMode
                address={address}
                mobile={isMobile}
            />
        </div>
    );
};

export default AddressList;
