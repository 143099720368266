import Avatar from 'antd/lib/avatar/avatar';
import Rating from 'react-rating';
import carrotFilled from '../../../assets/images/AboutUs/carrot-filled.png';
import carrot from '../../../assets/images/AboutUs/carrot.png';
import { defaultImage } from '../../../helpers/constants';
import { ITestimonial } from '../../../interfaces/Testimonial';

interface IProps {
    testimonial: ITestimonial;
}

const TestimonialCard = ({ testimonial }: IProps) => {
    return (
        <>
            <div className="testimonial-card-container">
                <p className="text-line-3">{testimonial.testimonial}</p>
                <div className="testimonial-person-info">
                    <Avatar
                        style={{ border: '2px solid #fcfcfc' }}
                        size={70}
                        src={
                            !!testimonial.profile
                                ? testimonial.profile
                                : defaultImage
                        }
                    />
                    <br />
                    <h5>{testimonial.name}</h5>
                    <address>{testimonial.designation}</address>
                </div>
                <div className="testimonial-ratings-container">
                    <Rating
                        emptySymbol={<img src={carrot} alt="carrot" />}
                        fullSymbol={<img src={carrotFilled} alt="carrot" />}
                        fractions={2}
                        readonly
                        initialRating={testimonial.rating}
                        className="me-3"
                    />
                </div>
            </div>
        </>
    );
};

export default TestimonialCard;
