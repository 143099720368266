import AuthLayout from '../../layouts/AuthLayout';
import { Link } from 'react-router-dom';
import AppButton from '../../components/shared/AppButton';
import { useMediaQuery } from 'react-responsive';

interface IProps {}

const CheckMail = (props: IProps) => {
    const isMobile = useMediaQuery({ maxWidth: 576 });

    return (
        <AuthLayout>
            <div className="login-form">
                <div className="recovery-form-section">
                    <h2>Check your mail</h2>
                    <p className="mb-4 mb-lg-5">
                        We have sent a password recover instruction to your
                        email
                    </p>

                    <Link to="/">
                        <AppButton
                            className="w-100 mb-4"
                            size={isMobile ? 'sm' : 'lg'}
                        >
                            Skip, I'll confirm later
                        </AppButton>
                    </Link>

                    <hr />

                    <p className="recovery-text">
                        Didn’t receive the email? Check your spam filter, or{' '}
                        <Link to="/forgot-password">try another email</Link>
                    </p>
                </div>
            </div>
        </AuthLayout>
    );
};

export default CheckMail;
