import { Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { toggleDrawer } from '../../../actions/uiActions';
import { ReactComponent as CloseIcon } from '../../../assets/images/cancel-icon.svg';
import { StateType } from '../../../reducers';
import AppButton from '../shared/AppButton';
import AppSpinner from '../shared/AppSpinner';
import Item from '../shared/Item';
import PromoCode from '../shared/PromoCode';

interface IProps {}

const DrawerCart = (props: IProps) => {
    const cart = useSelector((state: any) => state.ui.drawer);

    const {
        cartsLoading,
        carts,
        total_amount,
        sub_total,
        delivery_fee,
        coupon_discount_amount,
        wallet_adjustment
    } = useSelector((state: StateType) => state.cart);

    const dispatch = useDispatch();

    const isMobile = useMediaQuery({ maxWidth: 576 });

    return (
        <Drawer
            visible={cart}
            closable={false}
            className="cart-page"
            bodyStyle={{ padding: 0 }}
            onClose={() => dispatch(toggleDrawer(false))}
            width={isMobile ? '100%' : 404}
        >
            <div className="sidedrawer-cart d-flex flex-column">
                {cart && (
                    <div
                        className="close_search"
                        onClick={() => dispatch(toggleDrawer(false))}
                    >
                        <CloseIcon />
                    </div>
                )}

                {cartsLoading && (
                    <div className="loader-overlay flex-column">
                        <span>Loading...</span>
                        <AppSpinner size="sm" />
                    </div>
                )}

                <div className="cart-header">
                    <h2 className="mb-0">My Cart ({carts.length} Items)</h2>
                </div>

                <div className="cart-list flex-grow-1 py-1">
                    {carts.length ? (
                        carts.map((cart, index) => {
                            return <Item key={index} item={cart} />;
                        })
                    ) : (
                        <p className="text-montserrat text-14 text-gray mt-2 px-4">
                            No items found
                        </p>
                    )}
                </div>

                {carts.length ? (
                    <div className="price-section px-4 py-3">
                        <PromoCode
                            carts={carts.map((cart) => cart._id ?? '')}
                        />

                        <hr />
                        <div className="sub-total">
                            <p className="sub-total-inner">Sub Total</p>

                            <span className="sub-total-amount">
                                Rs. {sub_total}
                            </span>
                        </div>
                        <div className="delivery-charge mb-1">
                            <p className="delivery-charge-inner">
                                Delivery Charge
                            </p>
                            <span className="delivery-charge-amount">
                                Rs. {delivery_fee}
                            </span>
                        </div>

                        {!!coupon_discount_amount && (
                            <div className="d-flex align-items-center mb-1">
                                <span className="text-montserrat text-400 text-16 text-gray me-2">
                                    Promo Code Discount
                                </span>

                                <span className="text-montserrat text-400 text-16 text-gray flex-grow-1 text-end">
                                    Rs. {coupon_discount_amount}
                                </span>
                            </div>
                        )}

                        {!!wallet_adjustment && (
                            <div className="d-flex align-items-center mb-1">
                                <span className="text-montserrat text-400 text-16 text-gray me-2">
                                    Redeem Amount
                                </span>

                                <span className="text-montserrat text-400 text-16 text-gray flex-grow-1 text-end">
                                    Rs. {wallet_adjustment}
                                </span>
                            </div>
                        )}

                        <div className="grand-total">
                            <p className="grand-total-inner">Grand Total</p>
                            <span className="grand-total-amount">
                                Rs. {total_amount}
                            </span>
                        </div>
                        <div className="d-flex mx-n2">
                            <Link
                                to="/cart"
                                onClick={() => dispatch(toggleDrawer(false))}
                                className="w-100 mx-2"
                            >
                                <AppButton
                                    type="primary-outlined"
                                    size="sm"
                                    className="w-100"
                                >
                                    View Cart
                                </AppButton>
                            </Link>

                            <Link
                                to="/cart/checkout"
                                onClick={() => dispatch(toggleDrawer(false))}
                                className="w-100 mx-2"
                            >
                                <AppButton
                                    type="primary"
                                    size="sm"
                                    className="w-100"
                                >
                                    Checkout
                                </AppButton>
                            </Link>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </Drawer>
    );
};

export default DrawerCart;
