import request from '../helpers/request';
import { Dispatch } from 'redux';
import {
  GET_AREAS_BY_DISTRICT,
  GET_DISTRICTS,
  GET_PROVINCES,
} from '../helpers/constants';
import { AxiosResponse } from 'axios';
import { IDistrict } from '../interfaces/Address';

export const getProvinces = () => async (dispatch: Dispatch) => {
  request.getProvinces().then((res: AxiosResponse) => {
    dispatch({ type: GET_PROVINCES, payload: res.data });
  });
};

export const getDistrictByProvince =
  (province: string) => async (dispatch: Dispatch) => {
    return request
      .getDistricts(province)
      .then((res: AxiosResponse) => {
        let payload: IDistrict[] = res.data;

        dispatch({ type: GET_DISTRICTS, payload });

        return Promise.resolve(payload);
      })
      .catch(() => {
        return Promise.reject();
      });
  };

export const getAreasByDistrict =
  (id: string) => async (dispatch: Dispatch) => {
    request.getAreasByDistrict(id).then((res: AxiosResponse) => {
      dispatch({ type: GET_AREAS_BY_DISTRICT, payload: res.data });
    });
  };
