import {
    DISLIKE_BLOG,
    GET_BLOGS,
    GET_BLOG_BY_SLUG,
    GET_BLOG_COMMENTS,
    GET_SIMILAR_BLOG,
    LIKE_BLOG,
    RESET_BLOG_COMMENTS,
    SET_BLOG_COMMENTS_LOADING
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IBlog, IBlogCommentResponse } from '../interfaces/Blog';

export interface IBlogsState {
    blogsLoading: boolean;
    blogs: IBlog[];
    blogLoading: boolean;
    blog: IBlog;
    blogCommentsLoading: boolean;
    blogComments: IBlogCommentResponse;
    similarBlogs: IBlog[];
}

const initState: IBlogsState = {
    blogsLoading: false,
    blogs: [],
    blogLoading: false,
    blog: {},
    blogCommentsLoading: false,
    blogComments: {
        comments: []
    },
    similarBlogs: []
};

const blogsReducer = (state = initState, action: ActionType): IBlogsState => {
    let { type, payload } = action;

    switch (type) {
        case GET_BLOGS:
            return {
                ...state,
                ...payload
            };

        case GET_SIMILAR_BLOG:
            return {
                ...state,
                similarBlogs: payload
            };

        case GET_BLOG_BY_SLUG:
            return {
                ...state,
                ...payload
            };

        case LIKE_BLOG:
            return {
                ...state,
                blog: {
                    ...state.blog,
                    has_liked: state.blog.has_liked === true ? null : true,
                    dislikes_count:
                        state.blog.has_liked === false
                            ? (state.blog.dislikes_count ?? 1) - 1
                            : state.blog.dislikes_count,
                    likes_count:
                        state.blog.has_liked === true
                            ? (state.blog.likes_count ?? 1) - 1
                            : (state.blog.likes_count ?? 0) + 1
                }
            };

        case DISLIKE_BLOG:
            return {
                ...state,
                blog: {
                    ...state.blog,
                    has_liked: state.blog.has_liked === false ? null : false,
                    dislikes_count:
                        state.blog.has_liked === false
                            ? (state.blog.dislikes_count ?? 1) - 1
                            : (state.blog.dislikes_count ?? 0) + 1,
                    likes_count:
                        state.blog.has_liked === true
                            ? (state.blog.likes_count ?? 1) - 1
                            : state.blog.likes_count
                }
            };

        case GET_BLOG_COMMENTS:
            return {
                ...state,
                blogComments: {
                    ...payload,
                    comments: [
                        ...state.blogComments?.comments,
                        ...payload?.comments
                    ]
                }
            };

        case SET_BLOG_COMMENTS_LOADING:
            return {
                ...state,
                blogCommentsLoading: payload
            };

        case RESET_BLOG_COMMENTS:
            return {
                ...state,
                blogComments: initState.blogComments
            };

        default:
            return state;
    }
};

export default blogsReducer;
