import { Link, NavLink, useHistory } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import LogoAlt from '../../assets/images/logo-1.png';
import {
    EnvironmentOutlined,
    CaretDownFilled,
    HeartOutlined,
    ShoppingCartOutlined
} from '@ant-design/icons';
import { Dropdown, Menu, Avatar, Button, Popover } from 'antd';
import DrawerCart from '../components/home/DrawerCart';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../reducers';
import Cookies from 'universal-cookie';
import { $FIXME } from '../../helpers/constants';
import { toggleDrawer } from '../../actions/uiActions';
import { FiLogOut, FiMapPin, FiMenu, FiPackage, FiUser } from 'react-icons/fi';
import { setDeliveryAddress } from '../../actions/deliveryAddressActions';
import NavSearch from '../components/shared/NavSearch';
import { useEffect, useState } from 'react';
import { getSearchHistory } from '../../actions/userActions';
import { getCarts } from '../../actions/cartActions';
import { BiUser } from 'react-icons/bi';
import { useMediaQuery } from 'react-responsive';
import NavDrawer from './NavDrawer';
import { getInitials } from '../../utils/functions';
import { DWallet } from '../../assets/images/icons';

interface IProps {}

const NavBar = (props: IProps) => {
    const { authenticated, authUser } = useSelector(
        (state: StateType) => state.auth
    );

    const { carts, sub_total } = useSelector((state: StateType) => state.cart);

    const { ringroadArea, address, selectedDeliveryAddress } = useSelector(
        (state: StateType) => state.deliveryAddress
    );

    const [menuVisible, setMenuVisible] = useState(false);

    const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

    const dispatch = useDispatch();

    const history = useHistory();

    const cookies = new Cookies();

    const handleClick = () => {
        history.push('/login');
    };

    const handleLogout = () => {
        const cookiesNames = [
            'authUser',
            'authenticated',
            'token',
            'loginSource'
        ];
        for (const cookie in cookiesNames) {
            cookies.remove(cookiesNames[cookie], { path: '/' });
        }

        (window as $FIXME).location.reload();
    };

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768 });

    const addressMenu = (
        <Menu>
            {!address.length
                ? ringroadArea.areas?.map((area, index) => (
                      <Menu.Item
                          key={index}
                          onClick={() => {
                              dispatch(
                                  setDeliveryAddress({
                                      inside_ringroad: true,
                                      address: { area: area.name }
                                  })
                              );
                          }}
                      >
                          {area.name}
                      </Menu.Item>
                  ))
                : address.map((add, index) => (
                      <Menu.Item
                          key={index}
                          onClick={() => {
                              dispatch(setDeliveryAddress(add));

                              dispatch(getCarts());
                          }}
                      >
                          {`${add.address?.landmark1}, ${add.address?.area}, ${add.address?.district}`}
                      </Menu.Item>
                  ))}
        </Menu>
    );

    useEffect(() => {
        dispatch(getSearchHistory({ resultsPerPage: 5 }));
    }, [dispatch]);

    const hideMenu = () => {
        setMenuVisible(false);
    };

    const profileMenu = (
        <div className="d-flex flex-column menu-container" onClick={hideMenu}>
            <Link to="/dashboard?profile">
                <FiUser size={24} className="me-2" /> My Profile
            </Link>
            <Link to="/dashboard?orders">
                <FiPackage size={24} className="me-2" /> My Orders
            </Link>
            <Link to="/dashboard?wallet">
                <DWallet width={24} className="me-2" /> My Wallet
            </Link>
            <Link to="/dashboard?address">
                <FiMapPin size={24} className="me-2" /> My Addresses
            </Link>

            <div className="logout-btn-container">
                <button onClick={handleLogout} className="logout-btn">
                    <FiLogOut size={24} style={{ marginRight: '5px' }} /> Log
                    out
                </button>
            </div>
        </div>
    );

    return (
        <>
            <div className="nav-container">
                <div className="nav-container-inner mx-auto">
                    <NavLink to="/" className="me-3 me-lg-5">
                        <img
                            src={isDesktopOrLaptop ? Logo : LogoAlt}
                            alt="farmgro-logo"
                            className="nav-logo"
                        />
                    </NavLink>

                    <div className="flex-grow-1 d-flex align-items-center justify-content-center me-lg-5">
                        <div className="d-flex align-items-center justify-content-end me-md-4">
                            <p className="mb-0 me-2 text-nowrap text-montserrat text-12 text-gray-3">
                                Delivery to
                            </p>

                            <div className="flex-grow-1 d-flex align-items-center">
                                <EnvironmentOutlined className="text-orange me-2" />

                                <Dropdown
                                    overlay={addressMenu}
                                    trigger={['click']}
                                    className="cursor-pointer"
                                >
                                    <div className="d-flex align-items-center">
                                        <p
                                            className={`mb-0 me-2 text-truncate text-montserrat text-600 text-gray ${
                                                isDesktopOrLaptop
                                                    ? 'text-16'
                                                    : 'text-14'
                                            }`}
                                        >
                                            {selectedDeliveryAddress
                                                ? selectedDeliveryAddress
                                                      ?.address?.area
                                                : 'Select Address'}{' '}
                                        </p>

                                        <CaretDownFilled
                                            className="text-gray"
                                            style={{ fontSize: 14 }}
                                        />
                                    </div>
                                </Dropdown>
                            </div>
                        </div>

                        {isDesktopOrLaptop && <NavSearch />}
                    </div>

                    {authenticated ? (
                        <>
                            <div className="d-none d-md-flex align-items-center me-3 me-lg-4">
                                <Link
                                    to="/dashboard?wishlist"
                                    className="icon-wrapper me-3 me-lg-4"
                                >
                                    <HeartOutlined
                                        style={{
                                            fontSize: isDesktopOrLaptop
                                                ? 20
                                                : 16
                                        }}
                                        className="text-gray-2"
                                    />
                                </Link>

                                <div
                                    className="d-flex align-items-center me-2 cursor-pointer"
                                    onClick={() => {
                                        dispatch(toggleDrawer(true));
                                        dispatch(getCarts());
                                    }}
                                >
                                    <div className="position-relative nav-cart">
                                        <div className="position-absolute bg-orange rounded-circle nav-cart-count">
                                            <span className="text-roboto text-12 text-500 text-light-gray-2">
                                                {carts.reduce(
                                                    (a, b) =>
                                                        a + (b.quantity ?? 0),
                                                    0
                                                )}
                                            </span>
                                        </div>

                                        <ShoppingCartOutlined
                                            style={{
                                                color: '#BDBDBD',
                                                fontSize: isDesktopOrLaptop
                                                    ? 24
                                                    : 22
                                            }}
                                        />
                                    </div>

                                    <div className="d-none d-lg-flex flex-column ms-2">
                                        <p className="text-montserrat text-600 text-16 text-gray-5 mb-0 text-nowrap">
                                            My Cart
                                        </p>

                                        {carts.length ? (
                                            <span className="cart-price text-montserrat">
                                                Rs.
                                                {sub_total}
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>

                            {isTablet && (
                                <Popover
                                    content={profileMenu}
                                    trigger={isDesktopOrLaptop ? ['click'] : []}
                                    className="cursor-pointer"
                                    overlayClassName="profile-menu"
                                    placement="bottomRight"
                                    visible={menuVisible}
                                    onVisibleChange={(visible) =>
                                        setMenuVisible(visible)
                                    }
                                >
                                    <div className="d-flex align-items-center">
                                        <Link to="/dashboard" className="me-2">
                                            <Avatar
                                                src={authUser.profile}
                                                className="nav-avatar d-flex align-items-center justify-content-center"
                                            >
                                                {getInitials(authUser.name)}
                                            </Avatar>
                                        </Link>

                                        <p
                                            className="d-none d-lg-block mb-0 ms-1 text-truncate text-montserrat text-600 text-16 text-gray-5"
                                            style={{ maxWidth: 105 }}
                                        >
                                            {authUser.name}
                                        </p>

                                        {isDesktopOrLaptop && (
                                            <CaretDownFilled className="ms-1" />
                                        )}
                                    </div>
                                </Popover>
                            )}
                        </>
                    ) : (
                        isDesktopOrLaptop && (
                            <Button
                                size={'large'}
                                className="button-main"
                                onClick={handleClick}
                            >
                                <BiUser
                                    style={{
                                        fontSize: 20
                                    }}
                                    className="me-1"
                                />
                                Login/Register
                            </Button>
                        )
                    )}

                    {!isDesktopOrLaptop && (
                        <FiMenu
                            size={24}
                            className="text-gray text-hover-orange cursor-pointer ms-2"
                            onClick={() => setSideDrawerOpen(true)}
                        />
                    )}
                </div>
                <DrawerCart />
            </div>

            {!isDesktopOrLaptop && (
                <NavDrawer
                    visible={sideDrawerOpen}
                    onClose={() => setSideDrawerOpen(false)}
                />
            )}
        </>
    );
};

export default NavBar;
