import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import {
    chickenTypeId,
    fishTypeId,
    GET_CHICKEN_ITEMS,
    GET_FISH_ITEMS,
    GET_MUTTOM_ITEMS,
    muttonTypeId
} from '../helpers/constants';
import request from '../helpers/request';

export const getChickenItems = () => async (dispatch: Dispatch) => {
    request
        .getProducts({
            resultsPerPage: 8,
            sort: 'popular',
            type: chickenTypeId
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_CHICKEN_ITEMS,
                payload: res.data?.products
            });
        });
};

export const getMuttonItems = () => async (dispatch: Dispatch) => {
    request
        .getProducts({
            resultsPerPage: 8,
            sort: 'popular',
            type: muttonTypeId
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_MUTTOM_ITEMS,
                payload: res.data?.products
            });
        });
};

export const getFishItems = () => async (dispatch: Dispatch) => {
    request
        .getProducts({
            resultsPerPage: 8,
            sort: 'popular',
            type: fishTypeId
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_FISH_ITEMS,
                payload: res.data?.products
            });
        });
};
