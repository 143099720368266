import { useState } from 'react';
import AppButton from '../../components/shared/AppButton';
import { Drawer, Radio, RadioChangeEvent, Space } from 'antd';
import dayjs from 'dayjs';
import { DeliveryProps } from './Checkout';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

export type DeliveryAddressType = 'Default' | 'Home' | 'Office' | 'Other';

interface IProps {
    onNext: (props: DeliveryProps) => void;
    goBack: () => void;
}

const DeliveryDate = (props: IProps) => {
    const [selectedDate, setSelectedDate] = useState<string>();
    const [selectedTime, setSelectedTime] = useState<string>();

    const { isMinOrderValid } = useSelector((state: StateType) => state.cart);

    const handleDateChange = (e: RadioChangeEvent) => {
        setSelectedTime(undefined);
        setSelectedDate(e.target.value);
    };

    const handleTimeChange = (e: RadioChangeEvent) => {
        setSelectedTime(e.target.value);
    };

    const getDates = () => {
        let dates = [];

        for (
            let i = moment().isBefore(moment({ h: 17, m: 0 })) ? 0 : 1;
            i < 7;
            i++
        ) {
            let date = dayjs(Date.now()).add(i, 'day');

            let value = date.format('YYYY-MM-DD');

            if (i === 0) {
                dates.push({
                    label: 'Today',
                    value
                });
            } else if (i === 1) {
                dates.push({
                    label: 'Tomorrow',
                    value
                });
            } else {
                dates.push({
                    label: date.format('DD MMM. YYYY'),
                    value
                });
            }
        }

        return dates;
    };

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const isMd = useMediaQuery({ minWidth: 768 });

    return (
        <div className="py-3 px-md-3">
            <p className="text-inter text-500 text-gray-5">
                Select Date for Delivery
            </p>

            <Radio.Group
                value={selectedDate}
                buttonStyle="solid"
                onChange={handleDateChange}
                size="large"
                className="mb-4"
            >
                <Space direction="horizontal" size={10} wrap>
                    {getDates().map((date, index) => (
                        <Radio.Button
                            value={date.value}
                            className="app-radio-btn text-nowrap"
                        >
                            {date.label}
                        </Radio.Button>
                    ))}
                </Space>
            </Radio.Group>

            {!!selectedDate && (
                <>
                    <p className="text-inter text-500 text-gray-5">
                        Select Time for Delivery
                    </p>

                    <Radio.Group
                        onChange={handleTimeChange}
                        value={selectedTime}
                        size="large"
                        className="app-radio-group"
                    >
                        <Space direction="vertical" size={20} wrap={false}>
                            {!(
                                selectedDate &&
                                moment(selectedDate).isSame(moment(), 'day') &&
                                !moment().isBefore({ h: 7, m: 0 })
                            ) && (
                                <Radio
                                    value="07 : 00 AM - 10 : 00 AM"
                                    className="app-radio d-flex align-items-center w-100"
                                >
                                    <div className="text-montserrat text-16 text-gray pt-1">
                                        07 : 00 AM - 10 : 00 AM
                                    </div>
                                </Radio>
                            )}

                            {!(
                                moment(selectedDate).isSame(moment(), 'day') &&
                                !moment().isBefore({ h: 17, m: 0 })
                            ) && (
                                <Radio
                                    value="05 : 00 PM - 07 : 00 PM"
                                    className="app-radio d-flex align-items-center w-100"
                                >
                                    <div className="text-montserrat text-16 text-gray pt-1">
                                        05 : 00 PM - 07 : 00 PM
                                    </div>
                                </Radio>
                            )}
                        </Space>
                    </Radio.Group>
                </>
            )}

            <div className="d-flex justify-content-between mt-5">
                <p className="text-montserrat text-14 mb-0 text-gray-2 me-5">
                    Based on the number of item, delivery can be delayed by half
                    or one hour. If you find any difficulty, please contact us
                    through @01-4567898
                </p>

                {isMd && (
                    <div>
                        <AppButton
                            size={isDesktopOrLaptop ? 'lg' : 'sm'}
                            onClick={() =>
                                props.onNext({
                                    deliveryDate: selectedDate,
                                    deliveryTime: selectedTime
                                })
                            }
                            className="px-4"
                            disabled={
                                !selectedDate ||
                                !selectedTime ||
                                !isMinOrderValid
                            }
                        >
                            Next: Payment Options
                        </AppButton>
                    </div>
                )}
            </div>

            <Drawer
                visible={!isMd}
                placement="bottom"
                closable={false}
                mask={false}
                height={88}
            >
                <div className="d-flex justify-content-between">
                    <AppButton
                        size="sm"
                        onClick={() => props.goBack()}
                        className="px-4"
                    >
                        Back
                    </AppButton>

                    <AppButton
                        size="sm"
                        onClick={() =>
                            props.onNext({
                                deliveryDate: selectedDate,
                                deliveryTime: selectedTime
                            })
                        }
                        className="px-4"
                        disabled={
                            !selectedDate || !selectedTime || !isMinOrderValid
                        }
                    >
                        Next
                    </AppButton>
                </div>
            </Drawer>
        </div>
    );
};

export default DeliveryDate;
