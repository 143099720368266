import React, { useCallback, useEffect, useState } from 'react';
import {
    Switch,
    Route,
    RouteProps,
    Redirect,
    useLocation
} from 'react-router-dom';
import routes from './helpers/routes';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from './reducers';
import Cookies from 'universal-cookie';
import { checkAuthentication } from './utils/checkAuthentication';
import { getCarts } from './actions/cartActions';
import {
    getDeliveryAddress,
    getRingroadAreas
} from './actions/deliveryAddressActions';
import { getDeliveryCharge } from './actions/paymentActions';
import { getContactInfo } from './actions/contactActions';
import { getUserProfile } from './actions/userActions';
import { getProductTypes } from './actions/categoryActions';
import Splash from './views/screens/splash/Splash';
import 'simplebar/dist/simplebar.min.css';
import {
    getFooterDairy,
    getFooterFruitsVeg,
    getFooterMeat,
    getFooterMisc,
    getNavMeatMarket,
    getNavSeasonalExotic,
    getNavVegetable
} from './actions/homeActions';

const cookies = new Cookies();

export interface RouteType {
    path: string;
    component: React.FunctionComponent<RouteProps>;
    protected?: boolean;
    exact?: boolean;
    initialAction?: any[];
}

interface PrivateRouteProps extends RouteProps {
    component: React.FunctionComponent<RouteProps>;
}

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const PrivateRoutes = (props: PrivateRouteProps) => {
    const auth = useSelector((state: StateType) => state.auth);

    const { component: Component, ...rest } = props;
    return (
        <Route
            {...rest}
            render={(props) =>
                auth?.authenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

function App() {
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch<AppDispatch>();

    const authenticated = cookies.get('authenticated');

    const initialLoad = useCallback(async () => {
        await checkAuthentication();

        if (authenticated) {
            await dispatch(getUserProfile());
            await dispatch(getCarts());
            await dispatch(getDeliveryAddress());
        }

        await dispatch(getRingroadAreas());
        await dispatch(getDeliveryCharge());
        await dispatch(getContactInfo());
        await dispatch(getProductTypes());

        setLoading(false);

        dispatch(getNavVegetable());
        dispatch(getNavMeatMarket());
        dispatch(getNavSeasonalExotic());
        dispatch(getFooterFruitsVeg());
        dispatch(getFooterDairy());
        dispatch(getFooterMeat());
        dispatch(getFooterMisc());
    }, [dispatch, authenticated]);

    useEffect(() => {
        initialLoad();
    }, [initialLoad]);

    return (
        <>
            <ScrollToTop />
            {loading ? (
                <Splash />
            ) : (
                <Switch>
                    {routes.map((route: RouteType, i: number) => {
                        return route.protected === true ? (
                            <PrivateRoutes
                                key={i}
                                {...route}
                                component={route.component}
                            />
                        ) : (
                            <Route
                                key={i}
                                {...route}
                                component={route.component}
                            />
                        );
                    })}
                </Switch>
            )}
            <ToastContainer hideProgressBar />
        </>
    );
}

export default App;
