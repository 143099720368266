import {
    GET_FAQ,
    GET_PRIVACY_POLICY,
    GET_RETURN_POLICY,
    GET_TERMS_AND_CONDITIONS
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { FAQ } from '../interfaces/FAQ';

export interface IPagesState {
    faqLoading: boolean;
    faq: FAQ[];
    privacyPolicyLoading: boolean;
    privacyPolicy: string;
    termsConditionsLoading: boolean;
    termsConditions: string;
    returnPolicyLoading: boolean;
    returnPolicy: string;
}

const initState: IPagesState = {
    faqLoading: false,
    faq: [],
    privacyPolicyLoading: false,
    privacyPolicy: '',
    termsConditionsLoading: false,
    termsConditions: '',
    returnPolicyLoading: false,
    returnPolicy: ''
};

const pagesReducer = (state = initState, action: ActionType) => {
    let { type, payload } = action;

    switch (type) {
        case GET_FAQ:
            return {
                ...state,
                ...payload
            };

        case GET_PRIVACY_POLICY:
            return {
                ...state,
                ...payload
            };

        case GET_TERMS_AND_CONDITIONS:
            return {
                ...state,
                ...payload
            };

        case GET_RETURN_POLICY:
            return {
                ...state,
                ...payload
            };

        default:
            return state;
    }
};

export default pagesReducer;
