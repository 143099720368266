import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import {
    GET_PRODUCT_CATEGORIES,
    GET_PRODUCT_TYPES
} from '../helpers/constants';
import request from '../helpers/request';

export const getProductCategories = () => async (dispatch: Dispatch) => {
    request.getProductCategories().then((res: AxiosResponse) => {
        dispatch({
            type: GET_PRODUCT_CATEGORIES,
            payload: res.data
        });
    });
};

export const getProductTypes = () => async (dispatch: Dispatch) => {
    request.getProductTypes().then((res: AxiosResponse) => {
        dispatch({
            type: GET_PRODUCT_TYPES,
            payload: res.data
        });
    });
};
