import React from 'react';
import NewsLetterImg from '../../../assets/images/newsletter-img.png';
import { ReactComponent as EnvelopIcon } from '../../../assets/images/envelop-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { subscribeNewsfeed } from '../../../actions/newsfeedActions';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface IProps {}

const Newsletter = (props: IProps) => {
    const dispatch = useDispatch<AppDispatch>();

    const { loading } = useSelector((state: StateType) => state.newsfeed);

    const initialValues = {
        email: ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required')
    });

    return (
        <div className="newsletter-container">
            <div className="app-container newsletter-container-inner">
                <div className="newsletter-content d-flex flex-column text-center text-md-start align-items-center align-items-md-start">
                    <h3 className="mb-0">$20 discount for your first order</h3>
                    <h2>Join Our Newsletter and get...</h2>
                    <p className="mb-3">
                        Join our email subscription now to get updates on
                        promotions and coupons.
                    </p>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) => {
                            dispatch(subscribeNewsfeed(values.email)).then(
                                () => {
                                    resetForm();
                                }
                            );
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            isValid,
                            errors
                        }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                                className="w-100"
                            >
                                <div className="newsletter-input-container mx-auto mx-md-0">
                                    <EnvelopIcon className="input-icon" />
                                    <input
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        placeholder="Your Email Address"
                                    />

                                    {errors.email && (
                                        <span className="position-absolute news-error-label text-danger mb-0 text-12">
                                            {errors.email}
                                        </span>
                                    )}

                                    <button type="submit" disabled={!isValid}>
                                        {loading ? (
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined className="text-white" />
                                                }
                                            />
                                        ) : (
                                            'Subscribe'
                                        )}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
                <img src={NewsLetterImg} alt="" />
            </div>
        </div>
    );
};

export default Newsletter;
