import { Col, Form, Input, Row, Space } from 'antd';
import {
    AiOutlineInstagram,
    AiOutlineMail,
    AiOutlineUser
} from 'react-icons/ai';
import AppInput from '../shared/AppInput';
import {
    FiFacebook,
    FiMail,
    FiMapPin,
    FiPhone,
    FiTwitter
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import AppButton from '../shared/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ISaveContactBody } from '../../../interfaces/Contact';
import { saveContact } from '../../../actions/contactActions';
import Error from '../shared/Error';
import { useForm } from 'antd/lib/form/Form';
import { nameRegExp } from '../../../helpers/constants';

const ContactForm = () => {
    const { social, contact_number, email, address } = useSelector(
        (state: StateType) => state.contact.contact
    );

    const { saveContactLoading, saveContactSuccess } = useSelector(
        (state: StateType) => state.contact
    );

    const [form] = useForm();

    const dispatch = useDispatch<AppDispatch>();

    const initialValues: ISaveContactBody = {
        name: '',
        message: '',
        subject: '',
        email: ''
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .matches(nameRegExp, 'Please enter your full name')
            .required('Name is required'),
        email: Yup.string()
            .trim()
            .email('Email is invalid')
            .required('Email is required'),
        subject: Yup.string().required('Subject is required'),
        message: Yup.string().required('Message is required')
    });

    return (
        <div
            className="app-container contact-form-container"
            style={{ marginTop: '-170px' }}
        >
            <Row gutter={20}>
                <Col xs={24} md={12} lg={14} className="contact-form">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={saveContactSuccess}
                        onSubmit={(values, { resetForm }) => {
                            dispatch(saveContact(values)).then(() => {
                                resetForm();
                                form.resetFields();
                            });
                        }}
                        isInitialValid={false}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            isValid,
                            errors
                        }) => (
                            <Form
                                layout="vertical"
                                onSubmitCapture={handleSubmit}
                                form={form}
                            >
                                <h4>Send Us a Message</h4>

                                <AppInput
                                    label="Full Name"
                                    placeholder="Your Name Here"
                                    name="name"
                                    onChange={handleChange}
                                    value={values.name}
                                    suffix={
                                        <AiOutlineUser className="text-gray-4" />
                                    }
                                />

                                {errors.name && <Error>{errors.name}</Error>}

                                <AppInput
                                    label="Reason for contact?"
                                    placeholder="Subject"
                                    name="subject"
                                    onChange={handleChange}
                                    value={values.subject}
                                />

                                {errors.subject && (
                                    <Error>{errors.subject}</Error>
                                )}

                                <AppInput
                                    label="Email Address"
                                    placeholder="Your Email Here"
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    value={values.email}
                                    suffix={
                                        <AiOutlineMail className="text-gray-4" />
                                    }
                                />

                                {errors.email && <Error>{errors.email}</Error>}

                                <Form.Item
                                    name={['user', 'introduction']}
                                    label="Description"
                                >
                                    <Input.TextArea
                                        className="app-input"
                                        placeholder="Your Text Here"
                                        name="message"
                                        onChange={handleChange}
                                        value={values.message}
                                        rows={6}
                                    />
                                </Form.Item>

                                {errors.message && (
                                    <Error>{errors.message}</Error>
                                )}

                                <Form.Item>
                                    <AppButton
                                        type="primary"
                                        htmlType="submit"
                                        size="sm"
                                        className="px-4"
                                        loading={saveContactLoading}
                                        disabled={
                                            saveContactLoading || !isValid
                                        }
                                    >
                                        Send Message
                                    </AppButton>
                                </Form.Item>
                            </Form>
                        )}
                    </Formik>

                    <span className="contact-form-footer">
                        By clicking “Send Message” you agree to recieve
                        marketing communication from us in accordance with our{' '}
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </span>
                </Col>

                <Col xs={24} md={12} lg={10} className="p-0">
                    <div className="contact-info-container px-4">
                        <h4 className="text-light-gray-2 mb-5 mb-md-3">
                            Contact Information
                        </h4>

                        <div className="contact-info-detail">
                            <div className="mb-4 d-flex">
                                <FiMapPin
                                    size={24}
                                    className="text-light-gray-2"
                                    style={{
                                        marginRight: 30,
                                        minWidth: 24,
                                        marginTop: 2
                                    }}
                                />
                                <span className="text-montserrat text-light-gray-2 text-600 text-18">{`${address?.address_line1}, ${address?.area}, ${address?.district}`}</span>
                            </div>
                            <div className="mb-4 d-flex">
                                <FiPhone
                                    size={24}
                                    className="text-light-gray-2"
                                    style={{
                                        marginRight: 30,
                                        minWidth: 24,
                                        marginTop: 2
                                    }}
                                />
                                <span className="text-montserrat text-light-gray-2 text-600 text-18">
                                    {contact_number}
                                </span>
                            </div>
                            <div className="mb-4 d-flex">
                                <FiMail
                                    size={24}
                                    className="text-light-gray-2"
                                    style={{
                                        marginRight: 30,
                                        minWidth: 24,
                                        marginTop: 2
                                    }}
                                />
                                <span className="text-montserrat text-light-gray-2 text-600 text-18">
                                    {email}
                                </span>
                            </div>
                        </div>
                        <Space
                            className="contact-info-social justify-content-center"
                            align="center"
                            direction="horizontal"
                            size={40}
                        >
                            {!!social?.facebook && (
                                <a href={social.facebook} target="#">
                                    <FiFacebook
                                        size={24}
                                        className="text-light-gray-2"
                                    />
                                </a>
                            )}

                            {!!social?.twitter && (
                                <a href={social.twitter} target="#">
                                    <FiTwitter
                                        size={24}
                                        className="text-light-gray-2"
                                    />
                                </a>
                            )}

                            {!!social?.instagram && (
                                <a href={social.instagram} target="#">
                                    <AiOutlineInstagram
                                        size={24}
                                        className="text-light-gray-2"
                                    />
                                </a>
                            )}
                        </Space>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ContactForm;
