import { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import BikeIcon from '../../../assets/images/bike-icon.png';
import CarrotIcon from '../../../assets/images/carrot.png';
import CarrotActiveIcon from '../../../assets/images/carrot-active.png';
import ChickenIcon from '../../../assets/images/chicken.png';
import ChickenActiveIcon from '../../../assets/images/chicken-active.png';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import Rating from 'react-rating';
import { Rate } from '../../../interfaces/Product';
import AppButton from '../shared/AppButton';
import Counter from '../shared/Counter';
import { addToCart, addToWishList, buyNow } from '../../../actions/cartActions';
import { useHistory, useLocation } from 'react-router';
import { IoMdHeart, IoMdHeartEmpty } from 'react-icons/io';
import { Col, Dropdown, Menu, Row, Space } from 'antd';
import {
    FacebookMessengerShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share';
import {
    Facebook,
    Twitter,
    Whatsapp,
    FacebookMessenger
} from '../../../assets/images/icons';
import { FACEBOOK_APPID, meatMarketTypeId } from '../../../helpers/constants';
import { ShareAltOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { FaHeart, FaRegHeart } from 'react-icons/fa';

interface IProps {}

const Brief = (props: IProps) => {
    const { product, reviews } = useSelector(
        (state: StateType) => state.product
    );

    const auth = useSelector((state: StateType) => state.auth);

    const freeDeliveryAmount = useSelector(
        (state: StateType) =>
            state.payment.deliveryCharge.free_delivery_min_order_amt
                ?.inside_ringroad
    );

    const [unit, setUnit] = useState(1);

    const [wishlisted, setWishlisted] = useState(product.has_wishlisted);

    const [animate, setAnimate] = useState(false);

    const [cartLoading, setCartLoading] = useState(false);

    const dispatch = useDispatch<AppDispatch>();

    const history = useHistory();

    const location = useLocation();

    const [selectedRate, setSelectedRate] = useState<Rate>();

    useEffect(() => {
        if (product.rates?.length) {
            setSelectedRate(product.rates[0]);
        }
    }, [product.rates]);

    const handleRateChange = (rate?: Rate) => {
        if (rate) setSelectedRate(rate);
    };

    const handleUnitChange = (value?: number) => {
        if (value) setUnit(value);
    };

    const handleAddToCart = () => {
        if (selectedRate?._id && product._id) {
            setCartLoading(true);

            dispatch(
                addToCart({
                    quantity: unit,
                    rate_id: selectedRate?._id,
                    product_id: product._id
                })
            )
                .then(() => setCartLoading(false))
                .catch(() => setCartLoading(false));
        }
    };

    const handleBuyNow = () => {
        if (selectedRate?._id && product._id) {
            dispatch(
                buyNow({
                    quantity: unit,
                    rate_id: selectedRate?._id,
                    product_id: product._id
                })
            ).then(() => {
                history.push('/cart/checkout?buy-now');
            });
        }
    };

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const isMeat =
        product.type?._id === meatMarketTypeId ||
        product.type?.parent_id?._id === meatMarketTypeId;

    const shareMenu = (
        <Menu className="px-3 rounded-3">
            <Space
                size={20}
                direction="horizontal"
                style={{ paddingTop: 12, paddingBottom: 12 }}
            >
                <FacebookShareButton
                    url={`farmgro.com.np/products/${product.slug}`}
                    quote="FarmGro"
                >
                    <Facebook />
                </FacebookShareButton>

                <FacebookMessengerShareButton
                    appId={FACEBOOK_APPID}
                    url={`farmgro.com.np/products/${product.slug}`}
                >
                    <FacebookMessenger />
                </FacebookMessengerShareButton>

                <WhatsappShareButton
                    url={`farmgro.com.np/products/${product.slug}`}
                    title={product.name}
                >
                    <Whatsapp />
                </WhatsappShareButton>

                <TwitterShareButton
                    url={`farmgro.com.np/products/${product.slug}`}
                    title={product.name}
                    via="farmgro.com.np"
                >
                    <Twitter />
                </TwitterShareButton>
            </Space>
        </Menu>
    );

    return (
        <div className="product-container">
            <Row
                className="product-wrapper pt-lg-3 pb-lg-3"
                gutter={{ lg: 56 }}
            >
                <Col className="product-wrapper-left" xs={24} lg={10}>
                    {product.discount?.is_active && (
                        <span className="product-discount">
                            {product.discount.discount_percentage}% Off
                        </span>
                    )}

                    <div className="image-slider">
                        <ImageGallery
                            items={
                                product.images?.map((image) => ({
                                    original: image,
                                    thumbnail: image,
                                    originalHeight: 245,
                                    thumbnailHeight: 70,
                                    thumbnailWidth: 70
                                })) ?? []
                            }
                            showPlayButton={false}
                            showFullscreenButton={false}
                            showBullets={!isDesktopOrLaptop}
                            showThumbnails={isDesktopOrLaptop}
                            showNav={isDesktopOrLaptop}
                            additionalClass={isMeat ? 'image-gallery-meat' : ''}
                        />
                    </div>

                    {isDesktopOrLaptop && (
                        <div className="offer-info">
                            <div className="home-delivery-section">
                                <img src={BikeIcon} alt="" />
                                <p className="mb-0">
                                    Free Home Delivery
                                    <br />
                                    Inside ringroad for over Rs.
                                    {freeDeliveryAmount}
                                </p>
                            </div>
                        </div>
                    )}
                </Col>
                <Col className="product-wrapper-right" xs={24} lg={14}>
                    <div>
                        <div className="detail-header">
                            <div className="d-flex flex-column flex-lg-row align-items-lg-center">
                                <h2 className="d-flex flex-grow-1 text-dark-color mb-3 me-3">
                                    <span className="flex-grow-1">
                                        {product.name}
                                    </span>

                                    {!isDesktopOrLaptop &&
                                        (wishlisted ? (
                                            <div
                                                className={`ms-3 detail-like-container detail-liked cursor-pointer ${
                                                    isMeat
                                                        ? 'detail-liked-meat'
                                                        : ''
                                                }`}
                                                onClick={() => {
                                                    if (auth.authenticated) {
                                                        setWishlisted(false);

                                                        if (product._id)
                                                            dispatch(
                                                                addToWishList(
                                                                    product._id
                                                                )
                                                            )
                                                                .then(() => {})
                                                                .catch(() => {
                                                                    setWishlisted(
                                                                        true
                                                                    );
                                                                });
                                                    } else {
                                                        history.push('/login', {
                                                            from: location
                                                        });
                                                    }
                                                }}
                                            >
                                                <FaHeart
                                                    className={`${
                                                        isMeat
                                                            ? 'text-meat-dark'
                                                            : 'text-orange'
                                                    } ${
                                                        animate
                                                            ? 'wishlist-animate'
                                                            : ''
                                                    }`}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="ms-3 detail-like-container cursor-pointer"
                                                onClick={() => {
                                                    if (auth.authenticated) {
                                                        setWishlisted(true);

                                                        setAnimate(true);

                                                        if (product._id)
                                                            dispatch(
                                                                addToWishList(
                                                                    product._id
                                                                )
                                                            )
                                                                .then(() => {
                                                                    setAnimate(
                                                                        false
                                                                    );
                                                                })
                                                                .catch(() => {
                                                                    setAnimate(
                                                                        false
                                                                    );
                                                                    setWishlisted(
                                                                        false
                                                                    );
                                                                });
                                                    } else {
                                                        history.push('/login', {
                                                            from: location
                                                        });
                                                    }
                                                }}
                                            >
                                                <FaRegHeart
                                                    size={18}
                                                    className="text-gray-3"
                                                />
                                            </div>
                                        ))}
                                </h2>

                                <Dropdown
                                    overlay={shareMenu}
                                    trigger={['click']}
                                    className="cursor-pointer mb-3 align-self-start"
                                    align={{ offset: [8, 8] }}
                                    placement="bottomRight"
                                >
                                    <span className="text-roboto text-400 text-gray-2 text-16 d-flex align-items-center text-nowrap">
                                        <ShareAltOutlined
                                            className="me-2"
                                            style={{ fontSize: 22 }}
                                        />
                                        Share Product
                                    </span>
                                </Dropdown>
                            </div>
                            <div className="detail-sub">
                                <div className="detail-sub__inner">
                                    Category:{' '}
                                    <span className="category">
                                        {product.category?.name}
                                    </span>
                                </div>

                                <div className="detail-sub__inner">
                                    Product Code: <span>{product.code}</span>
                                </div>

                                {!isDesktopOrLaptop && (
                                    <div className="detail-sub__inner">
                                        Status:{' '}
                                        <span>
                                            {product.in_stock
                                                ? 'In Stock'
                                                : 'Out of Stock'}
                                        </span>
                                    </div>
                                )}

                                <div className="detail-sub__inner">
                                    <Rating
                                        emptySymbol={
                                            <img
                                                src={
                                                    isMeat
                                                        ? ChickenIcon
                                                        : CarrotIcon
                                                }
                                                alt=""
                                            />
                                        }
                                        fullSymbol={
                                            <img
                                                src={
                                                    isMeat
                                                        ? ChickenActiveIcon
                                                        : CarrotActiveIcon
                                                }
                                                alt=""
                                            />
                                        }
                                        fractions={2}
                                        readonly
                                        initialRating={product.average_rating}
                                        className="me-3"
                                    />

                                    <span>
                                        {reviews.totalDocuments ?? 0} reviews
                                    </span>
                                </div>
                            </div>
                        </div>

                        {isDesktopOrLaptop && (
                            <p
                                className="description"
                                dangerouslySetInnerHTML={{
                                    __html: product.detail ?? ''
                                }}
                            />
                        )}
                    </div>

                    <div>
                        <div className="detail-footer">
                            <div className="detail-footer__inner">
                                {selectedRate?.discounted_price &&
                                selectedRate.price &&
                                selectedRate?.discounted_price <
                                    selectedRate?.price ? (
                                    <span>
                                        Rs. {selectedRate?.price} /{' '}
                                        {selectedRate?.quantity}
                                        {selectedRate?.unit_id?.name}
                                    </span>
                                ) : (
                                    ''
                                )}
                                <h3 className="price">
                                    Rs. {selectedRate?.discounted_price} /{' '}
                                    {selectedRate?.quantity}
                                    {selectedRate?.unit_id?.name}
                                </h3>
                                <div className="unit-tag">
                                    {product.rates?.map((rate, index) => (
                                        <button
                                            className={`unit-btn ${
                                                rate === selectedRate
                                                    ? 'unit-btn__active'
                                                    : ''
                                            } text-uppercase`}
                                            onClick={() => {
                                                handleRateChange(rate);
                                            }}
                                            key={index}
                                        >
                                            {rate.quantity} {rate.unit_id?.name}
                                        </button>
                                    ))}
                                </div>
                            </div>

                            {!isDesktopOrLaptop ? (
                                <Counter
                                    isMeat={isMeat}
                                    disabled={!product.in_stock}
                                    max={!product.in_stock ? 1 : undefined}
                                    min={1}
                                    onChange={handleUnitChange}
                                    value={unit}
                                />
                            ) : (
                                <span className="stock-tag">
                                    {product.in_stock
                                        ? 'In Stock'
                                        : 'Out of Stock'}
                                </span>
                            )}
                        </div>

                        {isDesktopOrLaptop && product.in_stock && (
                            <div className="mt-4">
                                <Counter
                                    isMeat={isMeat}
                                    disabled={!product.in_stock}
                                    max={!product.in_stock ? 1 : undefined}
                                    min={1}
                                    onChange={handleUnitChange}
                                    value={unit}
                                />
                            </div>
                        )}

                        <div className="product-btns d-flex flex-column flex-sm-row align-items-sm-center mt-4">
                            <div className="flex-grow-1 d-flex">
                                <AppButton
                                    className="px-4 px-xl-5 me-3 mb-2 flex-grow-1 flex-sm-grow-0"
                                    disabled={!product.in_stock || cartLoading}
                                    onClick={() => {
                                        if (auth.authenticated) {
                                            handleAddToCart();
                                        } else {
                                            history.push('/login', {
                                                from: location
                                            });
                                        }
                                    }}
                                    loading={cartLoading}
                                    size={isDesktopOrLaptop ? 'lg' : 'sm'}
                                    isMeat={isMeat}
                                >
                                    Add to cart
                                </AppButton>

                                <AppButton
                                    className="px-4 px-xl-5 mb-2 flex-grow-1 flex-sm-grow-0"
                                    disabled={!product.in_stock}
                                    type="primary-outlined"
                                    onClick={() => {
                                        if (auth.authenticated) {
                                            handleBuyNow();
                                        } else {
                                            history.push('/login', {
                                                from: location
                                            });
                                        }
                                    }}
                                    size={isDesktopOrLaptop ? 'lg' : 'sm'}
                                    isMeat={isMeat}
                                >
                                    Buy Now
                                </AppButton>
                            </div>

                            {isDesktopOrLaptop &&
                                (wishlisted ? (
                                    <div
                                        className={`product-whislist-btn ms-sm-3 mt-3 mt-sm-0 cursor-pointer text-nowrap mb-2 ${
                                            isMeat
                                                ? 'text-meat-dark'
                                                : 'text-orange-light'
                                        }`}
                                        onClick={() => {
                                            if (auth.authenticated) {
                                                setWishlisted(false);

                                                if (product._id)
                                                    dispatch(
                                                        addToWishList(
                                                            product._id
                                                        )
                                                    )
                                                        .then(() => {})
                                                        .catch(() => {
                                                            setWishlisted(true);
                                                        });
                                            } else {
                                                history.push('/login', {
                                                    from: location
                                                });
                                            }
                                        }}
                                    >
                                        {' '}
                                        <IoMdHeart
                                            size={24}
                                            className={`me-2 ${
                                                animate
                                                    ? 'wishlist-animate'
                                                    : ''
                                            }`}
                                        />
                                        Remove from wishlist
                                    </div>
                                ) : (
                                    <div
                                        className={`product-whislist-btn ms-sm-3 mt-3 mt-sm-0 cursor-pointer text-nowrap mb-2 ${
                                            isMeat
                                                ? 'text-meat-dark'
                                                : 'text-orange-light'
                                        }`}
                                        onClick={() => {
                                            if (auth.authenticated) {
                                                setWishlisted(true);

                                                setAnimate(true);

                                                if (product._id)
                                                    dispatch(
                                                        addToWishList(
                                                            product._id
                                                        )
                                                    )
                                                        .then(() => {
                                                            setAnimate(false);
                                                        })
                                                        .catch(() => {
                                                            setAnimate(false);
                                                            setWishlisted(
                                                                false
                                                            );
                                                        });
                                            } else {
                                                history.push('/login', {
                                                    from: location
                                                });
                                            }
                                        }}
                                    >
                                        {' '}
                                        <IoMdHeartEmpty
                                            size={24}
                                            className="me-2"
                                        />
                                        Add to Wishlist
                                    </div>
                                ))}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Brief;
