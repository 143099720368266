import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface IProps {
    className?: string;
    size?: 'sm';
}

const AppSpinner = ({ className = '', size }: IProps) => (
    <Spin
        className={`d-block ${className}`}
        indicator={
            <LoadingOutlined
                style={{ fontSize: size === 'sm' ? 18 : 32 }}
                spin
                className="text-orange"
            />
        }
    />
);

export default AppSpinner;
