import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPrivacyPolicy } from '../../../actions/pagesActions';
import { StateType } from '../../../reducers';
import MainLayout from '../../layouts/MainLayout';

const PrivacyPolicy = () => {
    const { privacyPolicy } = useSelector((state: StateType) => state.pages);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!privacyPolicy) dispatch(getPrivacyPolicy());
    }, [dispatch, privacyPolicy]);

    return (
        <MainLayout mainClassname="bg-white">
            <section className="bg-gray-1 text-center px-3 py-5 mb-4">
                <h1 className="mt-3 text-inter text-700 text-36 text-dark-color">
                    Privacy Policy
                </h1>
            </section>

            {!!privacyPolicy && (
                <section
                    className="app-container py-4"
                    dangerouslySetInnerHTML={{ __html: privacyPolicy }}
                />
            )}
        </MainLayout>
    );
};

export default PrivacyPolicy;
