import { ArrowRightOutlined } from '@ant-design/icons';
import { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { useHover } from 'usehooks-ts';
import { defaultImage } from '../../../helpers/constants';

interface IProps {
    categoryImg?: string;
    categoryName?: string;
    id?: string;
}
const ProductCategory = (props: IProps) => {
    const { categoryImg, categoryName, id } = props;

    const hoverRef = useRef<HTMLDivElement>(null);

    const isHover = useHover(hoverRef);

    const isMobile = useMediaQuery({ maxWidth: 576 });

    const history = useHistory();

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    return (
        <div
            className="categories-card bg-white py-2 px-1 px-md-2 px-lg-3 d-flex flex-column align-items-center align-items-lg-stretch justify-content-center justify-content-lg-between cursor-pointer"
            onClick={() => history.push(`/products?type=${id}&meatMarket=true`)}
            ref={hoverRef}
        >
            <div>
                <p className="mb-0 card-title">{categoryName}</p>
            </div>

            <div className="d-flex justify-content-between">
                {isDesktopOrLaptop && (
                    <div className="d-flex align-items-center align-self-end">
                        {isHover && (
                            <>
                                <p className="see-all-text mb-0">See all</p>
                                <ArrowRightOutlined className="see-all-text ms-1" />
                            </>
                        )}
                    </div>
                )}

                <img
                    className="mb-1 mb-lg-0"
                    src={!!categoryImg ? categoryImg : defaultImage}
                    height={isDesktopOrLaptop ? 85 : isMobile ? 45 : 60}
                    alt="category"
                />
            </div>
        </div>
    );
};

export default ProductCategory;
