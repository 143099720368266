import { GET_OFFERS } from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IOfferResponse } from '../interfaces/Offer';

export interface IOfferState {
    offersLoading: boolean;
    offers: IOfferResponse;
}

const initState: IOfferState = {
    offersLoading: false,
    offers: {
        offers: []
    }
};

const offerReducer = (state = initState, action: ActionType) => {
    let { type, payload } = action;

    switch (type) {
        case GET_OFFERS:
            return {
                ...state,
                ...payload
            };

        default:
            return state;
    }
};

export default offerReducer;
