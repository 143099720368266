import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTermsConditions } from '../../../actions/pagesActions';
import { StateType } from '../../../reducers';
import MainLayout from '../../layouts/MainLayout';

const TermsAndConditions = () => {
    const { termsConditions } = useSelector((state: StateType) => state.pages);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!termsConditions) dispatch(getTermsConditions());
    }, [dispatch, termsConditions]);

    return (
        <MainLayout mainClassname="bg-white">
            <section className="bg-gray-1 text-center px-3 py-5 mb-4">
                <h1 className="mt-3 text-inter text-700 text-36 text-dark-color">
                    Terms & Conditions
                </h1>
            </section>

            {!!termsConditions && (
                <section
                    className="app-container py-4"
                    dangerouslySetInnerHTML={{ __html: termsConditions }}
                />
            )}
        </MainLayout>
    );
};

export default TermsAndConditions;
