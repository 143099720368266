import { Avatar, Button, Card } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import { FiGrid, FiHelpCircle, FiPackage } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import {
    getUserDashboardOrders,
    getWalletBalance
} from '../../../../actions/userActions';
import { Cart, DWallet } from '../../../../assets/images/icons';
import { StateType } from '../../../../reducers';
import AppButton from '../../shared/AppButton';
import OrderPopover from './components/OrderPopover';

const Dashboard = () => {
    const { dashboardOrders: orders, wallet } = useSelector(
        (state: StateType) => state.user
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserDashboardOrders({ resultsPerPage: 5 }));

        dispatch(getWalletBalance());
    }, [dispatch]);

    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <div
            className={`dashboard-container ${
                isMobile ? 'app-container mb-5 mb-md-0' : ''
            }`}
        >
            {isMobile && (
                <div className="py-3">
                    <Link
                        to="/dashboard"
                        className="text-600 text-14 text-montserrat text-black"
                    >
                        <BiChevronLeft size={24} className="me-2 ms-n2" />
                        Dashboard
                    </Link>
                </div>
            )}

            <div className="row">
                {!isMobile && (
                    <span className="d-flex align-items-center mb-3 text-inter text-20 text-500 text-dark-color">
                        <FiGrid size={24} className="me-2" />
                        Dashboard
                    </span>
                )}

                <div
                    className="col-12 col-lg-7 mb-3 mb-lg-0"
                    style={{ wordBreak: 'break-all' }}
                >
                    <div className="site-card-border-less-wrapper h-100">
                        <Card
                            title={
                                <span className="text-inter text-500 text-dark-color text-16">
                                    <FiPackage
                                        size={24}
                                        style={{ marginRight: '10px' }}
                                    />
                                    My Orders
                                </span>
                            }
                            bordered={false}
                            style={{ width: '100%' }}
                            headStyle={{
                                padding: isMobile ? '0 16px' : undefined
                            }}
                            actions={
                                orders.orders.length &&
                                orders.totalPage &&
                                orders.totalPage > 1
                                    ? [
                                          <Link to="/dashboard?orders">
                                              <Button
                                                  type="link"
                                                  size={'large'}
                                                  style={{
                                                      fontSize: isMobile
                                                          ? 14
                                                          : 16
                                                  }}
                                              >
                                                  See All Orders
                                              </Button>
                                          </Link>
                                      ]
                                    : undefined
                            }
                            className="rounded-3 overflow-hidden d-flex flex-column"
                            bodyStyle={{
                                flexGrow: 1,
                                padding: isMobile ? '0 16px' : undefined
                            }}
                        >
                            <div className="my-2">
                                {orders.orders.length ? (
                                    orders.orders.map((order) => (
                                        <div key={order._id} className="row">
                                            <div className="col-5 order-col">
                                                <span>
                                                    Order ID:{' '}
                                                    <span
                                                        className="d-inline-block text-truncate"
                                                        style={{
                                                            verticalAlign:
                                                                'middle',
                                                            maxWidth: 115
                                                        }}
                                                    >
                                                        #{order.identifier}
                                                    </span>
                                                </span>
                                                <br />
                                                <span>
                                                    <b>
                                                        {order.carts?.length ??
                                                            0}{' '}
                                                        Item
                                                        {(order.carts?.length ??
                                                            0) > 1 && 's'}
                                                        {order.carts
                                                            ?.length && (
                                                            <OrderPopover
                                                                carts={
                                                                    order.carts
                                                                }
                                                            >
                                                                <FiHelpCircle
                                                                    className="ms-2 text-gray-2 text-hover-orange cursor-pointer"
                                                                    size={14}
                                                                />
                                                            </OrderPopover>
                                                        )}
                                                    </b>
                                                </span>
                                            </div>
                                            <div className="col-4 status-col">
                                                <span>Delivery Status</span>
                                                <br />
                                                <span>
                                                    <b>{order.status}</b>
                                                </span>
                                            </div>
                                            <div className="col-3 price-col">
                                                <span>Price</span>
                                                <br />
                                                <span>
                                                    <b className="text-nowrap">
                                                        Rs. {order.total_amount}
                                                    </b>
                                                </span>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="my-2 text-center">
                                        <Cart className="mb-4" />

                                        <h4 className="text-inter text-500 text-20 text-dark">
                                            No Orders Yet!
                                        </h4>

                                        <p className="text-montserrat text-16 text-400 text-gray-2">
                                            You dont have any orders till now.
                                            You can see list here once you place
                                            an order.
                                        </p>

                                        <Link to="/products">
                                            <AppButton
                                                type="primary-outlined"
                                                className="px-4"
                                            >
                                                Find me Products
                                            </AppButton>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </Card>
                    </div>
                </div>

                <div className="col-12 col-lg-5">
                    <Card
                        title={
                            <span className="d-flex align-items-center text-inter text-500 text-dark-color text-16">
                                <DWallet style={{ marginRight: '10px' }} />
                                <span>My Wallet</span>
                            </span>
                        }
                        bordered={false}
                        className="rounded-3 overflow-hidden"
                        headStyle={{
                            padding: isMobile ? '0 16px' : undefined
                        }}
                    >
                        <div className="row my-2 text-center ">
                            <Avatar
                                className="d-flex align-items-center justify-content-center"
                                icon={
                                    <DWallet
                                        color="#f47b34"
                                        height={45}
                                        width={45}
                                    />
                                }
                                size={100}
                                style={{
                                    borderRadius: '50%',
                                    margin: '0 auto',
                                    backgroundColor: '#FDE5D6',
                                    marginBlock: '0 10px'
                                }}
                            />

                            <p>Available in Wallet</p>
                            <h4>Rs. {wallet.balance ?? 0}</h4>

                            {!!wallet.updatedAt && (
                                <span className="bg-color w-auto mx-auto text-montserrat text-14 text-gray-2 px-3 py-2 rounded-3">
                                    Added: {moment(wallet.updatedAt).fromNow()}{' '}
                                    (
                                    {moment(wallet.updatedAt).format(
                                        'D MMM YYYY'
                                    )}
                                    )
                                </span>
                            )}
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
