import { Collapse, Space } from 'antd';
import { useEffect } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { getFAQ } from '../../../actions/pagesActions';
import { StateType } from '../../../reducers';
import MainLayout from '../../layouts/MainLayout';

const { Panel } = Collapse;

const FAQ = () => {
    const { faq } = useSelector((state: StateType) => state.pages);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!faq.length) dispatch(getFAQ());
    }, [dispatch, faq.length]);

    return (
        <MainLayout mainClassname="bg-white">
            <section className="bg-gray-1 text-center px-3 py-5 mb-4">
                <h1 className="mt-3 text-inter text-700 text-56 text-dark-color">
                    Questions? Look Here
                </h1>

                <p className="text-montserrat text-16 text-black">
                    Can’t find answer? Call us at 01-4422331 or email
                    contact@farmgro.com{' '}
                </p>
            </section>

            {!!faq.length && (
                <section className="py-5 px-3 mx-auto d-flex justify-content-center">
                    <Space
                        direction="vertical"
                        className="w-100"
                        style={{ maxWidth: 900 }}
                        size={24}
                    >
                        {faq.map((items, index) => (
                            <Collapse
                                key={items._id}
                                bordered={false}
                                ghost
                                expandIconPosition="right"
                                className="rounded-3 bg-gray-1"
                                expandIcon={(props) =>
                                    props.isActive ? (
                                        <FiMinus size={24} />
                                    ) : (
                                        <FiPlus size={24} />
                                    )
                                }
                            >
                                <Panel
                                    header={items.question}
                                    key={index}
                                    className="text-inter text-20"
                                >
                                    <p className="text-montserrat text-16 text-gray">
                                        {items.answer}
                                    </p>
                                </Panel>
                            </Collapse>
                        ))}
                    </Space>
                </section>
            )}
        </MainLayout>
    );
};

export default FAQ;
