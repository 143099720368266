import { Button } from 'antd';
import { FunctionComponent } from 'react';

type ButtonType = 'primary' | 'primary-outlined' | 'secondary' | 'text';
type ButtonSizeType = 'sm' | 'lg';

interface IProps {
    size?: ButtonSizeType;
    children: React.ReactNode;
    className?: string;
    type?: ButtonType;
    disabled?: boolean;
    onClick?: React.MouseEventHandler;
    loading?: boolean;
    style?: React.CSSProperties;
    htmlType?: 'button' | 'submit' | 'reset';
    form?: string;
    isMeat?: boolean;
}

const AppButton: FunctionComponent<IProps> = (props) => {
    function getClassName(variant?: ButtonType) {
        switch (variant) {
            case 'secondary':
                return `app-secondary-btn ${
                    props.isMeat ? 'app-secondary-meat-btn' : ''
                }`;
            case 'primary-outlined':
                return `app-primary-outlined-btn ${
                    props.isMeat ? 'app-primary-outlined-meat-btn' : ''
                }`;
            default:
                return `app-primary-btn ${
                    props.isMeat ? 'app-primary-meat-btn' : ''
                }`;
        }
    }

    return (
        <Button
            className={
                `app-btn rounded-pill ${getClassName(props.type)} ${
                    props.size === 'sm' ? 'app-btn-sm' : 'app-btn-lg'
                } ` + props.className
            }
            disabled={props.disabled}
            onClick={props.onClick}
            style={props.style}
            loading={props.loading}
            htmlType={props.htmlType}
            form={props.form}
        >
            {props.children}
        </Button>
    );
};

AppButton.defaultProps = {
    className: ''
};

export default AppButton;
