import MainLayout from '../../layouts/MainLayout';
import { Breadcrumb, Space } from 'antd';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { FiMessageCircle, FiThumbsDown, FiThumbsUp } from 'react-icons/fi';
import { Facebook, Twitter, Whatsapp } from '../../../assets/images/icons';
import GlobalSlider from '../../components/shared/slider/GlobalSlider';
import BlogCard from '../AboutUs/BlogCard';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import { ChangeEvent, useEffect, useRef } from 'react';
import {
    dislikeBlog,
    getBlogBySlug,
    getBlogComments,
    getBlogs,
    getSimilarBlogs,
    likeBlog,
    resetBlogComments
} from '../../../actions/blogActions';
import AppButton from '../../components/shared/AppButton';
import { $FIXME, FACEBOOK_APPID } from '../../../helpers/constants';
import AppSpinner from '../../components/shared/AppSpinner';
import { useState } from 'react';
import BlogCommentCard from '../../components/shared/BlogCommentCard';
import request from '../../../helpers/request';
import { AxiosError } from 'axios';
import { showToast } from '../../../utils/toast';
import {
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share';
import moment from 'moment';

const Blog = () => {
    const { authenticated } = useSelector((state: StateType) => state.auth);

    const {
        blogs,
        blog,
        blogLoading,
        blogComments,
        blogCommentsLoading,
        similarBlogs
    } = useSelector((state: StateType) => state.blog);

    const [comment, setComment] = useState<string>('');

    const [likeState, setLikeState] = useState<boolean | null>();

    const [commenting, setCommenting] = useState(false);

    const { slug }: $FIXME = useParams();

    const location = useLocation();

    const history = useHistory();

    const dispatch = useDispatch<AppDispatch>();

    const commentEl = useRef<HTMLDivElement>(null);

    const blogResponsive = [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: blogs.length >= 2,
                centerPadding: '120px'
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: blogs.length >= 1,
                centerPadding: '24px'
            }
        }
    ];

    const commentResultsPerPage = 4;

    useEffect(() => {
        setLikeState(blog.has_liked);
    }, [blog.has_liked]);

    const handleLike = () => {
        if (blog._id) {
            dispatch(likeBlog(blog._id));
        }
    };

    const handleDislike = () => {
        if (blog._id) {
            dispatch(dislikeBlog(blog._id));
        }
    };

    const handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
    };

    const handleCommentSubmit = () => {
        if (authenticated) {
            if (blog._id && !!comment) {
                setCommenting(true);
                request
                    .postCommnent({ blog: blog._id, comment })
                    .then(() => {
                        dispatch(resetBlogComments());

                        if (blog._id)
                            dispatch(
                                getBlogComments(blog._id, {
                                    resultsPerPage: commentResultsPerPage
                                })
                            );
                        setCommenting(false);
                        setComment('');
                    })
                    .catch((err: AxiosError) => {
                        showToast('error', err.response?.data?.message);

                        setCommenting(false);
                    });
            }
        } else {
            history.push('/login', { from: location });
        }
    };

    const handleLoadMoreComments = () => {
        if (
            blog._id &&
            blogComments.currentPage &&
            blogComments.currentPage !== blogComments.totalPage
        ) {
            dispatch(
                getBlogComments(blog._id, {
                    resultsPerPage: commentResultsPerPage,
                    page: blogComments.currentPage + 1
                })
            );
        }
    };

    useEffect(() => {
        dispatch(resetBlogComments());

        if (!!slug) {
            dispatch(getBlogBySlug(slug)).then((id) => {
                dispatch(
                    getBlogComments(id, {
                        resultsPerPage: commentResultsPerPage
                    })
                );

                dispatch(getSimilarBlogs(id));
            });
        }
    }, [dispatch, slug]);

    useEffect(() => {
        dispatch(getBlogs());
    }, [dispatch]);

    return (
        <MainLayout>
            <div className="app-container">
                <Breadcrumb className="my-2 app-breadcrumbs">
                    <Breadcrumb.Item>
                        <Link
                            to="/"
                            className="text-montserrat text-12 text-light-gray-1"
                        >
                            Home
                        </Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                        <span className="text-montserrat text-12 text-orange">
                            Blog
                        </span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="app-container blog-detail-container">
                {blogLoading && slug !== blog.slug ? (
                    <div className="py-5">
                        <AppSpinner />
                    </div>
                ) : (
                    <>
                        {!!blog.thumbnail && (
                            <img
                                src={blog.thumbnail}
                                style={{ height: 360, maxHeight: 360 }}
                                className="w-100 object-cover rounded-4 mb-4"
                                alt="profile"
                            />
                        )}

                        <div style={{ maxWidth: 794 }} className="mx-auto pb-5">
                            <h1 className="text-inter text-600 text-24 text-dark-color mb-3">
                                {blog.title}
                            </h1>

                            <div className="mb-4 d-flex align-items-center">
                                <span className="text-montserrat text-16 text-gray-3 me-4">
                                    {moment(blog.createdAt).format(
                                        'MMMM DD YYYY'
                                    )}{' '}
                                    | {blog.reading_time} read
                                </span>

                                <div className="d-flex align-items-center me-3">
                                    <FiThumbsUp
                                        size={20}
                                        className={`${
                                            likeState === true
                                                ? 'text-orange'
                                                : 'text-gray-3'
                                        }${
                                            authenticated
                                                ? ' cursor-pointer'
                                                : ''
                                        }`}
                                        onClick={() =>
                                            authenticated && handleLike()
                                        }
                                    />
                                    <span className="text-montserrat text-16 text-gray-3 ms-1">
                                        {blog.likes_count}
                                    </span>
                                </div>

                                <div className="d-flex align-items-center me-3">
                                    <FiThumbsDown
                                        size={20}
                                        className={`${
                                            likeState === false
                                                ? 'text-orange'
                                                : 'text-gray-3'
                                        }${
                                            authenticated
                                                ? ' cursor-pointer'
                                                : ''
                                        }`}
                                        onClick={() =>
                                            authenticated && handleDislike()
                                        }
                                    />
                                    <span className="text-montserrat text-16 text-gray-3 ms-1">
                                        {blog.dislikes_count}
                                    </span>
                                </div>

                                <div className="d-flex align-items-center">
                                    <FiMessageCircle
                                        size={20}
                                        className={`text-gray-3 text-hover-orange cursor-pointer`}
                                        onClick={() => {
                                            commentEl.current?.scrollIntoView({
                                                block: 'center',
                                                behavior: 'smooth'
                                            });
                                        }}
                                    />
                                    <span className="text-montserrat text-16 text-gray-3 ms-1">
                                        {blogComments.totalDocuments ?? 0}
                                    </span>
                                </div>
                            </div>

                            <p
                                className="text-montserrat text-16 text-gray mb-4 blog-description"
                                dangerouslySetInnerHTML={{
                                    __html: blog.content ?? ''
                                }}
                            />

                            <div className="blog-share-section d-flex align-items-center">
                                <span className="flex-grow-1 me-3 text-inter text-600 text-black d-none d-sm-block">
                                    {blogComments.totalDocuments ?? 0} Comment
                                </span>

                                <div className="d-flex align-items-center py-2">
                                    <span className="me-3 text-inter text-600 text-black me-3">
                                        Social Shares
                                    </span>

                                    <Space size={14}>
                                        <FacebookShareButton
                                            url={`farmgro.com.np/blog/${slug}`}
                                            quote="FarmGro"
                                        >
                                            <Facebook />
                                        </FacebookShareButton>

                                        <FacebookMessengerShareButton
                                            appId={FACEBOOK_APPID}
                                            url={`farmgro.com.np/blog/${slug}`}
                                        >
                                            <FacebookMessengerIcon
                                                size={28}
                                                round
                                            />
                                        </FacebookMessengerShareButton>

                                        <WhatsappShareButton
                                            url={`farmgro.com.np/blog/${slug}`}
                                            title={blog.title}
                                        >
                                            <Whatsapp />
                                        </WhatsappShareButton>

                                        <TwitterShareButton
                                            url={`farmgro.com.np/blog/${slug}`}
                                            title={blog.title}
                                            via="farmgro.com.np"
                                        >
                                            <Twitter />
                                        </TwitterShareButton>
                                    </Space>
                                </div>
                            </div>

                            <div className="d-flex py-4" ref={commentEl}>
                                <input
                                    className="blog-comment-input flex-grow-1 me-3 my-2 rounded-pill px-3"
                                    placeholder="Write your comment"
                                    onChange={handleCommentChange}
                                    value={comment}
                                />

                                <AppButton
                                    size="sm"
                                    className="px-4 my-2"
                                    style={{
                                        paddingTop: 12,
                                        paddingBottom: 12
                                    }}
                                    disabled={!comment || commenting}
                                    loading={commenting}
                                    onClick={handleCommentSubmit}
                                >
                                    {authenticated
                                        ? 'Comment'
                                        : 'Login to comment'}
                                </AppButton>
                            </div>

                            <div>
                                {blogComments.comments.map((comment, index) => (
                                    <BlogCommentCard
                                        commentItem={comment}
                                        key={comment._id}
                                        isLast={
                                            blogComments.comments.length ===
                                            index + 1
                                        }
                                        hasMore={
                                            blogComments.currentPage !==
                                            blogComments.totalPage
                                        }
                                        loading={blogCommentsLoading}
                                        onLoadMore={handleLoadMoreComments}
                                    />
                                ))}

                                {blogCommentsLoading &&
                                    !blogComments.comments.length && (
                                        <AppSpinner
                                            size="sm"
                                            className="py-3"
                                        />
                                    )}
                            </div>
                        </div>
                    </>
                )}

                {!!similarBlogs.length && (
                    <div className="related-blog-container">
                        <h2 className="text-inter text-600 text-32 text-dark-color">
                            Related Blogs
                        </h2>

                        <GlobalSlider
                            customSettings={{
                                slidesToShow: 3,
                                infinite: blogs.length >= 3,
                                centerMode: true,
                                centerPadding: '0',
                                responsive: blogResponsive
                            }}
                        >
                            {similarBlogs?.map((blog) => (
                                <BlogCard key={blog._id} blog={blog} />
                            ))}
                        </GlobalSlider>
                    </div>
                )}
            </div>
        </MainLayout>
    );
};

export default Blog;
