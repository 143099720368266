import { Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { BsCheck2 } from 'react-icons/bs';
import { FiMail, FiPhone } from 'react-icons/fi';
import OtpInput from 'react-otp-input';
import { useTimer } from 'react-timer-hook';
import { phoneRegExp } from '../../../../../helpers/constants';
import request from '../../../../../helpers/request';
import { showToast } from '../../../../../utils/toast';
import AppButton from '../../../shared/AppButton';
import AppInput from '../../../shared/AppInput';

interface IProps {
    visible?: boolean;
    type?: 'email' | 'phone';
    onCancel?: React.MouseEventHandler<HTMLElement>;
    onSuccess?: () => void;
}

const OTPModal = ({ visible, onCancel, type, onSuccess }: IProps) => {
    const [OTP, setOTP] = useState('');

    const [loading, setLoading] = useState(false);

    const [verifed, setVerifed] = useState(false);

    const [requesting, setRequesting] = useState(false);

    const [requested, setRequested] = useState(false);

    const [form] = useForm();

    const { seconds, isRunning, restart } = useTimer({
        expiryTimestamp: new Date(),
        onExpire: () => console.warn('onExpire called')
    });

    const handleOTPChange = (otp: string) => {
        setOTP(otp);
    };

    const handleUpdateEmailRequest = (email: string) => {
        if (!!email) {
            setRequesting(true);

            request
                .updateEmailRequest(email)
                .then((res) => {
                    showToast('success', res.data.message);

                    setRequested(true);

                    setRequesting(false);
                })
                .catch((err) => {
                    showToast('error', err.response?.data?.message);

                    setRequesting(false);
                });
        }
    };

    const handleUpdatePhoneRequest = (phone: string) => {
        if (!!phone) {
            setRequesting(true);

            request
                .updatePhoneRequest(phone)
                .then((res) => {
                    showToast(
                        'success',
                        res.data.message + `. Your test OTP is ${res.data.otp}`
                    );

                    setRequested(true);

                    setRequesting(false);
                })
                .catch((err) => {
                    showToast('error', err.response?.data?.message);

                    setRequesting(false);
                });
        }
    };

    const handleOTPSubmit = () => {
        if (type && !!OTP) {
            let email = form.getFieldValue('email');
            let phone = form.getFieldValue('phone');

            if (type === 'email' && !!email) {
                setLoading(true);

                request
                    .updateEmail(email, OTP)
                    .then(() => {
                        showToast('success', 'Your email has been updated');

                        onSuccess && onSuccess();

                        setOTP('');

                        setLoading(false);

                        setVerifed(true);
                    })
                    .catch((err) => {
                        showToast('error', err.response?.data?.message);

                        setLoading(false);
                    });
            }

            if (type === 'phone' && !!phone) {
                setLoading(true);

                request
                    .updatePhone(phone, OTP)
                    .then((res) => {
                        showToast('success', 'Your phone has been updated');

                        onSuccess && onSuccess();

                        setOTP('');

                        setLoading(false);

                        setVerifed(true);
                    })
                    .catch((err) => {
                        showToast('error', err.response?.data?.message);

                        setLoading(false);
                    });
            }
        }
    };

    const handleResendOTP = () => {
        let email = form.getFieldValue('email');
        let phone = form.getFieldValue('phone');

        if (type === 'email' && !!email) {
            request
                .updateEmailRequest(email)
                .then((res) => {
                    const time = new Date();
                    time.setSeconds(time.getSeconds() + 60);

                    restart(time);

                    showToast('success', res.data.message);
                })
                .catch((err) => {
                    showToast('error', err.response?.data?.message);
                });
        }

        if (type === 'phone' && !!phone) {
            request
                .updatePhoneRequest(phone)
                .then((res) => {
                    const time = new Date();
                    time.setSeconds(time.getSeconds() + 60);

                    restart(time);

                    showToast(
                        'success',
                        res.data.message + `. Your test OTP is ${res.data.otp}`
                    );
                })
                .catch((err) => {
                    showToast('error', err.response?.data?.message);
                });
        }
    };

    const reset = () => {
        form.resetFields();
        setVerifed(false);
        setRequested(false);
        restart(new Date());
    };

    return (
        <Modal
            centered
            visible={visible}
            onCancel={(e) => {
                setOTP('');

                if (verifed) {
                    reset();
                }

                onCancel && onCancel(e);
            }}
            footer={null}
        >
            <div className="d-flex flex-column align-items-stretch p-0 p-md-2 p-lg-4">
                {!verifed ? (
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={(values) =>
                            type === 'email'
                                ? !!values.email &&
                                  handleUpdateEmailRequest(values.email)
                                : !!values.phone &&
                                  handleUpdatePhoneRequest(values.phone)
                        }
                    >
                        <h2 className="text-inter text-600 text-24 text-dark-color mb-3">
                            {type === 'email'
                                ? 'Change Email Address'
                                : 'Change Phone Number'}
                        </h2>

                        {type === 'email' ? (
                            <AppInput
                                disabled={requested || requesting}
                                label="Email *"
                                placeholder="Email Address"
                                suffix={
                                    <FiMail className="text-gray-4" size={24} />
                                }
                                formName="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email is not valid',
                                        warningOnly: true
                                    },
                                    {
                                        required: true,
                                        message: 'Email is required',
                                        warningOnly: true
                                    }
                                ]}
                            />
                        ) : (
                            <AppInput
                                disabled={requested || requesting}
                                label={'Phone Number *'}
                                placeholder="Phone Number"
                                suffix={
                                    <FiPhone
                                        className="text-gray-4"
                                        size={24}
                                    />
                                }
                                formName="phone"
                                rules={[
                                    {
                                        message: 'Phone number is invalid',
                                        pattern: new RegExp(phoneRegExp),
                                        warningOnly: true
                                    },
                                    {
                                        required: true,
                                        message: 'Phone number is required',
                                        warningOnly: true
                                    },
                                    {
                                        min: 10,
                                        message: 'Phone must be of 10 digits',
                                        warningOnly: true
                                    }
                                ]}
                                maxLength={10}
                            />
                        )}

                        <span className="text-montserrat text-400 text-14 text-gray-3">
                            {requested ? (
                                <>
                                    {`We’ve sent you a 6 digit code. Please verify your ${
                                        type === 'email'
                                            ? 'email address'
                                            : 'phone number'
                                    }.`}{' '}
                                    <span
                                        onClick={() => reset()}
                                        className={'text-orange cursor-pointer'}
                                    >
                                        Use another?
                                    </span>
                                </>
                            ) : (
                                `We'll send a code to your ${
                                    type === 'email'
                                        ? 'email address'
                                        : 'phone number'
                                }. Your ${
                                    type === 'email' ? 'email' : 'phone'
                                } will be updated once you verify.`
                            )}
                        </span>

                        {requested && (
                            <OtpInput
                                containerStyle="update-otp-container mt-3 mb-2"
                                className="update-otp-input"
                                value={OTP}
                                onChange={handleOTPChange}
                                numInputs={6}
                                isInputNum
                            />
                        )}

                        {requested ? (
                            <AppButton
                                onClick={handleOTPSubmit}
                                className="mt-3 w-100 mb-3"
                                loading={loading}
                                disabled={OTP.length !== 6 || loading}
                                size="sm"
                            >
                                Verify
                            </AppButton>
                        ) : (
                            <AppButton
                                htmlType="submit"
                                className="mt-3 w-100"
                                loading={requesting}
                                disabled={requesting}
                                size="sm"
                            >
                                GET CODE
                            </AppButton>
                        )}

                        {requested && (
                            <label className="text-montserrat text-400 text-14 text-gray-2 mb-0 mt-3">
                                Didn't receive any code?{' '}
                                <span
                                    onClick={() =>
                                        !isRunning && handleResendOTP()
                                    }
                                    className={`${
                                        isRunning
                                            ? 'text-light-gray-1'
                                            : 'text-orange cursor-pointer'
                                    } text-700`}
                                >
                                    {isRunning && seconds !== 0
                                        ? `Resend in ${seconds} seconds`
                                        : 'Resend Code'}
                                </span>
                            </label>
                        )}
                    </Form>
                ) : (
                    <>
                        <h2 className="text-inter text-600 text-24 mb-4 text-center text-gray-1">
                            Verified Successfully!!
                        </h2>

                        <div className="d-flex justify-content-center mb-5">
                            <div
                                style={{ width: 120, height: 120 }}
                                className="d-flex align-items-center justify-content-center bg-green-light rounded-circle"
                            >
                                <BsCheck2 size={88} className="text-green" />
                            </div>
                        </div>

                        <span className="text-montserrat text-400 text-16 text-gray-2 text-center">
                            Your{' '}
                            {type === 'email'
                                ? 'email address'
                                : 'phone number'}{' '}
                            has been verified successfully.
                        </span>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default OTPModal;
