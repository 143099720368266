import { Popover } from 'antd';
import { ICart } from '../../../../../interfaces/Order';

interface IProps {
    children: JSX.Element;
    carts: ICart[];
}

const OrderPopover = ({ children, carts }: IProps) => {
    return (
        <Popover
            overlayInnerStyle={{
                borderRadius: 8,
                width: 340,
                paddingTop: 8,
                paddingRight: 8,
                paddingLeft: 8
            }}
            content={
                <div>
                    {carts.map((cart) => (
                        <div className="mb-3" key={cart._id}>
                            <div className="d-flex text-nowrap">
                                <p className="text-montserrat text-dark-color text-600 text-14 mb-0 me-3 flex-grow-1 text-truncate">
                                    {cart.cart?.product?.name}
                                </p>

                                <p className="text-montserrat text-orange text-600 text-14 mb-0">
                                    Rs.{' '}
                                    {(cart.cart?.quantity ?? 0) *
                                        (cart.rate?.price ?? 0)}
                                </p>
                            </div>

                            <span
                                className="text-montserrat text-12 text-gray-2 d-inline-block"
                                style={{
                                    minWidth: 120
                                }}
                            >
                                Weight: {cart.rate?.quantity} {cart.rate?.unit}
                            </span>

                            <span className="text-montserrat text-12 text-gray-2">
                                X {cart.cart?.quantity}
                            </span>
                        </div>
                    ))}
                </div>
            }
        >
            {children}
        </Popover>
    );
};

export default OrderPopover;
