import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import {
    GET_BANNERS,
    GET_NAV_MEAT_MARKET,
    GET_NAV_SEASONAL_EXOTIC,
    GET_NAV_VEGETABLES,
    GET_VALUED_CUSTOMERS,
    GET_HOME_OFFERS,
    meatMarketTypeId,
    seasonalExoticTypeId,
    vegetableTypeId,
    GET_HOME_VEGETABLES,
    GET_HOME_MEAT_PRODUCTS,
    GET_HOME_FRUITS,
    dairyTypeId,
    GET_HOME_DAIRY_PRODUCTS,
    miscellaneousTypeId,
    GET_HOME_MISCELLANEOUS_PRODUCTS,
    fruitBasketTypeId,
    GET_FOOTER_FRUITS_VEGETABLES,
    GET_FOOTER_DAIRY,
    GET_FOOTER_MEAT,
    GET_FOOTER_MISC
} from '../helpers/constants';
import request from '../helpers/request';
import { IOfferParams } from '../interfaces/Offer';

export const getValuedCustomers = () => async (dispatch: Dispatch) => {
    return request.getValuedCustomers().then((res: AxiosResponse) => {
        dispatch({ type: GET_VALUED_CUSTOMERS, payload: res.data?.customers });
    });
};

export const getHomeBanners = () => async (dispatch: Dispatch) => {
    return request.getHomeBanners().then((res: AxiosResponse) => {
        dispatch({ type: GET_BANNERS, payload: res.data });
    });
};

export const getNavVegetable = () => async (dispatch: Dispatch) => {
    return request
        .getProducts({
            sort: 'popular',
            resultsPerPage: 5,
            type: vegetableTypeId
        })
        .then((res: AxiosResponse) => {
            dispatch({ type: GET_NAV_VEGETABLES, payload: res.data?.products });
        });
};

export const getNavMeatMarket = () => async (dispatch: Dispatch) => {
    return request
        .getProducts({
            sort: 'popular',
            resultsPerPage: 5,
            type: meatMarketTypeId
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_NAV_MEAT_MARKET,
                payload: res.data?.products
            });
        });
};

export const getNavSeasonalExotic = () => async (dispatch: Dispatch) => {
    return request
        .getProducts({
            sort: 'popular',
            resultsPerPage: 5,
            type: seasonalExoticTypeId
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_NAV_SEASONAL_EXOTIC,
                payload: res.data?.products
            });
        });
};

export const getHomeOffers =
    (params?: IOfferParams) => async (dispatch: Dispatch) => {
        return request
            .getOffers({ ...params, status: 'active' })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_HOME_OFFERS,
                    payload: res.data?.offers
                        ?.sort(() => Math.random() - Math.random())
                        .slice(0, 2)
                });
            });
    };

export const getHomeVegetables = () => async (dispatch: Dispatch) => {
    return request
        .getProducts({
            resultsPerPage: 8,
            type: vegetableTypeId
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_HOME_VEGETABLES,
                payload: res.data?.products
            });
        });
};

export const getHomeMeat = () => async (dispatch: Dispatch) => {
    return request
        .getProducts({
            resultsPerPage: 6,
            type: meatMarketTypeId
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_HOME_MEAT_PRODUCTS,
                payload: res.data?.products
            });
        });
};

export const getHomeFruits = () => async (dispatch: Dispatch) => {
    return request
        .getProducts({
            resultsPerPage: 8,
            type: fruitBasketTypeId
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_HOME_FRUITS,
                payload: res.data?.products
            });
        });
};

export const getHomeDairy = () => async (dispatch: Dispatch) => {
    return request
        .getProducts({
            resultsPerPage: 8,
            type: dairyTypeId
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_HOME_DAIRY_PRODUCTS,
                payload: res.data?.products
            });
        });
};

export const getHomeMiscellaneous = () => async (dispatch: Dispatch) => {
    return request
        .getProducts({
            resultsPerPage: 8,
            type: miscellaneousTypeId
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_HOME_MISCELLANEOUS_PRODUCTS,
                payload: res.data?.products
            });
        });
};

//Footer

export const getFooterFruitsVeg = () => async (dispatch: Dispatch) => {
    return request
        .getProducts({
            sort: 'popular',
            resultsPerPage: 8,
            type: [fruitBasketTypeId, vegetableTypeId].join(',')
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_FOOTER_FRUITS_VEGETABLES,
                payload: res.data?.products
            });
        });
};

export const getFooterDairy = () => async (dispatch: Dispatch) => {
    return request
        .getProducts({
            sort: 'popular',
            resultsPerPage: 8,
            type: dairyTypeId
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_FOOTER_DAIRY,
                payload: res.data?.products
            });
        });
};

export const getFooterMeat = () => async (dispatch: Dispatch) => {
    return request
        .getProducts({
            sort: 'popular',
            resultsPerPage: 8,
            type: meatMarketTypeId
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_FOOTER_MEAT,
                payload: res.data?.products
            });
        });
};

export const getFooterMisc = () => async (dispatch: Dispatch) => {
    return request
        .getProducts({
            sort: 'popular',
            resultsPerPage: 8,
            type: miscellaneousTypeId
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_FOOTER_MISC,
                payload: res.data?.products
            });
        });
};
