import { Link } from 'react-router-dom';
import meatBanner from '../../../assets/images/meatMarket/meatBanner.png';
import AppButton from './AppButton';
import { useMediaQuery } from 'react-responsive';

const HeroCategory = () => {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    return (
        <div className="hero-outer-container">
            <div
                className="hero-container app-container d-flex flex-column justify-content-center"
                style={{
                    backgroundImage: `url(${meatBanner})`
                }}
            >
                <p className="hero-meat-heading mb-0">
                    Weekend Special
                    <span className="d-block">Offer!!</span>
                </p>
                <p className="hero-meat-subheading mb-0 mb-4">
                    15-25 Kartik 2078
                    <p className="hero-offer mb-0">
                        <span>20</span>
                        <span>% OFF</span>
                    </p>
                </p>

                <Link to="/products?meatMarket=true">
                    <AppButton
                        className="px-4"
                        size={isDesktopOrLaptop ? 'lg' : 'sm'}
                        isMeat
                        type="secondary"
                    >
                        Explore Products
                    </AppButton>
                </Link>
            </div>
        </div>
    );
};

export default HeroCategory;
