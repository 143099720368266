import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import Cookies from 'universal-cookie';
import {
    GET_ORDER_DETAIL,
    GET_SEARCH_HISTORY,
    GET_USER,
    GET_USER_DASHBOARD_ORDERS,
    GET_USER_ORDERS,
    GET_WALLET_BALANCE,
    GET_WALLET_STATEMENTS,
    LOGIN_SUCCESS
} from '../helpers/constants';
import request from '../helpers/request';
import { IUserOrderParams } from '../interfaces/Order';
import { ISearchHistoryParams } from '../interfaces/User';
import { IWalletStatementParams } from '../interfaces/Wallet';
import { IAuthUser } from '../reducers/authReducers';
import { setErrors } from './errorActions';

const cookies = new Cookies();

export const getUserProfile = () => async (dispatch: Dispatch) => {
    return request.getProfile().then((res: AxiosResponse) => {
        const authData: IAuthUser = res.data;
        cookies.set('authUser', authData);
        dispatch({ type: LOGIN_SUCCESS, payload: authData });
        dispatch({
            type: GET_USER,
            payload: res.data
        });
    });
};

export const getUserOrders =
    (params?: IUserOrderParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_USER_ORDERS,
            payload: {
                ordersLoading: true
            }
        });

        request
            .getUserOrders(params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_USER_ORDERS,
                    payload: {
                        orders: res.data,
                        ordersLoading: false
                    }
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_USER_ORDERS,
                    payload: {
                        ordersLoading: false
                    }
                });
            });
    };

export const getUserDashboardOrders =
    (params?: IUserOrderParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_USER_DASHBOARD_ORDERS,
            payload: {
                dashboardOrdersLoading: true
            }
        });

        request
            .getUserOrders(params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_USER_DASHBOARD_ORDERS,
                    payload: {
                        dashboardOrders: res.data,
                        dashboardOrdersLoading: false
                    }
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_USER_DASHBOARD_ORDERS,
                    payload: {
                        dashboardOrdersLoading: false
                    }
                });
            });
    };

export const getOrderDetail = (id: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_ORDER_DETAIL,
        payload: {
            orderLoading: true
        }
    });

    request
        .getOrderDetail(id)
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_ORDER_DETAIL,
                payload: {
                    order: res.data,
                    orderLoading: false
                }
            });
        })
        .catch(() => {
            dispatch({
                type: GET_ORDER_DETAIL,
                payload: {
                    orderLoading: false
                }
            });
        });
};

export const getWalletBalance = () => async (dispatch: Dispatch) => {
    return request
        .getWalletBalance()
        .then((res: AxiosResponse) => {
            dispatch({ type: GET_WALLET_BALANCE, payload: res.data });
        })
        .catch((err: AxiosError) => {
            dispatch(
                setErrors(err.response?.data.message ?? 'Something went wrong')
            );
        });
};

export const getWalletStatements =
    (params?: IWalletStatementParams) => async (dispatch: Dispatch) => {
        return request
            .getWalletStatements(params)
            .then((res: AxiosResponse) => {
                dispatch({ type: GET_WALLET_STATEMENTS, payload: res.data });
            })
            .catch((err: AxiosError) => {
                dispatch(
                    setErrors(
                        err.response?.data.message ?? 'Something went wrong'
                    )
                );
            });
    };

export const getSearchHistory =
    (params?: ISearchHistoryParams) => async (dispatch: Dispatch) => {
        return request
            .getSearchHistory(params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_SEARCH_HISTORY,
                    payload: res.data?.queries
                });
            })
            .catch((err: AxiosError) => {
                dispatch(
                    setErrors(
                        err.response?.data.message ?? 'Something went wrong'
                    )
                );
            });
    };
