import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductTypes } from '../../../actions/categoryActions';
import { getHomeOffers } from '../../../actions/homeActions';
import {
    getChickenItems,
    getFishItems,
    getMuttonItems
} from '../../../actions/meatMarketActions';
import {
    chickenTypeId,
    fishTypeId,
    meatMarketTypeId,
    muttonTypeId
} from '../../../helpers/constants';
import { StateType } from '../../../reducers';
import MeatMarketLanding from '../../components/home/MeatMarketLanding';
import Promo from '../../components/home/Promo';
import ExploreCategory from '../../components/shared/ExploreCategory';
import HeroCategory from '../../components/shared/HeroCategory';
import MainLayout from '../../layouts/MainLayout';

const Meat = () => {
    const { chicken, mutton, fish } = useSelector(
        (state: StateType) => state.meatMarket
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProductTypes());
        dispatch(getChickenItems());
        dispatch(getHomeOffers({ product_type: meatMarketTypeId }));
        dispatch(getMuttonItems());
        dispatch(getFishItems());
    }, [dispatch]);

    const itemSection = [
        {
            title: 'Chicken Items',
            linkTo: `/products?type=${chickenTypeId}&meatMarket=true`,
            linkTitle: 'All Chicken Items',
            products: chicken
        },

        {
            title: 'Mutton Items',
            linkTo: `/products?type=${muttonTypeId}&meatMarket=true`,
            linkTitle: 'All Mutton Items',
            products: mutton
        },

        {
            title: 'Fish Items',
            linkTo: `/products?type=${fishTypeId}&meatMarket=true`,
            linkTitle: 'All Fish Items',
            products: fish
        }
    ];

    return (
        <MainLayout mainClassname="pb-5">
            <HeroCategory />

            <ExploreCategory category="meat" />

            {itemSection.map((item, index) => (
                <div key={index}>
                    {!!item.products.length && (
                        <MeatMarketLanding
                            title={item.title}
                            linkTo={item.linkTo}
                            linkTitle={item.linkTitle}
                            products={item.products}
                        />
                    )}

                    {index === 0 && <Promo category="meat-promo" meat />}
                </div>
            ))}
        </MainLayout>
    );
};

export default Meat;
