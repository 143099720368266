import { Image } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteCart, updateCartUnit } from '../../../actions/cartActions';
import { toggleDrawer } from '../../../actions/uiActions';
import { ReactComponent as CloseIcon } from '../../../assets/images/cancel-icon.svg';
import { defaultImage } from '../../../helpers/constants';
import { ICart } from '../../../interfaces/Cart';
import { AppDispatch, StateType } from '../../../reducers';
import AppSpinner from './AppSpinner';
import Counter from './Counter';

interface IProps {
    item: ICart;
}

const Item = (props: IProps) => {
    const { quantity, rate, product, _id } = props.item;

    const { cartsLoading } = useSelector((state: StateType) => state.cart);

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch<AppDispatch>();

    return (
        <div className="cart-item px-4 py-3 position-relative">
            <div className="cart-img">
                <Link
                    to={`/products/${product?.slug}`}
                    onClick={() => dispatch(toggleDrawer(false))}
                >
                    <Image
                        src={product?.images?.length ? product.images[0] : ''}
                        alt=""
                        fallback={defaultImage}
                        preview={false}
                    />
                </Link>
            </div>

            <div className="cart-content">
                <div className="cart-content-header">
                    <Link
                        to={`/products/${product?.slug}`}
                        onClick={() => dispatch(toggleDrawer(false))}
                    >
                        <p>{product?.name}</p>
                    </Link>

                    <div
                        className="remove-item d-flex align-items-center justify-content-center"
                        onClick={async () => {
                            if (_id) {
                                setLoading(true);

                                await dispatch(deleteCart(_id));

                                setLoading(false);
                            }
                        }}
                    >
                        <CloseIcon />
                    </div>
                </div>

                <div className="cart-meta w-100">
                    <Counter
                        size="sm"
                        value={quantity}
                        onChange={async (value) => {
                            if (_id) {
                                setLoading(true);

                                await dispatch(
                                    updateCartUnit(_id, {
                                        quantity: value
                                    })
                                );
                                setLoading(false);
                            }
                        }}
                    />

                    <p className="price flex-grow-1">
                        Rs.{' '}
                        {(rate?.discounted_price ?? rate?.price ?? 0) *
                            (quantity ?? 0)}
                    </p>
                </div>
            </div>

            {loading && !cartsLoading && (
                <div className="loader-overlay">
                    <AppSpinner size="sm" />
                </div>
            )}
        </div>
    );
};

export default Item;
