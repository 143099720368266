import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import { defaultImage } from '../../../helpers/constants';
import Counter from '../shared/Counter';
import CartButton from '../shared/CartButton';
import { IProduct, Rate } from '../../../interfaces/Product';
import { useHistory, useLocation } from 'react-router';
import { addToCart } from '../../../actions/cartActions';
import { Link } from 'react-router-dom';

interface IProps {}

interface IProductCardAltProps {
    product: IProduct;
}

const ProductCardAlt = ({ product }: IProductCardAltProps) => {
    const [unit, setUnit] = useState(1);

    const [selectedRate, setSelectedRate] = useState<Rate>();

    const { authenticated } = useSelector((state: StateType) => state.auth);

    const dispatch = useDispatch<AppDispatch>();

    const history = useHistory();

    const location = useLocation();

    const handleUnitChange = (value?: number) => {
        if (value) setUnit(value);
    };

    useEffect(() => {
        if (product?.rates?.length) {
            setSelectedRate(product.rates[0]);
        }
    }, [product?.rates]);

    const handleAddToCart = (e: React.MouseEvent) => {
        e.preventDefault();

        if (authenticated) {
            if (selectedRate?._id && product._id) {
                dispatch(
                    addToCart({
                        quantity: unit,
                        rate_id: selectedRate?._id,
                        product_id: product._id
                    })
                ).then(() => {
                    setUnit(1);
                });
            }
        } else {
            history.push('/login', {
                from: location
            });
        }
    };

    return (
        <div className="product-bought-card">
            <div className="image-section">
                {product?.discount?.is_active && (
                    <span className="discount">
                        {product?.discount?.discount_percentage}% Off
                    </span>
                )}

                <img
                    src={
                        product?.images?.length
                            ? product?.images[0]
                            : defaultImage
                    }
                    alt=""
                />
            </div>

            <div className="product-bought-body">
                <h2 className="product-title">{product?.name}</h2>
                <div className="product-bought-description">
                    {product?.rates?.length ? (
                        <span className="price">
                            Rs. {selectedRate?.discounted_price} /{' '}
                            {selectedRate?.quantity}
                            {selectedRate?.unit_id?.name}
                        </span>
                    ) : (
                        ''
                    )}

                    <div className="cart-quantity-section">
                        <Counter
                            className="me-3"
                            disabled={!product?.in_stock}
                            max={!product?.in_stock ? 1 : undefined}
                            min={1}
                            onChange={handleUnitChange}
                            value={unit}
                            size="sm"
                            color="#F8AA7D"
                        />
                        <CartButton onClick={handleAddToCart} size="sm" dark />
                    </div>
                </div>
            </div>
        </div>
    );
};

const ProductBought = (props: IProps) => {
    const { randomProducts: products } = useSelector(
        (state: StateType) => state.product
    );

    return (
        <div className="product-bought-inner">
            <h2 className="product-bought-head">People Also Bought</h2>

            <div className="product-bought-cards-wapper">
                <div className="product-bought-cards">
                    {products
                        .filter((item) => item.in_stock)
                        .slice(0, 5)
                        .map((product, index) => (
                            <Link to={`/products/${product.slug}`}>
                                <ProductCardAlt product={product} key={index} />
                            </Link>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default ProductBought;
