import { CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';

interface IProps {
    min?: number;
    max?: number;
    value?: number;
    onChange?: (value: number) => void;
    disabled?: boolean;
    className?: string;
    size?: 'sm' | 'lg';
    color?: string;
    style?: CSSProperties;
    isMeat?: boolean;
}

const Counter: FunctionComponent<IProps> = (props) => {
    const [counter, setCounter] = useState<number>(1);

    const handleIncrement = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.max && counter >= props.max) {
            return;
        } else {
            setCounter(counter + 1);
            props.onChange && props.onChange(counter + 1);
        }
    };

    const handleDecrement = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.min && counter <= props.min) {
            return;
        } else {
            setCounter(counter - 1);
            props.onChange && props.onChange(counter - 1);
        }
    };

    useEffect(() => {
        if (props.value) setCounter(props.value);
    }, [props.value]);

    return (
        <div
            className={`counter-container d-inline-flex align-items-center ${
                props.className
            } ${props.size === 'sm' ? 'counter-sm' : 'counter-lg'} ${
                props.isMeat ? 'counter-meat' : ''
            }`}
            style={props.style}
        >
            <div
                className={
                    `${
                        props.size === 'sm' ? 'pe-1 ps-2' : 'pe-2 ps-3'
                    } h-100 counter-icon-wrapper` +
                    (!props.disabled ? ' cursor-pointer' : ' opacity-25')
                }
                onClick={!props.disabled ? handleDecrement : undefined}
                style={{ backgroundColor: props.color }}
            >
                <FiMinus
                    style={{ verticalAlign: 'middle' }}
                    className="text-light-gray-2 h-100"
                    size={props.size === 'sm' ? 16 : 24}
                />
            </div>

            <span
                className={`text-roboto text-500 text-dark-color text-center ${
                    props.size === 'sm' ? 'text-12' : 'text-16'
                }`}
                style={{ width: props.size === 'sm' ? 45 : 66 }}
            >
                {counter}
            </span>

            <div
                className={
                    `${
                        props.size === 'sm' ? 'ps-1 pe-2' : 'ps-2 pe-3'
                    } h-100 counter-icon-wrapper` +
                    (!props.disabled ? ' cursor-pointer' : ' opacity-25')
                }
                onClick={!props.disabled ? handleIncrement : undefined}
                style={{ backgroundColor: props.color }}
            >
                <FiPlus
                    style={{ verticalAlign: 'middle' }}
                    className="text-light-gray-2 h-100"
                    size={props.size === 'sm' ? 16 : 24}
                />
            </div>
        </div>
    );
};

Counter.defaultProps = {
    value: 1,
    className: '',
    min: 1,
    color: '#e8e8e8'
};

export default Counter;
