import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { GET_COUPON_DETAIL, RESET_COUPON_DETAIL } from '../helpers/constants';
import request from '../helpers/request';
import { ICoupon } from '../interfaces/Coupon';
import { setErrors } from './errorActions';

export const checkCouponValidity =
    (code: string, carts: string[]) => async (dispatch: Dispatch) => {
        return request
            .checkCouponValidity(code, carts)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_COUPON_DETAIL,
                    payload: {
                        coupon: res.data?.coupon,
                        coupon_discount_amount: res.data?.coupon_discount_amount
                    }
                });

                return Promise.resolve<ICoupon>(res.data?.coupon);
            })
            .catch((err: AxiosError) => {
                if (err.response?.status === 404) {
                    dispatch({
                        type: GET_COUPON_DETAIL
                    });
                } else {
                    dispatch(
                        setErrors(
                            err.response?.data.message ?? 'Something went wrong'
                        )
                    );
                }
                return Promise.reject<AxiosError>(err);
            });
    };

export const resetCoupon = () => async (dispatch: Dispatch) => {
    dispatch({ type: RESET_COUPON_DETAIL });
};
