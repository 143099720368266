import { useEffect, useState } from 'react';
import { Col, Radio, Row, Space, Form, Select, Drawer } from 'antd';
import AppButton from '../../components/shared/AppButton';
import AppInput from '../../components/shared/AppInput';
import { AiOutlineUser, AiOutlineMail } from 'react-icons/ai';
import { FiMapPin } from 'react-icons/fi';
import { BsPhone } from 'react-icons/bs';
import { BiSearch } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDeliveryAddress,
    saveDeliveryAddress,
    setDeliveryAddress
} from '../../../actions/deliveryAddressActions';
import { AppDispatch, StateType } from '../../../reducers';
import {
    DeliveryAddressType,
    IDeliveryAddress
} from '../../../interfaces/DeliveryAddress';
import request from '../../../helpers/request';
import { DeliveryProps } from './Checkout';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import {
    getDistrictByProvince,
    getProvinces,
    getAreasByDistrict
} from '../../../actions/addressActions';
import { nameRegExp, phoneRegExp } from '../../../helpers/constants';
import { buyNow } from '../../../actions/cartActions';
import AppRadio from '../../components/shared/AppRadio';
import { useMediaQuery } from 'react-responsive';

interface IProps {
    onNext: (props: DeliveryProps) => void;
    isBuyNow: boolean;
}

const DeliveryAddress = (props: IProps) => {
    const [option, setOption] = useState<'saved' | 'new'>('saved');

    const [addressSearchValue, setAddressSearchValue] = useState('');

    const addressTypes: Array<DeliveryAddressType> = ['Home', 'Work', 'Other'];

    const [addresses, setAddresses] = useState<IDeliveryAddress[]>([]);

    const [sendingOTP, setSendingOTP] = useState(false);

    const {
        address: deliveryAddress,
        saveDeliveryLoading,
        selectedDeliveryAddress: selectedAddress
    } = useSelector((state: StateType) => state.deliveryAddress);

    const { deliveryCharge } = useSelector((state: StateType) => state.payment);

    const { isMinOrderValid, cartDetail } = useSelector(
        (state: StateType) => state.cart
    );

    const cart = cartDetail.carts.length ? cartDetail.carts[0] : {};

    const { provinces, districts, areas } = useSelector(
        (state: StateType) => state.address
    );

    const { coupon } = useSelector((state: StateType) => state.coupon);

    const [form] = Form.useForm<IDeliveryAddress>();

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getDeliveryAddress());
        dispatch(getProvinces());
    }, [dispatch]);

    useEffect(() => {
        if (deliveryAddress.length) {
            if (selectedAddress?.label) {
                const filteredAddress = deliveryAddress.filter(
                    (item) => item.label === selectedAddress?.label
                );

                setAddresses(filteredAddress);
            } else {
                dispatch(setDeliveryAddress(deliveryAddress[0]));
            }
        }
    }, [dispatch, deliveryAddress, selectedAddress?.label]);

    useEffect(() => {
        if (props.isBuyNow) {
            dispatch(
                buyNow({
                    quantity: cart.quantity ?? 1,
                    rate_id: cart.rate?._id ?? '',
                    product_id: cart.product?._id ?? ''
                })
            );
        }
    }, [
        coupon?.code,
        dispatch,
        props.isBuyNow,
        selectedAddress?._id,
        cart.product?._id,
        cart.quantity,
        cart.rate?._id
    ]);

    const onAddressOptionChange = (value: string) => {
        if (value === 'saved' || value === 'new') setOption(value);
    };

    const onSavedAddressTypeChange = (value: DeliveryAddressType) => {
        if (deliveryAddress.length) {
            const filteredAddress = deliveryAddress.filter(
                (item) => item.label === value
            );

            if (filteredAddress.length) {
                dispatch(setDeliveryAddress(filteredAddress[0]));
            } else {
                setOption('new');
                form.setFields([{ name: 'label', value }]);
            }
        } else {
            setOption('new');
            form.setFields([{ name: 'label', value }]);
        }
    };

    const handleAddressSelect = (e: string) => {
        const filteredAddress = addresses.find(({ _id }) => _id === e);

        if (filteredAddress) {
            dispatch(setDeliveryAddress(filteredAddress));

            setAddressSearchValue('');
        }
    };

    const handleAddressSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAddressSearchValue(e.target.value);
    };

    const handleSendOTP = () => {
        setSendingOTP(true);

        request
            .sendOTP(selectedAddress?._id ?? '')
            .then((res: AxiosResponse) => {
                setSendingOTP(false);

                toast.success(
                    `${res.data?.otp} is your test OTP. Please enter the OTP to verify your phone.`,
                    {
                        position: toast.POSITION.TOP_CENTER
                    }
                );

                props.onNext({
                    deliveryId: selectedAddress?._id
                });
            })
            .catch((err: AxiosError) => {
                setSendingOTP(false);

                toast.error(
                    err.response?.data.message ?? 'Something went wrong',
                    {
                        position: toast.POSITION.TOP_CENTER
                    }
                );
            });
    };

    const onSubmit = (values: IDeliveryAddress) => {
        dispatch(
            saveDeliveryAddress({
                ...values,
                primary: false,
                address: { ...values?.address, country: 'Nepal' },
                inside_ringroad: values?.inside_ringroad ?? false
            })
        ).then(() => {
            setOption('saved');

            form.resetFields();
        });
    };

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const isMd = useMediaQuery({ minWidth: 768 });

    return (
        <div className="py-3 px-md-3">
            <AppRadio
                value={'saved'}
                selected={option}
                onChange={onAddressOptionChange}
                text="Saved Addresses (Select from any saved address)"
                containerClassName="mb-4"
                labelClassName={!isMd ? 'text-14' : ''}
            >
                {option === 'saved' ? (
                    <div>
                        <Radio.Group
                            value={selectedAddress?.label}
                            buttonStyle="solid"
                            onChange={(e) =>
                                onSavedAddressTypeChange(e.target.value)
                            }
                        >
                            <Space direction="horizontal" size={10}>
                                {addressTypes.map((address) => (
                                    <Radio.Button
                                        value={address}
                                        className="app-radio-btn"
                                    >
                                        {address}
                                    </Radio.Button>
                                ))}
                            </Space>
                        </Radio.Group>

                        {addresses.length ? (
                            <div>
                                {addresses.length > 1 ? (
                                    <div className="mt-4 app-select">
                                        <Select
                                            size="large"
                                            style={{
                                                maxWidth: 480
                                            }}
                                            className="w-100"
                                            placeholder="Search Saved Areas"
                                            value={selectedAddress?._id}
                                            onChange={handleAddressSelect}
                                            optionLabelProp="label"
                                            dropdownClassName="app-select-dropdown"
                                            dropdownRender={(menu) => (
                                                <div className="p-3">
                                                    <AppInput
                                                        placeholder="Search your area"
                                                        value={
                                                            addressSearchValue
                                                        }
                                                        prefix={
                                                            <BiSearch className="text-gray-4" />
                                                        }
                                                        onChange={
                                                            handleAddressSearch
                                                        }
                                                    />
                                                    <p className="mb-2 text-inter text-500 text-14 text-gray-5">
                                                        Select Area
                                                    </p>
                                                    <hr className="my-0 text-border" />
                                                    <div className="mx-n3">
                                                        {menu}
                                                    </div>
                                                </div>
                                            )}
                                        >
                                            {addresses
                                                .filter((item) =>
                                                    !!addressSearchValue
                                                        ? `${item.address?.landmark1}, ${item.address?.area}, ${item.address?.district}, ${item.address?.province}, ${item.address?.country}`
                                                              .toLowerCase()
                                                              .search(
                                                                  addressSearchValue.toLowerCase()
                                                              ) !== -1
                                                        : true
                                                )
                                                .map(
                                                    (
                                                        {
                                                            _id,
                                                            address,
                                                            inside_ringroad
                                                        },
                                                        key
                                                    ) => (
                                                        <Select.Option
                                                            value={_id ?? ''}
                                                            label={
                                                                address?.area
                                                            }
                                                            className="px-3 py-0 app-select-item"
                                                            key={key}
                                                        >
                                                            <div>
                                                                <div className="d-flex py-2 text-wrap">
                                                                    <span className="address-label flex-grow-1 me-3 text-montserrat text-14 text-gray-5">
                                                                        {`${
                                                                            address?.landmark1
                                                                        }, ${
                                                                            address?.area
                                                                        }, ${
                                                                            address?.district
                                                                        }, ${
                                                                            address?.province
                                                                        }, ${
                                                                            address?.country
                                                                        }${
                                                                            inside_ringroad
                                                                                ? ' (Inside Ringroad)'
                                                                                : ''
                                                                        }`}
                                                                    </span>
                                                                    <span className="price-label text-montserrat text-14 text-gray-2 text-end text-nowrap">
                                                                        Delivery:
                                                                        Rs.
                                                                        {deliveryCharge.delivery_fee
                                                                            ? inside_ringroad
                                                                                ? deliveryCharge
                                                                                      .delivery_fee
                                                                                      .inside_ringroad
                                                                                : deliveryCharge
                                                                                      .delivery_fee
                                                                                      .outside_ringroad
                                                                            : 0}
                                                                    </span>
                                                                </div>

                                                                <hr className="my-0 p-0 text-border" />
                                                            </div>
                                                        </Select.Option>
                                                    )
                                                )}
                                        </Select>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {selectedAddress ? (
                                    <Row className="mt-4" gutter={20}>
                                        <Col xs={24} lg={12}>
                                            <div>
                                                <div className="d-flex mb-3">
                                                    <span
                                                        className="flex-grow-0 text-montserrat text-14 text-gray"
                                                        style={{
                                                            minWidth: 110
                                                        }}
                                                    >
                                                        Name
                                                    </span>
                                                    <span className="ms-3 text-montserrat text-14 text-gray text-500">
                                                        {selectedAddress.name}
                                                    </span>
                                                </div>

                                                <div className="d-flex mb-3">
                                                    <span
                                                        className="flex-grow-0 text-montserrat text-14 text-gray"
                                                        style={{
                                                            minWidth: 110
                                                        }}
                                                    >
                                                        Email Address
                                                    </span>
                                                    <span className="ms-3 text-montserrat text-14 text-gray text-500">
                                                        {selectedAddress.email}
                                                    </span>
                                                </div>

                                                <div className="d-flex mb-3">
                                                    <span
                                                        className="flex-grow-0 text-montserrat text-14 text-gray"
                                                        style={{
                                                            minWidth: 110
                                                        }}
                                                    >
                                                        Phone Number
                                                    </span>
                                                    <span className="ms-3 text-montserrat text-14 text-gray text-500">
                                                        {selectedAddress.mobile}
                                                    </span>
                                                </div>

                                                <div className="d-flex mb-3">
                                                    <span
                                                        className="flex-grow-0 text-montserrat text-14 text-gray"
                                                        style={{
                                                            minWidth: 110
                                                        }}
                                                    >
                                                        District
                                                    </span>
                                                    <span className="ms-3 text-montserrat text-14 text-gray text-500">
                                                        {
                                                            selectedAddress
                                                                .address
                                                                ?.district
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={24} lg={12}>
                                            <div>
                                                <div className="d-flex mb-3">
                                                    <span
                                                        className="flex-grow-0 text-montserrat text-14 text-gray"
                                                        style={{
                                                            minWidth: 110
                                                        }}
                                                    >
                                                        Area
                                                    </span>
                                                    <span className="ms-3 text-montserrat text-14 text-gray text-500">
                                                        {
                                                            selectedAddress
                                                                .address?.area
                                                        }
                                                    </span>
                                                </div>

                                                <div className="d-flex mb-3">
                                                    <span
                                                        className="flex-grow-0 text-montserrat text-14 text-gray"
                                                        style={{
                                                            minWidth: 110
                                                        }}
                                                    >
                                                        Local Address
                                                    </span>
                                                    <span className="ms-3 text-montserrat text-14 text-gray text-500">
                                                        {
                                                            selectedAddress
                                                                .address
                                                                ?.landmark1
                                                        }
                                                    </span>
                                                </div>

                                                <div className="d-flex mb-3">
                                                    <span
                                                        className="flex-grow-0 text-montserrat text-14 text-gray"
                                                        style={{
                                                            minWidth: 110
                                                        }}
                                                    >
                                                        District
                                                    </span>
                                                    <span className="ms-3 text-montserrat text-14 text-gray text-500">
                                                        {
                                                            selectedAddress
                                                                .address
                                                                ?.district
                                                        }
                                                    </span>
                                                </div>

                                                <div className="d-flex mb-3">
                                                    <span
                                                        className="flex-grow-0 text-montserrat text-14 text-gray"
                                                        style={{
                                                            minWidth: 110
                                                        }}
                                                    >
                                                        Province
                                                    </span>
                                                    <span className="ms-3 text-montserrat text-14 text-gray text-500">
                                                        {
                                                            selectedAddress
                                                                .address
                                                                ?.province
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : (
                                    ''
                                )}
                            </div>
                        ) : (
                            <p className="mt-4 mb-0">
                                You have not added{' '}
                                {selectedAddress?.label?.toLowerCase()} address.
                            </p>
                        )}
                    </div>
                ) : (
                    ''
                )}
            </AppRadio>

            <AppRadio
                value={'new'}
                selected={option}
                onChange={onAddressOptionChange}
                text="Or add new delivery address"
                labelClassName={!isMd ? 'text-14' : ''}
            >
                {option === 'new' ? (
                    <Form
                        layout="vertical"
                        initialValues={{ label: 'Home' }}
                        form={form}
                        requiredMark={false}
                        onFinish={onSubmit}
                    >
                        {() => (
                            <>
                                <Form.Item name="label">
                                    <Radio.Group buttonStyle="solid">
                                        <Space direction="horizontal" size={10}>
                                            {addressTypes.map(
                                                (address, index) => (
                                                    <Radio.Button
                                                        value={address}
                                                        className="app-radio-btn"
                                                        key={index}
                                                    >
                                                        {address}
                                                    </Radio.Button>
                                                )
                                            )}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>

                                <Row gutter={20} className="mt-3">
                                    <Col xs={24} sm={12}>
                                        <AppInput
                                            label="Full Name *"
                                            placeholder="Full Name"
                                            suffix={
                                                <AiOutlineUser className="text-gray-4" />
                                            }
                                            formName="name"
                                            rules={[
                                                {
                                                    message:
                                                        'Please enter your full name',
                                                    pattern: new RegExp(
                                                        nameRegExp
                                                    )
                                                },
                                                {
                                                    required: true,
                                                    message: 'Name is required'
                                                }
                                            ]}
                                        />
                                    </Col>

                                    <Col xs={24} sm={12}>
                                        <AppInput
                                            label="Email Address *"
                                            placeholder="Email Address"
                                            suffix={
                                                <AiOutlineMail className="text-gray-4" />
                                            }
                                            formName="email"
                                            rules={[
                                                {
                                                    type: 'email',
                                                    message:
                                                        'Email is not valid'
                                                },
                                                {
                                                    required: true,
                                                    message: 'Email is required'
                                                }
                                            ]}
                                        />
                                    </Col>

                                    <Col xs={24} sm={12}>
                                        <AppInput
                                            label="Phone Number *"
                                            placeholder="Phone Number"
                                            suffix={
                                                <BsPhone className="text-gray-4" />
                                            }
                                            formName="mobile"
                                            maxLength={10}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Phone number is required'
                                                },
                                                {
                                                    message:
                                                        'Phone number is invalid',
                                                    pattern: new RegExp(
                                                        phoneRegExp
                                                    )
                                                },
                                                {
                                                    min: 10,
                                                    message:
                                                        'Phone must be of 10 digits'
                                                }
                                            ]}
                                        />
                                    </Col>

                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            label="Region *"
                                            className="app-form text-montserrat text-14 text-dark-color app-select"
                                            name={['address', 'province']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select your region'
                                                }
                                            ]}
                                        >
                                            <Select
                                                placeholder="Region"
                                                size="large"
                                                suffixIcon={
                                                    <FiMapPin className="text-gray-4" />
                                                }
                                                onChange={(value) => {
                                                    if (value) {
                                                        dispatch(
                                                            getDistrictByProvince(
                                                                value.toString()
                                                            )
                                                        );
                                                    }

                                                    form.setFields([
                                                        {
                                                            name: [
                                                                'address',
                                                                'district'
                                                            ],
                                                            value: undefined
                                                        }
                                                    ]);
                                                }}
                                            >
                                                {provinces.map(
                                                    (province, index) => (
                                                        <Select.Option
                                                            value={province}
                                                            key={index}
                                                        >
                                                            {province}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            label="District *"
                                            className="app-form text-montserrat text-14 text-dark-color app-select"
                                            name={['address', 'district']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select your district'
                                                }
                                            ]}
                                        >
                                            <Select
                                                placeholder="District"
                                                size="large"
                                                suffixIcon={
                                                    <FiMapPin className="text-gray-4" />
                                                }
                                                disabled={!districts.length}
                                                onChange={(value) => {
                                                    if (
                                                        value === 'Kathmandu' ||
                                                        value === 'Lalitpur'
                                                    ) {
                                                        form.setFields([
                                                            {
                                                                name: 'inside_ringroad',
                                                                value: true
                                                            }
                                                        ]);

                                                        let districtId =
                                                            districts.find(
                                                                (district) =>
                                                                    district.name ===
                                                                    form.getFieldsValue()
                                                                        .address
                                                                        ?.district
                                                            )?._id;

                                                        if (districtId)
                                                            dispatch(
                                                                getAreasByDistrict(
                                                                    districtId
                                                                )
                                                            );
                                                    }
                                                }}
                                            >
                                                {districts.map(
                                                    (district, index) => (
                                                        <Select.Option
                                                            value={
                                                                district?.name ??
                                                                ''
                                                            }
                                                            key={index}
                                                        >
                                                            {district.name}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    {(form.getFieldsValue().address
                                        ?.district === 'Kathmandu' ||
                                        form.getFieldsValue().address
                                            ?.district === 'Lalitpur') && (
                                        <Col span={24}>
                                            <Form.Item
                                                label="Inside Ringroad?"
                                                className="app-form text-montserrat text-14 text-dark-color"
                                                name="inside_ringroad"
                                            >
                                                <Radio.Group
                                                    size="large"
                                                    className="app-radio-group"
                                                    onChange={() => {
                                                        form.setFields([
                                                            {
                                                                name: [
                                                                    'address',
                                                                    'area'
                                                                ],
                                                                value: undefined
                                                            }
                                                        ]);
                                                    }}
                                                >
                                                    <Radio
                                                        value={true}
                                                        className="app-radio d-flex align-items-center mb-3"
                                                    >
                                                        Yes
                                                    </Radio>

                                                    <Radio
                                                        value={false}
                                                        className="app-radio d-flex align-items-center mb-3"
                                                    >
                                                        No
                                                    </Radio>

                                                    <span className="text-montserrat text-12 text-gray-3 mt-n3">
                                                        * Please select 'No' if
                                                        your area is not in the
                                                        list.
                                                    </span>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    )}

                                    <Col xs={24} sm={12}>
                                        {form.getFieldsValue()
                                            .inside_ringroad ? (
                                            <Form.Item
                                                label="Area *"
                                                className="app-form text-montserrat text-14 text-dark-color app-select"
                                                name={['address', 'area']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please select your area'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Area"
                                                    size="large"
                                                    suffixIcon={
                                                        <FiMapPin className="text-gray-4" />
                                                    }
                                                    disabled={!areas.length}
                                                >
                                                    {areas.map(
                                                        (area, index) => (
                                                            <Select.Option
                                                                value={
                                                                    area?.name ??
                                                                    ''
                                                                }
                                                                key={index}
                                                            >
                                                                {area.name}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        ) : (
                                            <AppInput
                                                label="Area *"
                                                placeholder="Area"
                                                suffix={
                                                    <FiMapPin className="text-gray-4" />
                                                }
                                                formName={['address', 'area']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please input your area'
                                                    }
                                                ]}
                                            />
                                        )}
                                    </Col>

                                    <Col xs={24} sm={12}>
                                        <AppInput
                                            label="Local Address *"
                                            placeholder="Local Address"
                                            suffix={
                                                <FiMapPin className="text-gray-4" />
                                            }
                                            formName={['address', 'landmark1']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input your address'
                                                }
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form>
                ) : (
                    ''
                )}
            </AppRadio>

            <div className="d-flex justify-content-end justify-content-md-between mt-3 mt-lg-5">
                {option === 'new' ? (
                    <AppButton
                        size={isDesktopOrLaptop ? 'lg' : 'sm'}
                        type="primary-outlined"
                        className="px-4 px-lg-5"
                        disabled={saveDeliveryLoading}
                        form="deliveryAddressForm"
                        loading={saveDeliveryLoading}
                        onClick={() => {
                            form.submit();
                        }}
                    >
                        Save Address
                    </AppButton>
                ) : (
                    <div />
                )}

                {isMd && (
                    <AppButton
                        size={isDesktopOrLaptop ? 'lg' : 'sm'}
                        onClick={() =>
                            !!selectedAddress &&
                            selectedAddress?.mobile_verified
                                ? props.onNext({
                                      deliveryId: selectedAddress._id,
                                      otpVerified: true
                                  })
                                : handleSendOTP()
                        }
                        className="px-4"
                        disabled={
                            !selectedAddress?._id ||
                            sendingOTP ||
                            !isMinOrderValid
                        }
                        loading={sendingOTP}
                    >
                        Next: Mobile Verification
                    </AppButton>
                )}
            </div>

            <Drawer
                visible={!isMd}
                placement="bottom"
                closable={false}
                mask={false}
                height={88}
            >
                <div className="d-flex justify-content-end">
                    <AppButton
                        size={isDesktopOrLaptop ? 'lg' : 'sm'}
                        onClick={() =>
                            !!selectedAddress &&
                            selectedAddress?.mobile_verified
                                ? props.onNext({
                                      deliveryId: selectedAddress._id,
                                      otpVerified: true
                                  })
                                : handleSendOTP()
                        }
                        className="px-4"
                        disabled={
                            !selectedAddress?._id ||
                            sendingOTP ||
                            !isMinOrderValid
                        }
                        loading={sendingOTP}
                    >
                        Next
                    </AppButton>
                </div>
            </Drawer>
        </div>
    );
};

export default DeliveryAddress;
