import { Input, InputProps, Form } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { Rule } from 'rc-field-form/es/interface';
import { FunctionComponent, ReactNode } from 'react';

interface IProps extends InputProps {
    label?: ReactNode;
    formName?: NamePath;
    name?: string;
    className?: string;
    rules?: Rule[];
    help?: ReactNode;
    extra?: ReactNode;
    inputClassName?: string;
}

const AppInput: FunctionComponent<IProps> = (props) => {
    return (
        <Form.Item
            help={props.help}
            requiredMark={false}
            label={props.label}
            name={props.formName}
            rules={props.rules}
            validateFirst
            className={`app-form text-montserrat text-14 text-dark-color ${props.className}`}
            extra={props.extra}
        >
            <Input
                {...props}
                size="large"
                className={`app-input ${props.inputClassName}`}
            />
        </Form.Item>
    );
};

AppInput.defaultProps = {
    className: '',
    inputClassName: ''
};

export default AppInput;
