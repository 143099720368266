import moment from 'moment';
import { Link } from 'react-router-dom';
import { defaultImage } from '../../../helpers/constants';
import { IBlog } from '../../../interfaces/Blog';

interface IProps {
    blog: IBlog;
}

const BlogCard = ({ blog }: IProps) => {
    return (
        <Link to={`/blog/${blog.slug}`}>
            <div className="blog-card-container">
                <div className="blog-content">
                    <picture>
                        <img
                            src={
                                !!blog.thumbnail ? blog.thumbnail : defaultImage
                            }
                            alt="blog-img"
                        />
                    </picture>
                    <span>
                        {moment(blog.createdAt).format('MMMM DD YYYY')} |{' '}
                        {blog.reading_time} read
                    </span>
                    <h4 className="text-line-1">{blog.title}</h4>
                    <p
                        className="text-line-3"
                        dangerouslySetInnerHTML={{
                            __html: blog.content?.replace(/<[^>]+>/g, '') ?? ''
                        }}
                    />
                </div>
            </div>
        </Link>
    );
};

export default BlogCard;
