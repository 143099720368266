import { Col, Row } from 'antd';
import MainLayout from '../../layouts/MainLayout';
import Carrot from '../../../assets/images/404.png';
import Background from '../../../assets/images/404-bg.png';
import AppButton from '../../components/shared/AppButton';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const PageNotFound = () => {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    return (
        <MainLayout hideFooter>
            <div
                style={{
                    backgroundImage: `url(${Background})`,
                    height: 'calc(100vh - 81px)',
                    backgroundPosition: 'right',
                    backgroundSize: 'cover'
                }}
                className="px-3 px-lg-5"
            >
                <Row className="py-5" gutter={24}>
                    <Col xs={0} md={12}>
                        <div className="d-flex justify-content-center">
                            <img src={Carrot} className="mx-auto" alt="404" />
                        </div>
                    </Col>

                    <Col xs={24} md={12}>
                        <div className="d-flex flex-column h-100 justify-content-center">
                            <h1 className="not-found-title text-black text-600 text-inter">
                                404
                            </h1>

                            <h2
                                className={`text-montserrat text-black text-600 mb-3 ${
                                    isDesktopOrLaptop ? 'text-36' : 'text-24'
                                }`}
                            >
                                Something’s Missing
                            </h2>

                            <p
                                className={`text-montserrat text-black text-400 mb-3 ${
                                    isDesktopOrLaptop ? 'text-18' : 'text-16'
                                }`}
                            >
                                This page is Missing or you <br />
                                assembled the link Incorrectly
                            </p>

                            <div>
                                <Link to="/">
                                    <AppButton
                                        className="px-5"
                                        size={isDesktopOrLaptop ? 'lg' : 'sm'}
                                    >
                                        Back to Home
                                    </AppButton>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </MainLayout>
    );
};

export default PageNotFound;
