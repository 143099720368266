import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useLocation, Redirect, useHistory } from 'react-router-dom';
import { $FIXME } from '../../../helpers/constants';
import queryString from 'query-string';
import AppButton from '../../components/shared/AppButton';
import request from '../../../helpers/request';
import { BsCheck2 } from 'react-icons/bs';
import { AxiosError } from 'axios';
import AuthLayout from '../../layouts/AuthLayout';
import { useTimer } from 'react-timer-hook';
import { showToast } from '../../../utils/toast';
import { useMediaQuery } from 'react-responsive';

interface IProps {}
const OtpVerification = (props: IProps) => {
    const { seconds, isRunning, restart } = useTimer({
        expiryTimestamp: new Date(),
        onExpire: () => console.warn('onExpire called'),
        autoStart: false
    });

    const [OTP, setOTP] = useState('');

    const [errorOTP, setErrorOTP] = useState('');

    const [OTPLoading, setOTPLoading] = useState(false);

    const [OTPVerifed, setOTPVerified] = useState(false);

    const history = useHistory();

    const location = useLocation();

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const isMobile = useMediaQuery({ maxWidth: 576 });

    const query: $FIXME = queryString.parse(location.search);

    const handleOTPChange = (otp: string) => {
        setOTP(otp);
    };

    const handleOTPSubmit = () => {
        if (query.phone) {
            request
                .verifyPhone({ phone: query.phone as string, code: OTP })
                .then(() => {
                    setOTPVerified(true);
                    setOTPLoading(false);
                })
                .catch((err: AxiosError) => {
                    setErrorOTP(OTP);

                    showToast(
                        'error',
                        err.response?.status === 500
                            ? err.response.data?.message
                            : 'Something went wrong',
                        'otp-toast'
                    );

                    setOTPLoading(false);
                });
        }
    };

    const handleResendOTP = () => {
        if (query.phone) {
            request
                .otpVerification(query.phone)
                .then((res) => {
                    const time = new Date();
                    time.setSeconds(time.getSeconds() + 60);

                    restart(time);

                    showToast(
                        'success',
                        `${res.data?.otp} is your test OTP. Please enter the OTP to verify your phone.`
                    );
                })
                .catch((err: AxiosError) => {
                    showToast(
                        'error',
                        err.response?.data?.message ?? 'Something went wrong'
                    );
                });
        }
    };

    if (!query.phone) {
        return <Redirect to="/login" />;
    }

    return (
        <AuthLayout>
            {!OTPVerifed ? (
                <div className="login-form">
                    <div className="login-form-inner">
                        <h2>OTP Verification</h2>
                        <p>
                            We’ve sent you a 6 digit code. Please verify your
                            account.
                        </p>
                        <OtpInput
                            containerStyle="opt_input"
                            value={OTP}
                            onChange={handleOTPChange}
                            numInputs={6}
                            isInputNum
                        />

                        <AppButton
                            size={isMobile ? 'sm' : 'lg'}
                            className="w-100"
                            loading={OTPLoading}
                            disabled={
                                OTP.length !== 6 ||
                                OTPLoading ||
                                OTP === errorOTP
                            }
                            onClick={handleOTPSubmit}
                        >
                            Verify Account
                        </AppButton>

                        <hr />

                        <p className="otp-text mb-lg-4">
                            Didn't receive any code?{' '}
                            <span
                                onClick={() => !isRunning && handleResendOTP()}
                                className={`${
                                    isRunning
                                        ? 'text-light-gray-1'
                                        : 'text-orange cursor-pointer'
                                } text-montserrat text-16 text-700`}
                            >
                                {isRunning && seconds !== 0
                                    ? `Resend in ${seconds} seconds`
                                    : 'Resend Code'}
                            </span>
                        </p>
                    </div>
                </div>
            ) : (
                <div className="login-form">
                    <div className="login-form-inner">
                        <h2 className="mt-lg-4 text-roboto text-500 text-24 mb-4 mb-lg-5">
                            Phone Verified
                        </h2>

                        <div className="d-flex justify-content-center mb-4 mb-lg-5">
                            <div
                                style={{
                                    width: isDesktopOrLaptop ? 145 : 100,
                                    height: isDesktopOrLaptop ? 145 : 100
                                }}
                                className="d-flex align-items-center justify-content-center bg-green-light rounded-circle"
                            >
                                <BsCheck2
                                    size={isDesktopOrLaptop ? 88 : 60}
                                    className="text-green"
                                />
                            </div>
                        </div>

                        <p className="text-montserrat text-400 text-16 text-gray-3">
                            Your phone has been verified. Please login to your
                            account.
                        </p>

                        <AppButton
                            size={isMobile ? 'sm' : 'lg'}
                            className="w-100"
                            onClick={() => history.push('/login')}
                        >
                            Login Now
                        </AppButton>
                    </div>
                </div>
            )}{' '}
        </AuthLayout>
    );
};

export default OtpVerification;
