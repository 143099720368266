import { ProductNotFound } from '../../../../assets/images/icons';

interface IProps {
    search?: string;
}

const NoProduct = ({ search }: IProps) => {
    return (
        <div className="p-4 bg-white rounded-3 text-center">
            <h2 className="text-inter text-600 text-24 text-dark-color">
                No Product Found
            </h2>

            <ProductNotFound className="my-2" />

            <p className="text-montserrat text-14 text-gray-3 mb-0">
                {!!search
                    ? `No result for “${search}”.`
                    : 'We could not find the product you’re looking for.'}
            </p>
        </div>
    );
};

export default NoProduct;
