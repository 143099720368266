import { Image } from 'antd';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import { FiCalendar, FiGift } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { getOffers } from '../../../../actions/offerActions';
import { StateType } from '../../../../reducers';
import { showToast } from '../../../../utils/toast';
import AppButton from '../../shared/AppButton';
import Pagination from '../../shared/Pagination';

const MyOffers = () => {
    const { offers } = useSelector((state: StateType) => state.offer);

    const offerEl = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    const status = 'active';

    const resultsPerPage = 5;

    useEffect(() => {
        dispatch(getOffers({ resultsPerPage, status }));
    }, [dispatch]);

    const isMobile = useMediaQuery({ maxWidth: 768 });

    const handleCopyClick = (code: string) => {
        navigator.clipboard.writeText(code).then(() => {
            showToast('success', `Copied to clipboard`);
        });
    };

    return (
        <div ref={offerEl} className={isMobile ? 'app-container' : ''}>
            {isMobile ? (
                <div className="py-3">
                    <Link
                        to="/dashboard"
                        className="text-600 text-14 text-montserrat text-black"
                    >
                        <BiChevronLeft size={24} className="me-2 ms-n2" />
                        Offers
                    </Link>
                </div>
            ) : (
                <span className="d-flex align-items-center mb-0 mb-md-3 text-inter text-20 text-500 text-dark-color">
                    <FiGift className="me-2 " size={24} />
                    Special Offers
                </span>
            )}

            {offers.offers.length ? (
                <div className="rounded">
                    {offers.offers.map((offer, index) => (
                        <div key={offer._id}>
                            <div className="bg-white p-3 p-md-4">
                                <div className="d-flex mb-3">
                                    <div className="flex-grow-1 me-3 align-items-start">
                                        <p className="mb-1 text-montserrat text-18 text-600 text-dark-color">
                                            {offer.title}
                                        </p>
                                        <p className="mb-0 text-montserrat text-14 text-gray-3">
                                            {offer.info}
                                        </p>
                                    </div>

                                    {/* <div>
                                    <AppButton size="sm" className="px-4">
                                        View Offer
                                    </AppButton>
                                </div> */}
                                </div>

                                {!!offer.cover && (
                                    <Image
                                        src={offer.cover}
                                        height={200}
                                        wrapperClassName="mb-3"
                                        className="rounded"
                                    />
                                )}

                                {!!offer.coupon_code && (
                                    <div>
                                        <p className="mb-2 text-montserrat text-14 text-gray-3">
                                            Redeem Code
                                        </p>

                                        <div className="offer-redeem-container mb-3">
                                            <div className="px-3 px-md-5 py-2 offer-redeem-code">
                                                <span>{offer.coupon_code}</span>
                                            </div>
                                            <button
                                                className="copy-btn px-2 px-md-4"
                                                onClick={() =>
                                                    !!offer.coupon_code &&
                                                    handleCopyClick(
                                                        offer.coupon_code
                                                    )
                                                }
                                            >
                                                COPY CODE
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <div className="d-flex align-items-center me-3">
                                    <FiCalendar
                                        size={20}
                                        className="text-gray-3 me-2"
                                    />
                                    <span className="text-gray-3">
                                        Valid till{' '}
                                        {moment(offer.ends_at).format(
                                            'DD MMM, YYYY'
                                        )}
                                    </span>
                                </div>
                            </div>

                            {offers.offers.length !== index + 1 && (
                                <hr className="my-0 mx-4 text-light-gray" />
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <div className="py-3 px-4 bg-white rounded mb-3 mt-4">
                    <div className="d-flex flex-column text-center align-items-center text-black py-5">
                        <div
                            style={{
                                height: 100,
                                width: 100,
                                background: 'rgba(244, 123, 52, 0.2)'
                            }}
                            className="rounded-circle d-flex align-items-center justify-content-center"
                        >
                            <FiGift size={50} className="text-orange" />
                        </div>

                        <p className="text-14 text-montserrat text-400 my-4">
                            We do not have any offers at the moment.
                        </p>

                        <Link to="/products">
                            <AppButton className="px-4">
                                Explore Products
                            </AppButton>
                        </Link>
                    </div>
                </div>
            )}

            {!!offers.totalPage && !!offers.currentPage && (
                <Pagination
                    totalPages={offers.totalPage}
                    currentPage={offers.currentPage}
                    className="mt-4"
                    onChange={(page) => {
                        offerEl.current?.scrollIntoView({
                            behavior: 'smooth'
                        });

                        dispatch(getOffers({ resultsPerPage, page, status }));
                    }}
                />
            )}
        </div>
    );
};

export default MyOffers;
