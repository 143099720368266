import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import {
    $FIXME,
    dairyTypeId,
    GET_DAIRY_PRODUCTS,
    GET_PRODUCT,
    GET_PRODUCTS,
    GET_PRODUCT_REVIEWS,
    GET_RANDOM_PRODUCTS,
    RESET_PRODUCT_REVIEWS,
    RESET_PRODUCT_STATE
} from '../helpers/constants';
import request from '../helpers/request';
import {
    ICreateProductReviewBody,
    IProductParams,
    IProductReviewParams
} from '../interfaces/Product';
import { toast } from 'react-toastify';

export const getProductBySlug =
    (slug: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_PRODUCT,
            payload: { productLoading: true }
        });

        return request
            .getProductBySlug(slug)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_PRODUCT,
                    payload: { product: res.data, productLoading: false }
                });

                return Promise.resolve(res.data?._id);
            })
            .catch(() => {
                dispatch({
                    type: GET_PRODUCT,
                    payload: { productLoading: false }
                });

                return Promise.reject();
            });
    };

export const getProductReviews =
    (id: string, params: IProductReviewParams) =>
    async (dispatch: Dispatch) => {
        dispatch({
            type: GET_PRODUCT_REVIEWS,
            payload: { reviewsLoading: true }
        });

        request.getProductReviews(id, params).then((res: AxiosResponse) => {
            dispatch({
                type: GET_PRODUCT_REVIEWS,
                payload: { reviews: res.data, reviewsLoading: false }
            });
        });
    };

export const createProductReview =
    (body: ICreateProductReviewBody) => async (dispatch: $FIXME) => {
        return request
            .createProductReview(body)
            .then((res: AxiosResponse) => {
                toast.success('Your review has been posted.', {
                    position: toast.POSITION.TOP_CENTER
                });

                return Promise.resolve();
            })
            .catch((err: AxiosError) => {
                toast.error(err.response?.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });

                return Promise.reject(err.response?.data.message);
            });
    };

export const getProducts =
    (params: IProductParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_PRODUCTS,
            payload: { productsLoading: true }
        });
        request.getProducts(params).then((res: AxiosResponse) => {
            dispatch({
                type: GET_PRODUCTS,
                payload: { products: res.data, productsLoading: false }
            });
        });
    };

export const getRandomProducts =
    (exclude?: string) => async (dispatch: Dispatch) => {
        request.getRandomProducts(exclude).then((res: AxiosResponse) => {
            dispatch({
                type: GET_RANDOM_PRODUCTS,
                payload: res.data
            });
        });
    };

export const resetProductState = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_PRODUCT_STATE
    });
};

export const resetProductReviews = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_PRODUCT_REVIEWS
    });
};

export const getDairyProducts =
    (params?: IProductParams) => async (dispatch: Dispatch) => {
        request
            .getProducts({ ...params, type: dairyTypeId })
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_DAIRY_PRODUCTS,
                    payload: res.data.products
                });
            });
    };
