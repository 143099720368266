import { GET_COUPON_DETAIL, RESET_COUPON_DETAIL } from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { ICoupon } from '../interfaces/Coupon';

export interface ICouponState {
    coupon?: ICoupon;
    coupon_discount_amount?: number;
}

const initialState: ICouponState = {};

const couponReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_COUPON_DETAIL:
            return {
                ...action.payload
            };

        case RESET_COUPON_DETAIL:
            return {
                coupon: undefined
            };

        default:
            return state;
    }
};

export default couponReducer;
