import { useEffect } from 'react';
import MainLayout from '../../layouts/MainLayout';
import Hero from '../../components/home/Hero';
import Categories from '../../components/home/Categories';
import Product from '../../components/home/Product';
import CategoryOffer from '../../components/home/CategoryOffer';
import MeatMarket from '../../components/home/MeatMarket';
import DairyProduct from '../../components/home/DairyProduct';
import Promo from '../../components/home/Promo';
import Customers from '../../components/home/Customers';
import Newsletter from '../../components/home/Newsletter';
import { useDispatch, useSelector } from 'react-redux';
import { getProductTypes } from '../../../actions/categoryActions';
import {
    fruitBasketTypeId,
    miscellaneousTypeId,
    vegetableTypeId
} from '../../../helpers/constants';
import {
    getHomeBanners,
    getHomeDairy,
    getHomeVegetables,
    getHomeMeat,
    getHomeMiscellaneous,
    getHomeOffers,
    getHomeFruits,
    getValuedCustomers
} from '../../../actions/homeActions';
import { StateType } from '../../../reducers';

interface IProps {}

const Home = (props: IProps) => {
    const {
        homeVegetables,
        homeMeatProducts,
        homeDairyProducts,
        homeMiscellaneousProducts,
        homeFruits
    } = useSelector((state: StateType) => state.home);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHomeBanners());
        dispatch(getProductTypes());
        dispatch(getHomeVegetables());
        dispatch(getHomeMeat());
        dispatch(getHomeFruits());
        dispatch(getHomeDairy());
        dispatch(getHomeOffers());
        dispatch(getHomeMiscellaneous());
        dispatch(getValuedCustomers());
    }, [dispatch]);

    return (
        <MainLayout backgroundColor="#fcfcfc">
            <Hero />

            <Categories category="grocery" />

            {!!homeVegetables.length && (
                <Product
                    title={'Farmgro Fresh Vegetables'}
                    linkTo={`/products?type=${vegetableTypeId}`}
                    linkTitle="All Vegetables"
                    products={homeVegetables}
                />
            )}

            <CategoryOffer />

            {!!homeMeatProducts.length && (
                <MeatMarket
                    title={'Mutton Items'}
                    linkTo={`/products`}
                    linkTitle={'All Mutton Items'}
                    products={homeMeatProducts}
                />
            )}

            {!!homeFruits.length && (
                <Product
                    title={'Farmgro Fruits'}
                    linkTo={`/products?type=${fruitBasketTypeId}`}
                    linkTitle={'All fruits'}
                    products={homeFruits}
                />
            )}

            {!!homeDairyProducts.length && (
                <DairyProduct products={homeDairyProducts} />
            )}

            <Promo category="" />

            {!!homeMiscellaneousProducts.length && (
                <Product
                    isMiscelleneous={true}
                    title={'Miscelleneous Products'}
                    linkTo={`/products?type=${miscellaneousTypeId}`}
                    linkTitle={'All seasonal & exotic fruits'}
                    products={homeMiscellaneousProducts}
                />
            )}

            <Customers />

            <Newsletter />
        </MainLayout>
    );
};

export default Home;
