import { BiChevronLeft, BiUser } from 'react-icons/bi';
import Avatar from 'antd/lib/avatar/avatar';
import AppButton from '../../../shared/AppButton';
import { Col, DatePicker, Form, Row, Select } from 'antd';
import AppInput from '../../../shared/AppInput';
import {
    FiCalendar,
    FiChevronDown,
    FiMail,
    FiMapPin,
    FiPhone,
    FiUser
} from 'react-icons/fi';
import { useEffect, useRef, useState } from 'react';
import {
    deleteImageFromURL,
    firebaseImageUpload,
    getInitials
} from '../../../../../utils/functions';
import request from '../../../../../helpers/request';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile } from '../../../../../actions/userActions';
import { StateType } from '../../../../../reducers';
import {
    getDistrictByProvince,
    getProvinces
} from '../../../../../actions/addressActions';
import { IAuthUser } from '../../../../../reducers/authReducers';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import OTPModal from './OTPModal';
import { useMediaQuery } from 'react-responsive';
import { nameRegExp } from '../../../../../helpers/constants';

const EditProfileForm = () => {
    const { user } = useSelector((state: StateType) => state.user);

    const { name, profile, dob } = user;

    const { provinces, districts } = useSelector(
        (state: StateType) => state.address
    );

    const [changeEmailModalOpen, setChangeEmailModalOpen] = useState(false);

    const [changePhoneModalOpen, setChangePhoneModalOpen] = useState(false);

    const [form] = Form.useForm();

    const [imageUploading, setImageUploading] = useState(false);

    const [imageDeleting, setImageDeleting] = useState(false);

    const [updating, setUpdating] = useState(false);

    const dispatch = useDispatch();

    const inputRef = useRef<HTMLInputElement>(null);

    const history = useHistory();

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setImageUploading(true);

            const image = e.target.files[0];

            firebaseImageUpload(image, profile)
                .then((url) => {
                    request
                        .updateProfile({ ...user, profile: url })
                        .then(() => {
                            setImageUploading(false);

                            dispatch(getUserProfile());
                        })
                        .catch(() => {
                            setImageUploading(false);
                        });
                })
                .catch(() => {
                    setImageUploading(false);
                });
        }
    };

    const removeProfilePicture = () => {
        if (!!profile) {
            setImageDeleting(true);

            request
                .updateProfile({ ...user, profile: '' })
                .then(() => {
                    dispatch(getUserProfile());

                    setImageDeleting(false);
                })
                .catch(() => {
                    setImageDeleting(false);
                })
                .finally(() => {
                    deleteImageFromURL(profile);
                });
        }
    };

    const onSubmit = (values: IAuthUser) => {
        setUpdating(true);
        request
            .updateProfile({ ...values, email: user.email, phone: user.phone })
            .then(() => {
                toast.success('Your profile has been updated', {
                    position: toast.POSITION.TOP_CENTER
                });

                dispatch(getUserProfile());

                setUpdating(false);

                history.push('/dashboard?profile');

                window.scrollTo({ top: 200 });
            })
            .catch((err: AxiosError) => {
                toast.error(
                    err.response?.data?.message ?? 'Something went wrong',
                    {
                        position: toast.POSITION.TOP_CENTER
                    }
                );

                setUpdating(false);
            });
    };

    const handleRegionSelect = (value?: string) => {
        if (value) {
            dispatch(getDistrictByProvince(value.toString()));
        }
    };

    const handleEmailChangeClick = () => {
        setChangeEmailModalOpen(true);
    };

    const handlePhoneChangeClick = () => {
        setChangePhoneModalOpen(true);
    };

    useEffect(() => {
        dispatch(getProvinces());
        if (!!user.address?.province) {
            dispatch(getDistrictByProvince(user.address.province));
        }
    }, [dispatch, user.address?.province]);

    useEffect(() => {
        form.setFieldsValue({
            ...user,
            dob: user.dob ? moment(user.dob) : undefined
        });
    }, [form, user]);

    const isMobile = useMediaQuery({ maxWidth: 576 });

    return (
        <div>
            {!isMobile ? (
                <div>
                    <span className="d-flex align-items-center mb-3 text-inter text-20 text-500 text-dark-color">
                        <BiUser className="me-2" size={24} />
                        My Profile/Edit Profile
                    </span>

                    <Form
                        initialValues={{
                            ...user,
                            dob: dob ? moment(dob) : undefined
                        }}
                        layout="vertical"
                        className="rounded p-4 bg-white"
                        name="address"
                        requiredMark={false}
                        form={form}
                        scrollToFirstError
                        onFinish={onSubmit}
                    >
                        {() => (
                            <>
                                <div className="mb-4">
                                    <p className="text-montserrat text-600 text-18 text-gray mb-4">
                                        Personal Details
                                    </p>

                                    <div className="d-flex align-items-center mb-4">
                                        <Avatar
                                            src={profile}
                                            size={100}
                                            style={{
                                                border: '2px solid #f47b34'
                                            }}
                                            className="me-3 bg-orange"
                                        >
                                            {getInitials(name)}
                                        </Avatar>

                                        <div>
                                            <input
                                                className="d-none"
                                                ref={inputRef}
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageUpload}
                                            />

                                            <AppButton
                                                size="sm"
                                                type="primary"
                                                className="me-3 px-4"
                                                onClick={() => {
                                                    inputRef.current?.click();
                                                }}
                                                loading={imageUploading}
                                                disabled={
                                                    imageUploading ||
                                                    imageDeleting
                                                }
                                            >
                                                Upload Picture
                                            </AppButton>
                                        </div>

                                        {!!profile && (
                                            <div>
                                                <AppButton
                                                    size="sm"
                                                    type="primary-outlined"
                                                    className="px-4"
                                                    disabled={
                                                        imageUploading ||
                                                        imageDeleting
                                                    }
                                                    onClick={
                                                        removeProfilePicture
                                                    }
                                                    loading={imageDeleting}
                                                >
                                                    Remove
                                                </AppButton>
                                            </div>
                                        )}
                                    </div>

                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <AppInput
                                                label="Full Name *"
                                                placeholder="Full Name"
                                                suffix={
                                                    <FiUser
                                                        className="text-gray-4"
                                                        size={24}
                                                    />
                                                }
                                                formName="name"
                                                rules={[
                                                    {
                                                        message:
                                                            'Please enter your full name',
                                                        pattern: new RegExp(
                                                            nameRegExp
                                                        )
                                                    },
                                                    {
                                                        required: true,
                                                        message:
                                                            'Name is required'
                                                    }
                                                ]}
                                            />
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label="Gender *"
                                                name="gender"
                                                className="app-form text-montserrat text-14 text-dark-color app-select"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please select your gender'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Gender"
                                                    size="large"
                                                    suffixIcon={
                                                        <FiChevronDown
                                                            className="text-gray-4"
                                                            size={24}
                                                        />
                                                    }
                                                >
                                                    {[
                                                        'Male',
                                                        'Female',
                                                        'Other'
                                                    ].map((province, index) => (
                                                        <Select.Option
                                                            value={province}
                                                            key={index}
                                                        >
                                                            {province}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <AppInput
                                                disabled
                                                label={
                                                    <>
                                                        Email *
                                                        <span className="ms-2 text-montserrat text-14 text-600 text-gray-3 text-hover-orange cursor-pointer">
                                                            <span
                                                                onClick={
                                                                    handleEmailChangeClick
                                                                }
                                                            >
                                                                Change
                                                            </span>
                                                        </span>
                                                    </>
                                                }
                                                placeholder="Email Address"
                                                suffix={
                                                    <FiMail
                                                        className="text-gray-4"
                                                        size={24}
                                                    />
                                                }
                                                formName="email"
                                            />
                                        </Col>

                                        <Col span={12}>
                                            <AppInput
                                                disabled
                                                label={
                                                    <>
                                                        Phone Number *
                                                        <span className="ms-2 text-montserrat text-14 text-600 text-gray-3 text-hover-orange cursor-pointer">
                                                            <span
                                                                onClick={
                                                                    handlePhoneChangeClick
                                                                }
                                                            >
                                                                Change
                                                            </span>
                                                        </span>
                                                    </>
                                                }
                                                placeholder="9876544331"
                                                suffix={
                                                    <FiPhone
                                                        className="text-gray-4"
                                                        size={24}
                                                    />
                                                }
                                                formName="phone"
                                            />
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label="Date of birth"
                                                className="app-form text-montserrat text-14 text-dark-color app-select"
                                                name="dob"
                                            >
                                                <DatePicker
                                                    className="app-datepicker w-100"
                                                    size="large"
                                                    suffixIcon={
                                                        <FiCalendar
                                                            className="text-gray-4"
                                                            size={24}
                                                        />
                                                    }
                                                    format="YYYY/MM/DD"
                                                    allowClear={false}
                                                    inputReadOnly
                                                    showToday={false}
                                                    disabledDate={(current) =>
                                                        current &&
                                                        current >
                                                            moment().endOf(
                                                                'day'
                                                            )
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <p className="text-montserrat text-600 text-18 text-gray mb-4">
                                        Location
                                    </p>

                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Region *"
                                                className="app-form text-montserrat text-14 text-dark-color app-select"
                                                name={['address', 'province']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Region is required'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Region"
                                                    size="large"
                                                    suffixIcon={
                                                        <FiMapPin
                                                            className="text-gray-4"
                                                            size={24}
                                                        />
                                                    }
                                                    value={
                                                        user.address
                                                            ?.province ?? ''
                                                    }
                                                    onSelect={
                                                        handleRegionSelect
                                                    }
                                                >
                                                    {provinces.map(
                                                        (province, index) => (
                                                            <Select.Option
                                                                value={province}
                                                                key={index}
                                                            >
                                                                {province}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label="District *"
                                                className="app-form text-montserrat text-14 text-dark-color app-select"
                                                name={['address', 'district']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'District is required'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    placeholder="District"
                                                    size="large"
                                                    suffixIcon={
                                                        <FiMapPin
                                                            className="text-gray-4"
                                                            size={24}
                                                        />
                                                    }
                                                    disabled={!districts.length}
                                                >
                                                    {districts.map(
                                                        (district, index) => (
                                                            <Select.Option
                                                                value={
                                                                    district?.name ??
                                                                    ''
                                                                }
                                                                key={index}
                                                            >
                                                                {district.name}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <AppInput
                                                label="Area *"
                                                placeholder="Area"
                                                suffix={
                                                    <FiMapPin
                                                        className="text-gray-4"
                                                        size={24}
                                                    />
                                                }
                                                formName={['address', 'area']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Area is required'
                                                    }
                                                ]}
                                            />
                                        </Col>

                                        <Col span={12}>
                                            <AppInput
                                                label="Local Address *"
                                                placeholder="Local Address"
                                                formName={[
                                                    'address',
                                                    'landmark'
                                                ]}
                                                suffix={
                                                    <FiMapPin
                                                        className="text-gray-4"
                                                        size={24}
                                                    />
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Address is required'
                                                    }
                                                ]}
                                            />
                                        </Col>
                                    </Row>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <AppButton
                                        className="px-5 mt-4"
                                        htmlType="submit"
                                        form="address"
                                        disabled={updating}
                                        loading={updating}
                                    >
                                        Save Profile
                                    </AppButton>
                                </div>
                            </>
                        )}
                    </Form>
                </div>
            ) : (
                <div className="app-container">
                    <div className="py-3">
                        <Link
                            to="/dashboard"
                            className="text-600 text-14 text-montserrat text-black"
                        >
                            <BiChevronLeft size={24} className="me-2 ms-n2" />
                            My Profile/Edit Profile
                        </Link>
                    </div>

                    <p className="text-montserrat text-600 text-16 text-gray mb-3">
                        Profile
                    </p>

                    <div className="d-flex align-items-center mb-4">
                        <Avatar
                            src={profile}
                            size={60}
                            style={{ border: '2px solid #f47b34' }}
                            className="me-3 bg-orange"
                        >
                            {getInitials(name)}
                        </Avatar>

                        <div>
                            <input
                                className="d-none"
                                ref={inputRef}
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                            />

                            <AppButton
                                size="sm"
                                type="primary"
                                className="me-2 px-3"
                                onClick={() => {
                                    inputRef.current?.click();
                                }}
                                loading={imageUploading}
                                disabled={imageUploading || imageDeleting}
                            >
                                Upload Picture
                            </AppButton>
                        </div>

                        {!!profile && (
                            <div>
                                <AppButton
                                    size="sm"
                                    type="primary-outlined"
                                    className="px-3"
                                    disabled={imageUploading || imageDeleting}
                                    onClick={removeProfilePicture}
                                    loading={imageDeleting}
                                >
                                    Remove
                                </AppButton>
                            </div>
                        )}
                    </div>

                    <Form
                        initialValues={{
                            ...user,
                            dob: dob ? moment(dob) : undefined
                        }}
                        layout="vertical"
                        name="address"
                        requiredMark={false}
                        form={form}
                        scrollToFirstError
                        onFinish={onSubmit}
                        className="mb-5"
                    >
                        {() => (
                            <>
                                <AppInput
                                    label="Full Name *"
                                    placeholder="Full Name"
                                    suffix={
                                        <FiUser
                                            className="text-gray-4"
                                            size={24}
                                        />
                                    }
                                    formName="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Name is required'
                                        }
                                    ]}
                                />

                                <Form.Item
                                    label="Gender *"
                                    name="gender"
                                    className="app-form text-montserrat text-14 text-dark-color app-select"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your gender'
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder="Gender"
                                        size="large"
                                        suffixIcon={
                                            <FiChevronDown
                                                className="text-gray-4"
                                                size={24}
                                            />
                                        }
                                    >
                                        {['Male', 'Female', 'Other'].map(
                                            (province, index) => (
                                                <Select.Option
                                                    value={province}
                                                    key={index}
                                                >
                                                    {province}
                                                </Select.Option>
                                            )
                                        )}
                                    </Select>
                                </Form.Item>

                                <AppInput
                                    disabled
                                    label={
                                        <>
                                            Email *
                                            <span className="ms-2 text-montserrat text-14 text-600 text-gray-3 text-hover-orange cursor-pointer">
                                                <span
                                                    onClick={
                                                        handleEmailChangeClick
                                                    }
                                                >
                                                    Change
                                                </span>
                                            </span>
                                        </>
                                    }
                                    placeholder="Email Address"
                                    suffix={
                                        <FiMail
                                            className="text-gray-4"
                                            size={24}
                                        />
                                    }
                                    formName="email"
                                />

                                <AppInput
                                    disabled
                                    label={
                                        <>
                                            Phone Number *
                                            <span className="ms-2 text-montserrat text-14 text-600 text-gray-3 text-hover-orange cursor-pointer">
                                                <span
                                                    onClick={
                                                        handlePhoneChangeClick
                                                    }
                                                >
                                                    Change
                                                </span>
                                            </span>
                                        </>
                                    }
                                    placeholder="9876544331"
                                    suffix={
                                        <FiPhone
                                            className="text-gray-4"
                                            size={24}
                                        />
                                    }
                                    formName="phone"
                                />

                                <Form.Item
                                    label="Date of birth"
                                    className="app-form text-montserrat text-14 text-dark-color app-select"
                                    name="dob"
                                >
                                    <DatePicker
                                        className="app-datepicker w-100"
                                        size="large"
                                        suffixIcon={
                                            <FiCalendar
                                                className="text-gray-4"
                                                size={24}
                                            />
                                        }
                                        format="YYYY/MM/DD"
                                        allowClear={false}
                                        inputReadOnly
                                        showToday={false}
                                        disabledDate={(current) =>
                                            current &&
                                            current > moment().endOf('day')
                                        }
                                    />
                                </Form.Item>

                                <p className="text-montserrat text-600 text-16 text-gray mb-3">
                                    Location
                                </p>

                                <Form.Item
                                    label="Region *"
                                    className="app-form text-montserrat text-14 text-dark-color app-select"
                                    name={['address', 'province']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Region is required'
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder="Region"
                                        size="large"
                                        suffixIcon={
                                            <FiMapPin
                                                className="text-gray-4"
                                                size={24}
                                            />
                                        }
                                        value={user.address?.province ?? ''}
                                        onSelect={handleRegionSelect}
                                    >
                                        {provinces.map((province, index) => (
                                            <Select.Option
                                                value={province}
                                                key={index}
                                            >
                                                {province}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="District *"
                                    className="app-form text-montserrat text-14 text-dark-color app-select"
                                    name={['address', 'district']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'District is required'
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder="District"
                                        size="large"
                                        suffixIcon={
                                            <FiMapPin
                                                className="text-gray-4"
                                                size={24}
                                            />
                                        }
                                        disabled={!districts.length}
                                    >
                                        {districts.map((district, index) => (
                                            <Select.Option
                                                value={district?.name ?? ''}
                                                key={index}
                                            >
                                                {district.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <AppInput
                                    label="Area *"
                                    placeholder="Area"
                                    suffix={
                                        <FiMapPin
                                            className="text-gray-4"
                                            size={24}
                                        />
                                    }
                                    formName={['address', 'area']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Area is required'
                                        }
                                    ]}
                                />

                                <AppInput
                                    label="Local Address *"
                                    placeholder="Local Address"
                                    formName={['address', 'landmark']}
                                    suffix={
                                        <FiMapPin
                                            className="text-gray-4"
                                            size={24}
                                        />
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Address is required'
                                        }
                                    ]}
                                />

                                <div className="mt-2 d-flex justify-content-end">
                                    <AppButton
                                        size="sm"
                                        className="px-5"
                                        htmlType="submit"
                                        form="address"
                                        disabled={updating}
                                        loading={updating}
                                    >
                                        Save Profile
                                    </AppButton>
                                </div>
                            </>
                        )}
                    </Form>
                </div>
            )}

            <OTPModal
                type="email"
                visible={changeEmailModalOpen}
                onCancel={() => setChangeEmailModalOpen(false)}
                onSuccess={() => dispatch(getUserProfile())}
            />

            <OTPModal
                type="phone"
                visible={changePhoneModalOpen}
                onCancel={() => setChangePhoneModalOpen(false)}
                onSuccess={() => dispatch(getUserProfile())}
            />
        </div>
    );
};

export default EditProfileForm;
