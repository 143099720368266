import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { BiDollar } from 'react-icons/bi';
import { BsCheck2Circle, BsMap } from 'react-icons/bs';
import { FiCreditCard, FiFlag, FiPrinter, FiShoppingBag } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getOrderDetail } from '../../../../actions/userActions';
import { $FIXME } from '../../../../helpers/constants';
import request from '../../../../helpers/request';
import { StateType } from '../../../../reducers';
import MainLayout from '../../../layouts/MainLayout';
import AppButton from '../../shared/AppButton';

const OrderBill = () => {
    const {
        carts,
        sub_total,
        total_amount,
        delivery_fee,
        payment_type,
        delivery_address,
        identifier,
        delivery_date,
        delivery_time,
        wallet_adjustment,
        coupon_discount_amount
    } = useSelector((state: StateType) => state.user.order);

    const [pdfDownloading, setPdfDownloading] = useState(false);

    const { id }: $FIXME = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOrderDetail(id));
    }, [dispatch, id]);

    const downloadBill = () => {
        if (id) {
            setPdfDownloading(true);
            request
                .generateBill(id)
                .then((res) => {
                    const blob = new Blob([res.data], {
                        type: 'application/pdf'
                    });
                    const blobURL = URL.createObjectURL(blob);

                    let pdfFrame = document.body.appendChild(
                        document.createElement('iframe')
                    );
                    pdfFrame.style.display = 'none';
                    pdfFrame.onload = () =>
                        void pdfFrame.contentWindow?.print();
                    pdfFrame.src = blobURL;

                    setPdfDownloading(false);
                })
                .catch(() => {
                    setPdfDownloading(false);
                });
        }
    };

    return (
        <>
            <MainLayout>
                <div
                    className="app-container"
                    style={{
                        maxWidth: '1019px',
                        margin: '20px auto 70px'
                    }}
                >
                    <div className="d-flex justify-content-end mb-4 mx-n2">
                        <Link to="/contact-us">
                            <AppButton size="sm">
                                <FiFlag className="me-2" size={16} />
                                Report an issue
                            </AppButton>
                        </Link>
                    </div>

                    <div
                        className="row py-4 px-0 px-lg-4"
                        style={{
                            background: '#fff',
                            borderRadius: '8px'
                        }}
                    >
                        <div className="col-6">
                            <b style={{ fontSize: '20px' }}>
                                <FiShoppingBag
                                    size={20}
                                    style={{ marginRight: '5px' }}
                                />
                                Order items
                            </b>
                        </div>
                        <div className="col-6" style={{ textAlign: 'right' }}>
                            <span>
                                {carts?.length} item
                                {(carts?.length ?? 0) > 1 && 's'}
                            </span>
                        </div>
                        <div className="col-12 order-list">
                            {carts?.map((cart) => (
                                <div
                                    className="row"
                                    style={{ padding: '8px 30px' }}
                                >
                                    <div className="col-5 item-title">
                                        {cart.cart?.product?.name}
                                    </div>
                                    <div
                                        className="col-3 item-weight"
                                        style={{ textAlign: 'center' }}
                                    >
                                        Weight : {cart.rate?.quantity}{' '}
                                        {cart.rate?.unit} X{' '}
                                        {cart.cart?.quantity}
                                    </div>
                                    <div
                                        className="col-4 item-price"
                                        style={{ textAlign: 'right' }}
                                    >
                                        Rs{' '}
                                        {(cart.rate?.price ?? 0) *
                                            (cart.cart?.quantity ?? 0)}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div
                            className="col-12"
                            style={{
                                borderTop: '1px solid #f0f0f0',
                                paddingTop: '20px'
                            }}
                        >
                            <b style={{ fontSize: '20px' }}>
                                <BsMap
                                    size={20}
                                    style={{ marginRight: '5px' }}
                                />
                                Delivery Detail
                            </b>
                        </div>

                        <div className="col-12 mypanel-delivery-detail">
                            <div className="block-content">
                                <b className="dark-bold-font ">Address</b>
                                <br />

                                {delivery_address ? (
                                    <span className="grey-font-16">
                                        {delivery_address?.label} -{' '}
                                        {delivery_address?.address?.landmark1},{' '}
                                        {delivery_address?.address?.area},{' '}
                                        {delivery_address?.address?.district},{' '}
                                        {delivery_address?.address?.province},{' '}
                                        {delivery_address?.address?.country}
                                    </span>
                                ) : (
                                    'N/A'
                                )}
                            </div>
                            <div className="block-content">
                                <b className="dark-bold-font ">
                                    Delivery date and time
                                </b>

                                <br />

                                <span className="text-montserrat text-16 text-gray-2 me-5">
                                    <span className="text-gray-1">Date : </span>
                                    {dayjs(delivery_date).format('MMM D, YYYY')}
                                </span>

                                <span className="text-montserrat text-16 text-gray-2 me-5">
                                    <span className="text-gray-1">Time : </span>{' '}
                                    {delivery_time}
                                </span>
                            </div>
                            <div className="block-content">
                                <b className="dark-bold-font ">Order ID </b>
                                <p className="text-montserrat text-16 text-gray-1 mb-0">
                                    #{identifier} - {carts?.length} item
                                    {(carts?.length ?? 0) > 1 && 's'}
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-12"
                            style={{
                                borderTop: '1px solid #f0f0f0',
                                paddingTop: '20px'
                            }}
                        >
                            <b style={{ fontSize: '20px' }}>
                                <BiDollar
                                    size={20}
                                    style={{ marginRight: '5px' }}
                                />
                                Payment Detail
                            </b>
                        </div>
                        <div className="row mypanel-payment-detail">
                            <div className="col-6">
                                <span>Sub Total</span>
                                <br />
                                <span>Delivery Charge</span>
                                <br />

                                {!!coupon_discount_amount && (
                                    <>
                                        <span>Promo Code Discount</span>
                                        <br />
                                    </>
                                )}

                                {!!wallet_adjustment && (
                                    <>
                                        <span>Redeem Amount</span>
                                        <br />
                                    </>
                                )}

                                <span>
                                    <b className="dark-thin-font ">
                                        Grand Total
                                    </b>
                                </span>
                            </div>
                            <div
                                className="col-6"
                                style={{ textAlign: 'right' }}
                            >
                                <span>Rs {sub_total}</span>
                                <br />
                                <span>Rs {delivery_fee}</span>
                                <br />

                                {!!coupon_discount_amount && (
                                    <>
                                        <span>
                                            Rs. {coupon_discount_amount}
                                        </span>
                                        <br />
                                    </>
                                )}

                                {!!wallet_adjustment && (
                                    <>
                                        <span>Rs. {wallet_adjustment}</span>
                                        <br />
                                    </>
                                )}

                                <span>
                                    <b className="dark-thin-font ">
                                        Rs {total_amount}
                                    </b>
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className="col-12"
                                style={{
                                    borderTop: '1px solid #f0f0f0',
                                    paddingTop: '20px'
                                }}
                            >
                                <b style={{ fontSize: '20px' }}>
                                    <FiCreditCard
                                        size={20}
                                        style={{ marginRight: '5px' }}
                                    />
                                    Payment Options
                                </b>
                            </div>
                            <div className="col-6 mypanel-payment-options">
                                <span>
                                    <BsCheck2Circle
                                        size={20}
                                        style={{ marginRight: '5px' }}
                                    />
                                    {payment_type}
                                </span>
                            </div>
                        </div>

                        <div
                            className="d-flex justify-content-end"
                            style={{
                                borderTop: '1px solid #f0f0f0',
                                paddingTop: '20px'
                            }}
                        >
                            <AppButton
                                size="sm"
                                onClick={downloadBill}
                                disabled={pdfDownloading}
                                loading={pdfDownloading}
                            >
                                <FiPrinter className="me-2" size={16} />
                                Print Bill
                            </AppButton>
                        </div>
                    </div>
                </div>
            </MainLayout>
        </>
    );
};

export default OrderBill;
