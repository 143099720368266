import { Card, List } from 'antd';
import { useEffect, useState } from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import { FiMapPin } from 'react-icons/fi';
import { GrLocation } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { getProvinces } from '../../../../actions/addressActions';
import { getDeliveryAddress } from '../../../../actions/deliveryAddressActions';
import { StateType } from '../../../../reducers';
import AppButton from '../../shared/AppButton';
import AddressList from './components/AddressList';
import AddressModal from './components/AddressModal';

export type DeliveryAddressType = 'Default' | 'Home' | 'Office' | 'Other';

const MyAddresses = () => {
    const { address } = useSelector(
        (state: StateType) => state.deliveryAddress
    );

    const { provinces } = useSelector((state: StateType) => state.address);

    const dispatch = useDispatch();

    const [isAddModalVisible, setIsAddModalVisible] = useState(false);

    const showAddModal = () => {
        if (!provinces.length) dispatch(getProvinces());

        setIsAddModalVisible(true);
    };

    const handleOk = () => {
        setIsAddModalVisible(false);
    };

    const handleCancel = () => {
        setIsAddModalVisible(false);
    };

    useEffect(() => {
        dispatch(getDeliveryAddress());
    }, [dispatch]);

    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <div
            className={`myAddresses-container ${
                isMobile ? 'app-container' : ''
            }`}
        >
            {isMobile ? (
                <div className="py-3 d-flex justify-content-between align-items-center">
                    <Link
                        to="/dashboard"
                        className="text-600 text-14 text-montserrat text-black"
                    >
                        <BiChevronLeft size={24} className="me-2 ms-n2" />
                        My Orders
                    </Link>

                    <AppButton
                        type="primary"
                        className="px-4"
                        size="sm"
                        onClick={showAddModal}
                    >
                        Add new address
                    </AppButton>
                </div>
            ) : (
                <div className="d-flex align-items-center justify-content-between">
                    <div className="mb-3 me-3">
                        <span className="d-flex align-items-center text-inter text-20 text-500 text-dark-color">
                            <FiMapPin size={24} className="me-2" /> My Addresses
                        </span>
                    </div>
                    <div className="mb-3 d-flex justify-content-end align-items-center">
                        <AppButton
                            type="primary"
                            className="px-4"
                            size="sm"
                            onClick={showAddModal}
                        >
                            Add new address
                        </AppButton>
                    </div>
                </div>
            )}
            <div
                style={{
                    wordBreak: 'break-all',
                    marginLeft: isMobile ? -16 : undefined,
                    marginRight: isMobile ? -16 : undefined
                }}
            >
                {address.length ? (
                    <div className="site-card-border-less-wrapper">
                        <Card
                            bordered={false}
                            style={{
                                width: '100%'
                            }}
                            bodyStyle={{
                                padding: isMobile ? '0 16px' : undefined
                            }}
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={address}
                                renderItem={(item, index) => (
                                    <AddressList address={item} key={index} />
                                )}
                            />
                        </Card>
                    </div>
                ) : (
                    <div className="site-card-border-less-wrapper mt-4">
                        <Card
                            bordered={false}
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                padding: '40px'
                            }}
                        >
                            <h4>No Address Found</h4>
                            <br />
                            <GrLocation
                                size={140}
                                style={{
                                    marginBottom: '20px',
                                    color: '#878B87',
                                    marginTop: '10px'
                                }}
                                className="locationIcon"
                            />
                            <br />
                            <p
                                style={{
                                    color: '#878B87',
                                    marginBottom: '20px'
                                }}
                            >
                                You have not added any addresses. Add now?
                            </p>
                            <br />
                            <AppButton
                                type="primary"
                                className="px-4"
                                size="sm"
                                onClick={showAddModal}
                            >
                                Add new address
                            </AppButton>
                        </Card>
                    </div>
                )}
            </div>

            <AddressModal
                visible={isAddModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                mobile={isMobile}
            />
        </div>
    );
};

export default MyAddresses;
