import { Breadcrumb, Checkbox, Col, Drawer, Row, Select, Tag } from 'antd';
import MainLayout from '../../../layouts/MainLayout';
import { BiChevronLeft } from 'react-icons/bi';
import { AiFillCaretDown, AiFillCloseCircle } from 'react-icons/ai';
import { Link, useLocation, useHistory } from 'react-router-dom';
import ProductCard, { CardType } from '../../../components/shared/Card';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getProductCategories,
    getProductTypes
} from '../../../../actions/categoryActions';
import { getProducts } from '../../../../actions/productActions';
import { $FIXME, meatMarketTypeId } from '../../../../helpers/constants';
import queryString from 'query-string';
import { AppDispatch, StateType } from '../../../../reducers';
import NoProduct from './NoProduct';
import AppSpinner from '../../../components/shared/AppSpinner';
import { useMediaQuery } from 'react-responsive';
import Search from 'antd/lib/input/Search';
import { FiSliders, FiX } from 'react-icons/fi';
import SimpleBar from 'simplebar-react';
import { useDebouncedCallback } from 'use-debounce';
import { IProductFilters } from '../../../../interfaces/Product';
import { isOfType } from '../../../../utils/functions';
import { Carrot, Chicken } from '../../../../assets/images/icons';
import Pagination from '../../../components/shared/Pagination';
import { BsFillGrid3X3GapFill, BsListUl } from 'react-icons/bs';

interface IProps {}

const ProductSearch = (props: IProps) => {
    const { products, productsLoading } = useSelector(
        (state: StateType) => state.product
    );

    const { productCategories, productTypes } = useSelector(
        (state: StateType) => state.category
    );

    const [view, setView] = useState<CardType>('grid');

    const location = useLocation();

    const history = useHistory();

    const dispatch = useDispatch<AppDispatch>();

    const query: $FIXME = queryString.parse(location.search);

    const [searchText, setSearchText] = useState<string>(query?.search);

    const [searching, setSearching] = useState(false);

    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

    const [selectedFilters, setSelectedFilters] = useState<IProductFilters>({});

    const resultsPerPage = 16;

    const isMeatMarket = query.meatMarket === 'true';

    useEffect(() => {
        dispatch(getProductCategories());
        dispatch(getProductTypes());
    }, [dispatch]);

    useEffect(() => {
        setSelectedFilters({
            sort: query.sort,
            q: query.search,
            category: query?.category?.split(','),
            type: query?.type?.split(','),
            perPage: query.perPage,
            stock: query.stock === 'available' ? 'available' : undefined,
            discounted: query.discounted === 'true' ? true : undefined,
            rating: query.rating,
            page: query.page
        });
    }, [
        query.sort,
        query.search,
        query.category,
        query.type,
        query.perPage,
        query.stock,
        query.discounted,
        query.rating,
        query.page
    ]);

    useEffect(() => {
        if (!!Object.keys(selectedFilters).length) {
            dispatch(
                getProducts({
                    ...selectedFilters,
                    sort: selectedFilters.sort ?? 'popular',
                    type:
                        !selectedFilters.type?.length && isMeatMarket
                            ? meatMarketTypeId
                            : selectedFilters?.type?.join(','),
                    category: selectedFilters?.category?.join(','),
                    exclude_type: isMeatMarket ? undefined : meatMarketTypeId,
                    resultsPerPage: selectedFilters.perPage ?? resultsPerPage
                })
            )
                .then(() => {
                    setSearching(false);
                })
                .catch(() => {
                    setSearching(false);
                });
        }
    }, [dispatch, selectedFilters, isMeatMarket]);

    const sortOptions = [
        {
            value: 'popular',
            label: 'Most Popular'
        },
        {
            value: 'latest',
            label: 'Most Recent'
        },
        {
            value: 'expensive',
            label: 'Price: High to Low'
        },
        {
            value: 'cheap',
            label: 'Price: Low to High'
        }
    ];

    const ratingOptions = [
        {
            value: '4',
            label: (
                <p className="d-flex align-items-center mb-0">
                    4{' '}
                    {isMeatMarket ? (
                        <Chicken className="mx-1" />
                    ) : (
                        <Carrot className="mx-1" />
                    )}{' '}
                    & above
                </p>
            )
        },
        {
            value: '3',
            label: (
                <p className="d-flex align-items-center mb-0">
                    3{' '}
                    {isMeatMarket ? (
                        <Chicken className="mx-1" />
                    ) : (
                        <Carrot className="mx-1" />
                    )}{' '}
                    & above
                </p>
            )
        },
        {
            value: '2',
            label: (
                <p className="d-flex align-items-center mb-0">
                    2{' '}
                    {isMeatMarket ? (
                        <Chicken className="mx-1" />
                    ) : (
                        <Carrot className="mx-1" />
                    )}{' '}
                    & above
                </p>
            )
        },
        {
            value: '1',
            label: (
                <p className="d-flex align-items-center mb-0">
                    1{' '}
                    {isMeatMarket ? (
                        <Chicken className="mx-1" />
                    ) : (
                        <Carrot className="mx-1" />
                    )}{' '}
                    & above
                </p>
            )
        }
    ];

    const handleClearFilter = () => {
        const search = queryString.stringify({
            meatMarket: query.meatMarket
        });

        history.push({
            pathname: `/products`,
            search
        });
    };

    const handleRemoveFilter = (
        key: keyof IProductFilters,
        value?: string | Array<string>
    ) => {
        const tempFilter = selectedFilters;

        if (key === 'category') {
            tempFilter.category = tempFilter.category?.filter(
                (cat) => cat !== value
            );
        } else if (key === 'type') {
            tempFilter.type = tempFilter.type?.filter((type) => type !== value);
        } else {
            delete tempFilter[key];
        }

        const search = queryString.stringify({
            ...tempFilter,
            type: tempFilter.type?.length
                ? tempFilter.type?.join(',')
                : undefined,
            category: tempFilter.category?.length
                ? tempFilter.category?.join(',')
                : undefined,
            search: tempFilter.q,
            q: undefined,
            meatMarket: query.meatMarket
        });

        history.push({
            pathname: `/products`,
            search
        });
    };

    const handleFilter = (
        name: string,
        value?: string | Array<string> | boolean
    ) => {
        let tempFilter = selectedFilters;

        let search: string = '';

        if (name === 'type') {
            if (isMeatMarket && Array.isArray(value)) {
                tempFilter.type = value;
            } else if (isOfType<string>(value)) {
                if (tempFilter.type?.includes(value)) {
                    tempFilter.type = tempFilter.type.filter(
                        (type) => type !== value
                    );
                } else {
                    const selectedType = productTypes.find(
                        (type) => type._id === value
                    );

                    const isParent = !selectedType?.parent_id;

                    if (isParent) {
                        tempFilter.type = [
                            ...(tempFilter.type?.filter(
                                (type) =>
                                    !selectedType?.subTypes?.find(
                                        (subType) => subType._id === type
                                    )
                            ) ?? []),
                            value
                        ];
                    } else {
                        const parentType = productTypes.find(
                            (type) =>
                                !!type.subTypes?.find(
                                    (subType) => subType._id === value
                                )
                        );

                        tempFilter.type = [
                            ...(tempFilter.type?.filter(
                                (type) => selectedType.parent_id !== type
                            ) ?? []),
                            ...(tempFilter.type?.includes(parentType?._id ?? '')
                                ? parentType?.subTypes
                                      ?.filter(
                                          (subType) => subType._id !== value
                                      )
                                      ?.map((subType) => subType._id ?? '') ??
                                  []
                                : [value])
                        ];

                        if (
                            tempFilter.type?.filter((type) =>
                                parentType?.subTypes?.find(
                                    (subType) => type === subType._id
                                )
                            ).length === parentType?.subTypes?.length
                        ) {
                            tempFilter.type = [
                                ...(tempFilter.type?.filter(
                                    (type) =>
                                        !parentType?.subTypes?.find(
                                            (subType) => subType._id === type
                                        )
                                ) ?? []),
                                parentType?._id ?? ''
                            ];
                        }
                    }
                }
            }
        } else if (name === 'category' && Array.isArray(value)) {
            tempFilter.category = value;
        } else {
            if (
                name === 'rating' &&
                !!tempFilter[name] &&
                tempFilter[name] === value
            ) {
                delete tempFilter[name];
            } else {
                tempFilter = { ...tempFilter, [name]: value };
            }
        }

        if (name !== 'page') {
            delete tempFilter['page'];
        }

        search = queryString.stringify({
            ...tempFilter,
            type: tempFilter.type?.length
                ? tempFilter.type?.join(',')
                : undefined,
            category: tempFilter.category?.length
                ? tempFilter.category?.join(',')
                : undefined,
            search: tempFilter.q,
            q: undefined,
            meatMarket: query.meatMarket
        });

        history.push({
            pathname: `/products`,
            search
        });
    };

    const debouncedSearch = useDebouncedCallback((value: string) => {
        if (!!value) {
            handleFilter('q', value);
        } else {
            handleFilter('q', undefined);
        }
    }, 500);

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearching(true);

        setSearchText(e.target.value);

        debouncedSearch(e.target.value);
    };

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const renderTabs = () => {
        return selectedFilters ? (
            Object.keys(selectedFilters)
                .filter((key) => key !== 'perPage')
                .map((value: string, index: number) => {
                    const key = value as keyof IProductFilters;

                    if (selectedFilters[key]) {
                        if (key === 'category' && !!query.category) {
                            return selectedFilters.category?.map((cat) => {
                                return (
                                    <Tag
                                        key={cat}
                                        className={`search-tag text-14 text-montserrat px-2 py-1 ${
                                            isMeatMarket
                                                ? 'search-tag-meat'
                                                : ''
                                        }`}
                                        closable
                                        onClose={() => {
                                            handleRemoveFilter(key, cat);
                                        }}
                                    >
                                        {
                                            productCategories.find(
                                                (category) =>
                                                    category._id === cat
                                            )?.name
                                        }
                                    </Tag>
                                );
                            });
                        } else if (key === 'type' && !!query.type) {
                            return selectedFilters.type?.map((type) => {
                                return (
                                    <Tag
                                        key={type}
                                        className={`search-tag text-14 text-montserrat px-2 py-1 ${
                                            isMeatMarket
                                                ? 'search-tag-meat'
                                                : ''
                                        }`}
                                        closable
                                        onClose={() => {
                                            handleRemoveFilter(key, type);
                                        }}
                                    >
                                        {
                                            productTypes.find(
                                                (category) =>
                                                    category._id === type
                                            )?.name
                                        }
                                    </Tag>
                                );
                            });
                        } else if (
                            !!query.sort ||
                            !!query.search ||
                            !!query.discounted ||
                            !!query.stock ||
                            !!query.rating
                        ) {
                            return (
                                <Tag
                                    key={index}
                                    className={`search-tag text-14 text-montserrat px-2 py-1 ${
                                        isMeatMarket ? 'search-tag-meat' : ''
                                    }`}
                                    closable
                                    onClose={() => {
                                        handleRemoveFilter(key);
                                    }}
                                >
                                    {(() => {
                                        if (key === 'sort') {
                                            return sortOptions.find(
                                                (sort) =>
                                                    sort.value ===
                                                    selectedFilters[key]
                                            )?.label;
                                        }

                                        if (key === 'q') {
                                            return (
                                                'Search: ' +
                                                selectedFilters[key]
                                            );
                                        }

                                        if (
                                            key === 'stock' &&
                                            selectedFilters[key] === 'available'
                                        ) {
                                            return 'In Stock';
                                        }

                                        if (
                                            key === 'discounted' &&
                                            selectedFilters[key] === true
                                        ) {
                                            return 'Sale';
                                        }

                                        if (key === 'rating') {
                                            return (
                                                'Rating: ' +
                                                selectedFilters[key] +
                                                ' & above'
                                            );
                                        }
                                    })()}
                                </Tag>
                            );
                        }
                    }

                    return <></>;
                })
        ) : (
            <></>
        );
    };

    const renderStatusCheckbox = () => {
        return (
            <div className="d-flex flex-column">
                <Checkbox
                    checked={selectedFilters.stock === 'available'}
                    className={`ms-0 search-checkbox text-roboto text-16 text-gray-2 pb-2 ${
                        isMeatMarket ? 'checkbox-dark' : ''
                    }`}
                    value={selectedFilters.stock}
                    onChange={(value) => {
                        handleFilter(
                            'stock',
                            selectedFilters.stock === 'available'
                                ? undefined
                                : 'available'
                        );
                    }}
                >
                    In Stock
                </Checkbox>

                <Checkbox
                    checked={selectedFilters.discounted === true}
                    className={`ms-0 search-checkbox text-roboto text-16 text-gray-2 pb-2 ${
                        isMeatMarket ? 'checkbox-dark' : ''
                    }`}
                    value={selectedFilters.discounted}
                    onChange={(value) => {
                        handleFilter(
                            'discounted',
                            selectedFilters.discounted === true
                                ? undefined
                                : true
                        );
                    }}
                >
                    Sale
                </Checkbox>
            </div>
        );
    };

    const renderCategoryCheckbox = () => {
        return (
            <Checkbox.Group
                options={productCategories.map((category) => ({
                    label: category.name ?? '',
                    value: category._id ?? ''
                }))}
                value={selectedFilters.category}
                onChange={(value) => {
                    if (isOfType<Array<string>>(value)) {
                        handleFilter('category', value);
                    }
                }}
                className={`d-inline-flex flex-column search-checkbox text-roboto text-16 text-gray-2 ${
                    isMeatMarket ? 'checkbox-group-dark' : ''
                }`}
            />
        );
    };

    const renderTypeCheckbox = () => {
        return isMeatMarket ? (
            <Checkbox.Group
                options={productTypes
                    .filter((type) => type.parent_id === meatMarketTypeId)
                    ?.map((meatType) => ({
                        label: meatType.name ?? '',
                        value: meatType._id ?? ''
                    }))}
                value={selectedFilters.type}
                onChange={(value) => {
                    if (isOfType<Array<string>>(value)) {
                        handleFilter('type', value);
                    }
                }}
                className={`d-inline-flex flex-column search-checkbox text-roboto text-16 text-gray-2 ${
                    isMeatMarket ? 'checkbox-group-dark' : ''
                }`}
            />
        ) : (
            productTypes
                .filter(
                    (type) => !type.parent_id && type._id !== meatMarketTypeId
                )
                .map((filteredType) => {
                    const selectedSubTypes = filteredType.subTypes?.filter(
                        (subType) =>
                            selectedFilters.type?.includes(subType._id ?? '')
                    );

                    const isParentSelected =
                        selectedFilters.type?.includes(
                            filteredType._id ?? ''
                        ) ||
                        (!!selectedSubTypes?.length &&
                            !!filteredType.subTypes?.length &&
                            selectedSubTypes.length ===
                                filteredType.subTypes.length);

                    return (
                        <div className="mb-2" key={filteredType._id}>
                            <Checkbox
                                checked={isParentSelected}
                                className={`search-checkbox text-roboto text-16 text-gray-2 ${
                                    isMeatMarket ? 'checkbox-dark' : ''
                                }`}
                                value={filteredType._id}
                                onChange={(value) => {
                                    if (filteredType._id) {
                                        handleFilter('type', filteredType._id);
                                    }
                                }}
                            >
                                {filteredType.name}
                            </Checkbox>

                            {(isParentSelected || !!selectedSubTypes?.length) &&
                                !!filteredType.subTypes?.length && (
                                    <div className="d-flex flex-column mb-3">
                                        {filteredType.subTypes.map(
                                            (subType) => {
                                                const isChildSelected =
                                                    selectedFilters.type?.includes(
                                                        subType._id ?? ''
                                                    ) ||
                                                    selectedFilters.type?.includes(
                                                        subType.parent_id ?? ''
                                                    );

                                                return (
                                                    <Checkbox
                                                        key={subType._id}
                                                        checked={
                                                            isChildSelected
                                                        }
                                                        className={`ms-4 search-checkbox text-roboto text-14 text-gray-2 ${
                                                            isMeatMarket
                                                                ? 'checkbox-dark'
                                                                : ''
                                                        }`}
                                                        value={subType._id}
                                                        onChange={(value) => {
                                                            if (subType._id) {
                                                                handleFilter(
                                                                    'type',
                                                                    subType._id
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        {subType.name}
                                                    </Checkbox>
                                                );
                                            }
                                        )}
                                    </div>
                                )}
                        </div>
                    );
                })
        );
    };

    const renderRatingsCheckbox = () => {
        return (
            <div className="d-flex flex-column mb-3">
                {ratingOptions?.map((rating) => (
                    <Checkbox
                        key={rating.value}
                        value={rating.value}
                        checked={
                            rating.value === selectedFilters.rating?.toString()
                        }
                        className={`search-checkbox text-roboto text-16 text-gray-2 mb-2 ms-0 ${
                            isMeatMarket ? 'checkbox-dark' : ''
                        }`}
                        onChange={(e) => {
                            handleFilter('rating', e.target.value);
                        }}
                    >
                        {rating.label}
                    </Checkbox>
                ))}
            </div>
        );
    };

    return (
        <MainLayout>
            {isDesktopOrLaptop ? (
                <>
                    <div className="bg-white">
                        <Breadcrumb className="app-container py-2 app-breadcrumbs">
                            <Breadcrumb.Item>
                                <Link
                                    to="/"
                                    className="text-montserrat text-12 text-light-gray-1"
                                >
                                    Home
                                </Link>
                            </Breadcrumb.Item>

                            <Breadcrumb.Item>
                                <Link
                                    to="/products"
                                    className={`text-montserrat text-12 ${
                                        isMeatMarket
                                            ? 'text-light-gray-1'
                                            : 'text-orange'
                                    }`}
                                >
                                    Products
                                </Link>
                            </Breadcrumb.Item>

                            {isMeatMarket && (
                                <Breadcrumb.Item>
                                    <Link
                                        to="/products?meatMarket=true"
                                        className="text-montserrat text-12 text-orange"
                                    >
                                        Meat Market
                                    </Link>
                                </Breadcrumb.Item>
                            )}
                        </Breadcrumb>
                    </div>

                    <div className="bg-gray">
                        <div
                            className="px-4 bg-gray mb-5 mx-auto"
                            style={{ maxWidth: 1440 }}
                        >
                            <Row className="py-5">
                                <Col span={5}>
                                    <div className="mb-4">
                                        <h2 className="text-20 text-500 text-gray-1 mb-3">
                                            Product Categories
                                        </h2>

                                        {renderCategoryCheckbox()}
                                    </div>

                                    <div className="mb-4">
                                        <h2 className="text-20 text-500 text-gray-1 mb-3">
                                            Product Type
                                        </h2>

                                        {renderTypeCheckbox()}
                                    </div>

                                    <div className="mb-4">
                                        <h2 className="text-20 text-500 text-gray-1 mb-3">
                                            Product Status
                                        </h2>

                                        {renderStatusCheckbox()}
                                    </div>

                                    <div className="mb-4">
                                        <h2 className="text-20 text-500 text-gray-1 mb-3">
                                            Product Ratings
                                        </h2>

                                        {renderRatingsCheckbox()}
                                    </div>
                                </Col>
                                <Col span={19}>
                                    <Row className="bg-white p-3 align-items-center rounded-3">
                                        <Col span={6}>
                                            <p className="text-montserrat text-500 text-20 mb-0">
                                                Showing Results
                                            </p>
                                        </Col>
                                        <Col span={18}>
                                            <div className="d-flex align-items-center justify-content-end">
                                                <div className="d-flex align-items-center pe-4">
                                                    <span className="text-gray-2 text-montserrat text-16 pe-3">
                                                        View
                                                    </span>

                                                    <BsFillGrid3X3GapFill
                                                        size={20}
                                                        className={
                                                            'cursor-pointer ' +
                                                            (view === 'grid'
                                                                ? isMeatMarket
                                                                    ? 'text-meat-dark'
                                                                    : 'text-orange'
                                                                : 'text-light-gray')
                                                        }
                                                        onClick={() => {
                                                            setView('grid');
                                                        }}
                                                    />
                                                    <p className="mb-0 text-border px-2">
                                                        |
                                                    </p>
                                                    <BsListUl
                                                        size={24}
                                                        className={
                                                            'cursor-pointer ' +
                                                            (view === 'list'
                                                                ? isMeatMarket
                                                                    ? 'text-meat-dark'
                                                                    : 'text-orange'
                                                                : 'text-light-gray')
                                                        }
                                                        onClick={() => {
                                                            setView('list');
                                                        }}
                                                    />
                                                </div>

                                                <div className="d-flex align-items-center pe-4">
                                                    <span className="text-gray-2 text-montserrat">
                                                        Sort By:
                                                    </span>
                                                    <Select
                                                        value={
                                                            selectedFilters.sort ??
                                                            'popular'
                                                        }
                                                        className="search-dropdown"
                                                        onChange={(value) =>
                                                            handleFilter(
                                                                'sort',
                                                                value
                                                            )
                                                        }
                                                        suffixIcon={
                                                            <AiFillCaretDown className="text-dark-color" />
                                                        }
                                                    >
                                                        {sortOptions.map(
                                                            (sort, index) => (
                                                                <Select.Option
                                                                    key={index}
                                                                    value={
                                                                        sort.value
                                                                    }
                                                                >
                                                                    {sort.label}
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </div>

                                                <div className="d-flex align-items-center">
                                                    <span className="text-gray-2 text-montserrat">
                                                        Show:
                                                    </span>
                                                    <Select
                                                        value={(
                                                            selectedFilters.perPage ??
                                                            resultsPerPage
                                                        ).toString()}
                                                        onChange={(value) =>
                                                            handleFilter(
                                                                'perPage',
                                                                value
                                                            )
                                                        }
                                                        className="pe-2 search-dropdown"
                                                        suffixIcon={
                                                            <AiFillCaretDown className="text-dark-color" />
                                                        }
                                                    >
                                                        <Select.Option value="16">
                                                            16
                                                        </Select.Option>
                                                        <Select.Option value="8">
                                                            8
                                                        </Select.Option>
                                                    </Select>

                                                    <span className="pe-3 text-dark-color text-montserrat">
                                                        Results
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="mt-3 mb-4 d-flex">
                                        <div className="flex-grow-1">
                                            {renderTabs()}
                                        </div>

                                        {!!query &&
                                            !!Object.keys(query).filter(
                                                (key) =>
                                                    ![
                                                        'meatMarket',
                                                        'perPage',
                                                        'page'
                                                    ].includes(key)
                                            )?.length && (
                                                <div
                                                    className="d-flex align-items-center cursor-pointer"
                                                    onClick={() =>
                                                        handleClearFilter()
                                                    }
                                                >
                                                    <AiFillCloseCircle
                                                        className="text-dark-color me-2 cursor-pointer"
                                                        size={20}
                                                    />
                                                    <span className="text-500 text-16 text-roboto text-gray-3">
                                                        Clear Filters
                                                    </span>
                                                </div>
                                            )}
                                    </div>

                                    {productsLoading ? (
                                        <AppSpinner />
                                    ) : products.products.length ? (
                                        <div>
                                            <Row className="mb-4">
                                                {products.products.map(
                                                    (product, index) => (
                                                        <Col
                                                            span={
                                                                view === 'list'
                                                                    ? 24
                                                                    : 6
                                                            }
                                                            className={
                                                                view === 'list'
                                                                    ? 'mb-3'
                                                                    : ''
                                                            }
                                                            key={index}
                                                        >
                                                            <ProductCard
                                                                isMeat={
                                                                    isMeatMarket
                                                                }
                                                                product={
                                                                    product
                                                                }
                                                                type={view}
                                                            />
                                                        </Col>
                                                    )
                                                )}
                                            </Row>

                                            <Pagination
                                                isMeat={isMeatMarket}
                                                totalPages={products.totalPage}
                                                currentPage={
                                                    products.currentPage
                                                }
                                                onChange={(page) =>
                                                    handleFilter(
                                                        'page',
                                                        page.toString()
                                                    )
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <NoProduct search={selectedFilters.q} />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </>
            ) : (
                <div>
                    <div
                        className="bg-light-gray-2 px-3 py-2 d-flex align-items-center"
                        style={{ borderBottom: '1px solid #E8E8E8' }}
                    >
                        <Link to="/">
                            <BiChevronLeft
                                size={24}
                                className={`me-3 text-gray cursor-pointer ${
                                    isMeatMarket
                                        ? 'text-hover-meat'
                                        : 'text-hover-orange'
                                }`}
                            />
                        </Link>

                        <Search
                            placeholder="Search products"
                            className="search-box"
                            onChange={handleSearchChange}
                            value={searchText}
                            loading={searching}
                        />

                        <FiSliders
                            size={24}
                            className={`ms-3 text-gray cursor-pointer ${
                                isMeatMarket
                                    ? 'text-hover-meat'
                                    : 'text-hover-orange'
                            }`}
                            onClick={() => setFilterDrawerOpen(true)}
                        />
                    </div>

                    <div className="p-3 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <span className="text-14 text-gray-3 text-montserrat">
                                Show:
                            </span>
                            <Select
                                value={query.perPage ?? '16'}
                                onChange={(value) =>
                                    handleFilter('perPage', value)
                                }
                                className="search-dropdown"
                                suffixIcon={
                                    <AiFillCaretDown className="text-dark-color" />
                                }
                            >
                                <Select.Option value="16">16</Select.Option>
                                <Select.Option value="8">8</Select.Option>
                            </Select>

                            <span className="d-none d-sm-block text-14 text-dark-color text-montserrat">
                                Results
                            </span>
                        </div>

                        <div
                            className="d-flex align-items-center"
                            style={{ marginRight: -11 }}
                        >
                            <span className="text-14 text-gray-3 text-montserrat">
                                Sort By:
                            </span>
                            <Select
                                value={query.sort ?? 'popular'}
                                className="search-dropdown"
                                onChange={(value) =>
                                    handleFilter('sort', value)
                                }
                                suffixIcon={
                                    <AiFillCaretDown className="text-dark-color" />
                                }
                            >
                                {sortOptions.map((sort, index) => (
                                    <Select.Option
                                        value={sort.value}
                                        key={index}
                                    >
                                        {sort.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>

                    {!!query &&
                        !!Object.keys(query).filter(
                            (key) =>
                                !['meatMarket', 'perPage', 'page'].includes(key)
                        )?.length && (
                            <SimpleBar>
                                <div className="px-3 py-2 d-flex align-items-center">
                                    {renderTabs()}

                                    <div
                                        className="d-inline-flex align-items-center cursor-pointer text-nowrap"
                                        onClick={() => handleClearFilter()}
                                    >
                                        <AiFillCloseCircle
                                            className="text-dark-color me-2 cursor-pointer"
                                            size={20}
                                        />
                                        <span className="text-500 text-16 text-roboto text-gray-3">
                                            Clear Filters
                                        </span>
                                    </div>
                                </div>
                            </SimpleBar>
                        )}

                    <div className="px-3">
                        {productsLoading ? (
                            <AppSpinner className="my-5" />
                        ) : products.products.length ? (
                            <div className="mb-5">
                                <Row className="mb-4">
                                    {products.products.map((product, index) => (
                                        <Col xs={12} sm={8} md={6} key={index}>
                                            <ProductCard
                                                isMeat={isMeatMarket}
                                                product={product}
                                                type={view}
                                                disableHover
                                            />
                                        </Col>
                                    ))}
                                </Row>

                                <Pagination
                                    isMeat={isMeatMarket}
                                    totalPages={products.totalPage}
                                    currentPage={products.currentPage}
                                    onChange={(page) =>
                                        handleFilter('page', page.toString())
                                    }
                                    className="w-100 justify-content-between"
                                    size="sm"
                                />
                            </div>
                        ) : (
                            <NoProduct search={query.search} />
                        )}
                    </div>

                    <Drawer
                        mask={false}
                        placement="bottom"
                        visible={filterDrawerOpen}
                        onClose={() => setFilterDrawerOpen(false)}
                        closable={false}
                        className="filter-drawer"
                    >
                        <div className="d-flex mb-4 align-items-center">
                            <span className="text-inter text-600 text-20 text-black flex-grow-1">
                                Filter
                            </span>

                            <FiX
                                size={24}
                                className="text-black"
                                onClick={() => setFilterDrawerOpen(false)}
                            />
                        </div>

                        <div className="mb-4">
                            <h2 className="text-16 text-600 text-gray-1 mb-3">
                                Product Categories
                            </h2>

                            {renderCategoryCheckbox()}
                        </div>

                        <div className="mb-4">
                            <h2 className="text-16 text-600 text-gray-1 mb-3">
                                Product Type
                            </h2>

                            {renderTypeCheckbox()}
                        </div>

                        <div className="mb-4">
                            <h2 className="text-16 text-600 text-gray-1 mb-3">
                                Product Status
                            </h2>

                            {renderStatusCheckbox()}
                        </div>

                        <div>
                            <h2 className="text-16 text-600 text-gray-1 mb-3">
                                Product Ratings
                            </h2>

                            {renderRatingsCheckbox()}
                        </div>
                    </Drawer>
                </div>
            )}
        </MainLayout>
    );
};

export default ProductSearch;
