import { useState } from 'react';
import AuthLayout from '../../layouts/AuthLayout';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { ReactComponent as EnvelopIcon } from '../../../assets/images/carbon_email.svg';
import AppButton from '../../components/shared/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { StateType } from '../../../reducers';
import request from '../../../helpers/request';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { setErrors } from '../../../actions/errorActions';
import { useMediaQuery } from 'react-responsive';

interface IProps {}

const ForgotPassword = (props: IProps) => {
    const dispatch = useDispatch();

    const history = useHistory();

    const auth = useSelector((state: StateType) => state.auth);

    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery({ maxWidth: 576 });

    const initialValues = {
        email: ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required')
    });

    if (auth.authenticated) {
        return <Redirect to="/" />;
    }

    return (
        <AuthLayout>
            <div className="login-form">
                <div className="recovery-form-section">
                    <h2>Recover password</h2>

                    <p>
                        Please add your email address and we will send you the
                        password recovery detail associated with your email
                    </p>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                            setLoading(true);
                            request
                                .forgotPassword(values.email)
                                .then((res: AxiosResponse) => {
                                    toast.success(res.data.message, {
                                        position: toast.POSITION.TOP_CENTER
                                    });

                                    setLoading(false);

                                    history.push('/check-mail');
                                })
                                .catch((err: AxiosError) => {
                                    dispatch(
                                        setErrors(
                                            err.response?.data?.message ??
                                                'Something went wrong.'
                                        )
                                    );

                                    setLoading(false);
                                });
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            isValid,
                            errors
                        }) => (
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="form-field recover-email">
                                    <label htmlFor="">Email Address*</label>

                                    <div className="position-relative">
                                        <input
                                            type="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            placeholder="email@domain.com"
                                        />
                                        <EnvelopIcon />
                                    </div>

                                    {errors.email ? (
                                        <p className="text-danger mb-0 mt-2 text-12">
                                            {errors.email}
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                <AppButton
                                    htmlType="submit"
                                    className="w-100"
                                    disabled={!isValid || loading}
                                    loading={loading}
                                    size={isMobile ? 'sm' : 'lg'}
                                >
                                    Send recovery link
                                </AppButton>
                            </form>
                        )}
                    </Formik>

                    <hr className="hor-line" />

                    <div className="forget-link recover-bottom">
                        <p>
                            I already have an account?
                            <Link to="/register"> Sign In Now</Link>
                        </p>
                    </div>
                </div>
            </div>
        </AuthLayout>
    );
};

export default ForgotPassword;
