import ProductCard from '../shared/Card';
import FreshMeatImg from '../../../assets/images/offer-img.png';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import { IProduct } from '../../../interfaces/Product';
import { useMediaQuery } from 'react-responsive';
import SimpleBar from 'simplebar-react';

interface IProps {
    title: string;
    linkTo: string;
    linkTitle: string;
    isMiscelleneous?: boolean;
    products: IProduct[];
}

const MeatMarket = (props: IProps) => {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    return (
        <div className="app-container meat-container-wrapper">
            <Row className="offer-container-inner">
                <Col lg={7} xs={24}>
                    <div className="offer-section h-100">
                        <div className="offer-content">
                            <h2>Meat Market</h2>

                            <p>Eat one per day - Be healthy</p>

                            <Link to="/meat-market">See All Meat Products</Link>
                        </div>

                        <div className="offer-img">
                            <img src={FreshMeatImg} alt="" />
                        </div>
                    </div>
                </Col>

                <Col lg={17} xs={24}>
                    {isDesktopOrLaptop ? (
                        <div className="d-flex flex-wrap">
                            {props.products.map((product, index) => {
                                return (
                                    <ProductCard
                                        disableHover={!isDesktopOrLaptop}
                                        key={index}
                                        product={product}
                                        style={{ width: '33.33%' }}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <SimpleBar className="mx-n3 product-card-wrapper">
                            {props.products.map((product, index) => {
                                return (
                                    <ProductCard
                                        disableHover={!isDesktopOrLaptop}
                                        key={index}
                                        product={product}
                                        style={{ minWidth: 200, width: 200 }}
                                    />
                                );
                            })}
                        </SimpleBar>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default MeatMarket;
