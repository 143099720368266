import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import {
    DISLIKE_BLOG,
    GET_BLOGS,
    GET_BLOG_BY_SLUG,
    GET_BLOG_COMMENTS,
    GET_SIMILAR_BLOG,
    LIKE_BLOG,
    RESET_BLOG_COMMENTS,
    SET_BLOG_COMMENTS_LOADING
} from '../helpers/constants';
import request from '../helpers/request';
import { IBlogCommentParams } from '../interfaces/Blog';

export const getBlogs = () => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_BLOGS,
        payload: { blogsLoading: true }
    });
    request.getBlogs().then((res: AxiosResponse) => {
        dispatch({
            type: GET_BLOGS,
            payload: { blogs: res.data?.blogs, blogsLoading: false }
        });
    });
};

export const getSimilarBlogs = (id: string) => async (dispatch: Dispatch) => {
    request.getSimilarBlogs(id).then((res: AxiosResponse) => {
        dispatch({
            type: GET_SIMILAR_BLOG,
            payload: res.data
        });
    });
};

export const getBlogBySlug = (slug: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_BLOG_BY_SLUG,
        payload: { blogLoading: true }
    });

    return request
        .getBlogBySlug(slug)
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_BLOG_BY_SLUG,
                payload: { blog: res.data, blogLoading: false }
            });

            return Promise.resolve(res.data._id);
        })
        .catch(() => {
            dispatch({
                type: GET_BLOG_BY_SLUG,
                payload: { blogLoading: false }
            });

            return Promise.reject();
        });
};

export const likeBlog = (id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: LIKE_BLOG, payload: id });

    request.likeBlog(id);
};

export const dislikeBlog = (id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: DISLIKE_BLOG, payload: id });

    request.dislikeBlog(id);
};

export const getBlogComments =
    (id: string, params?: IBlogCommentParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: SET_BLOG_COMMENTS_LOADING,
            payload: true
        });

        request
            .getBlogComments(id, params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_BLOG_COMMENTS,
                    payload: res.data
                });

                dispatch({
                    type: SET_BLOG_COMMENTS_LOADING,
                    payload: false
                });
            })
            .catch(() => {
                dispatch({
                    type: SET_BLOG_COMMENTS_LOADING,
                    payload: false
                });
            });
    };

export const resetBlogComments = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_BLOG_COMMENTS
    });
};
