import {
    GET_CHICKEN_ITEMS,
    GET_FISH_ITEMS,
    GET_MUTTOM_ITEMS
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IProduct } from '../interfaces/Product';

export interface IMeatMarketState {
    mutton: IProduct[];
    chicken: IProduct[];
    fish: IProduct[];
}

const initState: IMeatMarketState = {
    mutton: [],
    chicken: [],
    fish: []
};

const meatMarketReducer = (
    state = initState,
    action: ActionType
): IMeatMarketState => {
    let { type, payload } = action;

    switch (type) {
        case GET_CHICKEN_ITEMS:
            return {
                ...state,
                chicken: payload
            };

        case GET_MUTTOM_ITEMS:
            return {
                ...state,
                mutton: payload
            };

        case GET_FISH_ITEMS:
            return {
                ...state,
                fish: payload
            };

        default:
            return state;
    }
};

export default meatMarketReducer;
