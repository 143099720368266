import { Col, Image, Row } from 'antd';
import Search from 'antd/lib/input/Search';
import { ChangeEvent, useRef, useState } from 'react';
import { defaultImage } from '../../../helpers/constants';
import request from '../../../helpers/request';
import { IProduct } from '../../../interfaces/Product';
import AppButton from './AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BiChevronRight } from 'react-icons/bi';
import { addToCart } from '../../../actions/cartActions';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { useDebouncedCallback } from 'use-debounce';
import AppSpinner from './AppSpinner';
import { useMediaQuery } from 'react-responsive';

const NavSearch = () => {
    const { productTypes } = useSelector((state: StateType) => state.category);

    const { searchHistory } = useSelector((state: StateType) => state.user);

    const [searchText, setSearchText] = useState('');

    const [searching, setSearching] = useState(false);

    const [searchOpen, setSearchOpen] = useState(false);

    const [results, setResults] = useState<IProduct[]>();

    const [total, setTotal] = useState(0);

    const history = useHistory();

    const searchRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(searchRef, () => {
        setSearchOpen(false);
    });

    const debouncedSearch = useDebouncedCallback((value: string) => {
        if (!!value) {
            request
                .getProducts({ q: value, resultsPerPage: 4 })
                .then((res) => {
                    setResults(res.data?.products);

                    setTotal(res.data?.totalDocuments);

                    setSearching(false);
                })
                .catch(() => {
                    setSearching(false);
                });
        }
    }, 500);

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!!e.target.value) {
            setSearching(true);
        }

        setResults(undefined);

        setTotal(0);

        setSearchText(e.target.value);

        !searchOpen && setSearchOpen(true);

        debouncedSearch(e.target.value);
    };

    const isXL = useMediaQuery({ minWidth: 1140 });

    return (
        <div className="flex-grow-1 position-relative" ref={searchRef}>
            <Search
                placeholder="Search products"
                className="search-box"
                onChange={handleSearchChange}
                loading={searching}
                onSearch={() => {
                    if (!!searchText) {
                        setSearchOpen(false);
                        history.push(`/products?search=${searchText}`);
                    }
                }}
                value={searchText}
            />

            {!!searchText && searchOpen && (
                <div className="search-container position-absolute bg-white overflow-hidden">
                    <Row style={{ minHeight: 200 }}>
                        <Col span={isXL ? 16 : 24}>
                            {results?.length ? (
                                results?.map(
                                    (item) =>
                                        !!item._id && (
                                            <Link
                                                to={`/products/${item.slug}`}
                                                onClick={() =>
                                                    setSearchOpen(false)
                                                }
                                                key={item._id}
                                            >
                                                <NavSearchItem item={item} />
                                            </Link>
                                        )
                                )
                            ) : searching ? (
                                <AppSpinner className="h-100 w-100 d-flex align-items-center justify-content-center" />
                            ) : (
                                <p className="text-700 text-montserrat text-14 text-gray p-3 m-0">
                                    No results found.
                                </p>
                            )}

                            {total > 4 && (
                                <Link
                                    to={`/products?search=${searchText}`}
                                    onClick={() => setSearchOpen(false)}
                                >
                                    <p className="pt-3 px-3 text-700 text-montserrat text-14 text-gray">
                                        View items({total}){' '}
                                        <BiChevronRight size={24} />
                                    </p>
                                </Link>
                            )}
                        </Col>

                        {isXL &&
                            (!!productTypes.length ||
                                !!searchHistory.length) && (
                                <Col span={8} className="border-left">
                                    {!!searchHistory.length && (
                                        <div className="p-3">
                                            <h2 className="text-14 text-montserrat text-600 text-dark-color mb-3">
                                                Commonly searched
                                            </h2>
                                            {searchHistory.map(
                                                (history, index) => (
                                                    <div
                                                        className="cursor-pointer mb-0 btn-text"
                                                        onClick={(e) => {
                                                            setSearching(true);

                                                            setSearchText(
                                                                history.query ??
                                                                    ''
                                                            );

                                                            debouncedSearch(
                                                                history.query ??
                                                                    ''
                                                            );
                                                        }}
                                                        key={history._id}
                                                    >
                                                        <p className="text-14 text-truncate text-montserrat text-400 text-hover-orange text-dark-color mb-2">
                                                            {history.query}
                                                        </p>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}

                                    {!!productTypes.length && (
                                        <div className="p-3">
                                            <h2 className="text-14 text-montserrat text-600 text-dark-color mb-3">
                                                Categories
                                            </h2>
                                            {productTypes.map(
                                                (category, index) =>
                                                    category.parent_id ===
                                                        null && (
                                                        <Link
                                                            to={`products?type=${category._id}`}
                                                            onClick={() =>
                                                                setSearchOpen(
                                                                    false
                                                                )
                                                            }
                                                            key={category._id}
                                                        >
                                                            <p className="text-14 text-truncate text-montserrat text-400 text-hover-orange text-dark-color mb-2">
                                                                {category.name}
                                                            </p>
                                                        </Link>
                                                    )
                                            )}
                                        </div>
                                    )}
                                </Col>
                            )}
                    </Row>
                </div>
            )}
        </div>
    );
};

interface SearchItemProps {
    item: IProduct;
}

const NavSearchItem = ({ item }: SearchItemProps) => {
    const { authenticated } = useSelector((state: StateType) => state.auth);

    const [cartLoading, setCartLoading] = useState(false);

    const dispatch = useDispatch<AppDispatch>();

    const history = useHistory();

    const location = useLocation();

    const handleAddToCart = (
        e: React.MouseEvent,
        product_id: string,
        rate_id: string
    ) => {
        e.stopPropagation();
        e.preventDefault();

        if (authenticated) {
            setCartLoading(true);

            dispatch(
                addToCart({
                    quantity: 1,
                    rate_id,
                    product_id
                })
            )
                .then(() => setCartLoading(false))
                .catch(() => setCartLoading(false));
        } else {
            history.push('/login', {
                from: location
            });
        }
    };

    return (
        <div key={item._id} className="py-4 mx-3 d-flex search-card">
            <Image
                src={item?.images?.length ? item?.images[0] : defaultImage}
                width={50}
                height={40}
                className="object-cover"
                wrapperClassName="me-3"
                preview={false}
            />

            <div className="me-3 flex-grow-1">
                <p className="text-montserrat text-14 text-dark-color text-400 mb-0">
                    {item.name}
                </p>

                {!!item?.rates?.length && item.rates[0].quantity && (
                    <p className="text-roboto text-14 text-dark text-500 mb-0 text-gray-1">
                        Rs. {item.rates[0].discounted_price} /{' '}
                        {item.rates[0].quantity > 1
                            ? item.rates[0].quantity
                            : ''}{' '}
                        {item.rates[0].unit_id?.name}
                    </p>
                )}
            </div>

            <div>
                <AppButton
                    size="sm"
                    disabled={!item.in_stock || cartLoading}
                    onClick={(e) =>
                        !!item._id &&
                        !!item.rates?.length &&
                        !!item.rates[0]._id &&
                        handleAddToCart(e, item._id, item.rates[0]._id)
                    }
                    loading={cartLoading}
                >
                    Add to Cart
                </AppButton>
            </div>
        </div>
    );
};

export default NavSearch;
