import { toast } from 'react-toastify';

type ToastType = 'success' | 'error';

export const showToast = (
    type: ToastType,
    message?: string,
    toastId?: string
) => {
    switch (type) {
        case 'success':
            return toast.success(message, {
                position: toast.POSITION.TOP_CENTER,
                toastId
            });

        case 'error':
            return toast.error(message ?? 'Something went wrong', {
                position: toast.POSITION.TOP_CENTER,
                toastId
            });
    }
};
