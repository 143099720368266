import { ShoppingCartOutlined } from '@ant-design/icons';
import { Avatar, Button, Drawer } from 'antd';
import { BiUser } from 'react-icons/bi';
import { BsWallet2 } from 'react-icons/bs';
import { FiLogOut, FiMapPin, FiPackage, FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import {
    $FIXME,
    meatMarketTypeId,
    seasonalExoticTypeId,
    vegetableTypeId
} from '../../helpers/constants';
import { StateType } from '../../reducers';
import { getInitials } from '../../utils/functions';
import AppButton from '../components/shared/AppButton';

interface IProps {
    visible: boolean;
    onClose: () => void;
}

const NavDrawer = ({ visible, onClose }: IProps) => {
    const { authenticated, authUser } = useSelector(
        (state: StateType) => state.auth
    );

    const { carts } = useSelector((state: StateType) => state.cart);

    const history = useHistory();

    const cookies = new Cookies();

    const handleClick = () => {
        history.push('/login');
    };

    const handleLogout = () => {
        const cookiesNames = [
            'authUser',
            'authenticated',
            'token',
            'loginSource'
        ];
        for (const cookie in cookiesNames) {
            cookies.remove(cookiesNames[cookie], { path: '/' });
        }

        (window as $FIXME).location.reload();
    };

    return (
        <Drawer
            headerStyle={{
                position: 'absolute',
                right: 0,
                top: 16,
                padding: 0
            }}
            placement="right"
            visible={visible}
            onClose={onClose}
            className="nav-drawer"
            width={300}
            bodyStyle={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <div className="d-header d-flex align-items-center">
                {authenticated ? (
                    <>
                        <Link to="/dashboard" className="me-2">
                            <Avatar
                                src={authUser.profile}
                                className="nav-d-avatar d-flex align-items-center"
                            >
                                {getInitials(authUser.name)}
                            </Avatar>
                        </Link>

                        <Link to="/dashboard">
                            <p className="mb-0 ms-1 text-truncate text-montserrat text-600 text-16 text-gray-5">
                                {authUser.name}
                            </p>
                        </Link>
                    </>
                ) : (
                    <Button
                        size={'large'}
                        className="button-main"
                        onClick={handleClick}
                    >
                        <BiUser
                            style={{
                                fontSize: 20
                            }}
                            className="me-1"
                        />
                        Login/Register
                    </Button>
                )}
            </div>

            <div className="d-body flex-grow-1">
                <div className="d-links">
                    <NavLink
                        to="/"
                        className="text-montserrat text-16 text-600 text-orange mb-3"
                        onClick={onClose}
                    >
                        Home
                    </NavLink>

                    <Link
                        to={`/products?type=${vegetableTypeId}`}
                        className="text-montserrat text-16 text-gray-3 mb-3"
                        onClick={onClose}
                    >
                        Vegetables
                    </Link>

                    <Link
                        to={`/products?type=${meatMarketTypeId}`}
                        className="text-montserrat text-16 text-gray-3 mb-3"
                        onClick={onClose}
                    >
                        Meat Market
                    </Link>

                    <Link
                        to={`/products?type=${seasonalExoticTypeId}`}
                        className="text-montserrat text-16 text-gray-3"
                        onClick={onClose}
                    >
                        Seasonal & Exotic Fruit
                    </Link>
                </div>

                {authenticated && (
                    <div className="d-menu">
                        <Link to="/dashboard?profile" onClick={onClose}>
                            <FiUser size={24} className="me-2" /> My Profile
                        </Link>
                        <Link to="/dashboard?orders" onClick={onClose}>
                            <FiPackage size={24} className="me-2" /> My Orders
                        </Link>
                        <Link to="/dashboard?wallet" onClick={onClose}>
                            <BsWallet2 size={24} className="me-2" /> My Wallet
                        </Link>
                        <Link to="/dashboard?address" onClick={onClose}>
                            <FiMapPin size={24} className="me-2" /> My Addresses
                        </Link>
                        <Link to="/cart" onClick={onClose}>
                            <div className="position-relative nav-cart me-2">
                                <div className="position-absolute bg-orange rounded-circle nav-cart-count">
                                    <span className="text-roboto text-12 text-500 text-light-gray-2">
                                        {carts.reduce(
                                            (a, b) => a + (b.quantity ?? 0),
                                            0
                                        )}
                                    </span>
                                </div>

                                <ShoppingCartOutlined
                                    style={{
                                        color: '#878b87',
                                        fontSize: 24
                                    }}
                                />
                            </div>{' '}
                            My Cart
                        </Link>
                    </div>
                )}
            </div>

            {authenticated && (
                <AppButton
                    onClick={handleLogout}
                    size="sm"
                    className="w-100"
                    style={{ marginBottom: 20 }}
                >
                    <FiLogOut size={22} style={{ marginRight: '5px' }} /> Log
                    out
                </AppButton>
            )}
        </Drawer>
    );
};

export default NavDrawer;
