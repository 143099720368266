import { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as GoogleIcon } from '../../../assets/images/google.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/images/Facebook.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import {
    $FIXME,
    FACEBOOK_APPID,
    GOOGLE_CLIENTID
} from '../../../helpers/constants';
import {
    facebookLogin,
    googleLogin,
    login
} from '../../../actions/authActions';
import { ILogin } from '../../../interfaces/User';
import AppButton from '../../components/shared/AppButton';
import {
    AiOutlineEye,
    AiOutlineEyeInvisible,
    AiOutlineUser
} from 'react-icons/ai';
import GoogleLogin from 'react-google-login';
import FacebookLogin, {
    ReactFacebookLoginInfo
} from 'react-facebook-login/dist/facebook-login-render-props';
import { setErrors } from '../../../actions/errorActions';
import AuthLayout from '../../layouts/AuthLayout';
import { useMediaQuery } from 'react-responsive';

interface IProps {}

const Login = (props: IProps) => {
    const dispatch = useDispatch();

    const auth = useSelector((state: StateType) => state.auth);

    const [showPassword, setShowPassword] = useState(false);

    const isMobile = useMediaQuery({ maxWidth: 576 });

    const location = useLocation();

    const history = useHistory();

    const { from }: $FIXME = location.state || { from: { pathname: '/' } };

    const initialValues: ILogin = {
        email: '',
        password: ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().required(''),
        password: Yup.string().required('')
    });

    const handleSubmit = (values: $FIXME) => {
        dispatch(login(values));
    };

    const responseFacebook = (response: ReactFacebookLoginInfo) => {
        dispatch(facebookLogin(response.accessToken));
    };

    if (auth.redirectToReferrer) {
        history.replace(from);
    }

    return (
        <AuthLayout>
            <div className="login-form">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    isInitialValid={false}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        isValid,
                        errors
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <h2 className="form-header">Sign In</h2>
                            <div className="form-field">
                                <label htmlFor="">Email or Phone Number</label>

                                <div className="position-relative">
                                    <input
                                        type="text"
                                        placeholder="Email or Phone Number"
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email}
                                    />
                                    <AiOutlineUser size={24} />
                                </div>
                            </div>

                            {errors.email ? (
                                <p className="text-danger mb-0 mt-2 text-12">
                                    {errors.email}
                                </p>
                            ) : (
                                ''
                            )}

                            <div className="form-field">
                                <label htmlFor="">Password</label>

                                <div className="position-relative">
                                    <input
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        placeholder="Password"
                                        name="password"
                                        onChange={handleChange}
                                        value={values.password}
                                    />

                                    {showPassword ? (
                                        <AiOutlineEye
                                            size={24}
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        />
                                    ) : (
                                        <AiOutlineEyeInvisible
                                            size={24}
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        />
                                    )}
                                </div>
                            </div>

                            {errors.password ? (
                                <p className="text-danger mb-0 mt-2 text-12">
                                    {errors.password}
                                </p>
                            ) : (
                                ''
                            )}

                            {auth.loginError ? (
                                <p className="text-12 text-danger mt-2 mb-3">
                                    {auth.loginError}
                                </p>
                            ) : (
                                ''
                            )}

                            <Link to="/forgot-password">
                                <p className="forget-password-text">
                                    Forgot Password?
                                </p>
                            </Link>

                            <AppButton
                                htmlType="submit"
                                className="w-100"
                                disabled={!isValid || auth.loginLoading}
                                loading={auth.loginLoading}
                                size={isMobile ? 'sm' : 'lg'}
                            >
                                Sign In
                            </AppButton>
                        </form>
                    )}
                </Formik>

                <div className="social-icon-section">
                    <p className="social-icon-text">Or Sign in With</p>
                    <div className="social-icon-inner">
                        <GoogleLogin
                            clientId={GOOGLE_CLIENTID}
                            render={(renderProps) => (
                                <div
                                    className="social-icon"
                                    onClick={renderProps.onClick}
                                >
                                    <GoogleIcon />
                                </div>
                            )}
                            onSuccess={(response: $FIXME) => {
                                dispatch(googleLogin(response.tokenId));
                            }}
                            onFailure={(response) => {
                                dispatch(setErrors(response ?? 'Login Failed'));
                            }}
                        />

                        <FacebookLogin
                            appId={FACEBOOK_APPID}
                            callback={responseFacebook}
                            render={(renderProps) => {
                                return (
                                    <div
                                        className="social-icon"
                                        onClick={() => renderProps.onClick()}
                                    >
                                        <FacebookIcon />
                                    </div>
                                );
                            }}
                        />
                    </div>
                </div>

                <hr />

                <div className="forget-link">
                    <p>
                        Don't have account?{' '}
                        <Link to="/register"> Sign Up Now</Link>
                    </p>
                </div>
            </div>
        </AuthLayout>
    );
};

export default Login;
