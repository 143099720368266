import { CaretDownFilled } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import { FiPackage } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { getUserOrders } from '../../../../actions/userActions';
import { Cart } from '../../../../assets/images/icons';
import {
    OrderParamsStatusType,
    OrderStatusType
} from '../../../../interfaces/Order';
import { StateType } from '../../../../reducers';
import AppButton from '../../shared/AppButton';
import Pagination from '../../shared/Pagination';
import OrderCard from './components/OrderCard';

interface FilterOption {
    label: OrderStatusType;
    value: OrderParamsStatusType;
}

const MyOrders = () => {
    const { orders } = useSelector((state: StateType) => state.user);

    const [selectedFilter, setSelectedFilter] = useState<FilterOption>();

    const resultsPerPage = 4;

    const dispatch = useDispatch();

    const orderEl = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(
            getUserOrders({ status: selectedFilter?.value, resultsPerPage })
        );
    }, [dispatch, selectedFilter?.value]);

    const orderFilters: FilterOption[] = [
        { label: 'Placed', value: 'placed' },
        { label: 'Approved', value: 'approved' },
        { label: 'Packed', value: 'packed' },
        { label: 'On the way', value: 'on_the_way' },
        { label: 'Delivered', value: 'delivered' },
        { label: 'Cancelled', value: 'cancelled' }
    ];

    const statusMenu = (
        <Menu>
            <Menu.Item onClick={() => setSelectedFilter(undefined)}>
                <span>All</span>
            </Menu.Item>

            {orderFilters.map((filter, index) => (
                <Menu.Item
                    onClick={() => setSelectedFilter(filter)}
                    key={index}
                >
                    <span>{filter.label}</span>
                </Menu.Item>
            ))}
        </Menu>
    );

    const isMobile = useMediaQuery({ maxWidth: 768 });

    return !isMobile ? (
        <div
            className="row justify-content-between myorders-container"
            ref={orderEl}
        >
            <div className="col-4">
                <span className="d-flex align-items-center mb-3 text-inter text-20 text-500 text-dark-color">
                    <FiPackage size={24} className="me-2" />
                    My Orders
                </span>
            </div>

            <div className="col-4">
                <Dropdown
                    overlay={statusMenu}
                    trigger={['click']}
                    className="cursor-pointer"
                >
                    <p className="mb-0 ms-1 d-flex align-items-center delivery-status">
                        Delivery status :{' '}
                        <span> {selectedFilter?.label ?? 'All'}</span>
                        <CaretDownFilled className="ms-1" />
                    </p>
                </Dropdown>
            </div>
            <div className="col-12" style={{ wordBreak: 'break-all' }}>
                {orders.orders.length ? (
                    orders.orders.map((order) => (
                        <div className="mb-4" key={order._id}>
                            <OrderCard
                                order={order}
                                onCancel={() => {
                                    dispatch(
                                        getUserOrders({
                                            status: selectedFilter?.value,
                                            resultsPerPage,
                                            page: orders.currentPage
                                        })
                                    );
                                }}
                            />
                        </div>
                    ))
                ) : (
                    <div className="py-3 px-4 bg-white rounded mb-3 mt-4">
                        <div className="d-flex flex-column text-center align-items-center text-black py-5">
                            <p className="text-inter text-600 text-24 mb-4">
                                No Orders Found
                            </p>

                            <Cart />

                            <p className="text-14 text-montserrat text-400 my-4">
                                You haven’t ordered anything. Order now
                            </p>

                            <Link to="/products">
                                <AppButton className="px-4">
                                    Explore Products
                                </AppButton>
                            </Link>
                        </div>
                    </div>
                )}

                {!!orders.totalPage && !!orders.currentPage && (
                    <Pagination
                        totalPages={orders.totalPage}
                        currentPage={orders.currentPage}
                        onChange={(page) => {
                            orderEl.current?.scrollIntoView({
                                behavior: 'smooth'
                            });

                            dispatch(
                                getUserOrders({
                                    status: selectedFilter?.value,
                                    resultsPerPage,
                                    page
                                })
                            );
                        }}
                    />
                )}
            </div>
        </div>
    ) : (
        <div className="app-container mb-5" ref={orderEl}>
            <div className="py-3 d-flex justify-content-between align-items-center">
                <Link
                    to="/dashboard"
                    className="text-600 text-14 text-montserrat text-black"
                >
                    <BiChevronLeft size={24} className="me-2 ms-n2" />
                    My Orders
                </Link>

                <Dropdown
                    overlay={statusMenu}
                    trigger={['click']}
                    className="cursor-pointer"
                >
                    <p className="mb-0 ms-1 d-flex align-items-center text-montserrat text-14 text-gray-3">
                        Delivery status :
                        <span className="ms-2 text-orange text-600">
                            {selectedFilter?.label ?? 'All'}
                        </span>
                        <CaretDownFilled className="ms-1" />
                    </p>
                </Dropdown>
            </div>

            <div>
                {orders.orders.length ? (
                    orders.orders.map((order) => (
                        <div className="mb-0 mb-md-4" key={order._id}>
                            <OrderCard
                                mobile={isMobile}
                                order={order}
                                onCancel={() => {
                                    dispatch(
                                        getUserOrders({
                                            status: selectedFilter?.value,
                                            resultsPerPage,
                                            page: orders.currentPage
                                        })
                                    );
                                }}
                            />
                        </div>
                    ))
                ) : (
                    <div className="py-3 px-4 bg-white rounded mb-3 mt-4">
                        <div className="d-flex flex-column text-center align-items-center text-black py-5">
                            <p className="text-inter text-600 text-20 mb-4">
                                No Orders Found
                            </p>

                            <Cart />

                            <p className="text-14 text-montserrat text-400 my-4">
                                You haven’t ordered anything. Order now
                            </p>

                            <Link to="/products">
                                <AppButton className="px-4" size="sm">
                                    Explore Products
                                </AppButton>
                            </Link>
                        </div>
                    </div>
                )}

                {!!orders.totalPage && !!orders.currentPage && (
                    <Pagination
                        totalPages={orders.totalPage}
                        currentPage={orders.currentPage}
                        onChange={(page) => {
                            orderEl.current?.scrollIntoView({
                                behavior: 'smooth'
                            });

                            dispatch(
                                getUserOrders({
                                    status: selectedFilter?.value,
                                    resultsPerPage,
                                    page
                                })
                            );
                        }}
                        className="mt-4"
                    />
                )}
            </div>
        </div>
    );
};

export default MyOrders;
