import { GET_TESTIMONIALS } from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { ITestimonial } from '../interfaces/Testimonial';

export interface ITestimonialState {
    testimonialsLoading: boolean;
    testimonials: ITestimonial[];
}

const initState: ITestimonialState = {
    testimonialsLoading: false,
    testimonials: []
};

const testimonialReducer = (state = initState, action: ActionType) => {
    let { type, payload } = action;

    switch (type) {
        case GET_TESTIMONIALS:
            return {
                ...state,
                ...payload
            };

        default:
            return state;
    }
};

export default testimonialReducer;
