import ProductCard from '../../shared/Card';
import { IProduct } from '../../../../interfaces/Product';
import { NextArrow, PrevArrow } from '../Arrows';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CSSProperties, useState } from 'react';
import { Swiper as SwiperType } from 'swiper/types';

interface IProps {
    products: IProduct[];
    cardClassName?: string;
}

const arrowStyles: CSSProperties = {
    position: 'absolute',
    top: 'calc(50% - 80px)',
    transform: 'translateY(calc(-50% + 40px))',
    zIndex: 100
};

const ProductSlider = ({ products, cardClassName }: IProps) => {
    const [swipe, setSwipe] = useState<SwiperType>();

    return products && products.length ? (
        <div className="position-relative">
            <Swiper
                loop
                slidesPerView={4}
                onBeforeInit={(swipper) => setSwipe(swipper)}
            >
                {products.map((product, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <ProductCard
                                product={product}
                                // disableHover
                                className={cardClassName}
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            <NextArrow
                onClick={() => swipe?.slideNext()}
                style={{
                    ...arrowStyles
                }}
            />

            <PrevArrow
                onClick={() => swipe?.slidePrev()}
                style={{
                    ...arrowStyles
                }}
            />
        </div>
    ) : (
        <></>
    );
};

export default ProductSlider;
