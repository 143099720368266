import { useState } from 'react';
import AppButton from '../../components/shared/AppButton';
import { Drawer, Radio, RadioChangeEvent, Space } from 'antd';
import esewa from '../../../assets/images/esewa.png';
// import khalti from '../../../assets/images/khalti.png';
import fonepay from '../../../assets/images/fonepay.png';
// import connectIPS from '../../../assets/images/connect-IPS.png';
import { $FIXME } from '../../../helpers/constants';
import { baseURL } from '../../../helpers/api';
import { DeliveryProps } from './Checkout';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import { initiateOrder } from '../../../actions/paymentActions';
import { PaymentType } from '../../../interfaces/Payment';
import { ICart } from '../../../interfaces/Cart';
import { getCarts } from '../../../actions/cartActions';
import { useMediaQuery } from 'react-responsive';

export type DeliveryAddressType = 'Default' | 'Home' | 'Office' | 'Other';

interface IProps {
    orderDetail?: DeliveryProps;
    onNext: () => void;
    carts: ICart[];
    goBack: () => void;
}

const PaymentOption = ({ orderDetail, onNext, carts, goBack }: IProps) => {
    const [option, setOption] = useState<PaymentType>('Cash on Delivery');

    const { isMinOrderValid } = useSelector((state: StateType) => state.cart);

    const { initiateOrderLoading } = useSelector(
        (state: StateType) => state.payment
    );

    const { coupon } = useSelector((state: StateType) => state.coupon);

    const onPaymentOptionChange = (e: RadioChangeEvent) => {
        setOption(e.target.value);
    };

    const dispatch = useDispatch<AppDispatch>();

    const esewaURL =
        process.env.REACT_APP_ESEWA_URL ?? 'https://uat.esewa.com.np/epay/main';
    const esewaClientId = process.env.REACT_APP_ESEWA_CLIENT_ID ?? 'EPAYTEST';
    const redirectUrl =
        process.env.REACT_APP_REDIRECT_URL ??
        'https://farmgro-web.asterdio.xyz';
    const fonePayURL =
        (process.env.REACT_APP_FONEPAY_URL ??
            'https://dev-clientapi.fonepay.com') + '/api/merchantRequest';
    const fonepayClientId =
        process.env.REACT_APP_FONEPAY_CLIENT_ID ?? 'Sudan0123';

    const handlePayment = (path: string, params: $FIXME) => {
        var form = document.createElement('form');
        form.setAttribute('method', 'POST');
        form.setAttribute('action', path);
        for (var key in params) {
            var hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', params[key]);
            form.appendChild(hiddenField);
        }
        document.body.appendChild(form);
        form.submit();
    };

    const handleCheckout = () => {
        if (orderDetail && carts.length) {
            const { deliveryId, deliveryTime, deliveryDate } = orderDetail;

            if (deliveryId && deliveryTime && deliveryDate)
                return dispatch(
                    initiateOrder({
                        delivery_address: deliveryId,
                        carts: carts.map((item) => item._id ?? ''),
                        delivery_date: deliveryDate,
                        delivery_time: deliveryTime,
                        payment_type: option,
                        coupon: coupon?.code
                    })
                );
        }
    };

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const isMd = useMediaQuery({ minWidth: 768 });

    return (
        <div className="px-md-3 py-3">
            <Radio.Group
                onChange={onPaymentOptionChange}
                value={option}
                size="large"
                className="app-radio-group"
            >
                <Space direction="vertical" size={30} wrap={false}>
                    <Radio
                        value={'Cash on Delivery'}
                        className="app-radio d-flex align-items-start w-100"
                    >
                        <div className="pt-1">
                            <span
                                className={`text-inter text-500 ${
                                    option === 'Cash on Delivery'
                                        ? 'text-dark-color'
                                        : 'text-gray-3'
                                }`}
                            >
                                Cash on delivery
                            </span>

                            {option === 'Cash on Delivery' ? (
                                <div className="px-3 py-5 cod-detail-container rounded mt-3">
                                    <p className="text-montserrat text-14 mb-3 text-gray-2">
                                        Based on the number of item, delivery
                                        can be delayed by half or one hour. If
                                        you find any difficulty, please contact
                                        us through @01-4567898
                                    </p>

                                    <p className="text-montserrat text-14 mb-0 text-gray-2">
                                        Cash on delivery wont be available if
                                        your order value exceed Rs. 5000
                                    </p>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </Radio>

                    <Radio
                        value={'Online Payment'}
                        className="app-radio d-flex align-items-start w-100"
                    >
                        <div className="pt-1">
                            <span
                                className={`text-inter text-500 ${
                                    option === 'Online Payment'
                                        ? 'text-dark-color'
                                        : 'text-gray-3'
                                }`}
                            >
                                Online Payment
                            </span>

                            {option === 'Online Payment' ? (
                                <div className="mt-3">
                                    <Space className="mb-3" size={30} wrap>
                                        <img
                                            src={esewa}
                                            width={138}
                                            className="object-contain"
                                            alt="esewa"
                                            onClick={() => {
                                                handleCheckout()?.then(
                                                    (res) => {
                                                        handlePayment(
                                                            esewaURL,
                                                            {
                                                                amt: res.order
                                                                    ?.total_amount,
                                                                psc: 0,
                                                                pdc: 0,
                                                                txAmt: 0,
                                                                tAmt: res.order
                                                                    ?.total_amount,
                                                                pid: res.order
                                                                    ?._id,
                                                                scd: esewaClientId,
                                                                su:
                                                                    baseURL +
                                                                    `/payment/transactions/esewa/success?q=su&user_id=${res.order?.user}`,
                                                                fu: `${redirectUrl}/cart/checkout`
                                                            }
                                                        );
                                                    }
                                                );
                                            }}
                                        />
                                        {/* <img
                                            src={khalti}
                                            width={102}
                                            className="object-contain"
                                            alt="khalti"
                                        />
                                        <img
                                            src={connectIPS}
                                            width={126}
                                            className="object-contain"
                                            alt="connect IPS"
                                        /> */}
                                        <img
                                            src={fonepay}
                                            width={120}
                                            className="object-contain"
                                            alt="fonepay"
                                            onClick={() => {
                                                const DT =
                                                    new Date().toLocaleString(
                                                        'en-US',
                                                        {
                                                            hour12: false,
                                                            year: 'numeric',
                                                            month: '2-digit',
                                                            day: '2-digit',
                                                            timeZone:
                                                                'Asia/Kathmandu'
                                                        }
                                                    );

                                                handleCheckout()?.then(
                                                    (res) => {
                                                        handlePayment(
                                                            fonePayURL,
                                                            {
                                                                PID: fonepayClientId,
                                                                MD: 'P',
                                                                AMT: res.order
                                                                    ?.total_amount,
                                                                CRN: 'NPR',
                                                                DT,
                                                                R1: res.order
                                                                    ?._id,
                                                                R2: res.order
                                                                    ?.remarks,
                                                                DV: res.order
                                                                    ?.fonepay_hash,
                                                                RU:
                                                                    baseURL +
                                                                    `/payment/transactions/fonepay/success?user_id=${res.order?.user}&oid=${res.order?._id}`,
                                                                PRN: res.order
                                                                    ?.identifier
                                                            }
                                                        );
                                                    }
                                                );
                                            }}
                                        />
                                    </Space>

                                    <p className="text-montserrat text-14 mb-0 text-gray-2">
                                        Based on the number of item, delivery
                                        can be delayed by half or one hour. If
                                        you find any difficulty, please contact
                                        us through @01-4567898
                                    </p>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </Radio>
                </Space>
            </Radio.Group>

            {isMd && option !== 'Online Payment' && (
                <div className="d-flex justify-content-between mt-5">
                    <div />

                    <AppButton
                        size={isDesktopOrLaptop ? 'lg' : 'sm'}
                        onClick={() => {
                            handleCheckout()?.then(() => {
                                dispatch(getCarts());
                                onNext();
                            });
                        }}
                        className="px-5"
                        disabled={initiateOrderLoading || !isMinOrderValid}
                        loading={initiateOrderLoading}
                    >
                        Place Order
                    </AppButton>
                </div>
            )}

            <Drawer
                visible={!isMd}
                placement="bottom"
                closable={false}
                mask={false}
                height={88}
            >
                <div className="d-flex justify-content-between">
                    <AppButton
                        size="sm"
                        onClick={() => goBack()}
                        className="px-4"
                    >
                        Back
                    </AppButton>

                    {option !== 'Online Payment' && (
                        <AppButton
                            size="sm"
                            onClick={() => {
                                handleCheckout()?.then(() => {
                                    dispatch(getCarts());
                                    onNext();
                                });
                            }}
                            className="px-5"
                            disabled={initiateOrderLoading || !isMinOrderValid}
                            loading={initiateOrderLoading}
                        >
                            Place Order
                        </AppButton>
                    )}
                </div>
            </Drawer>
        </div>
    );
};

export default PaymentOption;
