import { Breadcrumb } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { $FIXME } from '../../../helpers/constants';
import queryString from 'query-string';

const Breadcrum: React.FC<any> = () => {
    const location = useLocation();

    const query: $FIXME = queryString.parse(location.search);

    const renderBreadcrumbItem = () => {
        const items = [
            {
                title: 'Dashboard'
            },
            {
                title: 'Profile',
                params: 'profile'
            },
            {
                title: 'Orders',
                params: 'orders'
            },
            {
                title: 'Wallet',
                params: 'wallet'
            },
            {
                title: 'Offers',
                params: 'offers'
            },
            {
                title: 'Wishlist',
                params: 'wishlist'
            },
            {
                title: 'Address',
                params: 'address'
            }
        ];

        return items.find((item) => item.params && item.params in query);
    };

    return (
        <div className="app-container d-none d-lg-block">
            <Breadcrumb className="my-2 app-breadcrumbs">
                <Breadcrumb.Item>
                    <Link
                        to="/"
                        className="text-roboto text-12 text-light-gray-1"
                    >
                        Home
                    </Link>
                </Breadcrumb.Item>

                <Breadcrumb.Item>
                    <Link
                        to="/dashboard"
                        className="text-roboto text-12 text-light-gray-1"
                    >
                        Dashboard
                    </Link>
                </Breadcrumb.Item>

                {!!renderBreadcrumbItem() && (
                    <Breadcrumb.Item>
                        <Link
                            to={`/dashboard?${renderBreadcrumbItem()?.params}`}
                            className="text-roboto text-12 text-light-gray-1"
                        >
                            {renderBreadcrumbItem()?.title}
                        </Link>
                    </Breadcrumb.Item>
                )}
            </Breadcrumb>
        </div>
    );
};

export default Breadcrum;
