import { Breadcrumb, Space } from 'antd';
import { BiChevronLeft } from 'react-icons/bi';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Grocery } from '../../../assets/images/icons';
import AppButton from '../../components/shared/AppButton';
import MainLayout from '../../layouts/MainLayout';

const OrderConfirmed = () => {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const isSm = useMediaQuery({ minWidth: 576 });

    return (
        <MainLayout>
            {isDesktopOrLaptop ? (
                <div className="bg-white">
                    <Breadcrumb className="app-container py-2 bg-white">
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item className="text-orange">
                            Checkout
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            ) : (
                <div className="py-3 app-container">
                    <Link
                        to="/products"
                        className="text-600 text-14 text-montserrat text-black"
                    >
                        <BiChevronLeft size={24} className="me-2 ms-n2" />
                        Checkout
                    </Link>
                </div>
            )}

            <div className="app-container mb-5 mt-3">
                <div
                    className="d-flex flex-column align-items-center py-5 px-4 bg-white mx-auto"
                    style={{ maxWidth: 665 }}
                >
                    <h2 className="text-center text-inter text-600 text-24 text-gray mb-4">
                        Order Confirmed
                    </h2>

                    <Grocery className="mb-4" />

                    <p className="text-center text-montserrat text-14 text-gray-3 mb-5">
                        Based on the number of item, delivery can be delayed by
                        half or one hour. If you find any difficulty, please
                        contact us through @01-4567898
                    </p>

                    <Space
                        size={20}
                        align="center"
                        direction={!isSm ? 'vertical' : 'horizontal'}
                    >
                        <Link to="/products">
                            <AppButton
                                type="primary-outlined"
                                className="px-4 w-100 w-sm-auto"
                            >
                                Continue Shopping
                            </AppButton>
                        </Link>

                        <Link to="/dashboard?orders">
                            <AppButton className="px-4">
                                Track my orders
                            </AppButton>
                        </Link>
                    </Space>
                </div>
            </div>
        </MainLayout>
    );
};

export default OrderConfirmed;
