export type $FIXME = any;

//Keys
export const GOOGLE_CLIENTID =
    process.env.REACT_APP_GOOGLE_CLIENT_ID ??
    '164123035583-euqr67fij030em2356ombbd9mqrcic4r.apps.googleusercontent.com';
export const FACEBOOK_APPID =
    process.env.REACT_APP_FACEBOOK_APP_ID ?? '3016183038712564';

// Actions
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';

//Users
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const GET_USER = 'GET_USER';
export const GET_USER_DASHBOARD_ORDERS = 'GET_USER_DASHBOARD_ORDERS';
export const GET_USER_ORDERS = 'GET_USER_ORDERS';
export const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL';
export const GET_WALLET_BALANCE = 'GET_WALLET_BALANCE';
export const GET_WALLET_STATEMENTS = 'GET_WALLET_STATEMENTS';
export const GET_SEARCH_HISTORY = 'GET_SEARCH_HISTORY';

//Products
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_REVIEWS = 'GET_PRODUCT_REVIEWS';
export const RESET_PRODUCT_STATE = 'RESET_PRODUCT_STATE';
export const RESET_PRODUCT_REVIEWS = 'RESET_PRODUCT_REVIEWS';
export const GET_RANDOM_PRODUCTS = 'GET_RANDOM_PRODUCTS';
export const GET_DAIRY_PRODUCTS = 'GET_DAIRY_PRODUCTS';

//Carts
export const GET_CARTS = 'GET_CARTS';
export const GET_WISHLIST = 'GET_WISHLIST';
export const SET_MIN_ORDER_VALID = 'SET_MIN_ORDER_VALID';
export const GET_CART_DETAIL = 'GET_CART_DETAIL';

//Categories
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const GET_PRODUCT_TYPES = 'GET_PRODUCT_TYPES';

//Delivery Address
export const GET_DELIVERY_ADDRESS = 'GET_DELIVERY_ADDRESS';
export const SAVE_DELIVERY_REQUEST = 'SAVE_DELIVERY_REQUEST';
export const SAVE_DELIVERY_SUCCESS = 'SAVE_DELIVERY_SUCCESS';
export const SAVE_DELIVERY_FAILURE = 'SAVE_DELIVERY_FAILURE';
export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS';
export const GET_RINGROAD_AREAS = 'GET_RINGROAD_AREAS';

//Province & District
export const GET_PROVINCES = 'GET_PROVINCES';
export const GET_DISTRICTS = 'GET_DISTRICTS';
export const GET_AREAS_BY_DISTRICT = 'GET_AREAS_BY_DISTRICT';

//Payment
export const INITIATE_ORDER_REQUEST = 'INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_FAILURE = 'INITIATE_ORDER_FAILURE';
export const GET_DELIVERY_CHARGE = 'GET_DELIVERY_CHARGE';

//Coupon
export const GET_COUPON_DETAIL = 'GET_COUPON_DETAIL';
export const RESET_COUPON_DETAIL = 'RESET_COUPON_DETAIL';

//Newsfeed
export const SUBSCRIBE_NEWSFEED_REQUEST = 'SUBSCRIBE_NEWSFEED_REQUEST';
export const SUBSCRIBE_NEWSFEED_SUCCESS = 'SUBSCRIBE_NEWSFEED_SUCCESS';
export const SUBSCRIBE_NEWSFEED_FAIL = 'SUBSCRIBE_NEWSFEED_FAIL';

//Contacts
export const GET_CONTACTINFO = 'GET_CONTACTINFO';
export const SAVE_CONTACT_REQUEST = 'SAVE_CONTACT_REQUEST';
export const SAVE_CONTACT_SUCCESS = 'SAVE_CONTACT_SUCCESS';
export const SAVE_CONTACT_FAILURE = 'SAVE_CONTACT_FAILURE';

//Pages
export const GET_FAQ = 'GET_FAQ';
export const GET_PRIVACY_POLICY = 'GET_PRIVACY_POLICY';
export const GET_TERMS_AND_CONDITIONS = 'GET_TERMS_AND_CONDITIONS';
export const GET_RETURN_POLICY = 'GET_RETURN_POLICY';
export const privacyPolicySlug = 'privacy-policy';
export const termsConditionsSlug = 'terms-and-conditions';
export const returnPolicySlug = 'return-policy';

//Valued Customers
export const GET_VALUED_CUSTOMERS = 'GET_VALUED_CUSTOMERS';

//Blogs
export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOG_BY_SLUG = 'GET_BLOG_BY_SLUG';
export const LIKE_BLOG = 'LIKE_BLOG';
export const DISLIKE_BLOG = 'DISLIKE_BLOG';
export const GET_BLOG_COMMENTS = 'GET_BLOG_COMMENTS';
export const SET_BLOG_COMMENTS_LOADING = 'SET_BLOG_COMMENTS_LOADING';
export const RESET_BLOG_COMMENTS = 'RESET_BLOG_COMMENTS';
export const GET_SIMILAR_BLOG = 'GET_SIMILAR_BLOG';

//Tetimonials
export const GET_TESTIMONIALS = 'GET_TESTIMONIALS';

//Banners
export const GET_BANNERS = 'GET_BANNERS';

//Offers
export const GET_HOME_OFFERS = 'GET_HOME_OFFERS';
export const GET_OFFERS = 'GET_OFFERS';

export const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const nameRegExp = /^\s*[\S]+(\s[\S]+)+\s*$/gms;

export const defaultImage =
    'https://firebasestorage.googleapis.com/v0/b/oxstrength-4d9bf.appspot.com/o/images%2Fdefault-image.jpg?alt=media&token=58aaa54c-1e27-4f88-a521-bc6436bd10be';

//Nav Categories

export const GET_NAV_VEGETABLES = 'GET_NAV_VEGETABLES';
export const GET_NAV_MEAT_MARKET = 'GET_NAV_MEAT_MARKET';
export const GET_NAV_SEASONAL_EXOTIC = 'GET_NAV_SEASONAL_EXOTIC';

//Home Products
export const GET_HOME_VEGETABLES = 'GET_HOME_VEGETABLES';
export const GET_HOME_MEAT_PRODUCTS = 'GET_HOME_MEAT_PRODUCTS';
export const GET_HOME_FRUITS = 'GET_HOME_FRUITS';
export const GET_HOME_DAIRY_PRODUCTS = 'GET_HOME_DAIRY_PRODUCTS';
export const GET_HOME_MISCELLANEOUS_PRODUCTS =
    'GET_HOME_MISCELLANEOUS_PRODUCTS';

//Footer Categories
export const GET_FOOTER_FRUITS_VEGETABLES = 'GET_FOOTER_FRUITS_VEGETABLES';
export const GET_FOOTER_DAIRY = 'GET_FOOTER_DAIRY';
export const GET_FOOTER_MEAT = 'GET_FOOTER_MEAT';
export const GET_FOOTER_MISC = 'GET_FOOTER_MISC';

//Meat Market
export const GET_CHICKEN_ITEMS = 'GET_CHICKEN_ITEMS';
export const GET_MUTTOM_ITEMS = 'GET_MUTTOM_ITEMS';
export const GET_FISH_ITEMS = 'GET_FISH_ITEMS';

//Wallet
export const GET_WALLET_DETAIL = 'GET_WALLET_DETAIL';
export const RESET_WALLET_DETAIL = 'RESET_WALLET_DETAIL';

export const vegetableTypeId = '612ff1f4158ab516f4ddfeec';
export const meatMarketTypeId = '612ffa4aba62e54f6dc14c63';
export const fruitBasketTypeId = '618211f830673363cfc0b49b';
export const seasonalExoticTypeId = '6189fee830673363cfc0bc8a';
export const miscellaneousTypeId = '6182126230673363cfc0b4aa';
export const freshSeasonalTypeId = '6189fe9f30673363cfc0bc81';
export const dairyTypeId = '6189ff1130673363cfc0bc91';
export const chickenTypeId = '622893684230af2b4fcb4c8a';
export const muttonTypeId = '6228937d4230af2b4fcb4c97';
export const fishTypeId = '624425e80ed2915e25e1dca3';
