import { Col, Row } from 'antd';
import MainLayout from '../../layouts/MainLayout';
import vegetables from '../../../assets/images/AboutUs/fruits-vegetables.png';
import cheese from '../../../assets/images/AboutUs/delicious-pieces-cheese.png';
import meat from '../../../assets/images/AboutUs/meat.png';
import egg from '../../../assets/images/AboutUs/egg.png';
import veggies from '../../../assets/images/AboutUs/vegetables-hands.png';
import Newsletter from '../../components/home/Newsletter';
import GlobalSlider from '../../components/shared/slider/GlobalSlider';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getBlogs } from '../../../actions/blogActions';
import { getTestimonials } from '../../../actions/testimonialActions';
import { StateType } from '../../../reducers';
import BlogCard from './BlogCard';
import TestimonialCard from './TestimonialCard';
import { Link } from 'react-router-dom';

const AboutUs = () => {
    const { blogs } = useSelector((state: StateType) => state.blog);
    const { testimonials } = useSelector(
        (state: StateType) => state.testimonial
    );

    const testimonialResponsive = [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 2,
                infinite: testimonials.length >= 2,
                centerPadding: '120px',
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: testimonials.length >= 1,
                centerPadding: '30px'
            }
        }
    ];

    const blogResponsive = [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: blogs.length >= 2,
                centerPadding: '120px'
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: blogs.length >= 1,
                centerPadding: '30px'
            }
        }
    ];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBlogs());
        dispatch(getTestimonials());
    }, [dispatch]);

    return (
        <MainLayout>
            <div className="aboutUs-wrapper">
                <section className="banner-container">
                    <div className="banner-inner">
                        <h2>We’re FamGro</h2>
                        <br />
                        <p>
                            Aliqua id fugiat nostrud irure ex duis ea quis id
                            quis ad et. Sunt qui esse pariatur duis deserunt
                            mollit dolore cillum minim tempor enim. Elit aute
                            irure tempor cupidatat incididunt sint deserunt ut
                            voluptate aute id deserunt nisi.
                        </p>
                    </div>
                </section>
                <div className="aboutUs-container">
                    <section className="app-container">
                        <Row gutter={32}>
                            <Col className="gutter-row" sm={14}>
                                <h4>Who are we?</h4>
                                <p>
                                    Aliqua id fugiat nostrud irure ex duis ea
                                    quis id quis ad et. Sunt qui esse pariatur
                                    duis dete irure tempor cupidataAliqua id
                                    fugiat nostrud irure ex duis ea quis id quis
                                    ad et. Sunt qui esse pariatur duis deserunt
                                    mo Lit dolore cillum minim tempor enim.
                                </p>
                                <p>
                                    {' '}
                                    Elit aute irure tempor cupidatat incididunt
                                    sint deserunt ut voluptate aute id deserunt
                                    nisi.t incididunt sint deserunt ut voluptate
                                    aute id deserunt nisi.
                                </p>
                                <Link to="/contact-us">
                                    <button
                                        type="button"
                                        className="ant-btn ant-btn-lg button-main contact-us-btn"
                                    >
                                        Contact Us
                                    </button>
                                </Link>
                            </Col>
                            <Col className="gutter-row" sm={10}>
                                <Row gutter={[16, 16]}>
                                    <Col className="gutter-row" span={24}>
                                        <img src={vegetables} alt="" />
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <img src={cheese} alt="" />
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <img src={meat} alt="" />
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <img src={egg} alt="" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </section>
                    <section className="what-we-do app-container">
                        <Row gutter={32}>
                            <Col
                                className="gutter-row"
                                sm={{ span: 10, order: 1 }}
                                order={2}
                            >
                                <img src={veggies} alt="what-we-do" />
                            </Col>
                            <Col
                                className="gutter-row"
                                sm={{ span: 14, order: 2 }}
                                order={1}
                            >
                                <div className="h-100 d-flex flex-column justify-content-center">
                                    <h4>What do we do?</h4>
                                    <p>
                                        We take order from you and provide best
                                        organic raw food products. We provide
                                        food products direct from our farm. Our
                                        goal is to provide healthy ,organic
                                        products to our customers.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </section>
                    <section className="our-process-container app-container">
                        <h4>Our Process</h4>
                        <Row gutter={0}>
                            <Col
                                sm={24}
                                lg={6}
                                className="mx-auto d-flex flex-column-reverse flex-lg-column mb-3 mb-lg-0"
                            >
                                <div className="steps-content">
                                    <h5>Search Product</h5>
                                    <p>
                                        Search your desired product on our site
                                        or application
                                    </p>
                                </div>
                                <div className="step-item">
                                    <p className="timeline-number timeline-number-mobile">
                                        {' '}
                                        <b>1</b>
                                    </p>
                                    <div className="line first-line"></div>
                                </div>
                            </Col>
                            <Col
                                sm={24}
                                lg={6}
                                className="mx-auto d-flex flex-column-reverse flex-lg-column mb-3 mb-lg-0"
                            >
                                <div className="steps-content">
                                    <h5>ADD TO CART</h5>
                                    <p>Add the product to the cart to order</p>
                                </div>
                                <div className="step-item">
                                    <p className="timeline-number timeline-number-mobile">
                                        {' '}
                                        <b>2</b>
                                    </p>
                                    <div className="line"></div>
                                </div>
                            </Col>
                            <Col
                                sm={24}
                                lg={6}
                                className="mx-auto d-flex flex-column-reverse flex-lg-column mb-3 mb-lg-0"
                            >
                                <div className="steps-content">
                                    <h5>PAYMENT</h5>
                                    <p>
                                        You can pay digital cash or you can cash
                                        on delivery
                                    </p>
                                </div>
                                <div className="step-item">
                                    <p className="timeline-number timeline-number-mobile">
                                        {' '}
                                        <b>3</b>
                                    </p>
                                    <div className="line"></div>
                                </div>
                            </Col>
                            <Col
                                sm={24}
                                lg={6}
                                className="mx-auto d-flex flex-column-reverse flex-lg-column mb-3 mb-lg-0"
                            >
                                <div className="steps-content">
                                    <h5>DELIVER</h5>
                                    <p>
                                        We deliver your product on your selected
                                        location
                                    </p>
                                </div>
                                <div className="step-item">
                                    <p className="timeline-number timeline-number-mobile">
                                        {' '}
                                        <b>4</b>
                                    </p>
                                    <div className="line last-line"></div>
                                </div>
                            </Col>
                        </Row>
                    </section>
                    <section className="counts-container app-container">
                        <Row className="counts-row" justify="center">
                            <Col sm={24} lg={8}>
                                <h4>120</h4>
                                <p>Users</p>
                            </Col>
                            <Col sm={24} lg={8}>
                                <h4>20</h4>
                                <p>Delivery Boys</p>
                            </Col>
                            <Col sm={24} lg={8}>
                                <h4>5</h4>
                                <p>Years</p>
                            </Col>
                        </Row>
                    </section>
                </div>
                <section className="testimonial-container">
                    <Row className="app-container">
                        <Col sm={24} lg={24}>
                            <h4 className="text-center">Testimonials</h4>
                        </Col>
                        <Col sm={24} lg={24}>
                            <GlobalSlider
                                customSettings={{
                                    slidesToShow:
                                        testimonials.length >= 3
                                            ? 3
                                            : testimonials.length,
                                    centerMode: true,
                                    responsive: testimonialResponsive
                                }}
                            >
                                {testimonials?.map((testimonial) => (
                                    <TestimonialCard
                                        key={testimonial._id}
                                        testimonial={testimonial}
                                    />
                                ))}
                            </GlobalSlider>
                        </Col>
                    </Row>
                </section>
                <section className="aboutUs-promo-container">
                    <div className="app-container">
                        <div className="promo-container-wrapper">
                            <div className="promo-banner-section">
                                <div className="promo-banner-section-inner">
                                    <h3>
                                        Get Fresh Fruits and Vegetables and Meat
                                        Items
                                    </h3>
                                    <h2>ORDER NOW!!</h2>

                                    <Link to="/products">
                                        <button
                                            type="button"
                                            className="ant-btn ant-btn-lg button-main"
                                        >
                                            <span>Explore Product</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blog-container">
                    <Row className="app-container">
                        <Col sm={24} lg={24}>
                            <h4 className="text-center">Blogs</h4>
                        </Col>
                        <Col sm={24} lg={24}>
                            <div className="blog-slider-container">
                                <GlobalSlider
                                    customSettings={{
                                        slidesToShow: 3,
                                        infinite: blogs.length >= 3,
                                        centerMode: true,
                                        responsive: blogResponsive
                                    }}
                                >
                                    {blogs?.map((blog) => (
                                        <BlogCard key={blog._id} blog={blog} />
                                    ))}
                                </GlobalSlider>
                            </div>
                        </Col>
                    </Row>
                </section>
                <Newsletter />
            </div>
        </MainLayout>
    );
};

export default AboutUs;
