import {
    GET_DAIRY_PRODUCTS,
    GET_PRODUCT,
    GET_PRODUCTS,
    GET_PRODUCT_REVIEWS,
    GET_RANDOM_PRODUCTS,
    RESET_PRODUCT_REVIEWS,
    RESET_PRODUCT_STATE
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IProduct, IProductReview, IProducts } from '../interfaces/Product';

export interface IProductState {
    product: IProduct;
    productLoading: boolean;
    reviews: IProductReview;
    reviewsLoading: boolean;
    products: IProducts;
    productsLoading: boolean;
    randomProducts: Array<IProduct>;
    dairyProducts: IProduct[];
}

const initialState: IProductState = {
    product: {},
    productLoading: false,
    reviews: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        reviews: []
    },
    reviewsLoading: false,
    products: {
        currentPage: 1,
        totalPage: 1,
        totalDocuments: 0,
        products: []
    },
    productsLoading: false,
    randomProducts: [],
    dairyProducts: []
};

const productReducer = (
    state = initialState,
    { payload, type }: ActionType
): IProductState => {
    switch (type) {
        case GET_PRODUCT:
            return {
                ...state,
                ...payload
            };

        case GET_PRODUCT_REVIEWS:
            return {
                ...state,
                reviewsLoading: payload?.reviewsLoading,
                reviews: {
                    ...state.reviews,
                    ...payload.reviews,
                    reviews: [
                        ...state.reviews.reviews,
                        ...(payload?.reviews?.reviews ?? [])
                    ]
                }
            };

        case GET_PRODUCTS:
            return {
                ...state,
                productsLoading: payload?.productsLoading,
                products: {
                    ...payload.products,
                    products: payload?.products?.products ?? []
                }
            };

        case GET_RANDOM_PRODUCTS:
            return {
                ...state,
                randomProducts: payload
            };

        case RESET_PRODUCT_REVIEWS:
            return {
                ...state,
                reviews: initialState.reviews
            };

        case GET_DAIRY_PRODUCTS:
            return {
                ...state,
                dairyProducts: payload
            };

        case RESET_PRODUCT_STATE:
            return initialState;

        default:
            return state;
    }
};

export default productReducer;
