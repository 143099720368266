import request from '../helpers/request';
import { Dispatch } from 'redux';
import {
    GET_CONTACTINFO,
    SAVE_CONTACT_FAILURE,
    SAVE_CONTACT_REQUEST,
    SAVE_CONTACT_SUCCESS
} from '../helpers/constants';
import { AxiosResponse } from 'axios';
import { ISaveContactBody } from '../interfaces/Contact';
import { setErrors } from './errorActions';
import { toast } from 'react-toastify';

export const getContactInfo = () => async (dispatch: Dispatch) => {
    request.getContactInfo().then((res: AxiosResponse) => {
        dispatch({ type: GET_CONTACTINFO, payload: res.data });
    });
};

export const saveContact =
    (body: ISaveContactBody) => async (dispatch: Dispatch) => {
        dispatch({ type: SAVE_CONTACT_REQUEST });

        return request
            .saveContact(body)
            .then((res: AxiosResponse) => {
                dispatch({ type: SAVE_CONTACT_SUCCESS });

                toast.success('Your enquiry has been sent.', {
                    position: toast.POSITION.TOP_CENTER
                });

                return Promise.resolve();
            })
            .catch((err) => {
                dispatch({ type: SAVE_CONTACT_FAILURE });

                dispatch(
                    setErrors(
                        err.response?.data.message ?? 'Something went wrong'
                    )
                );

                return Promise.reject();
            });
    };
