import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import {
    $FIXME,
    GET_CARTS,
    GET_CART_DETAIL,
    GET_WISHLIST,
    SET_MIN_ORDER_VALID
} from '../helpers/constants';
import request from '../helpers/request';
import { store } from '../helpers/store';
import { IUpdateCartBody, IAddCartBody } from '../interfaces/Cart';
import { IDeliveryAddress } from '../interfaces/DeliveryAddress';
import { IWishlistParams } from '../interfaces/Wishlist';
import { showToast } from '../utils/toast';
import { toggleDrawer } from './uiActions';

export const getCarts = () => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_CARTS,
        payload: { cartsLoading: true }
    });

    const selectedAddress: IDeliveryAddress | undefined =
        store.getState().deliveryAddress.selectedDeliveryAddress;

    const coupon_code: string | undefined =
        store.getState().coupon?.coupon?.code;

    return request
        .getCartList({
            delivery_address: selectedAddress?._id,
            coupon_code
        })
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_CARTS,
                payload: res.data
            });
        })
        .then(() => {
            dispatch({
                type: GET_CARTS,
                payload: { cartsLoading: false }
            });
        })
        .catch((err: AxiosError) => {
            showToast('error', err.response?.data?.message);
            dispatch({
                type: GET_CARTS,
                payload: { cartsLoading: false }
            });
        });
};

export const getWishList =
    (params?: IWishlistParams) => async (dispatch: Dispatch) => {
        return request.getWishList(params).then((res: AxiosResponse) => {
            dispatch({
                type: GET_WISHLIST,
                payload: res.data
            });
        });
    };

export const addToCart = (body: IAddCartBody) => async (dispatch: $FIXME) => {
    return request
        .addToCart(body)
        .then((res: AxiosResponse) => {
            showToast('success', 'Product added to cart');

            dispatch(getCarts()).then(() => {
                dispatch(toggleDrawer(true));
            });

            return Promise.resolve();
        })
        .catch((err: AxiosError) => {
            showToast('error', err.response?.data?.message);

            return Promise.reject();
        });
};

export const buyNow = (body: IAddCartBody) => async (dispatch: $FIXME) => {
    const selectedAddress: IDeliveryAddress | undefined =
        store.getState().deliveryAddress.selectedDeliveryAddress;

    const coupon_code: string | undefined =
        store.getState().coupon?.coupon?.code;

    const params = {
        delivery_address: selectedAddress?._id,
        coupon_code
    };

    return request.addToCart(body, params).then((res: AxiosResponse) => {
        dispatch({
            type: GET_CART_DETAIL,
            payload: { ...res.data, carts: [res.data.carts] }
        });

        return Promise.resolve();
    });
};

export const addToWishList = (id: string) => async (dispatch: $FIXME) => {
    return request
        .addToWishList(id)
        .then((res: AxiosResponse) => {
            showToast('success', 'Added to Wish List');

            dispatch(getWishList());

            return Promise.resolve();
        })
        .catch((err: AxiosError) => {
            if (err.response?.status === 409) {
                showToast('success', err.response?.data.message);

                return Promise.resolve();
            } else {
                showToast('error', err.response?.data.message);

                return Promise.reject();
            }
        });
};

export const removeWishList = (id: string) => async (dispatch: Dispatch) => {
    return request
        .deleteWishlist(id)
        .then((res: AxiosResponse) => {
            showToast('success', 'Product removed from your wishlist');

            return Promise.resolve();
        })
        .catch((err: AxiosError) => {
            showToast('error', err.response?.data.message);

            return Promise.reject();
        });
};

export const updateCartUnit =
    (id: string, body: IUpdateCartBody) => async (dispatch: $FIXME) => {
        return request.updateCart(id, body).then((res: AxiosResponse) => {
            dispatch(getCarts());
        });
    };

export const deleteCart = (id: string) => async (dispatch: $FIXME) => {
    return request.deleteCart(id).then((res: AxiosResponse) => {
        dispatch(getCarts());
    });
};

export const resetCart = () => async (dispatch: $FIXME) => {
    return request.resetCart().then((res: AxiosResponse) => {
        dispatch(getCarts());
    });
};

export const setMinOrderValid =
    (valid: boolean) => async (dispatch: Dispatch) => {
        return dispatch({ type: SET_MIN_ORDER_VALID, payload: valid });
    };

export const addWishlistToCart = () => async (dispatch: Dispatch) => {
    return request
        .addWishlistToCart()
        .then((res: AxiosResponse) => {
            showToast('success', res.data?.message ?? 'Wishlist added to cart');

            return Promise.resolve();
        })
        .catch((err: AxiosError) => {
            showToast('error', err.response?.data?.message);

            return Promise.reject();
        });
};
