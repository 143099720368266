import { Breadcrumb, Col, Row } from 'antd';
import { useEffect } from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import {
    getDairyProducts,
    getProductBySlug,
    getRandomProducts
} from '../../../actions/productActions';
import { $FIXME, dairyTypeId } from '../../../helpers/constants';
import { AppDispatch, StateType } from '../../../reducers';
import Brief from '../../components/detail/Brief';
import ProductBought from '../../components/detail/ProductBought';
import ProductDetail from '../../components/detail/ProductDetail';
import AppSpinner from '../../components/shared/AppSpinner';
import Card from '../../components/shared/Card';
import ProductSlider from '../../components/shared/slider/Slider';
import MainLayout from '../../layouts/MainLayout';

interface IProps {}

const Detail = (props: IProps) => {
    const { productLoading, product, dairyProducts } = useSelector(
        (state: StateType) => state.product
    );

    const dispatch = useDispatch<AppDispatch>();

    const { slug }: $FIXME = useParams();

    useEffect(() => {
        if (slug) {
            dispatch(getProductBySlug(slug)).then((id) => {
                dispatch(getRandomProducts(id));
            });
            dispatch(getDairyProducts());
        }
    }, [dispatch, slug]);

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    return (
        <MainLayout>
            <div className="app-container pb-5">
                {productLoading && slug !== product.slug ? (
                    <div className="py-5 d-flex align-items-center justify-content-center">
                        <AppSpinner />
                    </div>
                ) : (
                    <>
                        {!isDesktopOrLaptop ? (
                            <div className="py-3">
                                <Link
                                    to="/products"
                                    className="text-600 text-14 text-montserrat text-black"
                                >
                                    <BiChevronLeft
                                        size={24}
                                        className="me-2 ms-n2"
                                    />
                                    Product Details
                                </Link>
                            </div>
                        ) : (
                            <Breadcrumb className="py-2 mb-2 app-breadcrumbs">
                                <Breadcrumb.Item>
                                    <Link
                                        to="/"
                                        className="text-montserrat text-12 text-light-gray-1"
                                    >
                                        Home
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link
                                        to="/products"
                                        className="text-montserrat text-12 text-light-gray-1"
                                    >
                                        Products
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link
                                        to={`/products/${product.slug}`}
                                        className="text-montserrat text-12 text-orange"
                                    >
                                        {product.name}
                                    </Link>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        )}

                        <Brief />

                        <Row
                            className="container-wrapper-inner"
                            gutter={{ lg: 20 }}
                        >
                            <Col xs={24} lg={16}>
                                <ProductDetail />
                            </Col>

                            <Col xs={0} lg={8}>
                                <ProductBought />
                            </Col>
                        </Row>
                    </>
                )}

                {!!dairyProducts.length && (
                    <div className="product-container-wrapper mt-n5">
                        <div className="product-header d-flex justify-content-between align-items-center mb-3">
                            <p className="landing-title mb-0">
                                Farmgro Dairy Products
                            </p>

                            {dairyProducts.length >= 4 && (
                                <Link to={`/products?type=${dairyTypeId}`}>
                                    All Dairy Products
                                </Link>
                            )}
                        </div>

                        {isDesktopOrLaptop ? (
                            <div className="my-2 dariy-slider-section">
                                <ProductSlider products={dairyProducts} />
                            </div>
                        ) : (
                            <SimpleBar className="mx-n3 product-card-wrapper">
                                {dairyProducts.map((product, index) => {
                                    return (
                                        <Card
                                            disableHover={!isDesktopOrLaptop}
                                            key={index}
                                            product={product}
                                            style={{
                                                minWidth: 200,
                                                width: 200
                                            }}
                                        />
                                    );
                                })}
                            </SimpleBar>
                        )}
                    </div>
                )}
            </div>
        </MainLayout>
    );
};

export default Detail;
