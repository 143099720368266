import { useEffect, useState } from 'react';
import { useLocation, Redirect, useHistory } from 'react-router-dom';
import { $FIXME } from '../../../helpers/constants';
import queryString from 'query-string';
import AppButton from '../../components/shared/AppButton';
import request from '../../../helpers/request';
import { BsCheck2 } from 'react-icons/bs';
import { AxiosError } from 'axios';
import { IEmailVerifyParams } from '../../../interfaces/User';
import { Spin } from 'antd';
import AuthLayout from '../../layouts/AuthLayout';
import { useMediaQuery } from 'react-responsive';

interface IProps {}
const EmailVerification = (props: IProps) => {
    const [emailVerifed, setEmailVerified] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string>();

    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery({ maxWidth: 576 });

    const history = useHistory();

    const location = useLocation();

    const query: $FIXME = queryString.parse(location.search);

    useEffect(() => {
        const params: IEmailVerifyParams = {
            email: query.email,
            code: query.token
        };

        if (params.email && params.code) {
            setLoading(true);

            request
                .verifyEmail(params)
                .then((res) => {
                    setEmailVerified(true);

                    setLoading(false);
                })
                .catch((err: AxiosError) => {
                    setErrorMessage(
                        err.response?.data?.message ?? 'Something went wrong.'
                    );

                    setLoading(false);
                });
        } else {
            setErrorMessage('No email or token was found');
        }
    }, [history, query.email, query.token]);

    if (!query.email || !query.token) {
        return <Redirect to="/login" />;
    }

    return (
        <AuthLayout>
            {!emailVerifed ? (
                <div className="login-form">
                    <div className="login-form-inner">
                        {loading ? (
                            <>
                                <h2 className="mb-3">Verifying email...</h2>
                                <Spin spinning />
                            </>
                        ) : (
                            ''
                        )}

                        {errorMessage ? (
                            <>
                                <h2 className="mb-3">Verification Failed</h2>
                                <p className="text-montserrat text-400 text-16 text-gray-3">
                                    {errorMessage}
                                </p>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            ) : (
                <div className="login-form">
                    <div className="login-form-inner">
                        <h2 className="mt-4 text-roboto text-500 text-24 mb-5">
                            Account Verified
                        </h2>

                        <div className="d-flex justify-content-center mb-5">
                            <div
                                style={{ width: 120, height: 120 }}
                                className="d-flex align-items-center justify-content-center bg-green-light rounded-circle"
                            >
                                <BsCheck2 size={88} className="text-green" />
                            </div>
                        </div>

                        <p className="text-montserrat text-400 text-16 text-gray-3">
                            Your account has been verified. Please login to your
                            account.
                        </p>

                        <AppButton
                            className="w-100"
                            onClick={() => history.push('/login')}
                            size={isMobile ? 'sm' : 'lg'}
                        >
                            Login Now
                        </AppButton>
                    </div>
                </div>
            )}
        </AuthLayout>
    );
};

export default EmailVerification;
