import OrderBill from '../views/components/myPanel/contents/OrderBill';
import EmailVerification from '../views/screens/Auth/EmailVerification';
import OtpVerification from '../views/screens/Auth/OtpVerification';
import Cart from '../views/screens/cart/Cart';
import { Checkout } from '../views/screens/checkout/Checkout';
import OrderConfirmed from '../views/screens/checkout/OrderConfirmed';
import ContactUs from '../views/screens/contactUs/ContactUs';
import Detail from '../views/screens/detail/Detail';
import Home from '../views/screens/home/Home';
import ProductSearch from '../views/screens/product/productSearch/ProductSearch';
import ForgotPassword from '../views/screens/Auth/ForgotPassword';
import ResetPassword from '../views/screens/Auth/ResetPassword';
import Login from '../views/screens/Auth/Login';
import Register from '../views/screens/Auth/Register';
import CheckMail from '../views/screens/Auth/CheckMail';
import MyPanel from '../views/screens/myPanel';
import Meat from '../views/screens/meatMarketLanding/Meat';
import { RouteType } from '../App';
import AboutUs from '../views/screens/AboutUs/AboutUs';
import FAQ from '../views/screens/FAQ/FAQ';
import PageNotFound from '../views/screens/pageNotFound/PageNotFound';
import Blog from '../views/screens/Blog/Blog';
import PrivacyPolicy from '../views/screens/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../views/screens/TermsAndConditions/TermsAndConditions';
import ReturnPolicy from '../views/screens/ReturnPolicy/ReturnPolicy';

const routes: Array<RouteType> = [
    {
        path: '/',
        component: Home,
        exact: true
    },
    {
        path: '/products/:slug',
        component: Detail,
        exact: true
    },
    {
        path: '/products',
        component: ProductSearch,
        exact: true
    },
    {
        path: '/cart',
        component: Cart,
        exact: true,
        protected: true
    },
    { path: '/login', component: Login },
    { path: '/register', component: Register },
    { path: '/otp-verify', component: OtpVerification },
    { path: '/email-verify', component: EmailVerification },
    { path: '/forgot-password', component: ForgotPassword },
    { path: '/check-mail', component: CheckMail },
    {
        path: '/reset-password',
        component: ResetPassword
    },
    {
        path: '/cart/checkout',
        component: Checkout,
        exact: true,
        protected: true
    },
    {
        path: '/cart/checkout/confirmed',
        component: OrderConfirmed,
        exact: true
    },
    {
        path: '/contact-us',
        component: ContactUs,
        exact: true
    },
    {
        path: '/dashboard',
        component: MyPanel,
        exact: true,
        protected: true
    },
    {
        path: '/order/:id',
        component: OrderBill,
        protected: true
    },
    {
        path: '/meat-market',
        component: Meat
    },
    {
        path: '/contact-us',
        component: ContactUs,
        exact: true
    },
    {
        path: '/about-us',
        component: AboutUs
    },
    {
        path: '/faq',
        component: FAQ
    },
    {
        path: '/blog/:slug',
        component: Blog
    },
    {
        path: '/privacy-policy',
        component: PrivacyPolicy
    },
    {
        path: '/terms-and-conditions',
        component: TermsAndConditions
    },
    {
        path: '/return-policy',
        component: ReturnPolicy
    },
    {
        path: '',
        component: PageNotFound
    }
];

export default routes;
