import { Modal, Select, Space, Form, Radio, Row, Col } from 'antd';
import { useState } from 'react';
import { AiOutlineMail, AiOutlineUser } from 'react-icons/ai';
import { BsPhone } from 'react-icons/bs';
import { FiMapPin } from 'react-icons/fi';
import {
    DeliveryAddressType,
    IDeliveryAddress
} from '../../../../../interfaces/DeliveryAddress';
import AppInput from '../../../shared/AppInput';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAreasByDistrict,
    getDistrictByProvince
} from '../../../../../actions/addressActions';
import { StateType } from '../../../../../reducers';
import { nameRegExp, phoneRegExp } from '../../../../../helpers/constants';
import request from '../../../../../helpers/request';
import { getDeliveryAddress } from '../../../../../actions/deliveryAddressActions';
import { AxiosError } from 'axios';
import { showToast } from '../../../../../utils/toast';
import AppButton from '../../../shared/AppButton';

interface IProps {
    editMode?: boolean;
    visible?: boolean;
    onOk?: () => void;
    onCancel?: React.MouseEventHandler<HTMLElement>;
    address?: IDeliveryAddress;
    mobile?: boolean;
}

const AddressModal = ({
    visible,
    onOk,
    onCancel,
    editMode,
    address,
    mobile
}: IProps) => {
    const { provinces, districts, areas } = useSelector(
        (state: StateType) => state.address
    );

    const [saving, setSaving] = useState(false);

    const [form] = Form.useForm<IDeliveryAddress>();

    const [selectedAddressType] = useState<DeliveryAddressType>('Home');

    const addressTypes: Array<DeliveryAddressType> = ['Home', 'Work', 'Other'];

    const dispatch = useDispatch();

    const onSubmit = (values: IDeliveryAddress) => {
        setSaving(true);

        const onSuccess = () => {
            dispatch(getDeliveryAddress());

            setSaving(false);

            onOk && onOk();

            form.resetFields();
        };

        if (!editMode) {
            request
                .saveDeliveryAddress({
                    ...values,
                    primary: false,
                    address: { ...values?.address, country: 'Nepal' },
                    inside_ringroad: values?.inside_ringroad ?? false
                })
                .then(() => {
                    showToast('success', 'Delivery address has been added');

                    onSuccess();
                })
                .catch((err: AxiosError) => {
                    showToast('error', err.response?.data?.message);

                    setSaving(false);
                });
        } else {
            if (address?._id)
                request
                    .updateAddress(address?._id, {
                        ...values,
                        primary: false,
                        address: { ...values?.address, country: 'Nepal' },
                        inside_ringroad: values?.inside_ringroad ?? false
                    })
                    .then(() => {
                        showToast(
                            'success',
                            'Delivery address has been updated'
                        );

                        onSuccess();
                    })
                    .catch((err: AxiosError) => {
                        showToast('error', err.response?.data?.message);

                        setSaving(false);
                    });
        }
    };

    return (
        <Modal
            title={
                <>
                    <h4>{editMode ? 'Edit' : 'Add New'} Address</h4>{' '}
                    {!editMode && (
                        <span>
                            Add new address that will be easy for used as
                            delivery address
                        </span>
                    )}
                </>
            }
            visible={visible}
            onCancel={(e) => {
                form.resetFields();

                onCancel && onCancel(e);
            }}
            footer={
                <AppButton
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                    loading={saving}
                    className="px-4"
                    size={mobile ? 'sm' : 'lg'}
                >
                    {editMode ? 'Save address' : 'Add new address'}
                </AppButton>
            }
        >
            <Form
                layout="vertical"
                className="mt-md-3"
                initialValues={{
                    ...address,
                    label: address?.label ?? 'Home',
                    inside_ringroad: address?.inside_ringroad ? true : false
                }}
                form={form}
                name={!editMode ? 'addAddressForm' : address?._id}
                requiredMark={false}
                onFinish={onSubmit}
            >
                {() => (
                    <>
                        <Form.Item name="label">
                            <Radio.Group
                                value={selectedAddressType}
                                buttonStyle="solid"
                            >
                                <Space direction="horizontal" size={10}>
                                    {addressTypes.map((address, index) => (
                                        <Radio.Button
                                            value={address}
                                            className="app-radio-btn"
                                            key={index}
                                        >
                                            {address}
                                        </Radio.Button>
                                    ))}
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        <Row gutter={20}>
                            <Col xs={24} md={12}>
                                <AppInput
                                    label="Full Name *"
                                    placeholder="Full Name"
                                    suffix={
                                        <AiOutlineUser className="text-gray-4" />
                                    }
                                    formName="name"
                                    rules={[
                                        {
                                            message:
                                                'Please enter your full name',
                                            pattern: new RegExp(nameRegExp)
                                        },
                                        {
                                            required: true,
                                            message: 'Name is required'
                                        }
                                    ]}
                                />
                            </Col>

                            <Col xs={24} md={12}>
                                <AppInput
                                    label="Email Address *"
                                    placeholder="Email Address"
                                    suffix={
                                        <AiOutlineMail className="text-gray-4" />
                                    }
                                    formName="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Email is not valid'
                                        },
                                        {
                                            required: true,
                                            message: 'Email is required'
                                        }
                                    ]}
                                />
                            </Col>

                            <Col xs={24} md={12}>
                                <AppInput
                                    label="Phone Number *"
                                    placeholder="Phone Number"
                                    suffix={<BsPhone className="text-gray-4" />}
                                    formName="mobile"
                                    maxLength={10}
                                    rules={[
                                        {
                                            message: 'Phone number is invalid',
                                            pattern: new RegExp(phoneRegExp)
                                        },
                                        {
                                            required: true,
                                            message: 'Phone number is required'
                                        },
                                        {
                                            min: 10,
                                            message:
                                                'Phone must be of 10 digits'
                                        }
                                    ]}
                                />
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Region *"
                                    className="app-form text-montserrat text-14 text-dark-color app-select"
                                    name={['address', 'province']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your region'
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder="Region"
                                        size="large"
                                        suffixIcon={
                                            <FiMapPin className="text-gray-4" />
                                        }
                                        onChange={(value) => {
                                            if (value) {
                                                dispatch(
                                                    getDistrictByProvince(
                                                        value.toString()
                                                    )
                                                );
                                            }

                                            form.setFields([
                                                {
                                                    name: [
                                                        'address',
                                                        'district'
                                                    ],
                                                    value: undefined
                                                }
                                            ]);
                                        }}
                                        dropdownStyle={{ zIndex: 2000 }}
                                    >
                                        {provinces.map((province, index) => (
                                            <Select.Option
                                                value={province}
                                                key={index}
                                            >
                                                {province}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="District *"
                                    className="app-form text-montserrat text-14 text-dark-color app-select"
                                    name={['address', 'district']}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select your district'
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder="District"
                                        size="large"
                                        suffixIcon={
                                            <FiMapPin className="text-gray-4" />
                                        }
                                        disabled={!districts.length}
                                        dropdownStyle={{ zIndex: 2000 }}
                                        onChange={(value) => {
                                            if (
                                                value === 'Kathmandu' ||
                                                value === 'Lalitpur'
                                            ) {
                                                form.setFields([
                                                    {
                                                        name: 'inside_ringroad',
                                                        value: true
                                                    }
                                                ]);

                                                let districtId = districts.find(
                                                    (district) =>
                                                        district.name ===
                                                        form.getFieldsValue()
                                                            .address?.district
                                                )?._id;

                                                if (districtId)
                                                    dispatch(
                                                        getAreasByDistrict(
                                                            districtId
                                                        )
                                                    );
                                            }
                                        }}
                                    >
                                        {districts.map((district, index) => (
                                            <Select.Option
                                                value={district?.name ?? ''}
                                                key={index}
                                            >
                                                {district.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            {form.getFieldsValue().address?.district ===
                                'Kathmandu' ||
                            form.getFieldsValue().address?.district ===
                                'Lalitpur' ? (
                                <Col span={24}>
                                    <Form.Item
                                        label="Inside Ringroad?"
                                        className="app-form text-montserrat text-14 text-dark-color"
                                        name="inside_ringroad"
                                    >
                                        <Radio.Group
                                            size="large"
                                            className="app-radio-group"
                                            onChange={() => {
                                                form.setFields([
                                                    {
                                                        name: [
                                                            'address',
                                                            'area'
                                                        ],
                                                        value: undefined
                                                    }
                                                ]);
                                            }}
                                        >
                                            <Radio
                                                value={true}
                                                className="app-radio d-flex align-items-center mb-3"
                                            >
                                                Yes
                                            </Radio>

                                            <Radio
                                                value={false}
                                                className="app-radio d-flex align-items-center mb-3"
                                            >
                                                No
                                            </Radio>

                                            <span className="text-montserrat text-12 text-gray-3 mt-n3">
                                                * Please select 'No' if your
                                                area is not in the list.
                                            </span>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            ) : (
                                ''
                            )}

                            <Col xs={24} md={12}>
                                {form.getFieldsValue().inside_ringroad ? (
                                    <Form.Item
                                        label="Area *"
                                        className="app-form text-montserrat text-14 text-dark-color app-select"
                                        name={['address', 'area']}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please select your area'
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Area"
                                            size="large"
                                            suffixIcon={
                                                <FiMapPin className="text-gray-4" />
                                            }
                                            disabled={!areas.length}
                                            dropdownStyle={{ zIndex: 2000 }}
                                        >
                                            {areas.map((area, index) => (
                                                <Select.Option
                                                    value={area?.name ?? ''}
                                                    key={index}
                                                >
                                                    {area.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                ) : (
                                    <AppInput
                                        label="Area *"
                                        placeholder="Area"
                                        suffix={
                                            <FiMapPin className="text-gray-4" />
                                        }
                                        formName={['address', 'area']}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input your area'
                                            }
                                        ]}
                                    />
                                )}
                            </Col>

                            <Col xs={24} md={12}>
                                <AppInput
                                    label="Local Address *"
                                    placeholder="Local Address"
                                    suffix={
                                        <FiMapPin className="text-gray-4" />
                                    }
                                    formName={['address', 'landmark1']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your address'
                                        }
                                    ]}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </Form>
        </Modal>
    );
};

export default AddressModal;
