import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { GET_OFFERS } from '../helpers/constants';
import request from '../helpers/request';
import { IOfferParams } from '../interfaces/Offer';

export const getOffers =
    (params?: IOfferParams) => async (dispatch: Dispatch) => {
        dispatch({
            type: GET_OFFERS,
            payload: {
                offersLoading: true
            }
        });

        request
            .getOffers(params)
            .then((res: AxiosResponse) => {
                dispatch({
                    type: GET_OFFERS,
                    payload: {
                        offers: res.data,
                        offersLoading: false
                    }
                });
            })
            .catch(() => {
                dispatch({
                    type: GET_OFFERS,
                    payload: {
                        offersLoading: false
                    }
                });
            });
    };
