import { Spin } from 'antd';
import { ReactComponent as CartIcon } from '../../../assets/images/cart-icon.svg';

interface IProps {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    className?: string;
    size?: 'sm' | 'lg';
    disabled?: boolean;
    loading?: boolean;
    dark?: boolean;
    isMeat?: boolean;
}

const CartButton = (props: IProps) => {
    return (
        <button
            className={`cart-btn-container ${
                props.size === 'sm' ? 'cart-btn-sm' : 'cart-btn-lg'
            } ${props.isMeat ? 'cart-btn-meat' : ''} ${
                props.className ? props.className : ''
            }`}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.loading ? (
                <Spin size="small" />
            ) : (
                <CartIcon
                    fontSize={18}
                    className={props.dark ? 'cart-icon-dark' : ''}
                />
            )}
        </button>
    );
};

export default CartButton;
