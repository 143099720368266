import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as RightArrow } from '../../../../assets/images/right-arrow.svg';
import { ReactComponent as LeftArrow } from '../../../../assets/images/left-arrow.svg';

interface ISliderSettings {
    dots?: boolean;
    nextArrow?: JSX.Element | undefined;
    prevArrow?: JSX.Element | undefined;
    infinite?: boolean;
    className?: string;
    centerPadding?: string;
    speed?: number;
    slidesToShow?: number;
    slidesToScroll?: number;
    centerMode?: boolean;
    responsive?: {
        breakpoint: number;
        settings: {
            slidesToShow: number;
            slidesToScroll: number;
            infinite: boolean;
            centerPadding: string;
        };
    }[];
}

interface IProps {
    children: React.ReactNode;

    customSettings?: ISliderSettings;
}

function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;

    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            <RightArrow />
        </div>
    );
}

function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;

    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            <LeftArrow />
        </div>
    );
}
const GlobalSlider = ({ children, customSettings }: IProps) => {
    var settings = {
        customPaging: function () {
            return <div className="custom-slick-dots"></div>;
        },
        dots: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        infinite: true,
        className: 'center',
        centerPadding: '120px',
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    centerPadding: '60px'
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '30px'
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '0',
                    slidesToScroll: 1
                }
            }
        ],
        ...customSettings
    };

    return <Slider {...settings}>{children}</Slider>;
};

export default GlobalSlider;
