import { GET_WALLET_DETAIL, RESET_WALLET_DETAIL } from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';

export interface IWalletState {
    redeem_wallet_amount?: number;
}

const initialState: IWalletState = {};

const walletReducer = (
    state = initialState,
    action: ActionType
): IWalletState => {
    switch (action.type) {
        case GET_WALLET_DETAIL:
            return {
                redeem_wallet_amount: action.payload
            };

        case RESET_WALLET_DETAIL:
            return {
                redeem_wallet_amount: undefined
            };

        default:
            return state;
    }
};

export default walletReducer;
