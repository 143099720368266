import { ReactNode } from 'react';

interface IProps {
    selected: string;
    onChange: (value: string) => void;
    text: string;
    value: string;
    className?: string;
    labelClassName?: string;
    containerClassName?: string;
    children?: ReactNode;
}

const AppRadio = (props: IProps) => {
    const { selected, onChange, text, value, children, containerClassName } =
        props;

    return (
        <div className={containerClassName}>
            <div
                className={
                    'radio-container ' +
                    (props.className ? props.className : '')
                }
                onClick={() => {
                    onChange(value);
                }}
            >
                <div
                    className={`radio-outer-circle  ${
                        value !== selected && 'unselected'
                    }`}
                >
                    <div
                        className={`radio-inner-circle ${
                            value !== selected && 'unselected-circle'
                        }`}
                    />
                </div>
                <span
                    className={
                        `helper-text text-inter text-500 ${
                            value === selected ? 'text-gray-5' : 'text-gray-3'
                        } ` +
                        (props.labelClassName
                            ? props.labelClassName
                            : 'text-16')
                    }
                >
                    {text}
                </span>
            </div>

            {!!children && <div className="radio-content">{children}</div>}
        </div>
    );
};

export default AppRadio;
