import { CloseCircleFilled } from '@ant-design/icons';
import { Input } from 'antd';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buyNow, getCarts } from '../../../actions/cartActions';
import {
    checkCouponValidity,
    resetCoupon
} from '../../../actions/couponActions';
import { AppDispatch, StateType } from '../../../reducers';
import AppButton from './AppButton';

interface IProps {
    carts: string[];
    isBuyNow?: boolean;
}

const PromoCode = ({ carts, isBuyNow }: IProps) => {
    const [applyDiscountActive, setApplyDiscountActive] = useState(false);

    const [coupon, setCoupon] = useState<string>();

    const [couponError, setCouponError] = useState<string>();

    const buyNowCart = useSelector((state: StateType) => state.cart.cartDetail);

    const activeCoupon = useSelector((state: StateType) => state.coupon.coupon);

    const dispatch = useDispatch<AppDispatch>();

    const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCoupon(e.target.value);
    };

    const handleCouponSubmit = () => {
        if (coupon) {
            setCouponError(undefined);
            dispatch(checkCouponValidity(coupon, carts))
                .then((res) => {
                    if (!isBuyNow) {
                        dispatch(getCarts());
                    } else {
                        if (buyNowCart.carts.length && buyNowCart.carts[0]) {
                            const cart = buyNowCart.carts[0];

                            dispatch(
                                buyNow({
                                    quantity: cart.quantity ?? 1,
                                    rate_id: cart.rate?._id ?? '',
                                    product_id: cart.product?._id ?? ''
                                })
                            );
                        }
                    }
                })
                .catch((err: AxiosError) => {
                    if (err.response?.status === 404) {
                        setCouponError(err.response.data.message);
                    }
                });
        }
    };

    return (
        <div className="rounded p-3 promo-container">
            {!activeCoupon ? (
                <>
                    <div className="d-flex align-items-center">
                        <span className="text-montserrat text-600 text-16 text-dark-color flex-grow-1">
                            Have Promo Code?
                        </span>

                        {!applyDiscountActive && (
                            <span
                                className="text-orange text-montserrat text-400 text-14 cursor-pointer apply-btn"
                                onClick={() => {
                                    setApplyDiscountActive(true);
                                }}
                            >
                                Apply Now
                            </span>
                        )}
                    </div>

                    {applyDiscountActive && (
                        <div className="mt-3 fade-in">
                            <Input
                                className="rounded mb-3 promo-input"
                                placeholder="Promo code"
                                onChange={handleCouponChange}
                            />

                            {couponError && (
                                <p className="mb-3 text-danger text-montserrat text-400 text-14">
                                    {couponError}
                                </p>
                            )}

                            <AppButton
                                type="primary-outlined"
                                size="sm"
                                className="w-100"
                                disabled={!coupon || coupon === ''}
                                onClick={handleCouponSubmit}
                            >
                                Apply Promo Code
                            </AppButton>
                        </div>
                    )}
                </>
            ) : (
                <div className="d-flex align-items-center">
                    <span className="text-orange text-montserrat text-400 text-14 flex-grow-1">
                        Promo Code "
                        <span className="text-600">{activeCoupon.code}</span>"
                        is applied.
                    </span>

                    <span
                        className="text-gray text-montserrat text-600 text-12 ms-2 text-hover-gray d-flex align-items-center cursor-pointer"
                        onClick={() =>
                            dispatch(resetCoupon()).then((res) => {
                                dispatch(getCarts());
                            })
                        }
                    >
                        <CloseCircleFilled />
                    </span>
                </div>
            )}
        </div>
    );
};

export default PromoCode;
