import {
    GET_PRODUCT_CATEGORIES,
    GET_PRODUCT_TYPES
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IProductCategory, IType } from '../interfaces/Product';

export interface ICategoryState {
    productTypes: Array<IType>;
    productCategories: Array<IProductCategory>;
}

const initialState: ICategoryState = {
    productTypes: [],
    productCategories: []
};

const categoryReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_PRODUCT_CATEGORIES:
            return {
                ...state,
                productCategories: action.payload
            };

        case GET_PRODUCT_TYPES:
            return {
                ...state,
                productTypes: action.payload
            };

        default:
            return state;
    }
};

export default categoryReducer;
