import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUserProfile } from '../../../actions/userActions';
import { $FIXME } from '../../../helpers/constants';
import Banner from '../../components/myPanel/Banner';
import Breadcrum from '../../components/myPanel/Breadcrum';
import Sidenav from '../../components/myPanel/Sidenav';
import MainLayout from '../../layouts/MainLayout';
import queryString from 'query-string';
import { useMediaQuery } from 'react-responsive';

const MyPanel: React.FC<any> = () => {
    const dispatch = useDispatch();

    const location = useLocation();

    const query: $FIXME = queryString.parse(location.search);

    const params = [
        'view',
        'profile',
        'orders',
        'wallet',
        'offers',
        'wishlist',
        'address'
    ];

    useEffect(() => {
        dispatch(getUserProfile());
    }, [dispatch]);

    const isDashboardlayout = !params.find((param) => param in query);

    const isMobile = useMediaQuery({ maxWidth: 576 });

    return (
        <MainLayout>
            {!isMobile && <Breadcrum />}

            {(!isMobile || isDashboardlayout) && <Banner />}

            <Sidenav />
        </MainLayout>
    );
};

export default MyPanel;
