import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';

interface IProps {
    children: React.ReactNode;
}

const AuthLayout = (props: IProps) => {
    return (
        <div className="login-container">
            <div className="login-container-inner">
                <Link to="/">
                    <div className="logo">
                        <img src={Logo} alt="" />
                    </div>
                </Link>
                {props.children}
            </div>
            <div className="auth-footer">
                <p>
                    Copyright 2022 © FarmGro. All rights reserved. Crafted with
                    &hearts; by{' '}
                    <a
                        href="https://www.asterdio.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-2"
                    >
                        Asterdio
                    </a>
                </p>
            </div>
        </div>
    );
};

export default AuthLayout;
