import { CaretDownFilled } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { BiGift } from 'react-icons/bi';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { StateType } from '../../reducers';

const SubNavBar = () => {
    const { navVegetables, navMeatMarket, navSeasonalExotic } = useSelector(
        (state: StateType) => state.home
    );

    const vegMenu = (
        <Menu>
            {navVegetables.map((product) => (
                <Menu.Item key={product._id}>
                    <Link to={`/products/${product.slug}`}>
                        {' '}
                        {product.name}
                    </Link>
                </Menu.Item>
            ))}
        </Menu>
    );

    const meatMenu = (
        <Menu>
            {navMeatMarket.map((product) => (
                <Menu.Item key={product._id}>
                    <Link to={`/products/${product.slug}`}>{product.name}</Link>
                </Menu.Item>
            ))}
        </Menu>
    );

    const seasonalMenu = (
        <Menu>
            {navSeasonalExotic.map((product) => (
                <Menu.Item key={product._id}>
                    <Link to={`/products/${product.slug}`}>{product.name}</Link>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <div className="app-sub-nav">
            <div className="app-container d-flex">
                <div className="d-flex align-items-center flex-grow-1 me-4">
                    <NavLink
                        to="/"
                        className="text-montserrat text-16 text-600 text-orange pe-2 py-1 me-4"
                    >
                        Home
                    </NavLink>

                    <div className="d-none d-lg-flex align-items-center">
                        <Dropdown
                            overlay={vegMenu}
                            trigger={['click']}
                            className="cursor-pointer px-2 py-2 me-4 text-hover-orange"
                        >
                            <div className="d-flex align-items-center">
                                <p className="mb-0 text-16 text-montserrat text-400 text-gray-3">
                                    Vegetables
                                </p>

                                <CaretDownFilled
                                    className="ms-2 text-gray-2"
                                    style={{ fontSize: 14 }}
                                />
                            </div>
                        </Dropdown>

                        <Dropdown
                            overlay={meatMenu}
                            trigger={['click']}
                            className="cursor-pointer px-2 py-2 me-4 text-hover-orange"
                        >
                            <div className="d-flex align-items-center">
                                <p className="mb-0 text-16 text-montserrat text-400 text-gray-3">
                                    Meat Market
                                </p>

                                <CaretDownFilled
                                    className="ms-2 text-gray-2 "
                                    style={{ fontSize: 14 }}
                                />
                            </div>
                        </Dropdown>

                        <Dropdown
                            overlay={seasonalMenu}
                            trigger={['click']}
                            className="cursor-pointer px-2 py-2 me-4 text-hover-orange"
                        >
                            <div className="d-flex align-items-center">
                                <p className="mb-0 text-16 text-montserrat text-400 text-gray-3">
                                    Seasonal & Exotic Fruit
                                </p>

                                <CaretDownFilled
                                    className="ms-2 text-gray-2"
                                    style={{ fontSize: 14 }}
                                />
                            </div>
                        </Dropdown>
                    </div>
                </div>

                <div className="d-flex align-items-center">
                    <Link
                        to="/dashboard?offers"
                        className="d-flex align-items-center text-dark-color text-hover-orange px-2 py-2 me-4"
                    >
                        <BiGift size={20} className="text-gray-3 me-2" />

                        <p className="mb-0 text-montserrat text-16">Offers</p>
                    </Link>

                    <Link
                        to="/contact-us"
                        className="d-flex align-items-center text-dark-color text-hover-orange ps-2 py-2"
                    >
                        <IoMdHelpCircleOutline
                            size={20}
                            className="text-gray-3 me-2"
                        />

                        <p className="mb-0 text-montserrat text-16">Help</p>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SubNavBar;
