import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { dairyTypeId } from '../../../helpers/constants';
import { IProduct } from '../../../interfaces/Product';
import Slider from '../shared/slider/Slider';
import ProductCard from '../shared/Card';

interface IProps {
    products: IProduct[];
}

const DairyProduct = (props: IProps) => {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    return (
        <div className="app-container product-container-wrapper">
            <div className="product-header d-flex justify-content-between align-items-center">
                <p className="landing-title mb-3 mb-lg-4">
                    Farmgro Dairy Products
                </p>

                {props.products.length >= 4 && (
                    <Link
                        to={`/products?type=${dairyTypeId}`}
                        className="mb-3 mb-lg-4"
                    >
                        All Dairy Products
                    </Link>
                )}
            </div>

            {isDesktopOrLaptop ? (
                <div className="my-2 dariy-slider-section">
                    <Slider products={props.products} />
                </div>
            ) : (
                <SimpleBar className="mx-n3 product-card-wrapper">
                    {props.products.map((product, index) => {
                        return (
                            <ProductCard
                                disableHover={!isDesktopOrLaptop}
                                key={index}
                                product={product}
                                style={{ minWidth: 200, width: 200 }}
                            />
                        );
                    })}
                </SimpleBar>
            )}
        </div>
    );
};

export default DairyProduct;
