import React from 'react';
import phone from '../../../assets/images/contactUs/phone.png';
const ContactBanner = () => {
    return (
        <div className="contact-banner-container justify-content-center">
            <div className="app-container d-flex flex-column justify-content-end position-relative h-100">
                <p className="hero-heading mb-0 mb-lg-3">
                    We’d love to hear from you.
                </p>
                <p className="hero-subheading">
                    Give us feedback about our product and sevices. Submit this
                    form and we will contact you soon.
                </p>

                <div
                    className="d-none d-md-block align-self-center image-container py-3 position-absolute"
                    style={{ top: 0, right: 0, bottom: 0 }}
                >
                    <img src={phone} height="auto" width="360" alt="products" />
                </div>
            </div>
        </div>
    );
};

export default ContactBanner;
