import esewa from '../../assets/images/esewa.png';
import fonepay from '../../assets/images/fonepay.png';
import playstore from '../../assets/images/playstore.png';
import appstore from '../../assets/images/appstore.png';
import freshproduct from '../../assets/images/freshproduct.png';
import delivery from '../../assets/images/delivery.png';
import tag from '../../assets/images/tag.png';
import price from '../../assets/images/price.png';
import { Link } from 'react-router-dom';
import { StateType } from '../../reducers';
import { useSelector } from 'react-redux';
import { Facebook, Instagram, Twitter } from '../../assets/images/icons';
import { Col, Row, Space } from 'antd';
import { FaPhoneAlt } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

interface IProps {}

const Footer = (props: IProps) => {
    const { social, contact_number } = useSelector(
        (state: StateType) => state.contact.contact
    );

    const { footerFruitsVeg, footerDairy, footerMeat, footerMisc } =
        useSelector((state: StateType) => state.home);

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const freeDeliveryAmount = useSelector(
        (state: StateType) =>
            state.payment.deliveryCharge.free_delivery_min_order_amt
                ?.inside_ringroad
    );

    return (
        <div className="footer bg-white pt-lg-4">
            <div className="app-container d-none d-lg-block">
                <div className="d-flex py-4 align-items-stretch justify-content-between feature-section mx-n3">
                    <div className="d-flex align-items-center px-3">
                        <img src={freshproduct} height="40" alt="products" />
                        <p className="mb-0 ms-2 feature-text">
                            Everyday fresh
                            <br />
                            products
                        </p>
                    </div>

                    <div className="feature-divider" />

                    <div className="d-flex align-items-center px-3">
                        <img src={delivery} height="40" alt="products" />
                        <p className="mb-0 ms-2 feature-text">
                            Free delivery inside ringroad
                            <br />
                            for over Rs.{freeDeliveryAmount}
                        </p>
                    </div>

                    <div className="feature-divider" />

                    <div className="d-flex align-items-center px-3">
                        <img src={tag} height="40" alt="products" />
                        <p className="mb-0 ms-2 feature-text">
                            Daily mega
                            <br />
                            discounts
                        </p>
                    </div>

                    <div className="feature-divider" />

                    <div className="d-flex align-items-center px-3">
                        <img src={price} height="40" alt="products" />
                        <p className="mb-0 ms-2 feature-text">
                            Best price on
                            <br />
                            the market
                        </p>
                    </div>
                </div>
            </div>
            <div className="footer-top">
                <div className="app-container footer-top py-4">
                    <div className="row g-0">
                        <div className="col-12 g-0 mb-4">
                            <div className="row d-flex align-items-center">
                                <div className="col-12 col-lg-5 col-xl-6">
                                    <div className="align-items-center d-flex flex-column flex-lg-row mb-4 mb-lg-0">
                                        <p className="mb-2 mb-lg-0 me-0 me-lg-5 follow-us-text">
                                            Follow Us On
                                        </p>
                                        <div className="mb-0 ms-0 ms-lg-4">
                                            {!!social?.facebook && (
                                                <a
                                                    href={social.facebook}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="me-3 me-lg-4"
                                                >
                                                    <Facebook
                                                        style={
                                                            isDesktopOrLaptop
                                                                ? {
                                                                      height: 32,
                                                                      width: 32
                                                                  }
                                                                : {
                                                                      height: 22,
                                                                      width: 22
                                                                  }
                                                        }
                                                    />
                                                </a>
                                            )}

                                            {!!social?.twitter && (
                                                <a
                                                    href={social.twitter}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="me-3 me-lg-4"
                                                >
                                                    <Twitter
                                                        style={{
                                                            color: '#00acee',
                                                            ...(isDesktopOrLaptop
                                                                ? {
                                                                      height: 32,
                                                                      width: 32
                                                                  }
                                                                : {
                                                                      height: 22,
                                                                      width: 22
                                                                  })
                                                        }}
                                                    />
                                                </a>
                                            )}

                                            {!!social?.instagram && (
                                                <a
                                                    href={
                                                        social.instagram ?? ''
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Instagram
                                                        style={
                                                            isDesktopOrLaptop
                                                                ? {
                                                                      height: 32,
                                                                      width: 32
                                                                  }
                                                                : {
                                                                      height: 22,
                                                                      width: 22
                                                                  }
                                                        }
                                                    />
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-7 col-xl-6">
                                    <div className="d-flex align-items-center justify-content-end flex-column flex-lg-row">
                                        <p className="mb-2 mb-lg-0 me-0 me-lg-5 follow-us-text text-nowrap">
                                            We Accept
                                        </p>

                                        <Space align="center" size={24}>
                                            <a
                                                href={'https://esewa.com.np'}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src={esewa}
                                                    alt="farmgro-logo"
                                                    style={{
                                                        width: isDesktopOrLaptop
                                                            ? 96
                                                            : 70
                                                    }}
                                                />
                                            </a>

                                            <a
                                                href={'https://fonepay.com'}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src={fonepay}
                                                    alt="fonepay"
                                                    style={{
                                                        width: isDesktopOrLaptop
                                                            ? 96
                                                            : 70
                                                    }}
                                                />
                                            </a>
                                        </Space>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!!footerFruitsVeg.length && (
                            <div className="col d-none d-lg-block">
                                <ul className="pe-1 pe-lg-4">
                                    <p className="footer-headings mb-4">
                                        Fruits &#38; Vegetables
                                    </p>

                                    {footerFruitsVeg.map((product) => (
                                        <li className="mb-2" key={product._id}>
                                            <Link
                                                to={`/products/${product.slug}`}
                                                style={{
                                                    textDecoration: 'none'
                                                }}
                                                className="footer-links"
                                            >
                                                {product.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {!!footerDairy.length && (
                            <div className="col d-none d-lg-block">
                                <ul className="pe-1 pe-lg-4">
                                    <li className="footer-headings mb-4">
                                        Breakfast &#38; Dairy
                                    </li>

                                    {footerDairy.map((product) => (
                                        <li className="mb-2" key={product._id}>
                                            <Link
                                                to={`/products/${product.slug}`}
                                                style={{
                                                    textDecoration: 'none'
                                                }}
                                                className="footer-links"
                                            >
                                                {product.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {!!footerMeat.length && (
                            <div className="col d-none d-lg-block">
                                <ul className="pe-1 pe-lg-4">
                                    <li className="footer-headings mb-4">
                                        Meat &#38; Meat Items
                                    </li>

                                    {footerMeat.map((product) => (
                                        <li className="mb-2" key={product._id}>
                                            <Link
                                                to={`/products/${product.slug}`}
                                                style={{
                                                    textDecoration: 'none'
                                                }}
                                                className="footer-links"
                                            >
                                                {product.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {!!footerMisc.length && (
                            <div className="col d-none d-lg-block">
                                <ul className="pe-0 pe-lg-4">
                                    <li className="footer-headings mb-4">
                                        Miscellaneous
                                    </li>

                                    {footerMisc.map((product) => (
                                        <li className="mb-2" key={product._id}>
                                            <Link
                                                to={`/products/${product.slug}`}
                                                style={{
                                                    textDecoration: 'none'
                                                }}
                                                className="footer-links"
                                            >
                                                {product.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        <div className="col">
                            <p className="footer-headings mb-2 mb-lg-4 w-100 w-lg-auto text-center text-lg-start">
                                Quick Links
                            </p>
                            <ul className="d-flex d-lg-block flex-wrap justify-content-center">
                                <li className="mb-2 me-3">
                                    <Link
                                        to="/about-us"
                                        style={{
                                            textDecoration: 'none',
                                            marginBottom: '8px'
                                        }}
                                        className="footer-links"
                                    >
                                        About Us
                                    </Link>
                                </li>
                                <li className="mb-2 me-3">
                                    <Link
                                        to="/faq"
                                        style={{ textDecoration: 'none' }}
                                        className="footer-links"
                                    >
                                        FAQ
                                    </Link>
                                </li>
                                <li className="mb-2 me-3">
                                    <Link
                                        to="/contact-us"
                                        style={{ textDecoration: 'none' }}
                                        className="footer-links"
                                    >
                                        Contact Us
                                    </Link>
                                </li>
                                <li className="mb-2 me-3">
                                    <Link
                                        to="/return-policy"
                                        style={{ textDecoration: 'none' }}
                                        className="footer-links"
                                    >
                                        Return Policy
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="app-container d-none d-md-block">
                    <Row className="pt-4 upper-container pb-5">
                        <Col
                            className="d-flex align-items-center justify-content-center justify-content-md-start mb-3 mb-md-0"
                            span={24}
                            md={10}
                        >
                            <div className="icon-wrapper">
                                <a
                                    href={`tel:${contact_number}`}
                                    className="d-flex"
                                >
                                    <FaPhoneAlt
                                        size={22}
                                        className="text-brown"
                                    />
                                </a>
                            </div>
                            <div className="mx-2">
                                <a href={`tel:${contact_number}`}>
                                    <p className="mb-0 phone-text">
                                        {contact_number}
                                    </p>
                                </a>

                                <p className="operating-hour-text mb-0">
                                    Operating Hours 8:00 - 22:00
                                </p>
                            </div>
                        </Col>
                        <Col
                            className="col d-flex justify-content-center justify-content-md-end flex-wrap"
                            span={24}
                            md={14}
                        >
                            <Space size={27} align="center">
                                <div className="d-flex flex-column flex-wrap justify-content-center">
                                    <p className="mb-0 download-text text-nowrap">
                                        Download our mobile app
                                    </p>
                                    <p className="mb-0 footer-bottom-text text-nowrap">
                                        15% discount on your first purchase
                                    </p>
                                </div>
                                <Space align="center" size={19}>
                                    <img
                                        src={playstore}
                                        height="40"
                                        alt="farmgro-logo"
                                    />
                                    <img
                                        src={appstore}
                                        height="40"
                                        alt="farmgro-logo"
                                    />
                                </Space>
                            </Space>
                        </Col>
                    </Row>
                </div>
                <hr className="footer-seperator" />

                <div className="app-container pt-4 pb-4 pb-lg-5 d-flex flex-wrap justify-content-center justify-content-lg-between">
                    <p className="footer-bottom-text text-center text-lg-start me-3">
                        Copyright 2022 © FarmGro. All rights reserved. Crafted
                        with &hearts; by{' '}
                        <a
                            href="https://www.asterdio.com"
                            target="_blank"
                            rel="noreferrer"
                            className="text-gray-3"
                        >
                            Asterdio
                        </a>
                    </p>

                    <div className="d-flex">
                        <Link to="/privacy-policy">
                            <p className="footer-bottom-text me-3 mb-1 mb-lg-2">
                                Privacy Policy
                            </p>
                        </Link>
                        <Link to="/terms-and-conditions">
                            <p className="footer-bottom-text mb-1 mb-lg-2">
                                Terms &#38; Condition
                            </p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
