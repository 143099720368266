import { CaretDownFilled } from '@ant-design/icons';
import { Avatar, Card, DatePicker, Dropdown, Menu, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AiOutlineGift } from 'react-icons/ai';
import { BiChevronLeft } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import {
    getWalletBalance,
    getWalletStatements
} from '../../../../actions/userActions';
import { DWallet } from '../../../../assets/images/icons';
import {
    IWalletStatementParams,
    WalletStatusType
} from '../../../../interfaces/Wallet';
import { StateType } from '../../../../reducers';
import AppButton from '../../shared/AppButton';
import Pagination from '../../shared/Pagination';

const Wallet = () => {
    const { wallet, statements } = useSelector(
        (state: StateType) => state.user
    );

    const [filter, setFilter] = useState<IWalletStatementParams>();

    const [status, setStatus] = useState<WalletStatusType>('all');

    const [fromDate, setFromDate] = useState<Date>();

    const [toDate, setToDate] = useState<Date>();

    const [dateDropdownOpen, setDateDropdownOpen] = useState(false);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'date'
        },
        {
            title: 'Order ID',
            dataIndex: ['order', 'identifier'],
            key: 'orderid'
        },
        {
            title: 'Detail',
            dataIndex: 'remarks',
            key: 'detail'
        },
        {
            title: 'Transaction',
            dataIndex: 'amount',
            key: 'transaction'
        }
    ];

    const dispatch = useDispatch();

    const resultsPerPage = 8;

    useEffect(() => {
        dispatch(getWalletBalance());
    }, [dispatch]);

    useEffect(() => {
        dispatch(
            getWalletStatements({
                resultsPerPage,
                status: status,
                from: filter?.from,
                to: filter?.to,
                page: filter?.page
            })
        );
    }, [dispatch, status, filter?.from, filter?.to, filter?.page]);

    const isMobile = useMediaQuery({ maxWidth: 768 });

    const walletFilters: WalletStatusType[] = ['all', 'paid', 'received'];

    const walletMenu = (
        <Menu>
            {walletFilters.map((filter, index) => (
                <Menu.Item onClick={() => setStatus(filter)} key={index}>
                    <span>
                        {filter.charAt(0).toUpperCase() + filter.slice(1)}
                    </span>
                </Menu.Item>
            ))}
        </Menu>
    );

    const dateMenu = (
        <Menu>
            <div className="p-3 d-flex flex-column">
                <div className="mb-2">
                    <p className="mb-1 text-14 text-montserrat text-gray-2">
                        From
                    </p>

                    <DatePicker
                        disabledDate={(date) =>
                            date.isSameOrAfter(toDate ?? new Date())
                        }
                        value={fromDate ? moment(fromDate) : undefined}
                        onChange={(value) =>
                            setFromDate(!!value ? value.toDate() : undefined)
                        }
                        allowClear
                    />
                </div>

                <div className="mb-3">
                    <p className="mb-1 text-14 text-montserrat text-gray-2">
                        To
                    </p>

                    <DatePicker
                        disabledDate={(date) =>
                            (fromDate ? date.isBefore(fromDate) : false) ||
                            date.isAfter(new Date())
                        }
                        value={toDate ? moment(toDate) : undefined}
                        onChange={(value) =>
                            setToDate(!!value ? value.toDate() : undefined)
                        }
                        allowClear
                    />
                </div>

                <AppButton
                    size="sm"
                    onClick={() => {
                        setFilter((prev) => ({
                            ...prev,
                            from: fromDate
                                ? moment(fromDate).format('MM/DD/YYYY')
                                : undefined,
                            to: toDate
                                ? moment(toDate).format('MM/DD/YYYY')
                                : undefined
                        }));

                        setDateDropdownOpen(false);
                    }}
                >
                    Apply
                </AppButton>
            </div>
        </Menu>
    );

    const renderFilterLabel = () => {
        if (filter?.from && filter.to) {
            return `${moment(filter.from).format('MMMM D, YYYY')} to ${moment(
                filter.to
            ).format('MMMM D, YYYY')}`;
        } else if (filter?.from && !filter?.to) {
            return `${moment(filter.from).format(
                'MMMM D, YYYY'
            )} to ${moment().format('MMMM D, YYYY')}`;
        } else {
            return `Upto ${moment(filter?.to).format('MMMM D, YYYY')}`;
        }
    };

    return (
        <div className={isMobile ? 'app-container' : ''}>
            {isMobile && (
                <div className="py-3">
                    <Link
                        to="/dashboard"
                        className="text-600 text-14 text-montserrat text-black"
                    >
                        <BiChevronLeft size={24} className="me-2 ms-n2" />
                        My Wallet
                    </Link>
                </div>
            )}

            <div className="myWallet-container">
                <p className="text-inter text-dark-color text-600 text-20 mb-4 d-flex align-items-center">
                    <DWallet className="me-2 mb-1" />
                    My Wallet
                </p>

                <Card title="" bordered={false} style={{ width: '100%' }}>
                    <div className="my-2 text-center">
                        <Avatar
                            icon={
                                <DWallet
                                    color="#f47b34"
                                    height={45}
                                    width={45}
                                />
                            }
                            size={{
                                xs: 24,
                                sm: 32,
                                md: 40,
                                lg: 64,
                                xl: 100,
                                xxl: 100
                            }}
                            style={{
                                borderRadius: '50%',
                                margin: '0 auto',
                                backgroundColor: '#FDE5D6',
                                marginBlock: '0 10px'
                            }}
                            className="d-flex align-items-center justify-content-center mb-4"
                        />

                        <p className="text-montserrat text-14 text-gray-2 mb-2">
                            Available in Wallet
                        </p>

                        <h4 className="text-montserrat text-16 text-600 text-gray-5">
                            Rs. {wallet.balance ?? 0}
                        </h4>

                        {!!wallet.updatedAt && (
                            <span className="bg-color w-auto mx-auto text-montserrat text-14 text-gray-2 px-3 py-2 rounded-3">
                                Added: {moment(wallet.updatedAt).fromNow()} (
                                {moment(wallet.updatedAt).format('D MMM YYYY')})
                            </span>
                        )}
                    </div>
                </Card>
            </div>

            {(!!statements.statements?.length || !!filter) && (
                <div className="py-4">
                    <Card
                        headStyle={{
                            padding: isMobile ? '0 16px' : '8px 16px',
                            border: 'none'
                        }}
                        bodyStyle={{
                            padding: isMobile ? 0 : '0px 16px',
                            marginLeft: isMobile ? -16 : undefined,
                            marginRight: isMobile ? -16 : undefined
                        }}
                        style={{ borderRadius: 8 }}
                        title={
                            <div className="d-flex flex-column flex-lg-row align-items-lg-center">
                                <span className="text-inter text-500 text-dark-color text-16 d-flex align-items-center flex-grow-1">
                                    <AiOutlineGift
                                        size={24}
                                        className="me-2 mb-1"
                                    />
                                    Wallet History
                                </span>

                                <div className="d-flex justify-content-between justify-content-lg-end ms-0 ms-lg-3 mt-3 mt-lg-0">
                                    <Dropdown
                                        overlay={walletMenu}
                                        trigger={['click']}
                                        className="cursor-pointer me-3"
                                    >
                                        <p className="mb-0 ms-1 d-flex align-items-center delivery-status text-montserrat text-400 text-gray-3">
                                            Status :
                                            <span className="ms-2 text-orange text-600">
                                                {status
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    status.slice(1) ?? 'All'}
                                            </span>
                                            <CaretDownFilled className="ms-1 text-dark-color" />
                                        </p>
                                    </Dropdown>

                                    <Dropdown
                                        visible={dateDropdownOpen}
                                        onVisibleChange={(value) =>
                                            setDateDropdownOpen(value)
                                        }
                                        placement="bottomRight"
                                        overlayStyle={{
                                            maxWidth: 200,
                                            minWidth: 'auto'
                                        }}
                                        overlay={dateMenu}
                                        trigger={['click']}
                                        className="cursor-pointer"
                                    >
                                        <p className="mb-0 ms-1 d-flex align-items-center delivery-status text-montserrat text-400 text-gray-3">
                                            Date Range :
                                            <span className="ms-2 text-orange text-600">
                                                {(!!fromDate &&
                                                    !!filter?.from) ||
                                                (!!toDate && !!filter?.to)
                                                    ? renderFilterLabel()
                                                    : 'Select Date'}
                                            </span>
                                            <CaretDownFilled className="ms-1 text-dark-color" />
                                        </p>
                                    </Dropdown>
                                </div>
                            </div>
                        }
                        bordered={false}
                    >
                        <Table
                            scroll={{ x: true }}
                            className="wallet-history"
                            columns={columns}
                            dataSource={statements.statements?.map(
                                (statement) => {
                                    const amount = statement.amount ?? 0;

                                    const amountSign = Math.sign(amount);

                                    const order = statement.order;

                                    return {
                                        ...statement,
                                        createdAt: moment(
                                            statement.createdAt
                                        ).format('D MMM YYYY'),
                                        amount: `${
                                            amountSign !== 0
                                                ? amountSign === -1
                                                    ? '- '
                                                    : '+ '
                                                : ''
                                        } Rs. ${Math.abs(amount)}`,
                                        order: {
                                            ...order,
                                            identifier: `#${order?.identifier}`
                                        }
                                    };
                                }
                            )}
                            pagination={false}
                        />

                        {!!statements.totalPage && !!statements.currentPage && (
                            <Pagination
                                className="mt-3"
                                totalPages={statements.totalPage}
                                currentPage={statements.currentPage}
                                onChange={(page) =>
                                    setFilter((prev) => ({ ...prev, page }))
                                }
                            />
                        )}
                    </Card>
                </div>
            )}
        </div>
    );
};

export default Wallet;
