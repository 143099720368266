import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import moment from 'moment';
import { IComment } from '../../../interfaces/Blog';
import AppSpinner from './AppSpinner';

interface IProps {
    commentItem: IComment;
    isLast: boolean;
    hasMore: boolean;
    loading: boolean;
    onLoadMore: () => void;
}

const BlogCommentCard = ({
    commentItem,
    isLast,
    hasMore,
    loading,
    onLoadMore
}: IProps) => {
    const { user, comment, createdAt } = commentItem;

    return (
        <div className={`py-3 ${isLast && hasMore ? 'position-relative' : ''}`}>
            <div className={`d-flex ${isLast && hasMore ? 'opacity-25' : ''}`}>
                <div>
                    <Avatar
                        size={40}
                        className="me-3 d-flex align-items-center justify-content-center"
                        icon={<UserOutlined />}
                        src={user?.profile}
                    />
                </div>

                <div>
                    <p className="text-montserrat text-600 text-14 text-dark-1 mb-0">
                        By {user?.name}
                    </p>

                    <p className="text-montserrat text-400 text-14 text-gray-8 mb-2">
                        Posted on {moment(createdAt).format('MM/DD/YYYY')}
                    </p>

                    <p className="text-montserrat text-400 text-14 text-gray-3 mb-0">
                        {comment}
                    </p>
                </div>
            </div>

            {isLast && hasMore && (
                <div
                    className="position-absolute"
                    style={{ top: 20, left: 0, right: 0 }}
                >
                    {loading ? (
                        <AppSpinner size="sm" className="py-3" />
                    ) : (
                        hasMore && (
                            <p className="text-montserrat text-600 text-14 mt-4 mb-0 text-center cursor-pointer text-gray-3">
                                <span
                                    onClick={onLoadMore}
                                    className="text-hover-orange"
                                >
                                    Load More Comments
                                </span>
                            </p>
                        )
                    )}
                </div>
            )}
        </div>
    );
};

export default BlogCommentCard;
