import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

interface IProps {
    totalPages: number;
    currentPage: number;
    onChange: (page: number) => void;
    className?: string;
    size?: 'sm';
    isMeat?: boolean;
}

const Pagination = ({
    totalPages,
    currentPage,
    onChange,
    className,
    size,
    isMeat
}: IProps) => {
    const isNextEnabled = currentPage < totalPages;
    const isPrevEnabled = currentPage >= 2 && currentPage <= totalPages;

    const handleOnNext = () => {
        if (isNextEnabled) {
            onChange(currentPage + 1);
        }
    };

    const handleOnPrev = () => {
        if (isPrevEnabled) {
            onChange(currentPage - 1);
        }
    };

    return (
        <div
            className={`pagination-container d-flex justify-content-end mb-3 ${
                isMeat ? 'pagination-meat' : ''
            }`}
        >
            <div
                className={`pagination-inner d-inline-flex align-items-center ${
                    className ?? ''
                }`}
            >
                <div
                    className={`pagination-arrow-container arrow-prev mb-2 ${
                        !isPrevEnabled ? 'arrow-disabled' : ''
                    }`}
                    onClick={handleOnPrev}
                >
                    <FiChevronLeft size={20} className="me-1" />
                </div>

                <div className="d-flex flex-wrap">
                    {[...Array(totalPages)].map((_, index) => {
                        const isActive = currentPage === index + 1;
                        return (
                            <div
                                className={`page-number d-flex align-items-center justify-content-center mb-2 ${
                                    isActive ? 'page-active' : ''
                                }`}
                                key={index}
                                style={{
                                    height: size === 'sm' ? 32 : 40,
                                    borderTopLeftRadius:
                                        index + 1 === 1 ? 25 : 0,
                                    borderBottomLeftRadius:
                                        index + 1 === 1 ? 25 : 0,
                                    borderTopRightRadius:
                                        index + 1 === totalPages ? 25 : 0,
                                    borderBottomRightRadius:
                                        index + 1 === totalPages ? 25 : 0
                                }}
                                onClick={() => !isActive && onChange(index + 1)}
                            >
                                <span
                                    className={`text-roboto text-500 text-14`}
                                >
                                    {index + 1}
                                </span>
                            </div>
                        );
                    })}
                </div>

                <div
                    className={`pagination-arrow-container arrow-next mb-2 ${
                        !isNextEnabled ? 'arrow-disabled' : ''
                    }`}
                    onClick={handleOnNext}
                >
                    <FiChevronRight size={20} className="ms-1" />
                </div>
            </div>
        </div>
    );
};

export default Pagination;
