import request from '../helpers/request';
import { Dispatch } from 'redux';
import {
    SUBSCRIBE_NEWSFEED_FAIL,
    SUBSCRIBE_NEWSFEED_REQUEST,
    SUBSCRIBE_NEWSFEED_SUCCESS
} from '../helpers/constants';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { setErrors } from './errorActions';

export const subscribeNewsfeed =
    (email: string) => async (dispatch: Dispatch) => {
        dispatch({
            type: SUBSCRIBE_NEWSFEED_REQUEST
        });

        return request
            .subscribeNewsfeed(email)
            .then((res: AxiosResponse) => {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });

                dispatch({
                    type: SUBSCRIBE_NEWSFEED_SUCCESS
                });

                return Promise.resolve();
            })
            .catch((err: AxiosError) => {
                dispatch(
                    setErrors(
                        err.response?.data?.message[0]?.msg ??
                            err.response?.data?.message ??
                            'Something went wrong'
                    )
                );
                dispatch({
                    type: SUBSCRIBE_NEWSFEED_FAIL
                });

                return Promise.reject();
            });
    };
