import Slider, { Settings } from 'react-slick';
import ProductCategory from '../shared/ProductCategory';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import { meatMarketTypeId } from '../../../helpers/constants';
import { nextArrow, prevArrow } from './Arrows';

interface IProps {
    category: string;
}

const ExploreCategory = (props: IProps) => {
    const { category } = props;

    const productTypes = useSelector((state: StateType) =>
        state.category.productTypes.filter(
            (type) => type.parent_id === meatMarketTypeId
        )
    );

    const settings: Settings = {
        dots: true,
        infinite: true,
        className: 'center',
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: nextArrow({ isMeat: true }),
        prevArrow: prevArrow({ isMeat: true }),
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: '30px'
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerPadding: '30px'
                }
            }
        ]
    };

    return (
        <div>
            <div className={`app-container meat-cat-container ${category}`}>
                <p className="landing-title mb-3 mb-lg-4">
                    Explore by categories
                </p>

                <Slider {...settings}>
                    {productTypes.map((category) => (
                        <div className="px-2" key={category._id}>
                            <ProductCategory
                                id={category._id}
                                categoryImg={category.image}
                                categoryName={category.name}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default ExploreCategory;
