import { TOGGLE_DRAWER } from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';

export interface IUIState {
    drawer: boolean;
}

const initState: IUIState = {
    drawer: false
};

const uiReducer = (state = initState, action: ActionType) => {
    let { type, payload } = action;

    switch (type) {
        case TOGGLE_DRAWER:
            return {
                ...state,
                drawer: payload
            };

        default:
            return state;
    }
};

export default uiReducer;
