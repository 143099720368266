import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { $FIXME } from '../../../helpers/constants';
import CheckoutLayout from '../../layouts/CheckoutLayout';
import DeliveryAddress from './DeliveryAddress';
import DeliveryDate from './DeliveryDate';
import MobileVerification from './MobileVerification';
import PaymentOption from './PaymentOption';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';

interface IProps {}

export interface DeliveryProps {
    deliveryId?: string;
    otpVerified?: boolean;
    deliveryTime?: string;
    deliveryDate?: string;
}

export const Checkout = (props: IProps) => {
    const [step, setStep] = useState(1);

    const [deliveryProps, setDeliveryProps] = useState<DeliveryProps>();

    const { carts, cartDetail } = useSelector((state: StateType) => state.cart);

    const location = useLocation();

    const query: $FIXME = queryString.parse(location.search);

    const isBuyNow = 'buy-now' in query;

    const history = useHistory();

    return (
        <CheckoutLayout step={step} goToStep={(step) => setStep(step)}>
            {(() => {
                switch (step) {
                    case 1:
                        return (
                            <DeliveryAddress
                                onNext={(props) => {
                                    setStep(props.otpVerified ? 3 : 2);
                                    setDeliveryProps({
                                        ...deliveryProps,
                                        ...props
                                    });
                                }}
                                isBuyNow={isBuyNow}
                            />
                        );

                    case 2:
                        return (
                            <MobileVerification
                                onNext={() => setStep(3)}
                                goBack={() => setStep(1)}
                            />
                        );

                    case 3:
                        return (
                            <DeliveryDate
                                onNext={(props) => {
                                    setStep(4);
                                    setDeliveryProps({
                                        ...deliveryProps,
                                        ...props
                                    });
                                }}
                                goBack={() => setStep(1)}
                            />
                        );

                    case 4:
                        return (
                            <PaymentOption
                                carts={isBuyNow ? cartDetail.carts : carts}
                                orderDetail={deliveryProps}
                                onNext={() =>
                                    history.push('/cart/checkout/confirmed')
                                }
                                goBack={() => setStep(3)}
                            />
                        );

                    default:
                        return <></>;
                }
            })()}
        </CheckoutLayout>
    );
};
