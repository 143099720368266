import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import { BiChevronDown } from 'react-icons/bi';
import PromoCode from '../../components/shared/PromoCode';
import { getWalletBalance } from '../../../actions/userActions';
import { ICartResponse } from '../../../interfaces/Cart';

interface IProps {
    cart: ICartResponse;
    isBuyNow?: boolean;
}

const CheckoutSummary = ({ cart, isBuyNow }: IProps) => {
    const {
        carts,
        sub_total,
        total_amount,
        coupon_discount_amount,
        wallet_adjustment,
        delivery_fee
    } = cart;

    const { selectedDeliveryAddress } = useSelector(
        (state: StateType) => state.deliveryAddress
    );

    const freeDeliveryAmount = useSelector(
        (state: StateType) =>
            state.payment.deliveryCharge.free_delivery_min_order_amt
                ?.inside_ringroad
    );

    const [numberOfitemsShown, setNumberOfItemsToShown] = useState(2);

    const dispatch = useDispatch();

    const showMore = () => {
        if (numberOfitemsShown + 2 <= carts.length) {
            setNumberOfItemsToShown(numberOfitemsShown + 2);
        } else {
            setNumberOfItemsToShown(carts.length);
        }
    };

    useEffect(() => {
        dispatch(getWalletBalance());
    }, [dispatch]);

    return (
        <div className="bg-white rounded order-summary-container d-flex flex-column">
            <div className="flex-grow-1">
                <p className="p-3 text-inter text-500 text-16 text-gray mb-0">
                    Order Summary
                </p>

                <hr className="m-0 bg-border" />

                <div className="p-3">
                    <div>
                        <p className="text-inter text-500 text-12 text-dark-color mb-2">
                            {carts.length} Items
                        </p>

                        {carts
                            .slice(0, numberOfitemsShown)
                            .map((cart, index) => (
                                <div
                                    className="d-flex text-black text-montserrat text-12 mb-2"
                                    key={index}
                                >
                                    <span className="w-50 me-3 text-truncate">
                                        {cart.product?.name}(
                                        {cart.rate?.quantity}/
                                        {cart.rate?.unit_id?.name}){' '}
                                    </span>

                                    <div className="d-flex flex-grow-1">
                                        <span className="me-3">
                                            x{cart.quantity}
                                        </span>
                                        <span className="flex-grow-1 text-end">
                                            Rs.
                                            {(cart.rate?.discounted_price ??
                                                cart.rate?.price ??
                                                0) * (cart.quantity ?? 0)}
                                        </span>
                                    </div>
                                </div>
                            ))}

                        {carts.length > numberOfitemsShown ? (
                            <div className="d-flex justify-content-center cursor-pointer">
                                <span
                                    className="text-montserrat text-600 text-12 text-gray bg-light-gray-2 rounded p-2"
                                    onClick={showMore}
                                >
                                    View{' '}
                                    {carts.length - numberOfitemsShown > 2
                                        ? 2
                                        : carts.length -
                                          numberOfitemsShown}{' '}
                                    more Item
                                    {carts.length - numberOfitemsShown !== 1
                                        ? 's'
                                        : ''}{' '}
                                    <BiChevronDown className="text-gray" />
                                </span>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>

                <div className="p-3">
                    {carts.length ? (
                        <>
                            <div className="d-flex align-items-center mb-3">
                                <span className="text-montserrat text-600 text-16 text-dark-color me-2">
                                    Sub Total
                                </span>
                                <span className="text-montserrat text-600 text-16 text-dark-color flex-grow-1 text-end">
                                    Rs. {sub_total}
                                </span>
                            </div>

                            <div className="d-flex align-items-center mb-3">
                                <div className="me-2">
                                    <p className="text-montserrat text-400 text-16 text-dark-color mb-0">
                                        Delivery Charge
                                    </p>

                                    {(selectedDeliveryAddress?.inside_ringroad ||
                                        !selectedDeliveryAddress) && (
                                        <span className="text-montserrat text-400 text-10 text-dark-color d-inline-block">
                                            (Free delivery inside
                                            <br />
                                            ringroad for over Rs.
                                            {freeDeliveryAmount})
                                        </span>
                                    )}
                                </div>

                                <span className="text-montserrat text-400 text-16 text-dark-color flex-grow-1 text-end">
                                    Rs. {delivery_fee}
                                </span>
                            </div>

                            {/* <WalletRedeem /> */}

                            <PromoCode
                                carts={carts.map((cart) => cart._id ?? '')}
                                isBuyNow={isBuyNow}
                            />
                        </>
                    ) : (
                        <p className="text-montserrat text-400 text-16 text-black">
                            No items found
                        </p>
                    )}
                </div>
            </div>

            {carts.length ? (
                <div>
                    {!!coupon_discount_amount && (
                        <div className="d-flex align-items-center px-3 mb-3">
                            <span className="text-montserrat text-400 text-16 text-gray-2 me-2">
                                Promo Code Discount
                            </span>

                            <span className="text-montserrat text-400 text-16 text-gray-2 flex-grow-1 text-end">
                                Rs. {coupon_discount_amount}
                            </span>
                        </div>
                    )}

                    {!!wallet_adjustment && (
                        <div className="d-flex align-items-center px-3 mb-3">
                            <span className="text-montserrat text-400 text-16 text-gray-2 me-2">
                                Redeem Amount
                            </span>

                            <span className="text-montserrat text-400 text-16 text-gray-2 flex-grow-1 text-end">
                                Rs. {wallet_adjustment}
                            </span>
                        </div>
                    )}

                    <hr className="m-0 bg-border" />

                    <div className="p-3">
                        <div className="d-flex align-items-center">
                            <span className="text-montserrat text-600 text-18 text-gray-5 me-2">
                                Grand Total
                            </span>
                            <span className="text-montserrat text-600 text-18 text-gray-5 flex-grow-1 text-end">
                                Rs. {total_amount}
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default CheckoutSummary;
