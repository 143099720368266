import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';

interface IProps {}

const Customers = (props: IProps) => {
    const { customers } = useSelector((state: StateType) => state.home);

    return customers.length ? (
        <div className="app-container product-container-wrapper">
            <h2 className="landing-title mb-3 mb-lg-4">Our Valued Customers</h2>

            <Row
                className="customer-section w-100"
                gutter={{ xs: 10, sm: 20, md: 40, lg: 80, xl: 120 }}
            >
                {customers.map((customer) => (
                    <Col
                        style={{
                            flexBasis: `${100 / 5}%`
                        }}
                        className="mb-5 d-flex align-items-center"
                        key={customer._id}
                    >
                        <img src={customer.logo} alt={customer.name} />
                    </Col>
                ))}
            </Row>
        </div>
    ) : (
        <></>
    );
};
export default Customers;
