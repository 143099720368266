import { useState } from 'react';
import { Redirect, Link, useLocation } from 'react-router-dom';
import { ReactComponent as CheckIcon } from '../../../assets/images/check.svg';
import AuthLayout from '../../layouts/AuthLayout';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { $FIXME } from '../../../helpers/constants';
import queryString from 'query-string';
import { StateType } from '../../../reducers';
import request from '../../../helpers/request';
import { AxiosError, AxiosResponse } from 'axios';
import AppButton from '../../components/shared/AppButton';
import { showToast } from '../../../utils/toast';
import { useMediaQuery } from 'react-responsive';

interface IProps {}

const ResetPassword = (props: IProps) => {
    const location = useLocation();

    const { email, token }: $FIXME = queryString.parse(location.search);

    const auth = useSelector((state: StateType) => state.auth);

    const [changed, setChange] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [loading, setLoading] = useState(false);

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const isMobile = useMediaQuery({ maxWidth: 576 });

    const initialValues = {
        password: '',
        confirm_password: ''
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Must be of 8 characters'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Both password must match')
            .required('Confirm Password is required')
    });

    if (auth.authenticated || !email || !token) {
        return <Redirect to="/" />;
    }

    return (
        <AuthLayout>
            <div className="login-form">
                {changed ? (
                    <div className="login-form-inner">
                        <h2 className="mt-lg-4">Password Changed!</h2>

                        <div className="checked-icon">
                            <CheckIcon
                                width={isDesktopOrLaptop ? 116 : 72}
                                height={isDesktopOrLaptop ? 116 : 72}
                            />
                        </div>

                        <p>
                            Your password has been changed successfully. Please
                            login with your new password
                        </p>

                        <Link to="/login">
                            <button className="otp-btn mb-lg-4">
                                Login Now
                            </button>
                        </Link>
                    </div>
                ) : (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={({ password, confirm_password }) => {
                            setLoading(true);
                            request
                                .resetPassword(token, {
                                    password,
                                    confirm_password
                                })
                                .then((res: AxiosResponse) => {
                                    setChange(true);
                                })
                                .catch((err: AxiosError) => {
                                    showToast(
                                        'error',
                                        err.response?.data?.message ??
                                            'Error resetting password',
                                        'reset-passowrd'
                                    );

                                    setLoading(false);
                                });
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            isValid,
                            errors
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <h2 className="form-header">
                                    Create new password
                                </h2>

                                <p className="form-text">
                                    Your new password must be different from
                                    previous used password.
                                </p>

                                <div className="form-field">
                                    <label htmlFor="">New Password</label>

                                    <div className="position-relative">
                                        <input
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            name="password"
                                            placeholder="*********"
                                            value={values.password}
                                            onChange={handleChange}
                                        />

                                        {showPassword ? (
                                            <AiOutlineEye
                                                size={24}
                                                className="cursor-pointer"
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword
                                                    )
                                                }
                                            />
                                        ) : (
                                            <AiOutlineEyeInvisible
                                                size={24}
                                                className="cursor-pointer"
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword
                                                    )
                                                }
                                            />
                                        )}
                                    </div>

                                    {errors.password && (
                                        <p className="text-danger mb-0 mt-2 text-12">
                                            {errors.password}
                                        </p>
                                    )}
                                </div>

                                <div className="form-field">
                                    <label htmlFor="">Confirm Password</label>

                                    <div className="position-relative">
                                        <input
                                            type={
                                                showConfirmPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            name="confirm_password"
                                            value={values.confirm_password}
                                            onChange={handleChange}
                                            placeholder="*********"
                                        />

                                        {showConfirmPassword ? (
                                            <AiOutlineEye
                                                size={24}
                                                className="cursor-pointer"
                                                onClick={() =>
                                                    setShowConfirmPassword(
                                                        !showConfirmPassword
                                                    )
                                                }
                                            />
                                        ) : (
                                            <AiOutlineEyeInvisible
                                                size={24}
                                                className="cursor-pointer"
                                                onClick={() =>
                                                    setShowConfirmPassword(
                                                        !showConfirmPassword
                                                    )
                                                }
                                            />
                                        )}
                                    </div>

                                    {errors.confirm_password && (
                                        <p className="text-danger mb-0 mt-2 text-12">
                                            {errors.confirm_password}
                                        </p>
                                    )}
                                </div>

                                <AppButton
                                    className="mt-4 mt-lg-5 mb-lg-5 w-100"
                                    htmlType="submit"
                                    disabled={!isValid || loading}
                                    loading={loading}
                                    size={isMobile ? 'sm' : 'lg'}
                                >
                                    Reset Password
                                </AppButton>
                            </form>
                        )}
                    </Formik>
                )}
            </div>
        </AuthLayout>
    );
};

export default ResetPassword;
