import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import addressReducer, { IAddressState } from './addressReducers';
import authReducer, { AuthState } from './authReducers';
import cartReducer, { ICartState } from './cartReducers';
import categoryReducer, { ICategoryState } from './categoryReducers';
import couponReducer, { ICouponState } from './couponReducers';
import deliveryAddressReducer, {
    IDeliveryAddressState
} from './deliveryAddressReducers';
import errorReducer, { IErrorState } from './errorReducers';
import paymentReducer, { IPaymentState } from './paymentReducers';
import contactReducer, { IContactState } from './contactReducers';
import newsfeedReducer, { INewsfeedState } from './newsfeedReducers';
import productReducer, { IProductState } from './productReducers';
import uiReducer, { IUIState } from './uiReducer';
import userReducer, { IUserState } from './userReducers';
import pagesReducer, { IPagesState } from './pagesReducers';
import homeReducer, { IHomeState } from './homeReducers';
import blogsReducer, { IBlogsState } from './blogReducers';
import testimonialReducer, { ITestimonialState } from './testimonialReducers';
import offerReducer, { IOfferState } from './offerReducers';
import meatMarketReducer, { IMeatMarketState } from './meatMarketReducers';
import walletReducer, { IWalletState } from './walletReducers';

export interface StateType {
    ui: IUIState;
    auth: AuthState;
    errors: IErrorState;
    product: IProductState;
    cart: ICartState;
    category: ICategoryState;
    deliveryAddress: IDeliveryAddressState;
    address: IAddressState;
    payment: IPaymentState;
    coupon: ICouponState;
    newsfeed: INewsfeedState;
    contact: IContactState;
    user: IUserState;
    pages: IPagesState;
    home: IHomeState;
    blog: IBlogsState;
    testimonial: ITestimonialState;
    offer: IOfferState;
    meatMarket: IMeatMarketState;
    wallet: IWalletState;
}

export default combineReducers({
    ui: uiReducer,
    auth: authReducer,
    errors: errorReducer,
    product: productReducer,
    cart: cartReducer,
    category: categoryReducer,
    deliveryAddress: deliveryAddressReducer,
    address: addressReducer,
    payment: paymentReducer,
    coupon: couponReducer,
    newsfeed: newsfeedReducer,
    contact: contactReducer,
    user: userReducer,
    pages: pagesReducer,
    home: homeReducer,
    blog: blogsReducer,
    testimonial: testimonialReducer,
    offer: offerReducer,
    meatMarket: meatMarketReducer,
    wallet: walletReducer
});

export type AppDispatch = ThunkDispatch<StateType, any, AnyAction>;
