import {
    GET_DELIVERY_ADDRESS,
    GET_RINGROAD_AREAS,
    SAVE_DELIVERY_FAILURE,
    SAVE_DELIVERY_REQUEST,
    SAVE_DELIVERY_SUCCESS,
    SET_DELIVERY_ADDRESS
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import {
    DeliveryAddressType,
    IDeliveryAddress,
    IRingroadArea
} from '../interfaces/DeliveryAddress';

export interface IDeliveryAddressState {
    address: Array<IDeliveryAddress>;
    saveDeliveryLoading: boolean;
    saveDeliverySuccess?: DeliveryAddressType;
    selectedDeliveryAddress?: IDeliveryAddress;
    ringroadArea: IRingroadArea;
}

const initialState: IDeliveryAddressState = {
    address: [],
    saveDeliveryLoading: false,
    ringroadArea: {}
};

const deliveryAddressReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_DELIVERY_ADDRESS:
            return {
                ...state,
                address: action.payload
            };

        case SAVE_DELIVERY_REQUEST:
            return {
                ...state,
                saveDeliveryLoading: true
            };

        case SAVE_DELIVERY_SUCCESS:
            return {
                ...state,
                saveDeliveryLoading: false,
                saveDeliverySuccess: action.payload
            };

        case SAVE_DELIVERY_FAILURE:
            return {
                ...state,
                saveDeliveryLoading: false
            };

        case SET_DELIVERY_ADDRESS:
            return {
                ...state,
                selectedDeliveryAddress: action.payload
            };

        case GET_RINGROAD_AREAS:
            return {
                ...state,
                ringroadArea: action.payload
            };

        default:
            return state;
    }
};

export default deliveryAddressReducer;
