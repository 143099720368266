import ContactBanner from '../../components/contactUs/ContactBanner';
import ContactForm from '../../components/contactUs/ContactForm';
import MainLayout from '../../layouts/MainLayout';

const ContactUs = () => {
    return (
        <MainLayout>
            <ContactBanner />

            <ContactForm />
        </MainLayout>
    );
};

export default ContactUs;
