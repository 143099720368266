import request from '../helpers/request';
import { Dispatch } from 'redux';
import {
    GET_DELIVERY_CHARGE,
    INITIATE_ORDER_FAILURE,
    INITIATE_ORDER_REQUEST,
    INITIATE_ORDER_SUCCESS,
    RESET_COUPON_DETAIL,
    RESET_WALLET_DETAIL
} from '../helpers/constants';
import { AxiosError, AxiosResponse } from 'axios';
import { setErrors } from './errorActions';
import { IInitiateOrderBody, IOrderResponse } from '../interfaces/Payment';

export const initiateOrder =
    (body: IInitiateOrderBody) => async (dispatch: Dispatch) => {
        dispatch({ type: INITIATE_ORDER_REQUEST });

        return request
            .initiateOrder(body)
            .then((res: AxiosResponse) => {
                dispatch({ type: INITIATE_ORDER_SUCCESS, payload: res.data });

                if (body.payment_type === 'Cash on Delivery') {
                    dispatch({ type: RESET_COUPON_DETAIL });

                    dispatch({ type: RESET_WALLET_DETAIL });
                }

                return Promise.resolve<IOrderResponse>(res.data);
            })
            .catch((err: AxiosError) => {
                dispatch({ type: INITIATE_ORDER_FAILURE });
                dispatch(
                    setErrors(
                        err.response?.data.message ?? 'Something went wrong'
                    )
                );

                return Promise.reject();
            });
    };

export const getDeliveryCharge = () => async (dispatch: Dispatch) => {
    return request
        .getDeliveryCharge()
        .then((res: AxiosResponse) => {
            dispatch({ type: GET_DELIVERY_CHARGE, payload: res.data });
        })
        .catch((err: AxiosError) => {
            dispatch(
                setErrors(err.response?.data.message ?? 'Something went wrong')
            );
        });
};
