import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { GET_TESTIMONIALS } from '../helpers/constants';
import request from '../helpers/request';

export const getTestimonials = () => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_TESTIMONIALS,
        payload: { testimonialsLoading: true }
    });
    request.getTestimonials().then((res: AxiosResponse) => {
        dispatch({
            type: GET_TESTIMONIALS,
            payload: {
                testimonials: res.data?.testimonials,
                testimonialsLoading: false
            }
        });
    });
};
