import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { IProduct } from '../../../interfaces/Product';
import ProductCard from '../shared/Card';

interface IProps {
    title: string;
    linkTo: string;
    linkTitle: string;
    isMiscelleneous?: boolean;
    products: IProduct[];
}

const Product = (props: IProps) => {
    const { title, linkTo, linkTitle, isMiscelleneous, products } = props;

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    return (
        <div className="app-container product-container-wrapper">
            <div className="product-header d-flex justify-content-between align-items-center">
                <p className="landing-title mb-3 mb-lg-4">{title}</p>

                {!isMiscelleneous && products.length >= 8 && (
                    <Link to={linkTo} className="mb-3 mb-lg-4">
                        {linkTitle}
                    </Link>
                )}
            </div>

            {isDesktopOrLaptop ? (
                <div className="d-flex flex-wrap">
                    {props.products.map((product, index) => {
                        return (
                            <ProductCard
                                disableHover={!isDesktopOrLaptop}
                                key={product._id}
                                product={product}
                                style={{ flexBasis: '25%' }}
                            />
                        );
                    })}
                </div>
            ) : (
                <SimpleBar className="mx-n3 product-card-wrapper">
                    {products.map((product, index) => {
                        return (
                            <ProductCard
                                disableHover={!isDesktopOrLaptop}
                                key={product._id}
                                product={product}
                                style={{ minWidth: 200, width: 200 }}
                            />
                        );
                    })}
                </SimpleBar>
            )}
        </div>
    );
};

export default Product;
