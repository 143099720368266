import { CSSProperties } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

interface IProps {
    isMeat?: boolean;
    size?: 'sm';
    style?: CSSProperties;
    onClick?: () => void;
    className?: string;
}

export const prevArrow = ({ isMeat, size }: IProps) => (
    <div>
        <div
            className={`custom-arrow ${isMeat ? 'arrow-meat' : ''} ${
                size === 'sm' ? 'arrow-sm' : ''
            }`}
        >
            <FiChevronLeft color="black" className="me-1" />
        </div>
    </div>
);

export const nextArrow = ({ isMeat, size }: IProps) => (
    <div>
        <div
            className={`custom-arrow ${isMeat ? 'arrow-meat' : ''} ${
                size === 'sm' ? 'arrow-sm' : ''
            }`}
        >
            <FiChevronRight color="black" className="ms-1" />
        </div>
    </div>
);

export const NextArrow = (props: IProps) => (
    <button
        style={props.style}
        className={`custom-arrow-btn custom-arrow-next ${
            props.className ? props.className : ''
        }`}
        onClick={props.onClick}
    >
        <div
            className={`custom-arrow ${props.isMeat ? 'arrow-meat' : ''} ${
                props.size === 'sm' ? 'arrow-sm' : ''
            }`}
        >
            <FiChevronRight color="black" className="ms-1" />
        </div>
    </button>
);

export const PrevArrow = (props: IProps) => (
    <button
        style={props.style}
        className={`custom-arrow-btn custom-arrow-prev ${
            props.className ? props.className : ''
        }`}
        onClick={props.onClick}
    >
        <div
            className={`custom-arrow ${props.isMeat ? 'arrow-meat' : ''} ${
                props.size === 'sm' ? 'arrow-sm' : ''
            }`}
        >
            <FiChevronLeft color="black" className="me-1" />
        </div>
    </button>
);
