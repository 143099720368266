import { useEffect, useRef, useState } from 'react';
import CarrotIcon from '../../../assets/images/carrot.png';
import CarrotActiveIcon from '../../../assets/images/carrot-active.png';
import ChickenIcon from '../../../assets/images/chicken.png';
import ChickenActiveIcon from '../../../assets/images/chicken-active.png';
import Counter from './Counter';
import CartButton from './CartButton';
import { IProduct, Rate } from '../../../interfaces/Product';
import { defaultImage, meatMarketTypeId } from '../../../helpers/constants';
import Rating from 'react-rating';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import { addToCart, addToWishList } from '../../../actions/cartActions';
import { useHistory, useLocation } from 'react-router';
import { IoMdHeart, IoMdHeartEmpty } from 'react-icons/io';
import { useMediaQuery } from 'react-responsive';
import { useImageOnLoad } from 'usehooks-ts';

export type CardType = 'grid' | 'list';

interface CardProps {
    product: IProduct;
    type?: CardType;
    disableHover?: boolean;
    style?: React.CSSProperties;
    className?: string;
    isMeat?: boolean;
}

const Card = (props: CardProps) => {
    const { product, type, disableHover } = props;

    const [wishlisted, setWishlisted] = useState(product.has_wishlisted);

    const [animate, setAnimate] = useState(false);

    const [cartLoading, setCartLoading] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    const [height, setHeight] = useState<string | number>('auto');

    const { authenticated } = useSelector((state: StateType) => state.auth);

    const [unit, setUnit] = useState(1);

    const dispatch = useDispatch<AppDispatch>();

    const history = useHistory();

    const location = useLocation();

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const [selectedRate, setSelectedRate] = useState<Rate>();

    const { handleImageOnLoad, css } = useImageOnLoad();

    useEffect(() => {
        if (product?.rates?.length) {
            setSelectedRate(product.rates[0]);
        }
    }, [product?.rates]);

    const handleUnitChange = (value?: number) => {
        if (value) setUnit(value);
    };

    const handleAddToCart = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (authenticated) {
            if (selectedRate?._id && product._id) {
                setCartLoading(true);

                dispatch(
                    addToCart({
                        quantity: unit,
                        rate_id: selectedRate?._id,
                        product_id: product._id
                    })
                )
                    .then(() => {
                        setUnit(1);
                        setCartLoading(false);
                    })
                    .catch(() => {
                        setCartLoading(false);
                    });
            }
        } else {
            history.push('/login', {
                from: location
            });
        }
    };

    useEffect(() => {
        setHeight(ref.current?.clientHeight ?? 'auto');
    }, []);

    const isMeat =
        product.type?._id === meatMarketTypeId ||
        product.type?.parent_id?._id === meatMarketTypeId;

    return type !== 'list' ? (
        <div
            className={`position-relative cursor-pointer ${
                props.className ?? ''
            }`}
            style={{ height, ...props.style }}
            onClick={() => history.push(`/products/${product.slug}`)}
            ref={ref}
        >
            <div
                className={`p-card-sm product-card-container active-card ${
                    !disableHover
                        ? `product-card-hover ${
                              props.isMeat ? 'product-card-meat-hover' : ''
                          }`
                        : ''
                } ${props.isMeat ? 'product-card-meat' : ''}`}
            >
                <div className="product-card-inner">
                    <div className="product-card-header">
                        {product.in_stock ? (
                            product?.discount?.is_active && (
                                <span className="offer">
                                    {product?.discount?.discount_percentage}%
                                    Off
                                </span>
                            )
                        ) : (
                            <span className="nostock-pill">Out of stock</span>
                        )}
                        <div className="product-card-img">
                            <img
                                onLoad={handleImageOnLoad}
                                style={css.fullSize}
                                src={
                                    product?.images?.length
                                        ? product?.images[0]
                                        : defaultImage
                                }
                                alt=""
                            />
                        </div>
                        <div
                            className="product-bookmark"
                            onClick={(e) => {
                                e.stopPropagation();

                                if (authenticated) {
                                    if (wishlisted) {
                                        setWishlisted(false);

                                        if (product._id)
                                            dispatch(addToWishList(product._id))
                                                .then(() => {})
                                                .catch(() => {
                                                    setWishlisted(true);
                                                });
                                    } else {
                                        setWishlisted(true);

                                        setAnimate(true);

                                        if (product._id)
                                            dispatch(addToWishList(product._id))
                                                .then(() => {
                                                    setAnimate(false);
                                                })
                                                .catch(() => {
                                                    setAnimate(false);
                                                    setWishlisted(false);
                                                });
                                    }
                                } else {
                                    history.push('/login', {
                                        from: location
                                    });
                                }
                            }}
                        >
                            {wishlisted ? (
                                <IoMdHeart
                                    size={24}
                                    className={`${
                                        props.isMeat
                                            ? 'text-meat-dark'
                                            : 'text-orange'
                                    } ${animate ? 'wishlist-animate' : ''}`}
                                />
                            ) : (
                                <IoMdHeartEmpty
                                    size={24}
                                    className="text-gray"
                                />
                            )}
                        </div>
                    </div>
                    <div className="product-card-body">
                        <span className="product-category">
                            {product?.category?.name}
                        </span>
                        <h2 className="product-title">{product?.name}</h2>
                        <p className="product-code-section">
                            Product Code:{' '}
                            <span className="product-code">
                                {product?.code}
                            </span>
                        </p>
                        <div className="product-card-footer d-flex align-items-end mb-2">
                            {product?.rates?.length ? (
                                <div className="product-price-section flex-grow-1">
                                    <span className="product-old-price set-height">
                                        {product?.discount?.is_active
                                            ? `Rs. ${product?.rates[0].price}`
                                            : ''}
                                    </span>

                                    <p className="product-new-price">
                                        <span>
                                            {product.rates[0].discounted_price}
                                        </span>
                                        Rs / {product.rates[0].quantity}{' '}
                                        {product.rates[0].unit_id?.name}
                                    </p>
                                </div>
                            ) : (
                                <div className="flex-grow-1" />
                            )}

                            <CartButton
                                isMeat={props.isMeat}
                                className="add-to-cart d-none d-lg-flex"
                                onClick={handleAddToCart}
                                disabled={!product?.in_stock || cartLoading}
                                loading={cartLoading}
                                size={isDesktopOrLaptop ? 'lg' : 'sm'}
                            />
                        </div>
                        <div className="active">
                            <div className="d-flex justify-content-between align-items-end">
                                <Counter
                                    isMeat={props.isMeat}
                                    className="me-3"
                                    disabled={!product?.in_stock}
                                    max={!product?.in_stock ? 1 : undefined}
                                    min={1}
                                    onChange={handleUnitChange}
                                    value={unit}
                                    size={isDesktopOrLaptop ? 'lg' : 'sm'}
                                    style={{
                                        height: isDesktopOrLaptop
                                            ? undefined
                                            : 32
                                    }}
                                />

                                <CartButton
                                    isMeat={props.isMeat}
                                    className="add-to-cart-btm"
                                    onClick={handleAddToCart}
                                    disabled={!product?.in_stock || cartLoading}
                                    loading={cartLoading}
                                    size={isDesktopOrLaptop ? 'lg' : 'sm'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div
            className="p-card-list bg-light-gray-2 d-flex cursor-pointer"
            onClick={() => history.push(`/products/${product.slug}`)}
        >
            <img
                onLoad={handleImageOnLoad}
                style={css.fullSize}
                src={
                    product?.images?.length ? product?.images[0] : defaultImage
                }
                alt={product?.name}
                className="p-card-img m-3"
            />

            <div className="d-flex flex-column p-card-content py-3 pe-4 flex-grow-1">
                <p className="text-12 text-600 text-montserrat text-gray mb-0">
                    {product?.category?.name}
                </p>

                <div className="d-flex align-items-center">
                    <p
                        className="text-gray-1 text-600 text-inter text-24 mb-0 me-5 w-100"
                        style={{ maxWidth: 365 }}
                    >
                        {product?.name}
                    </p>

                    {wishlisted ? (
                        <IoMdHeart
                            size={24}
                            className={`${
                                props.isMeat ? 'text-meat-dark' : 'text-orange'
                            } ${animate ? 'wishlist-animate' : ''}`}
                            onClick={(e) => {
                                e.stopPropagation();

                                setWishlisted(false);

                                if (authenticated) {
                                    if (product._id)
                                        dispatch(addToWishList(product._id))
                                            .then(() => {})
                                            .catch(() => {
                                                setWishlisted(true);
                                            });
                                } else {
                                    history.push('/login', {
                                        from: location
                                    });
                                }
                            }}
                        />
                    ) : (
                        <IoMdHeartEmpty
                            size={24}
                            className="text-gray"
                            onClick={(e) => {
                                e.stopPropagation();

                                setWishlisted(true);

                                setAnimate(true);

                                if (authenticated) {
                                    if (product._id)
                                        dispatch(addToWishList(product._id))
                                            .then(() => {
                                                setAnimate(false);
                                            })
                                            .catch(() => {
                                                setAnimate(false);
                                                setWishlisted(false);
                                            });
                                } else {
                                    history.push('/login', {
                                        from: location
                                    });
                                }
                            }}
                        />
                    )}
                </div>

                <div className="mb-3 d-flex align-items-center">
                    <p className="text-montserrat text-14 text-gray-3 me-3 mb-0">
                        Product Code:{' '}
                        <span className="text-roboto text-gray-1">
                            {product?.code}
                        </span>
                    </p>

                    <div className="d-flex align-items-center">
                        <Rating
                            emptySymbol={
                                <img
                                    src={isMeat ? ChickenIcon : CarrotIcon}
                                    alt=""
                                />
                            }
                            fullSymbol={
                                <img
                                    src={
                                        isMeat
                                            ? ChickenActiveIcon
                                            : CarrotActiveIcon
                                    }
                                    alt=""
                                />
                            }
                            fractions={2}
                            readonly
                            initialRating={product?.average_rating}
                            className="me-3"
                        />
                        <span className="text-14 text-roboto text-gray">
                            4 reviews
                        </span>
                    </div>
                </div>

                <p
                    className="text-gray-3 text-montserrat text-14 mb-0 text-line-3"
                    dangerouslySetInnerHTML={{ __html: product.detail ?? '' }}
                />
            </div>

            <div
                className="py-3 pe-3 mt-auto"
                style={{ width: 290, minWidth: 290 }}
            >
                {!!product.rates?.length && (
                    <div className="d-flex align-items-end justify-content-end mb-3 flex-wrap">
                        <span
                            className="text-strike text-gray-3 text-montserrat text-600 text-16 text-nowrap me-2 text-end"
                            style={{ width: 80 }}
                        >
                            {product?.discount?.is_active &&
                                `Rs. ${product?.rates[0].price}`}
                        </span>

                        <p className="text-inter text-600 text-dark-color text-24 mb-0">
                            <span>
                                Rs. {product?.rates[0].discounted_price} /{' '}
                                {product.rates[0].quantity}{' '}
                                {product.rates[0].unit_id?.name}
                            </span>
                        </p>
                    </div>
                )}

                <div className="d-flex align-items-end justify-content-end">
                    <Counter
                        isMeat={props.isMeat}
                        className="me-3"
                        disabled={!product?.in_stock}
                        max={!product?.in_stock ? 1 : undefined}
                        min={1}
                        onChange={handleUnitChange}
                        value={unit}
                    />

                    <CartButton
                        isMeat={props.isMeat}
                        onClick={handleAddToCart}
                        disabled={!product?.in_stock || cartLoading}
                        loading={cartLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default Card;
