import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import {
    GET_FAQ,
    GET_PRIVACY_POLICY,
    GET_RETURN_POLICY,
    GET_TERMS_AND_CONDITIONS,
    privacyPolicySlug,
    returnPolicySlug,
    termsConditionsSlug
} from '../helpers/constants';
import request from '../helpers/request';

export const getFAQ = () => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_FAQ,
        payload: { faqLoading: true }
    });
    request
        .getFAQ()
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_FAQ,
                payload: { faq: res.data, faqLoading: false }
            });
        })
        .catch(() => {
            dispatch({
                type: GET_FAQ,
                payload: { faqLoading: false }
            });
        });
};

export const getPrivacyPolicy = () => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_PRIVACY_POLICY,
        payload: { privacyPolicyLoading: true }
    });
    request
        .getPageBySlug(privacyPolicySlug)
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_PRIVACY_POLICY,
                payload: {
                    privacyPolicy: res.data?.description,
                    privacyPolicyLoading: false
                }
            });
        })
        .catch(() => {
            dispatch({
                type: GET_PRIVACY_POLICY,
                payload: { privacyPolicyLoading: false }
            });
        });
};

export const getTermsConditions = () => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_TERMS_AND_CONDITIONS,
        payload: { termsConditionsLoading: true }
    });
    request
        .getPageBySlug(termsConditionsSlug)
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_TERMS_AND_CONDITIONS,
                payload: {
                    termsConditions: res.data?.description,
                    termsConditionsLoading: false
                }
            });
        })
        .catch(() => {
            dispatch({
                type: GET_TERMS_AND_CONDITIONS,
                payload: { termsConditionsLoading: false }
            });
        });
};

export const getReturnPolicy = () => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_RETURN_POLICY,
        payload: { returnPolicyLoading: true }
    });
    request
        .getPageBySlug(returnPolicySlug)
        .then((res: AxiosResponse) => {
            dispatch({
                type: GET_RETURN_POLICY,
                payload: {
                    returnPolicy: res.data?.description,
                    returnPolicyLoading: false
                }
            });
        })
        .catch(() => {
            dispatch({
                type: GET_RETURN_POLICY,
                payload: { returnPolicyLoading: false }
            });
        });
};
