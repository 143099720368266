import {
    GET_ORDER_DETAIL,
    GET_SEARCH_HISTORY,
    GET_USER,
    GET_USER_DASHBOARD_ORDERS,
    GET_USER_ORDERS,
    GET_WALLET_BALANCE,
    GET_WALLET_STATEMENTS
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IOrder, IUserOrderResponse } from '../interfaces/Order';
import { ISearchHistory, IWallet } from '../interfaces/User';
import { IWalletResponse } from '../interfaces/Wallet';
import { IAuthUser } from './authReducers';

export interface IUserState {
    user: IAuthUser;
    dashboardOrders: IUserOrderResponse;
    dashboardOrdersLoading: boolean;
    orders: IUserOrderResponse;
    ordersLoading: boolean;
    order: IOrder;
    orderLoading: boolean;
    wallet: IWallet;
    statements: IWalletResponse;
    searchHistory: ISearchHistory[];
}

const initState: IUserState = {
    user: {},
    dashboardOrders: {
        orders: []
    },
    dashboardOrdersLoading: false,
    orders: {
        orders: []
    },
    ordersLoading: false,
    order: {},
    orderLoading: false,
    wallet: {},
    statements: {},
    searchHistory: []
};

const userReducer = (
    state = initState,
    { payload, type }: ActionType
): IUserState => {
    switch (type) {
        case GET_USER:
            return {
                ...state,
                user: payload
            };

        case GET_USER_DASHBOARD_ORDERS:
            return {
                ...state,
                ...payload
            };

        case GET_USER_ORDERS:
            return {
                ...state,
                ...payload
            };

        case GET_ORDER_DETAIL:
            return {
                ...state,
                ...payload
            };

        case GET_WALLET_BALANCE:
            return {
                ...state,
                wallet: payload
            };

        case GET_WALLET_STATEMENTS:
            return {
                ...state,
                statements: payload
            };

        case GET_SEARCH_HISTORY:
            return {
                ...state,
                searchHistory: payload
            };

        default:
            return state;
    }
};

export default userReducer;
