import { useEffect, useRef, useState } from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import { FiHeart } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import {
    addWishlistToCart,
    getCarts,
    getWishList
} from '../../../../actions/cartActions';
import { AppDispatch, StateType } from '../../../../reducers';
import AppButton from '../../shared/AppButton';
import Pagination from '../../shared/Pagination';
import WishListCard from './components/WishListCard';

const MyWishlist = () => {
    const { wishlist } = useSelector((state: StateType) => state.cart);

    const resultsPerPage = 4;

    const dispatch = useDispatch<AppDispatch>();

    const wishListEl = useRef<HTMLDivElement>(null);

    const [addToCartLoading, setAddToCartLoading] = useState(false);

    useEffect(() => {
        dispatch(getWishList({ resultsPerPage }));
    }, [dispatch]);

    const isMobile = useMediaQuery({ maxWidth: 768 });

    const handleAddToCart = () => {
        setAddToCartLoading(true);

        dispatch(addWishlistToCart())
            .then(() => {
                dispatch(getWishList({ resultsPerPage }));
                dispatch(getCarts());

                setAddToCartLoading(false);
            })
            .catch(() => setAddToCartLoading(false));
    };

    return (
        <div
            className={`wishlist-container ${
                isMobile ? 'app-container mb-5 mb-md-0' : ''
            }`}
            ref={wishListEl}
        >
            {isMobile ? (
                <div className="py-3 d-flex justify-content-between align-items-center">
                    <Link
                        to="/dashboard"
                        className="text-600 text-14 text-montserrat text-black"
                    >
                        <BiChevronLeft size={24} className="me-2 ms-n2" />
                        Wishlist
                    </Link>

                    <AppButton
                        type="primary"
                        className="px-4"
                        size="sm"
                        onClick={handleAddToCart}
                        loading={addToCartLoading}
                        disabled={addToCartLoading}
                    >
                        Add All To Cart
                    </AppButton>
                </div>
            ) : (
                <div className="d-flex align-items-center justify-content-between">
                    <div className="mb-3 me-3">
                        <span className="d-flex align-items-center text-inter text-20 text-500 text-dark-color">
                            <FiHeart size={24} className="me-2" />
                            Shopping Wishlist
                        </span>
                    </div>

                    {!!wishlist.lists.length && (
                        <div className="mb-3 d-flex justify-content-end align-items-center">
                            <AppButton
                                type="primary"
                                className="px-4"
                                size="sm"
                                onClick={handleAddToCart}
                                loading={addToCartLoading}
                                disabled={addToCartLoading}
                            >
                                Add All To Cart
                            </AppButton>
                        </div>
                    )}
                </div>
            )}

            {wishlist.lists.length ? (
                <div className="mx-n3 mx-md-0">
                    {wishlist.lists.map((list) => (
                        <WishListCard
                            isMobile={isMobile}
                            wishlist={list}
                            key={list._id}
                            onDelete={() =>
                                wishlist.currentPage &&
                                dispatch(
                                    getWishList({
                                        resultsPerPage,
                                        page:
                                            wishlist.lists.length === 1 &&
                                            wishlist.currentPage > 1
                                                ? wishlist.currentPage - 1
                                                : wishlist.currentPage
                                    })
                                )
                            }
                        />
                    ))}
                </div>
            ) : (
                <div className="py-3 px-4 bg-white rounded mb-3 mt-4">
                    <div className="d-flex flex-column text-center align-items-center text-black py-5">
                        <p className="text-inter text-600 text-24 mb-4">
                            Your wishlist is empty
                        </p>

                        <div
                            style={{
                                height: 100,
                                width: 100,
                                background: 'rgba(244, 123, 52, 0.2)'
                            }}
                            className="rounded-circle d-flex align-items-center justify-content-center"
                        >
                            <FiHeart size={50} className="text-orange" />
                        </div>

                        <p className="text-14 text-montserrat text-400 my-4">
                            You haven’t added anything to your wishlist. Add now
                        </p>

                        <Link to="/products">
                            <AppButton
                                className="px-4"
                                size={isMobile ? 'sm' : 'lg'}
                            >
                                Explore Products
                            </AppButton>
                        </Link>
                    </div>
                </div>
            )}

            {!!wishlist.totalPage && !!wishlist.currentPage && (
                <Pagination
                    totalPages={wishlist.totalPage}
                    currentPage={wishlist.currentPage}
                    className="mt-4"
                    onChange={(page) => {
                        wishListEl.current?.scrollIntoView({
                            behavior: 'smooth'
                        });

                        dispatch(getWishList({ resultsPerPage, page }));
                    }}
                />
            )}
        </div>
    );
};

export default MyWishlist;
