import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import { Carousel } from 'antd';
import AppButton from '../shared/AppButton';
import { useMediaQuery } from 'react-responsive';
import { nextArrow, prevArrow } from './../shared/Arrows';

interface IProps {}

const Hero = (props: IProps) => {
    const banners = useSelector((state: StateType) => state.home.banners);

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    return (
        <div className="app-hero">
            <Carousel
                autoplay
                arrows
                prevArrow={prevArrow({})}
                nextArrow={nextArrow({})}
                dots={{ className: 'hero-dot' }}
            >
                {banners.map((banner) => (
                    <div
                        key={banner._id}
                        className="hero-banner d-flex align-items-stretch"
                    >
                        <div
                            className="w-100 d-flex justify-content-center flex-column hero-bg position-relative"
                            style={{
                                background: `url(${banner.image_url})`
                            }}
                        >
                            <h2 className="hero-title text-inter text-dark-color text-700">
                                {banner.title} <br />
                                {banner.sub_title}
                            </h2>

                            <p
                                className="hero-subtitle text-montserrat text-dark-color text-500"
                                style={{ maxWidth: 406 }}
                            >
                                {banner.info}
                            </p>

                            <a href={banner.href}>
                                <AppButton
                                    className="px-4"
                                    size={isDesktopOrLaptop ? 'lg' : 'sm'}
                                >
                                    Explore Products
                                </AppButton>
                            </a>

                            {/* <img src={Veg} className="veg-img" alt="veg-img" /> */}
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default Hero;
