import { Card } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { defaultImage } from '../../../../../helpers/constants';
import request from '../../../../../helpers/request';
import { IWishlist } from '../../../../../interfaces/Wishlist';
import { showToast } from '../../../../../utils/toast';

interface IProps {
    wishlist: IWishlist;
    onDelete?: () => void;
    isMobile?: boolean;
}

const WishListCard = ({ wishlist, onDelete, isMobile }: IProps) => {
    const { product, _id } = wishlist;

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

    const [isDeleting, setIsDeleting] = useState(false);

    const handleOk = () => {
        if (_id) {
            setIsDeleting(true);

            request
                .deleteWishlist(_id)
                .then(() => {
                    onDelete && onDelete();

                    showToast('success', 'Product removed from your wishlist');

                    setIsDeleteModalVisible(false);

                    setIsDeleting(false);
                })
                .catch((err: AxiosError) => {
                    showToast(err.response?.data?.message);

                    setIsDeleting(false);
                });
        }
    };

    const handleCancel = () => {
        setIsDeleteModalVisible(false);
    };
    const showDeleteModal = () => {
        setIsDeleteModalVisible(true);
    };

    return (
        <>
            <div className="site-card-border-less-wrapper">
                <Card
                    bordered={false}
                    style={{ width: '100%' }}
                    className="position-relative"
                >
                    {!product?.in_stock && (
                        <span className="nostock-pill">Out of stock</span>
                    )}

                    <div
                        className="wishlist-container"
                        style={{
                            borderBottom: '1px solid #E0E0E0'
                        }}
                    >
                        <div className="d-flex py-4 px-3">
                            <Link
                                to={`/products/${product?.slug}`}
                                className="me-3"
                            >
                                <img
                                    src={
                                        product?.images?.length
                                            ? product?.images[0]
                                            : defaultImage
                                    }
                                    alt=""
                                    style={{
                                        width: isMobile ? 85 : 115,
                                        height: 85
                                    }}
                                    className="object-cover"
                                />
                            </Link>

                            <Link
                                to={`/products/${product?.slug}`}
                                className="flex-grow-1"
                            >
                                <ul className="list-group">
                                    <li className="product-category">
                                        <span>{product?.type?.name}</span>
                                    </li>
                                    <li className="product-title">
                                        {product?.name}
                                    </li>

                                    {!!product?.rates?.length && (
                                        <li className="product-price">
                                            <span>
                                                {
                                                    product.rates[0]
                                                        .discounted_price
                                                }
                                            </span>
                                            <span>
                                                Rs /{' '}
                                                {product.rates[0].quantity !== 1
                                                    ? `${product.rates[0].quantity} `
                                                    : ''}
                                                {product.rates[0].unit_id?.name}
                                            </span>

                                            {product.discount?.is_active && (
                                                <span>
                                                    Rs. {product.rates[0].price}
                                                </span>
                                            )}
                                        </li>
                                    )}
                                </ul>
                            </Link>

                            <div className="action-btn">
                                <button
                                    className="icon-btn"
                                    onClick={showDeleteModal}
                                >
                                    {' '}
                                    <AiOutlineDelete
                                        size={22}
                                        color="#828282"
                                    />{' '}
                                </button>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>

            <Modal
                title={
                    <>
                        <h4>Remove item from wishlist</h4>{' '}
                        <span>Delete this item</span>{' '}
                    </>
                }
                visible={isDeleteModalVisible}
                onOk={handleOk}
                confirmLoading={isDeleting}
                onCancel={handleCancel}
                okText="Yes! Remove it"
                cancelText="No! Don’t Remove"
                cancelButtonProps={{
                    style: {
                        border: '1px solid #F47B34',
                        color: '#F47B34',
                        width: '208px',
                        height: '55px',
                        borderRadius: '40px',
                        margin: '10px'
                    }
                }}
                okButtonProps={{
                    style: {
                        border: '1px solid #F47B34',
                        color: '#fff',
                        width: '208px',
                        height: '55px',
                        borderRadius: '40px',
                        margin: '10px 0'
                    }
                }}
            >
                {' '}
                <p>
                    Are you sure you want to remove this item from your wish
                    list? Dont worry you can add new item to list anytime you
                    feel like adding.
                </p>
            </Modal>
        </>
    );
};

export default WishListCard;
