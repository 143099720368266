import { Breadcrumb, Col, Drawer, Image, Row } from 'antd';
import AppButton from '../../components/shared/AppButton';
import MainLayout from '../../layouts/MainLayout';
import Counter from '../../components/shared/Counter';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import { defaultImage } from '../../../helpers/constants';
import {
    deleteCart,
    getCarts,
    resetCart,
    updateCartUnit
} from '../../../actions/cartActions';
import { Link } from 'react-router-dom';
import { Cart as CartIcon } from '../../../assets/images/icons';
import PromoCode from '../../components/shared/PromoCode';
import { useEffect, useState } from 'react';
import { getWalletBalance } from '../../../actions/userActions';
import AppSpinner from '../../components/shared/AppSpinner';
import { useMediaQuery } from 'react-responsive';
import { BiChevronLeft } from 'react-icons/bi';
import Item from '../../components/shared/Item';

interface IProps {}

const Cart = (props: IProps) => {
    const {
        cartsLoading,
        carts,
        delivery_fee,
        total_amount,
        sub_total,
        coupon_discount_amount,
        wallet_adjustment
    } = useSelector((state: StateType) => state.cart);

    const { coupon } = useSelector((state: StateType) => state.coupon);

    const freeDeliveryAmount = useSelector(
        (state: StateType) =>
            state.payment.deliveryCharge.free_delivery_min_order_amt
                ?.inside_ringroad
    );

    const { selectedDeliveryAddress } = useSelector(
        (state: StateType) => state.deliveryAddress
    );

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch<AppDispatch>();

    const handleClearCart = async () => {
        setLoading(true);

        await dispatch(resetCart());

        setLoading(false);
    };

    useEffect(() => {
        dispatch(getCarts());
        dispatch(getWalletBalance());
    }, [dispatch, coupon?.code, selectedDeliveryAddress?._id]);

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    const isMd = useMediaQuery({ minWidth: 768 });

    return (
        <>
            {cartsLoading ||
                (loading && (
                    <div className="loader-overlay flex-column">
                        <span>Loading...</span>
                        <AppSpinner size="sm" />
                    </div>
                ))}
            <MainLayout hideFooter={!isMd}>
                {isDesktopOrLaptop ? (
                    <div className="bg-white">
                        <Breadcrumb className="app-container py-2">
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                            <Breadcrumb.Item className="text-orange">
                                Cart
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                ) : (
                    <div className="bg-gray">
                        <div className="py-3 app-container">
                            <Link
                                to="/products"
                                className="text-600 text-14 text-montserrat text-black"
                            >
                                <BiChevronLeft
                                    size={24}
                                    className="me-2 ms-n2"
                                />
                                My Cart({carts.length} Items)
                            </Link>
                        </div>
                    </div>
                )}

                <div className="bg-gray pb-5 pb-md-0">
                    <div className="app-container pb-4">
                        <Row className="pt-md-4 pb-5" gutter={20}>
                            <Col xs={24} md={14} lg={17} className="mb-5">
                                <div
                                    className={`py-0 py-lg-3 px-0 px-md-4 rounded-4 cart-table-container mb-3 ${
                                        !isMd ? 'bg-gray' : 'bg-white'
                                    }`}
                                >
                                    {carts.length ? (
                                        isDesktopOrLaptop ? (
                                            <table className="bg-white w-100 cart-table">
                                                <thead>
                                                    <tr>
                                                        <th className="py-2 pe-2">
                                                            Product
                                                        </th>
                                                        <th className="p-2">
                                                            Price
                                                        </th>
                                                        <th
                                                            className="py-2 pe-2"
                                                            style={{
                                                                paddingLeft:
                                                                    '3.3rem'
                                                            }}
                                                        >
                                                            Quantity
                                                        </th>
                                                        <th className="py-2 ps-2">
                                                            Sub Total
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {carts.map(
                                                        (cart, index) => (
                                                            <tr key={index}>
                                                                <td
                                                                    style={{
                                                                        width: 350
                                                                    }}
                                                                    className="py-2 pe-2"
                                                                >
                                                                    <Link
                                                                        to={`/products/${cart.product?.slug}`}
                                                                    >
                                                                        <div className="d-flex align-items-center">
                                                                            <Image
                                                                                width={
                                                                                    68
                                                                                }
                                                                                height={
                                                                                    91
                                                                                }
                                                                                src={
                                                                                    cart
                                                                                        .product
                                                                                        ?.images
                                                                                        ?.length
                                                                                        ? cart
                                                                                              .product
                                                                                              ?.images[0]
                                                                                        : ''
                                                                                }
                                                                                className="object-cover"
                                                                                preview={
                                                                                    false
                                                                                }
                                                                                fallback={
                                                                                    defaultImage
                                                                                }
                                                                            />

                                                                            <div className="ps-3 flex-grow-1">
                                                                                <p className="text-montserrat text-16 text-400 text-dark-color mb-0">
                                                                                    {
                                                                                        cart
                                                                                            .product
                                                                                            ?.name
                                                                                    }
                                                                                    {
                                                                                        cart
                                                                                            .product
                                                                                            ?.category
                                                                                            ?.name
                                                                                    }{' '}
                                                                                    -{' '}
                                                                                    {
                                                                                        cart
                                                                                            .product
                                                                                            ?.vendor
                                                                                            ?.name
                                                                                    }
                                                                                </p>
                                                                                <span className="text-montserrat text-14 text-400 text-gray-2">
                                                                                    Weight:{' '}
                                                                                    {
                                                                                        cart.quantity
                                                                                    }
                                                                                    {
                                                                                        cart
                                                                                            .rate
                                                                                            ?.unit_id
                                                                                            ?.name
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </td>

                                                                <td className="p-2">
                                                                    <span className="text-montserrat text-16 text-600 text-dark-color">
                                                                        Rs.{' '}
                                                                        {cart
                                                                            .rate
                                                                            ?.discounted_price ??
                                                                            cart
                                                                                .rate
                                                                                ?.price ??
                                                                            0}
                                                                    </span>
                                                                </td>

                                                                <td className="p-2">
                                                                    <Counter
                                                                        value={
                                                                            cart.quantity
                                                                        }
                                                                        onChange={async (
                                                                            value
                                                                        ) => {
                                                                            if (
                                                                                cart._id
                                                                            ) {
                                                                                setLoading(
                                                                                    true
                                                                                );

                                                                                await dispatch(
                                                                                    updateCartUnit(
                                                                                        cart._id,
                                                                                        {
                                                                                            quantity:
                                                                                                value
                                                                                        }
                                                                                    )
                                                                                );
                                                                                setLoading(
                                                                                    false
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                </td>

                                                                <td className="py-2 ps-2">
                                                                    <span className="text-montserrat text-16 text-600 text-dark-color">
                                                                        Rs.{' '}
                                                                        {(cart
                                                                            .rate
                                                                            ?.discounted_price ??
                                                                            cart
                                                                                .rate
                                                                                ?.price ??
                                                                            0) *
                                                                            (cart.quantity ??
                                                                                0)}
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    <div className="d-flex justify-content-end">
                                                                        <AiFillCloseCircle
                                                                            className="text-gray-3 me-2 cursor-pointer"
                                                                            size={
                                                                                24
                                                                            }
                                                                            onClick={async () => {
                                                                                if (
                                                                                    cart._id
                                                                                ) {
                                                                                    setLoading(
                                                                                        true
                                                                                    );
                                                                                    await dispatch(
                                                                                        deleteCart(
                                                                                            cart._id
                                                                                        )
                                                                                    );
                                                                                    setLoading(
                                                                                        false
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className="mx-n3">
                                                {carts.map((cart, index) => (
                                                    <Item
                                                        key={index}
                                                        item={cart}
                                                    />
                                                ))}
                                            </div>
                                        )
                                    ) : (
                                        <div className="d-flex flex-column text-center align-items-center text-black py-5">
                                            <p className="text-inter text-600 text-24 mb-4">
                                                Your Cart is Empty
                                            </p>

                                            <CartIcon />

                                            <p className="text-14 text-montserrat text-400 my-4">
                                                You haven’t ordered anything.
                                                order now?
                                            </p>

                                            <Link to="/products">
                                                <AppButton className="px-4">
                                                    Explore Products
                                                </AppButton>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                                {carts.length ? (
                                    <div className="d-flex">
                                        <div className="flex-grow-1 me-3">
                                            {isMd && (
                                                <Link to="/products">
                                                    <AppButton
                                                        size="sm"
                                                        type="primary-outlined"
                                                    >
                                                        Continue Shopping
                                                    </AppButton>
                                                </Link>
                                            )}
                                        </div>

                                        <div className="d-flex">
                                            <div
                                                className="d-flex align-items-center cursor-pointer me-4"
                                                onClick={handleClearCart}
                                            >
                                                <AiFillCloseCircle
                                                    className="text-dark-color me-2 cursor-pointer"
                                                    size={20}
                                                />
                                                <span className="text-500 text-16 text-roboto text-gray-3">
                                                    Clear Cart
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </Col>

                            <Col xs={24} md={10} lg={7}>
                                {!isMd ? (
                                    !!carts.length && (
                                        <div className="bg-gray py-3">
                                            <div className="d-flex align-items-center mb-3">
                                                <span className="text-montserrat text-600 text-16 text-dark-color me-2">
                                                    Sub Total
                                                </span>
                                                <span className="text-montserrat text-600 text-16 text-dark-color flex-grow-1 text-end">
                                                    Rs. {sub_total}
                                                </span>
                                            </div>

                                            <div className="d-flex align-items-center mb-3">
                                                <div className="me-2">
                                                    <p className="text-montserrat text-400 text-16 text-dark-1 mb-0">
                                                        Delivery Charge
                                                    </p>

                                                    {(selectedDeliveryAddress?.inside_ringroad ||
                                                        !selectedDeliveryAddress) && (
                                                        <span className="text-montserrat text-400 text-10 text-dark-1 d-inline-block">
                                                            (Free delivery
                                                            inside
                                                            <br />
                                                            ringroad for over
                                                            Rs.
                                                            {freeDeliveryAmount}
                                                            )
                                                        </span>
                                                    )}
                                                </div>

                                                <span className="text-montserrat text-400 text-16 text-dark-1 flex-grow-1 text-end">
                                                    Rs. {delivery_fee}
                                                </span>
                                            </div>

                                            {/* <WalletRedeem /> */}

                                            <PromoCode
                                                carts={carts.map(
                                                    (cart) => cart._id ?? ''
                                                )}
                                            />
                                        </div>
                                    )
                                ) : (
                                    <div className="bg-white rounded-4 order-summary-container d-flex flex-column">
                                        <div className="flex-grow-1">
                                            <p className="p-3 text-inter text-500 text-16 text-gray mb-0">
                                                Order Summary
                                            </p>

                                            <hr className="m-0 bg-border" />

                                            <div className="p-3">
                                                {carts.length ? (
                                                    <>
                                                        <div className="d-flex align-items-center mb-3">
                                                            <span className="text-montserrat text-600 text-16 text-dark-color me-2">
                                                                Sub Total
                                                            </span>
                                                            <span className="text-montserrat text-600 text-16 text-dark-color flex-grow-1 text-end">
                                                                Rs. {sub_total}
                                                            </span>
                                                        </div>

                                                        <div className="d-flex align-items-center mb-3">
                                                            <div className="me-2">
                                                                <p className="text-montserrat text-400 text-16 text-dark-color mb-0">
                                                                    Delivery
                                                                    Charge
                                                                </p>

                                                                {(selectedDeliveryAddress?.inside_ringroad ||
                                                                    !selectedDeliveryAddress) && (
                                                                    <span className="text-montserrat text-400 text-10 text-dark-color d-inline-block">
                                                                        (Free
                                                                        delivery
                                                                        inside
                                                                        <br />
                                                                        ringroad
                                                                        for over
                                                                        Rs.
                                                                        {
                                                                            freeDeliveryAmount
                                                                        }
                                                                        )
                                                                    </span>
                                                                )}
                                                            </div>

                                                            <span className="text-montserrat text-400 text-16 text-dark-1 flex-grow-1 text-end text-nowrap">
                                                                Rs.{' '}
                                                                {delivery_fee}
                                                            </span>
                                                        </div>

                                                        {/* <WalletRedeem /> */}

                                                        <PromoCode
                                                            carts={carts.map(
                                                                (cart) =>
                                                                    cart._id ??
                                                                    ''
                                                            )}
                                                        />
                                                    </>
                                                ) : (
                                                    <p className="text-montserrat text-400 text-16 text-black">
                                                        No items found
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        {carts.length ? (
                                            <div>
                                                {!!coupon_discount_amount && (
                                                    <div className="d-flex align-items-center px-3 mb-3">
                                                        <span className="text-montserrat text-400 text-16 text-gray-2 me-2">
                                                            Promo Code Discount
                                                        </span>

                                                        <span className="text-montserrat text-400 text-16 text-gray-2 flex-grow-1 text-end">
                                                            Rs.{' '}
                                                            {
                                                                coupon_discount_amount
                                                            }
                                                        </span>
                                                    </div>
                                                )}

                                                {!!wallet_adjustment && (
                                                    <div className="d-flex align-items-center px-3 mb-3">
                                                        <span className="text-montserrat text-400 text-16 text-gray-2 me-2">
                                                            Redeem Amount
                                                        </span>

                                                        <span className="text-montserrat text-400 text-16 text-gray-2 flex-grow-1 text-end">
                                                            Rs.{' '}
                                                            {wallet_adjustment}
                                                        </span>
                                                    </div>
                                                )}

                                                <hr className="m-0 bg-border" />

                                                <div className="p-3">
                                                    <div className="d-flex align-items-center mb-3">
                                                        <span className="text-montserrat text-600 text-18 text-gray-5 me-2">
                                                            Grand Total
                                                        </span>
                                                        <span className="text-montserrat text-600 text-18 text-gray-5 flex-grow-1 text-end">
                                                            Rs. {total_amount}
                                                        </span>
                                                    </div>

                                                    <Link to="/cart/checkout">
                                                        <AppButton
                                                            className="w-100"
                                                            size="sm"
                                                        >
                                                            Proceed to Checkout
                                                        </AppButton>
                                                    </Link>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>

                <Drawer
                    visible={!isMd}
                    placement="bottom"
                    closable={false}
                    mask={false}
                    height={100}
                >
                    <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column">
                            <span className="text-montserrat text-14 text-dark-color text-600 mb-1">
                                Grand Total
                            </span>
                            <span className="text-montserrat text-16 text-dark-color text-600">
                                Rs. {carts.length ? total_amount : 0}
                            </span>
                        </div>

                        <Link to="/cart/checkout">
                            <AppButton className="px-5" size="sm">
                                Checkout
                            </AppButton>
                        </Link>
                    </div>
                </Drawer>
            </MainLayout>
        </>
    );
};

export default Cart;
