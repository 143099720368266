import {
    GET_DELIVERY_CHARGE,
    INITIATE_ORDER_FAILURE,
    INITIATE_ORDER_REQUEST,
    INITIATE_ORDER_SUCCESS
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IDeliveryCharge, IOrderResponse } from '../interfaces/Payment';

export interface IPaymentState {
    order: IOrderResponse;
    initiateOrderLoading: boolean;
    initiateOrderSuccess: boolean;
    deliveryCharge: IDeliveryCharge;
}

const initialState: IPaymentState = {
    order: {},
    initiateOrderLoading: false,
    initiateOrderSuccess: false,
    deliveryCharge: {}
};

const paymentReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case INITIATE_ORDER_REQUEST:
            return {
                ...state,
                initiateOrderLoading: true,
                initiateOrderSuccess: false
            };

        case INITIATE_ORDER_SUCCESS:
            return {
                ...state,
                initiateOrderLoading: false,
                initiateOrderSuccess: true,
                order: action.payload
            };

        case INITIATE_ORDER_FAILURE:
            return {
                ...state,
                initiateOrderLoading: false,
                initiateOrderSuccess: false
            };

        case GET_DELIVERY_CHARGE:
            return {
                ...state,
                deliveryCharge: action.payload
            };

        default:
            return state;
    }
};

export default paymentReducer;
