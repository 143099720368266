import { useMediaQuery } from 'react-responsive';
import Footer from './Footer';
import NavBar from './NavBar';
import SubNavBar from './SubNavBar';

interface IProps {
    children: React.ReactNode;
    backgroundColor?: string;
    mainClassname?: string;
    hideFooter?: boolean;
}

const MainLayout = (props: IProps) => {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    return (
        <>
            <NavBar />

            {isDesktopOrLaptop && <SubNavBar />}

            <main
                style={{ backgroundColor: props.backgroundColor }}
                className={props.mainClassname}
            >
                {props.children}
            </main>

            {!props.hideFooter && <Footer />}
        </>
    );
};

export default MainLayout;
