import { useLocation } from 'react-router-dom';
import { $FIXME } from '../../../../helpers/constants';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../reducers';
import dayjs from 'dayjs';
import EditProfileForm from './components/EditProfileForm';
import { FiUser } from 'react-icons/fi';
import { useMediaQuery } from 'react-responsive';

const MyProfile = () => {
    const { gender, dob, phone, email, address } = useSelector(
        (state: StateType) => state.user.user
    );

    const location = useLocation();

    const query: $FIXME = queryString.parse(location.search);

    const edit = query?.profile === 'edit';

    const isMobile = useMediaQuery({ maxWidth: 768 });

    return edit || isMobile ? (
        <EditProfileForm />
    ) : (
        <div>
            <span className="d-flex align-items-center mb-3 text-inter text-20 text-500 text-dark-color">
                <FiUser className="me-2" size={24} />
                My Profile{edit && '/Edit Profile'}
            </span>

            <div className="rounded p-4 bg-white">
                <div className="mb-4">
                    <p className="text-montserrat text-600 text-18 text-gray mb-4">
                        Personal Details
                    </p>

                    <div className="d-flex flex-wrap">
                        <span className="text-montserrat text-400 text-gray-3 me-5 mb-3">
                            Gender:{' '}
                            <span className="text-600 text-gray">
                                {gender ?? 'N/A'}
                            </span>
                        </span>

                        <span className="text-montserrat text-400 text-gray-3 me-5 mb-3">
                            Date of Birth:{' '}
                            <span className="text-600 text-gray">
                                {dob ? dayjs(dob).format('YYYY/MM/DD') : 'N/A'}
                            </span>
                        </span>

                        <span className="text-montserrat text-400 text-gray-3 me-5 mb-3">
                            Phone Number:{' '}
                            <span className="text-600 text-gray">
                                {phone ?? 'N/A'}
                            </span>
                        </span>
                    </div>

                    <span className="text-montserrat text-400 text-gray-3 me-5">
                        Email:{' '}
                        <span className="text-600 text-gray">
                            {email ?? 'N/A'}
                        </span>
                    </span>
                </div>

                <hr className="mt-0 mb-4 text-light-gray" />

                <div>
                    <p className="text-montserrat text-600 text-18 text-gray mb-4">
                        Location
                    </p>

                    <div className="d-flex flex-wrap">
                        <div className="d-flex flex-column me-5 mb-3">
                            <span className="text-montserrat text-400 text-gray-3 mb-3">
                                Local Address:{' '}
                                <span className="text-600 text-gray">
                                    {address?.landmark ?? 'N/A'}
                                </span>
                            </span>

                            <span className="text-montserrat text-400 text-gray-3">
                                District:{' '}
                                <span className="text-600 text-gray">
                                    {address?.district ?? 'N/A'}
                                </span>
                            </span>
                        </div>

                        <div className="d-flex flex-column">
                            <span className="text-montserrat text-400 text-gray-3 mb-3">
                                Area:{' '}
                                <span className="text-600 text-gray">
                                    {address?.area ?? 'N/A'}
                                </span>
                            </span>

                            <span className="text-montserrat text-400 text-gray-3">
                                Province:{' '}
                                <span className="text-600 text-gray">
                                    {address?.province ?? 'N/A'}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyProfile;
