import { Link } from 'react-router-dom';
import FreshMeatImg from '../../../assets/images/fresh-meat.png';
import FreshVegImg from '../../../assets/images/fresh-veg.png';
import FreshFruitImg from '../../../assets/images/fresh-fruit.png';
import { fruitBasketTypeId, vegetableTypeId } from '../../../helpers/constants';
import SimpleBar from 'simplebar-react';

interface IProps {}

const CategoryOffer = (props: IProps) => {
    return (
        <div className="app-container offer-container-wrapper">
            <SimpleBar className="mx-n3">
                <div className="offer-box meat-box">
                    <div className="offer-box-inner d-flex flex-column justify-content-between">
                        <div>
                            <label
                                className="text-montserrat text-12 text-700 mb-0 mb-lg-2 text-meat-dark"
                                style={{ height: 15 }}
                            ></label>
                            <h2 className="meat-title">Meat Market</h2>
                            <p className="offer-sub-title">
                                Eat one per day - Be healthy
                            </p>
                        </div>
                        <Link to={`/meat-market`} className="meat-button">
                            Shop Now
                        </Link>
                    </div>
                    <div className="img-section">
                        <img src={FreshMeatImg} alt="" />
                    </div>
                </div>
                <div className="offer-box veg-box">
                    <div className="offer-box-inner d-flex flex-column justify-content-between">
                        <div>
                            <label
                                className="text-montserrat text-12 text-700 mb-0 mb-lg-2 text-light-gray"
                                style={{ height: 15 }}
                            >
                                FARMGRO SPECIAL
                            </label>
                            <h2 className="veg-title">Fresh Veggies</h2>
                            <p className="veg-sub-title">
                                Directly from our farm
                            </p>
                        </div>
                        <Link
                            to={`/products?type=${vegetableTypeId}`}
                            className="veg-button"
                        >
                            Shop Now
                        </Link>
                    </div>
                    <div className="img-section">
                        <img src={FreshVegImg} alt="" />
                    </div>
                </div>
                <div className="offer-box fruits-box">
                    <div className="offer-box-inner d-flex flex-column justify-content-between">
                        <div>
                            <label
                                className="text-montserrat text-12 text-700 mb-0 mb-lg-2 text-meat-dark"
                                style={{ height: 15 }}
                            ></label>
                            <h2 className="fruit-title">Seasonal Fruits</h2>
                            <p className="fruit-sub-title">
                                Get fresh and seasonal fruits
                            </p>
                        </div>
                        <Link
                            to={`/products?type=${fruitBasketTypeId}`}
                            className="fruit-button"
                        >
                            Shop Now
                        </Link>
                    </div>
                    <div className="img-section">
                        <img src={FreshFruitImg} alt="" />
                    </div>
                </div>
            </SimpleBar>
        </div>
    );
};

export default CategoryOffer;
