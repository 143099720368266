import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StateType } from '../../../reducers';
import { Col, Row } from 'antd';
import AppButton from '../shared/AppButton';
import { useMediaQuery } from 'react-responsive';

interface IProps {
    category: string;
    meat?: boolean;
}

const Promo = (props: IProps) => {
    const { offers } = useSelector((state: StateType) => state.home);

    const { category } = props;

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

    return offers.length ? (
        <div className={`app-container promo-container-wrapper ${category}`}>
            <div className="promo-header d-none d-lg-block">
                <p className="promo-title">Awesome Offers - Awesome Values</p>
            </div>

            <Row gutter={20} className="mb-n3">
                {offers.map((offer) => (
                    <Col xs={24} sm={12} className="mb-3">
                        <div
                            className="app-promo-card bg-light-gray-3 d-flex flex-column"
                            style={{
                                background: `linear-gradient(90deg, rgba(242, 242, 242, 0.97) 41.12%, rgba(242, 242, 242, 0.8) 54.7%, rgba(242, 242, 242, 0) 83.11%), url(${offer.cover})`
                            }}
                        >
                            <div className="flex-grow-1">
                                <h2 className="app-promo-title text-inter text-600 text-gray-1 mb-2">
                                    {offer.title}
                                </h2>

                                <p className="app-promo-info text-montserrat text-600 text-gray-1">
                                    {offer.info}
                                </p>
                            </div>

                            <Link to="/products">
                                <AppButton
                                    className="px-5"
                                    size={isDesktopOrLaptop ? 'lg' : 'sm'}
                                >
                                    Shop Now
                                </AppButton>
                            </Link>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    ) : (
        <></>
    );
};

export default Promo;
