import { Tabs } from 'antd';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    FiGift,
    FiGrid,
    FiHeart,
    FiLogOut,
    FiMapPin,
    FiPackage,
    FiUser
} from 'react-icons/fi';
import Dashboard from './contents/Dashboard';
import MyOrders from './contents/MyOrders';
import MyAddresses from './contents/MyAddresses';
import Wallet from './contents/Wallet';
import MyWishlist from './contents/MyWishlist';
import Cookies from 'universal-cookie';
import { $FIXME } from '../../../helpers/constants';
import MyOffers from './contents/MyOffers';
import MyProfile from './contents/MyProfile';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { DWallet } from '../../../assets/images/icons';
import { useMediaQuery } from 'react-responsive';

const cookies = new Cookies();

const Sidenav: React.FC<any> = (props) => {
    const { TabPane } = Tabs;

    const [activeKey, setactiveKey] = useState('0');

    const location = useLocation();

    const history = useHistory();

    const query: $FIXME = queryString.parse(location.search);

    const handleLogout = useCallback(() => {
        const cookiesNames = [
            'authUser',
            'authenticated',
            'token',
            'loginSource'
        ];
        for (const cookie in cookiesNames) {
            cookies.remove(cookiesNames[cookie], { path: '/' });
        }

        (window as $FIXME).location.reload();
    }, []);

    const sideNavItems = useMemo(
        () => [
            {
                icon: <FiGrid size={24} style={{ marginRight: '10px' }} />,
                title: 'Dashboard',
                content: <Dashboard />,
                params: 'view'
            },
            {
                icon: <FiUser size={24} style={{ marginRight: '10px' }} />,
                title: 'My Profile',
                content: <MyProfile />,
                params: 'profile'
            },
            {
                icon: <FiPackage size={24} style={{ marginRight: '10px' }} />,
                title: 'My Orders',
                content: <MyOrders />,
                params: 'orders'
            },
            {
                icon: <DWallet fontSize={24} style={{ marginRight: '10px' }} />,
                title: 'My Wallet',
                content: <Wallet />,
                params: 'wallet'
            },
            {
                icon: <FiGift size={24} style={{ marginRight: '10px' }} />,
                title: 'Special Offers',
                content: <MyOffers />,
                params: 'offers'
            },
            {
                icon: <FiHeart size={24} style={{ marginRight: '10px' }} />,
                title: 'Shopping Wishlist',
                content: <MyWishlist />,
                params: 'wishlist'
            },
            {
                icon: <FiMapPin size={24} style={{ marginRight: '10px' }} />,
                title: 'My Addresses',
                content: <MyAddresses />,
                params: 'address'
            },
            {
                icon: <FiLogOut size={24} style={{ marginRight: '10px' }} />,
                title: 'Logout',
                content: '',
                action: handleLogout,
                params: 'logout'
            }
        ],
        [handleLogout]
    );

    useEffect(() => {
        sideNavItems.forEach((item, index) => {
            if (item.params) {
                if (item.params in query) {
                    setactiveKey(index.toString());
                }
            } else {
                setactiveKey(index.toString());
            }
        });
    }, [query, sideNavItems]);

    const changeTab = (key: string, action: any) => {
        if (action) {
            action();
        } else {
            history.push({
                pathname: '/dashboard',
                search: sideNavItems[parseInt(key)].params
            });
        }
    };

    const isMobile = useMediaQuery({ maxWidth: 768 });

    const page = sideNavItems.find(
        (item) => !!item.params && item.params in query
    );

    return isMobile ? (
        page ? (
            <div>{page.content}</div>
        ) : (
            <div className="app-container">
                {sideNavItems.map((navItem, index) => (
                    <div
                        key={index}
                        className="mobile-sidenav cursor-pointer text-hover-orange"
                        onClick={() => {
                            if (navItem.action) {
                                navItem.action();
                            } else {
                                history.push({
                                    pathname: '/dashboard',
                                    search: navItem.params
                                });
                            }
                        }}
                    >
                        <p className="mb-0">
                            {navItem.icon}
                            {navItem.title}
                        </p>
                    </div>
                ))}
            </div>
        )
    ) : (
        <div
            className="app-container subnav-container"
            style={{ background: 'none', margin: '60px auto' }}
        >
            <Tabs
                tabPosition="left"
                activeKey={activeKey}
                onChange={(e: string) =>
                    changeTab(e, sideNavItems[parseInt(e)].action)
                }
            >
                {sideNavItems.map((item, index) => {
                    return item.action ? (
                        <TabPane
                            tab={
                                <span
                                    className="nav-title"
                                    onClick={handleLogout}
                                >
                                    {item.icon}
                                    {item.title}
                                </span>
                            }
                            key={`${index}`}
                        >
                            {item.content}
                        </TabPane>
                    ) : (
                        <TabPane
                            tab={
                                <span className="nav-title">
                                    {item.icon}
                                    {item.title}
                                </span>
                            }
                            key={index}
                        >
                            {item.content}
                        </TabPane>
                    );
                })}
            </Tabs>
        </div>
    );
};

export default Sidenav;
