import {
    GET_AREAS_BY_DISTRICT,
    GET_DISTRICTS,
    GET_PROVINCES
} from '../helpers/constants';
import { ActionType } from '../interfaces/ActionType';
import { IArea, IDistrict } from '../interfaces/Address';

export interface IAddressState {
    provinces: Array<string>;
    districts: Array<IDistrict>;
    areas: Array<IArea>;
}

const initialState: IAddressState = {
    provinces: [],
    districts: [],
    areas: []
};

const addressReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case GET_PROVINCES:
            return {
                ...state,
                provinces: action.payload
            };

        case GET_DISTRICTS:
            return {
                ...state,
                districts: action.payload
            };

        case GET_AREAS_BY_DISTRICT:
            return {
                ...state,
                areas: action.payload
            };

        default:
            return state;
    }
};

export default addressReducer;
