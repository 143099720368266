import { useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { ReactComponent as MobileIcon } from '../../../assets/images/bi_phone.svg';
import { ReactComponent as UserIcon } from '../../../assets/images/ci_user.svg';
import { ReactComponent as EnvelopIcon } from '../../../assets/images/carbon_email.svg';
import { ISignUp } from '../../../interfaces/User';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { nameRegExp, phoneRegExp } from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, StateType } from '../../../reducers';
import { register } from '../../../actions/authActions';
import AppButton from '../../components/shared/AppButton';
import AuthLayout from '../../layouts/AuthLayout';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';

interface IProps {}

const Register = (props: IProps) => {
    const dispatch = useDispatch<AppDispatch>();

    const history = useHistory();

    const { authenticated, registerError, registerLoading } = useSelector(
        (state: StateType) => state.auth
    );

    const [termsAccepted, setTermsAccepeted] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const isMobile = useMediaQuery({ maxWidth: 576 });

    const initialValues: ISignUp = {
        name: '',
        email: '',
        password: '',
        phone: '',
        password_confirmation: ''
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .matches(nameRegExp, 'Please enter your full name')
            .required('Name is required'),
        email: Yup.string()
            .trim()
            .email('Email is invalid')
            .required('Email is required'),
        password: Yup.string()
            .trim()
            .required('Password is required')
            .min(8, 'Must be of 8 characters'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Both password must match')
            .required('Confirm Password is required'),
        phone: Yup.string()
            .trim()
            .required('Phone number is required')
            .min(10, 'Phone must be of 10 digits')
            .matches(phoneRegExp, 'Phone number is not valid')
    });

    if (authenticated) {
        return <Redirect to="/" />;
    }

    return (
        <AuthLayout>
            <div className="login-form">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    isInitialValid={false}
                    onSubmit={(values) => {
                        dispatch(register(values)).then(() => {
                            history.push(`/otp-verify?phone=${values.phone}`);
                        });
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        isValid,
                        errors,
                        touched
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <h2 className="form-header">Sign Up</h2>

                            <div className="form-field">
                                <label htmlFor="">Full Name*</label>

                                <div className="position-relative">
                                    <input
                                        type="text"
                                        placeholder="e.g: John Doe"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <UserIcon />
                                </div>
                            </div>

                            {touched.name && errors.name ? (
                                <p className="text-danger mb-0 mt-2 text-12">
                                    {errors.name}
                                </p>
                            ) : (
                                ''
                            )}

                            <div className="form-field">
                                <label htmlFor="">Email Address*</label>

                                <div className="position-relative">
                                    <input
                                        type="text"
                                        placeholder="email@domain.com"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <EnvelopIcon />
                                </div>
                            </div>

                            {touched.email && errors.email ? (
                                <p className="text-danger mb-0 mt-2 text-12">
                                    {errors.email}
                                </p>
                            ) : (
                                ''
                            )}

                            <div className="form-field">
                                <label htmlFor="">Phone Number*</label>

                                <div className="position-relative">
                                    <input
                                        type="text"
                                        placeholder="0987654321"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        maxLength={10}
                                        onBlur={handleBlur}
                                    />
                                    <MobileIcon />
                                </div>
                            </div>

                            {touched.phone && errors.phone ? (
                                <p className="text-danger mb-0 mt-2 text-12">
                                    {errors.phone}
                                </p>
                            ) : (
                                ''
                            )}

                            <div className="form-field">
                                <label htmlFor="">Password*</label>

                                <div className="position-relative">
                                    <input
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        placeholder="************"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    {showPassword ? (
                                        <AiOutlineEye
                                            size={24}
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        />
                                    ) : (
                                        <AiOutlineEyeInvisible
                                            size={24}
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        />
                                    )}
                                </div>
                            </div>

                            {touched.password && errors.password ? (
                                <p className="text-danger mb-0 mt-2 text-12">
                                    {errors.password}
                                </p>
                            ) : (
                                ''
                            )}

                            <div className="form-field">
                                <label htmlFor="">Confirm Password*</label>

                                <div className="position-relative">
                                    <input
                                        type={
                                            showConfirmPassword
                                                ? 'text'
                                                : 'password'
                                        }
                                        placeholder="************"
                                        name="password_confirmation"
                                        value={values.password_confirmation}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    {showConfirmPassword ? (
                                        <AiOutlineEye
                                            size={24}
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setShowConfirmPassword(
                                                    !showConfirmPassword
                                                )
                                            }
                                        />
                                    ) : (
                                        <AiOutlineEyeInvisible
                                            size={24}
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setShowConfirmPassword(
                                                    !showConfirmPassword
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            </div>

                            {touched.password_confirmation &&
                            errors.password_confirmation ? (
                                <p className="text-danger mb-0 mt-2 text-12">
                                    {errors.password_confirmation}
                                </p>
                            ) : (
                                ''
                            )}

                            {registerError ? (
                                <p className="text-12 text-danger mt-2 mb-0">
                                    {registerError}
                                </p>
                            ) : (
                                ''
                            )}

                            <div className="form-field checkbox-field">
                                <input
                                    type="checkbox"
                                    name="allow"
                                    checked={termsAccepted}
                                    onChange={(e) =>
                                        setTermsAccepeted(e.target.checked)
                                    }
                                />
                                <p className="checkbox-text">
                                    I agree to all the{' '}
                                    <Link to="/terms-and-conditions">
                                        <span>Terms & Condition</span>
                                    </Link>{' '}
                                </p>
                            </div>

                            <AppButton
                                className="w-100"
                                disabled={
                                    !(isValid && termsAccepted) ||
                                    registerLoading
                                }
                                htmlType="submit"
                                loading={registerLoading}
                                size={isMobile ? 'sm' : 'lg'}
                            >
                                Sign Up
                            </AppButton>
                        </form>
                    )}
                </Formik>
                <hr className="horizontal-l" />
                <div className="forget-link">
                    <p>
                        I already have an account?
                        <Link to="/login"> Sign In Now</Link>
                    </p>
                </div>
            </div>
        </AuthLayout>
    );
};

export default Register;
